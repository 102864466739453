import { HttpErrorResponse } from '@angular/common/http';

export const getErrorMessage = (
  e: HttpErrorResponse,
  defaultError: string = 'There was an error, please try again.'
) => {
  console.log(e);
  switch (e.status) {
    // TODO: handle all error cases on backend - this is a temp solution for cases where the server does not respond with an error formatted for the user
    case 500:
      return 'There was an error on the server, please contact support.';
    case 400:
      return 'There was an issue with the request, please contact support.';
    case 405:
      return 'METHOD NOT ALLOWED';

    default:
      if (e.error && e.error.errors) {
        const errorTitle = e.error.title;
        if (errorTitle.endsWith('.')) {
          errorTitle.slice(0, -1);
        }

        const errorMessages = Object.keys(e.error.errors)
          .map((key) => {
            return e.error.errors[key][0];
          })
          .join(',');

        let errorMessage = errorTitle
          ? `${errorTitle}: ${errorMessages}`
          : errorMessages;

        return errorMessage;
      }

      // intentional fall through
      if (typeof e === 'string') {
        return e;
      }
      return defaultError;
  }
};
