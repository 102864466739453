import { UserImageData } from '../../user/image/userImageData';
import { UserImagePdsEntry } from '../../user/image/userImagePdsEntry';

export class UserImageUpdateRequest {
  imageId: string;
  description: string;

  constructor(data?: Partial<UserImageUpdateRequest>) {
    this.imageId = data?.imageId ?? '';
    this.description = data?.description ?? '';
  }

  public static fromUserImagePdsEntry(
    image: UserImagePdsEntry
  ): UserImageUpdateRequest {
    return new UserImageUpdateRequest({
      imageId: image.imageId,
      description: image.description,
    });
  }
}
