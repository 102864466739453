import { Validators } from '@angular/forms';
import {
  FormItemCheckbox,
  FormItemDisplayLogic,
  FormItemDropdown,
  FormItemRepeater,
  FormItemTitle,
} from 'src/app/models/form/form';
import { getOptionsForFinderPathSelection } from 'src/assets/finder-nodes/finder.nodes.collection';

// -------------------------------------------- TITLE --------------------------------------------

export const displayConditionsFormTitle = new FormItemTitle({
  label: 'Display Settings',
  class: 'mt-6 mb-2',
});

// -------------------------------------------- FINDER PATHS --------------------------------------------

export const finderPaths = new FormItemRepeater({
  label: 'Finder Paths',
  key: 'finderPath',
  path: 'finderPaths',
  class: 'mb-5',
  formItems: [
    new FormItemDropdown({
      label: '',
      placeholder: 'Please select...',
      options: getOptionsForFinderPathSelection(),
      validators: [Validators.minLength(1), Validators.maxLength(100)],
    }),
  ],
});

// -------------------------------------------- PUBLIC DISPLAY --------------------------------------------

export const displayInPublicFinder = new FormItemCheckbox({
  key: 'displayInPublicFinder',
  label: 'Display in public finder',
  help: 'If checked, this connection will be displayed to all users, even if they are not logged in.',
  path: 'displayInPublicFinder',
  value: true,
  class: 'mb-5',
  // width: 'half',
});

// -------------------------------------------- CRITERIA --------------------------------------------

export const displayLogic = new FormItemDisplayLogic({
  key: 'displayLogic',
  path: 'displayLogic',
  class: 'mt-6',
});
