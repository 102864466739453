<div class="home-container flex h-full flex-1">
  <div class="h-full w-full container-sm title-labels">
    <!-- TITLE -->
    <h3 class="text-2xl italic font-thin opacity-80">
      Understanding the support you give
    </h3>
    <!-- END TITLE -->
    <div
      class="bg-white w-full mt-4 rounded p-16 box-border carer-questions-wrapper xl-labels"
    >
      <!-- STEP: FORM -->
      <ng-container *ngIf="step === 'form'">
        <!-- INTRO: AUTHED USER -->
        <p class="mb-14" *ngIf="authService.isAuthenticated$ | async">
          Below are the questions to understand the support you provide. We have
          added the details we already have but please fill in anything missing.
          We will also add any new details to your
          <strong>About Me</strong> when you click on 'Submit'.
        </p>
        <!-- END INTRO: AUTHED USER -->

        <!-- INTRO: UNAUTHED USER -->
        <p class="mb-14" *ngIf="!(authService.isAuthenticated$ | async)">
          Below are the questions to understand the support you provide. After
          you complete the questionnaire you can create an
          <strong>About Me</strong>. If you do, the entered details about your
          role as carer will be added for the initial set up but can always be
          changed again later.
        </p>
        <!-- END INTRO: UNAUTHED USER -->

        <app-form
          #carerForm
          [formData]="unpaidCarerForm"
          [formValues]="user"
          (submit)="saveUnpaidCarerForm($event)"
          submitText="Submit"
          [loading]="userService.loading$ | async"
          [cancellable]="false"
        >
        </app-form>
      </ng-container>
      <!-- END STEP: FORM -->

      <!-- STEP: RESULTS -->
      <ng-container *ngIf="step === 'results'">
        <div class="flex flex-col">
          <!-- VERIFIED USER: ABOUT ME INFO -->
          <!-- VERIFIED USER: LOADING SPINNER -->
          <app-loading-spinner
            *ngIf="
              pdsConnectionStatus?.isValid &&
              !(userService.savingError$ | async) &&
              (userService.saving$ | async)
            "
            text="Updating your About Me"
            class="mb-6 text-theme"
          >
          </app-loading-spinner>
          <!-- END VERIFIED USER: LOADING SPINNER -->

          <!-- VERIFIED USER: SUCCESS MESSAGE -->
          <div
            *ngIf="
              pdsConnectionStatus?.isValid &&
              !(userService.savingError$ | async) &&
              !(userService.saving$ | async)
            "
          >
            <p class="mb-6 mt-0 text-theme">
              We've updated your <strong>About Me</strong> with any new
              information.
            </p>
          </div>
          <!-- END VERIFIED USER: SUCCESS MESSAGE -->

          <!-- VERIFIED USER: ERROR MESSAGE -->
          <div
            *ngIf="
              pdsConnectionStatus?.isValid &&
              (userService.savingError$ | async) &&
              !(userService.saving$ | async)
            "
          >
            <p class="mb-6 mt-0 text-error-red">
              Unfortunately, an issue occured and we were unable to update your
              <strong>About Me</strong>.
            </p>
          </div>
          <!-- END VERIFIED USER: ERROR MESSAGE -->
          <!-- END VERIFIED USER: ABOUT ME INFO -->

          <!-- RESULTS -->
          <h3 class="text-xl font-semibold mt-0 mb-4">
            Your experience as an unpaid carer
          </h3>
          <p class="m-0">
            Thank you for completing the questionnaire. This information can be
            used, with your consent, to contact services you wish to access and
            share your circumstances with. It is also useful to reflect on the
            valuable contribution you make as a carer.
          </p>

          <h4 class="text-xl font-semibold mt-8 mb-2">Next Steps</h4>
          <p class="m-0">
            On the next page we will suggest some support options that may be of
            interest to you. You can also go back to update your answers to the
            questionnaire, if you wish.
          </p>
          <!-- END RESULTS -->

          <!-- NAV BUTTONS -->
          <div class="flex justify-between pt-4 mt-16">
            <button
              class="btn btn-theme-outline btn-sm"
              (click)="toStep('form')"
            >
              Update answers
            </button>

            <button class="btn btn-theme btn-sm" (click)="onReviewedResults()">
              See suggestions
            </button>
          </div>
          <!-- END NAV BUTTONS -->
        </div>
      </ng-container>
      <!-- END STEP: RESULTS -->
    </div>
  </div>
</div>
