import { Validators } from '@angular/forms';
import { FormItemPassword, FormItemTextbox } from 'src/app/models/form/form';

export const registrationMydexId = new FormItemTextbox({
  key: 'mydexid',
  placeholder: 'Mydex Username',
  type: 'string',
  icon: 'username.svg',
  required: true,
  validators: [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(50),
  ],
});

export const registrationEmail = new FormItemTextbox({
  key: 'email',
  placeholder: 'Email',
  icon: 'email.svg',
  required: true,
  validators: [
    Validators.email, // use built-in validator because trying to copy PHP's validator (Mydex uses) is not recommended, as testing correctness is difficult
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(50),
  ],
});

export const loginEmailOrMydexId = new FormItemTextbox({
  key: 'user',
  placeholder: 'Email or MydexId',
  type: 'string',
  icon: 'username.svg',
  required: true,
  validators: [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(50),
  ],
});

export const password = new FormItemPassword({
  key: 'password',
  type: 'password',
  icon: 'password.svg',
  required: true,
  placeholder: 'Password',
  validators: [
    Validators.required,
    Validators.minLength(8),
    Validators.maxLength(50),
  ],
});
