import { Validators } from '@angular/forms';
import {
  FormItemButtonSelect,
  FormItemTextArea,
} from 'src/app/models/form/form';
import { yesNoOptions } from '../item-options';

export const footCareRoutine = new FormItemTextArea({
  key: 'footCareRoutine',
  path: 'support.footCare.routine',
  label: 'Tell us how you care for your feet',
  placeholder: 'e.g. products you use, routines',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const footCareProblems = new FormItemButtonSelect({
  key: 'footCareProblems',
  path: 'support.footCare.problems',
  label: 'Do you have a foot problem?',
  class: 'mb-5',
  multiple: true,
  options: [
    { name: 'Hard skin', label: 'Hard skin' },
    { name: 'Toenails', label: 'Toenails' },
    { name: 'Bunions', label: 'Bunions' },
    { name: 'Blisters', label: 'Blisters' },
    { name: 'Pain', label: 'Pain' },
    { name: 'Other', label: 'Other' },
  ],
});

export const footCareProblemsAdditionalInfo = new FormItemTextArea({
  key: 'footCareProblemsAdditionalInfo',
  path: 'support.footCare.problemsAdditionalInfo',
  label: 'Can you describe the problem in more detail?',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const footCareWasTreatedByPodiatrist = new FormItemButtonSelect({
  key: 'footCareWasTreatedByPodiatrist',
  path: 'support.footCare.wasTreatedByPodiatrist',
  label: 'Have you received treatment from a podiatrist before?',
  class: 'mb-5',
  options: yesNoOptions,
});

export const footCarePodiatristTreatmentDetails = new FormItemTextArea({
  key: 'footCarePodiatristTreatmentDetails',
  path: 'support.footCare.podiatristTreatmentDetails',
  label: 'Please provide the details of your treatment',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
  displayConditions: [
    {
      key: 'footCareWasTreatedByPodiatrist',
      values: [true],
    },
  ],
});

export const footCarePodiatristDetails = new FormItemTextArea({
  key: 'footCarePodiatristDetails',
  path: 'support.footCare.podiatristDetails',
  label: 'Details of podiatrist',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
  displayConditions: [
    {
      key: 'footCareWasTreatedByPodiatrist',
      values: [true],
    },
  ],
});
