import { Component } from '@angular/core';
import sampleForm from 'src/assets/forms/sample/form-sample';

import addressForm from 'src/assets/forms/sample/form-sample-address';

import conditionalForm from 'src/assets/forms/sample/form-sample-conditionals';

@Component({
  selector: 'app-admin-form-items',
  templateUrl: './admin-form-items.component.html',
  styleUrls: ['./admin-form-items.component.css'],
})
export class AdminFormItemsComponent {
  form = sampleForm;

  addressForm = addressForm;

  conditionalForm = conditionalForm;

  sampleFormValues = {
    suggestionTest: 'test, suggestion1',
    // dob: '2001-08-25',
    // nativeDropdownOutput: '2',
    date: '2020-08-25',
    people2: [
      {
        firstName: 'John',
        lastName: 'Doe',
      },
    ],
  };

  constructor() {}
  handleSave(data: any) {}
}
