<div class="flex flex-col h-full justify-between">
  <ul
    class="list-none p-0 lg:[&_li]:py-4 lg:[&_span]:text-white lg:[&_span]:text-xl"
  >
    <li>
      <a routerLink="/account/about-me">
        <div class="sidebar-icon-wrapper">
          <img
            height="16"
            width="16"
            src="/assets/img/icons/sidebar/about-me.svg"
            alt=""
          />
        </div>
        <span>About Me</span>
      </a>
    </li>
    <li>
      <a routerLink="/account/summary" class="lg:block">
        <div class="sidebar-icon-wrapper">
          <app-icon
            height="16"
            width="16"
            name="text"
            class="text-theme"
          ></app-icon>
        </div>
        <span>My Summary</span>
      </a>
    </li>
    <li>
      <a routerLink="/finder" class="hidden lg:block">
        <div class="sidebar-icon-wrapper">
          <img
            height="16"
            width="16"
            src="/assets/img/icons/sidebar/services.svg"
            alt=""
          />
        </div>
        <span>Find Services</span>
      </a>
    </li>
    <li>
      <a routerLink="/search" class="hidden lg:block">
        <div class="sidebar-icon-wrapper" style="margin-left: 0.15rem">
          <app-icon
            height="14"
            width="14"
            name="search"
            class="text-theme"
          ></app-icon>
        </div>
        <span>Search Services</span>
      </a>
    </li>

    <!-- TODO: uncomment when service/cc lifecycle is ready
    <li>
    <a routerLink="/account/connections">
      <div class="sidebar-icon-wrapper">
        <img src="/assets/img/icons/sidebar/connections.svg" alt="" />
      </div>
      <span>My Connections</span>
    </a>
  </li> -->

    <!-- Other links from design, unused currently: -->
    <!-- <li>
      <a routerLink="/">
        <div class="sidebar-icon-wrapper">
            <img src="/assets/img/icons/sidebar/records.svg" alt="" />
        </div>
        <span>Records</span>
      </a>
    </li> -->
    <!-- <li>
      <a routerLink="/">
        <div class="sidebar-icon-wrapper">
            <img src="/assets/img/icons/sidebar/records.svg" alt="" />
        </div>
        <span>Settings</span>
      </a>
    </li> -->
  </ul>
  <div>
    <hr class="opacity-20 -mx-2" />
    <ul
      class="list-none flex flex-col gap-2 my-4 lg:text-sm p-0 m-0 lg:[&_a]:text-white [&_a]:font-semibold lg:[&_a]:font-normal"
    >
      <li><a routerLink="/faq">FAQ</a></li>
      <li><a routerLink="/report-problem">Report a Problem</a></li>
      <li><a routerLink="/cookie-preferences">Cookie Preferences</a></li>
      <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
    </ul>
  </div>
</div>
