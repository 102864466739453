<div class="flex mt-8 box-border">
  <div class="flex flex-col lg:w-1/2">
    <h1 class="text-2xl font-medium mt-0 mb-4">
      Step 2: Record your private key
    </h1>
    <p class="mb-12">
      Thank you for creating a private key. With this key you can decide who can
      see your data. You will need it in just a moment to give this website
      permission to connect to your personal data store. <br /><br />
      Please remember to keep it somewhere safe as without the key, nobody can
      access your <strong>About Me</strong>.
    </p>

    <a class="btn-theme px-8 mr-auto btn" routerLink="/register/setup/login">
      Yes, I've recorded my key
    </a>
  </div>
  <div class="-my-20 w-1/2 hidden lg:flex">
    <img
      class="h-2 ml-auto min-h-full"
      src="/assets/img/record-key-illustration.svg"
      alt=""
    />
  </div>
</div>
