import { Validators } from '@angular/forms';
import {
  FormItemButtonSelect,
  FormItemTextArea,
  FormItemTextbox,
  FormItemTitle,
} from 'src/app/models/form/form';

export const eatingAndDrinkingDietaryRestrictionsTitle = new FormItemTitle({
  key: 'eatingAndDrinkingDietaryRestrictionsTitle',
  label: "Foods you can and can't eat",
  class: 'mt-2 mb-5',
});

export const eatingAndDrinkingDiet = new FormItemTextbox({
  key: 'eatingAndDrinkingDiet',
  path: 'support.eatingAndDrinking.diet',
  label: 'Do you follow a specific diet, and if so, what type of diet is it?',
  placeholder: 'e.g. vegan',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const eatingAndDrinkingFavouriteMeal = new FormItemTextbox({
  key: 'eatingAndDrinkingFavouriteMeal',
  path: 'support.eatingAndDrinking.favouriteMeal',
  label: 'What is your favorite meal?',
  placeholder: 'e.g. fish and chips',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const eatingAndDrinkingPreferredFoodsAndDrinks = new FormItemTextArea({
  key: 'eatingAndDrinkingPreferredFoodsAndDrinks',
  path: 'support.eatingAndDrinking.preferredFoodsAndDrinks',
  label: 'Tell us about other foods and drinks you enjoy.',
  placeholder: 'e.g. fish and chips',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const eatingAndDrinkingAvoidedFoodsAndDrinks = new FormItemTextArea({
  key: 'eatingAndDrinkingAvoidedFoodsAndDrinks',
  path: 'support.eatingAndDrinking.avoidedFoodsAndDrinks',
  label: 'What kind of foods do you avoid?',
  placeholder: 'e.g. dairy products',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const eatingAndDrinkingSupportTitle = new FormItemTitle({
  key: 'eatingAndDrinkingSupportTitle',
  label: 'Support with eating and drinking',
  class: 'mt-2 mb-5',
});

export const eatingAndDrinkingAreasWithNoSupportNeed = new FormItemButtonSelect(
  {
    key: 'eatingAndDrinkingAreasWithNoSupportNeed',
    path: 'support.eatingAndDrinking.areasWithNoSupportNeed',
    label: 'Things that you can do without help',
    class: 'mb-5',
    multiple: true,
    options: [
      { name: 'Make a meal', label: 'Make a meal' },
      { name: 'Prepare a snack', label: 'Prepare a snack' },
      {
        name: 'Make a hot drink',
        label: 'Make a hot drink',
      },
      { name: 'Make a cold drink', label: 'Make a cold drink' },
      {
        name: 'Move food from kitchen to table',
        label: 'Move food from kitchen to table',
      },
      { name: 'Cutting up food', label: 'Cutting up food' },
      { name: 'Hand to mouth', label: 'Hand to mouth' },
      { name: 'Chewing', label: 'Chewing' },
      { name: 'Other', label: 'Other' },
    ],
  }
);

export const eatingAndDrinkingImpairments = new FormItemButtonSelect({
  key: 'eatingAndDrinkingImpairments',
  path: 'support.eatingAndDrinking.impairments',
  label: 'Do you have any impairments that affect eating and drinking?',
  class: 'mb-5',
  multiple: true,
  options: [
    { name: 'Tremor', label: 'Tremor' },
    { name: 'Upper limb impairment', label: 'Upper limb impairment' },
    { name: 'Visual impairment', label: 'Visual impairment' },
    { name: 'Swallowing', label: 'Swallowing' },
    { name: 'ARFID', label: 'ARFID' },
    { name: 'Other', label: 'Other' },
  ],
});

export const eatingAndDrinkingImpairmentsAdditionalInfo = new FormItemTextArea({
  key: 'eatingAndDrinkingImpairmentsAdditionalInfo',
  path: 'support.eatingAndDrinking.impairmentsAdditionalInfo',
  label:
    'Would you like to share more about impairments that affect eating and drinking?',
  placeholder:
    "e.g. I have dentures but don't enjoy wearing them during eating as it's painful. I need soft foods.",
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const eatingAndDrinkingEquipmentOrPreferences = new FormItemButtonSelect(
  {
    key: 'eatingAndDrinkingEquipmentOrPreferences',
    path: 'support.eatingAndDrinking.equipmentOrPreferences',
    label:
      'Tell us about any equipment or preferences that you use to support your eating and drinking',
    class: 'mb-5',
    multiple: true,
    options: [
      { name: 'Cup', label: 'Cup' },
      { name: 'Cutlery', label: 'Cutlery' },
      { name: 'Plate', label: 'Plate' },
      { name: 'Slip mat', label: 'Slip mat' },
      { name: 'Lap tray', label: 'Lap tray' },
      { name: 'Other', label: 'Other' },
    ],
  }
);

export const eatingAndDrinkingEquipmentOrPreferencesAdditionalInfo =
  new FormItemTextArea({
    key: 'eatingAndDrinkingEquipmentOrPreferencesAdditionalInfo',
    path: 'support.eatingAndDrinking.equipmentOrPreferencesAdditionalInfo',
    label:
      'Would you like to share more about equipment or preferences that support your eating and drinking',
    placeholder:
      'e.g. My vision is impaired and I like to use a dark plate. It helps me see the food more easily.',
    class: 'mb-5',
    validators: [Validators.minLength(1), Validators.maxLength(2000)],
  });

export const eatingAndDrinkingGeneralAdditionalInfo = new FormItemTextArea({
  key: 'eatingAndDrinkingGeneralAdditionalInfo',
  path: 'support.eatingAndDrinking.generalAdditionalInfo',
  label:
    'If you want to share more about what affects your eating and drinking:',
  placeholder:
    'e.g. I need personal support when eating. My PA has to make sure the food is pureed, at a lukewarm temperature and not too much food is on the spoon.',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const eatingAndDrinkingPreferredDiningLocation = new FormItemTextbox({
  key: 'eatingAndDrinkingPreferredDiningLocation',
  path: 'support.eatingAndDrinking.preferredDiningLocation',
  label: 'Where do you like to eat?',
  placeholder: 'e.g. in front of the telly on my lap',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});
