<div class="flex mt-8 box-border">
  <div class="flex flex-col lg:w-1/2">
    <!-- DESCRIPTION: REGISTERED ON CC -->
    <ng-container *ngIf="sessionStorageService.getRegistrationInProgress()">
      <h1 class="text-2xl font-medium mt-0 mb-4">
        Step 4: Approve Permissions
      </h1>
      <p class="mb-12">
        Well done, the next step is to approve the areas of your
        <strong>About Me</strong> that you're happy to share with this website.
        Again, we will take you to Mydex to do this and this is where you'll
        need your private key to give access.
      </p>
    </ng-container>
    <!-- END DESCRIPTION: REGISTERED ON CC -->

    <!-- DESCRIPTION: REGISTERED ELSEWHERE -->
    <ng-container *ngIf="!sessionStorageService.getRegistrationInProgress()">
      <h1 class="text-2xl font-medium mt-0 mb-4">Approve Permissions</h1>
      <p class="mb-12">
        Thank you for signing in to Community Connections via Mydex! Please
        approve the areas of your
        <strong>About Me</strong> that you're happy to share with us. We will
        take you to Mydex to do this and this is where you'll need your private
        key to give access.
      </p>
    </ng-container>
    <!-- END DESCRIPTION: REGISTERED ELSEWHERE -->

    <!-- AGREEMENT TO PRIVACY POLICY: REGISTERED ELSEWHERE -->
    <div
      *ngIf="!sessionStorageService.getRegistrationInProgress()"
      class="form-text flex mb-6"
    >
      <label class="custom-checkbox cursor flex cursor-pointer items-center">
        <input
          type="checkbox"
          class="hidden"
          (change)="agreementPrivacyPolicy = $event.target.checked"
        />
        <div
          class="checkbox w-4 h-4 rounded border border-solid flex transition-colors my-auto mr-4"
          [ngClass]="
            agreementPrivacyPolicy
              ? 'bg-theme border-theme'
              : 'bg-white border-gray-500'
          "
        >
          <app-icon name="check" class="text-white"></app-icon>
        </div>

        <span class="mt-auto mb-auto">
          I confirm that I have read, understood and consent to the
          <a
            class="text-theme underline"
            routerLink="/privacy-policy"
            target="_blank"
            >Privacy Policy</a
          >
          of Community Connections.
          <span class="text-theme">*</span>
        </span>
      </label>
    </div>
    <!-- END AGREEMENT TO PRIVACY POLICY: REGISTERED ELSEWHERE -->

    <p
      class="text-error-red form-error-text mb-4"
      *ngIf="
        (pdsService.awaitingUrlRedirectError$ | async) &&
        !(pdsService.awaitingUrlRedirect$ | async)
      "
    >
      {{
        (pdsService.awaitingUrlRedirectError$ | async) ||
          "An error occured. Please refresh the page and try again."
      }}
    </p>

    <p *ngIf="agreementPrivacyPolicyError" class="text-error-red">
      {{ agreementPrivacyPolicyError }}
    </p>

    <button
      (click)="submit()"
      [disabled]="pdsService.awaitingUrlRedirect$ | async"
      class="btn-theme px-8 mr-auto"
    >
      <app-loading-spinner
        *ngIf="pdsService.awaitingUrlRedirect$ | async"
        class="m-auto"
        text="Redirecting"
      ></app-loading-spinner>
      <ng-container *ngIf="!(pdsService.awaitingUrlRedirect$ | async)"
        >Approve Permissions</ng-container
      >
    </button>
  </div>
  <div class="-my-20 w-1/2 hidden lg:flex">
    <img
      class="h-2 ml-auto min-h-full"
      src="/assets/img/permissions-illustration.svg"
      alt=""
    />
  </div>
</div>
