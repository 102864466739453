import { Component, ViewChild, ElementRef, Input } from '@angular/core';

@Component({
  selector: 'app-accordion-item',
  templateUrl: './accordion-item.component.html',
  styleUrls: ['./accordion-item.component.css'],
})
export class AccordionItemComponent {
  @ViewChild('accordionContent') contentElement!: ElementRef;
  isOpen = false;
  style: any = { 'max-height': 0 }; // initial body style
  @Input() onlyTriggerViaCustomToggle = false; // if true, the toggle is .toggle (ng-content), false is the whole header

  get height(): number {
    // get the height of the body content
    if (!this.contentElement) {
      return 0;
    }
    return this.contentElement.nativeElement.scrollHeight;
  }

  toggle(event: any): void {
    if (
      this.onlyTriggerViaCustomToggle &&
      !event.target.classList.contains('toggle')
    )
      return;

    this.isOpen = !this.isOpen;
    this.style = { 'max-height': this.height + 'px' };
    if (this.isOpen) {
      setTimeout(() => {
        this.style = {}; //unset max height incase content changes height later
      }, 200);
    } else {
      setTimeout(() => {
        this.style = { 'max-height': 0 };
      });
    }
  }
}
