import { Validators } from '@angular/forms';
import {
  FormItemButtonSelect,
  FormItemTextArea,
  FormItemTextbox,
} from 'src/app/models/form/form';

export const gettingWashedMethods = new FormItemButtonSelect({
  key: 'gettingWashedMethods',
  path: 'support.gettingWashed.methods',
  label: 'How do you get washed?',
  class: 'mb-5',
  multiple: true,
  options: [
    { name: 'Wash down at the sink', label: 'Wash down at the sink' },
    { name: 'Shower', label: 'Shower' },
    { name: 'Bath', label: 'Bath' },
    { name: 'Bed bath', label: 'Bed bath' },
    { name: 'Other', label: 'Other' },
  ],
});

export const gettingWashedFrequency = new FormItemButtonSelect({
  key: 'gettingWashedFrequency',
  path: 'support.gettingWashed.frequency',
  label: 'How often do you get washed?',
  class: 'mb-5',
  options: [
    { name: 'Once per day or more', label: 'Once per day or more' },
    { name: 'Once per week or more', label: 'Once per week or more' },
    { name: 'Less than once per week', label: 'Less than once per week' },
  ],
});

export const gettingWashedTimes = new FormItemTextbox({
  key: 'gettingWashedTimes',
  path: 'support.gettingWashed.times',
  label: 'When do you get washed?',
  placeholder: 'e.g. In the morning',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const gettingWashedShavingDetails = new FormItemTextbox({
  key: 'gettingWashedShavingDetails',
  path: 'support.gettingWashed.shavingDetails',
  label: 'Shaving',
  placeholder: 'e.g. every day',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const gettingWashedHaircareDetails = new FormItemTextbox({
  key: 'gettingWashedHaircareDetails',
  path: 'support.gettingWashed.haircareDetails',
  label: 'Haircare',
  placeholder: 'e.g. once a week / my carer uses a hot brush everyday',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const gettingWashedDentalDetails = new FormItemTextbox({
  key: 'gettingWashedDentalDetails',
  path: 'support.gettingWashed.dentalDetails',
  label: 'Dental',
  placeholder: 'e.g. brush daily, attend regular check-ups',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const gettingWashedGeneralAdditionalInfo = new FormItemTextArea({
  key: 'gettingWashedGeneralAdditionalInfo',
  path: 'support.gettingWashed.generalAdditionalInfo',
  label: 'Anything else you would like to share about getting washed:',
  placeholder: 'e.g. apply skin cream',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});
