import { Validators } from '@angular/forms';
import {
  FormItemButtonSelect,
  FormItemTextArea,
  FormItemTextbox,
} from 'src/app/models/form/form';

export const movingAroundOutsideAreasWithNoSupportNeed =
  new FormItemButtonSelect({
    key: 'movingAroundOutsideAreasWithNoSupportNeed',
    path: 'support.movingAroundOutside.areasWithNoSupportNeed',
    label: 'Tell us what you can manage without support',
    class: 'mb-5',
    multiple: true,
    options: [
      { name: 'Leaving via the back door', label: 'Leaving via the back door' },
      {
        name: 'Leaving via the front door',
        label: 'Leaving via the front door',
      },
      { name: 'Getting out to the garden', label: 'Getting out to the garden' },
      { name: 'Walking to the local shop', label: 'Walking to the local shop' },
      {
        name: 'Getting transport into town',
        label: 'Getting transport into town',
      },
      { name: 'Other', label: 'Other' },
    ],
  });

export const movingAroundOutsideAreasWithSupportNeed = new FormItemButtonSelect(
  {
    key: 'movingAroundOutsideAreasWithSupportNeed',
    path: 'support.movingAroundOutside.areasWithSupportNeed',
    label: 'Share the things that you do need support with',
    class: 'mb-5',
    multiple: true,
    options: [
      { name: 'Leaving via the back door', label: 'Leaving via the back door' },
      {
        name: 'Leaving via the front door',
        label: 'Leaving via the front door',
      },
      { name: 'Getting out to the garden', label: 'Getting out to the garden' },
      { name: 'Walking to the local shop', label: 'Walking to the local shop' },
      {
        name: 'Getting transport into town',
        label: 'Getting transport into town',
      },
      { name: 'Other', label: 'Other' },
    ],
  }
);

export const movingAroundOutsideAreasWithSupportNeedAdditionalInfo =
  new FormItemTextArea({
    key: 'movingAroundOutsideAreasWithSupportNeedAdditionalInfo',
    path: 'support.movingAroundOutside.areasWithSupportNeedAdditionalInfo',
    label:
      'Anything else you would like to share about the support you need moving around outside?',
    class: 'mb-5',
    validators: [Validators.minLength(1), Validators.maxLength(2000)],
  });

export const movingAroundOutsideAids = new FormItemButtonSelect({
  key: 'movingAroundOutsideAids',
  path: 'support.movingAroundOutside.aids',
  label: 'Do you use an aid to get around?',
  multiple: true,
  class: 'mb-5',
  options: [
    { name: 'Rollator', label: 'Rollator' },
    { name: 'Zimmer', label: 'Zimmer' },
    { name: 'Walking Stick', label: 'Walking Stick' },
    { name: 'Crutches', label: 'Crutches' },
    { name: 'Wheelchair', label: 'Wheelchair' },
    { name: 'Scooter', label: 'Scooter' },
    { name: 'Other', label: 'Other' },
  ],
});

export const movingAroundOutsideAidsAdditionalInfo = new FormItemTextArea({
  key: 'movingAroundOutsideAidsAdditionalInfo',
  path: 'support.movingAroundOutside.aidsAdditionalInfo',
  label:
    'Anything else you would like to share about the aids you use to get around?',
  placeholder:
    'e.g. I take a portable eat with me so that I can take a rest when I need to.',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const movingAroundOutsideUnsteadinessOccurrences = new FormItemTextbox({
  key: 'movingAroundOutsideUnsteadinessOccurrences',
  path: 'support.movingAroundOutside.unsteadinessOccurrences',
  label: 'Are you sometimes unsteady on your feet and when?',
  placeholder: 'e.g. yes, stepping off the curb',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const movingAroundOutsideExistingSupport = new FormItemTextbox({
  key: 'movingAroundOutsideExistingSupport',
  path: 'support.movingAroundOutside.existingSupport',
  label: 'Tell us who, if anyone, helps you with these things?',
  placeholder: 'e.g. son',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const movingAroundOutsideGeneralAdditionalInfo = new FormItemTextArea({
  key: 'movingAroundOutsideGeneralAdditionalInfo',
  path: 'support.movingAroundOutside.generalAdditionalInfo',
  label: 'Anything else you want to share about getting around outside:',
  placeholder: "e.g. I need to be able to sit down regularly when I'm outside",
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});
