export class CallToActionButton {
  type: 'href' | 'routerLink';
  label: string;
  link: string;

  constructor(data: Partial<CallToActionButton>) {
    this.type = data?.type || 'href';
    this.label = data?.label || '';
    this.link = data?.link || '';
  }
}
