<div class="relative input-filter mb-6">
  <div class="flex items-center gap-1 w-full">
    <app-icon
      name="search"
      class="absolute my-auto left-5 text-grey"
    ></app-icon>
    <input
      type="text"
      class="input-filter__input w-full pl-11"
      [placeholder]="placeholder"
      [value]="value ? value : ''"
      (input)="handleOnChanged($event)"
    />
    <div
      class="absolute my-auto cursor-pointer right-4"
      *ngIf="clearable && !loading && value && value !== ''"
      (click)="clear()"
    >
      <img class="pt-1" src="/assets/img/icons/stop.svg" alt="Clear" />
    </div>
  </div>
</div>
