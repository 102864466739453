import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, combineLatest, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './services/auth/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private oidcSecurityService: OidcSecurityService,
    private injector: Injector
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return combineLatest([
      this.oidcSecurityService.getAccessToken(),
      this.oidcSecurityService.getIdToken(),
    ]).pipe(
      map(([accessToken, idToken]) => {
        // Determine the type of token to use based on the URL
        if (req.url.includes(environment.oidc.mydex.authority)) {
          // Mydex specific handling
          if (!accessToken) {
            return req; // Return the original request if no access token is available
          }
          // Use the access token for Mydex endpoints if available (needed by angular-auth-oidc-client for getUserInfo endpoint)
          return req.clone({
            headers: req.headers.set('Authorization', `Bearer ${accessToken}`),
          });
        }
        // Fallback to using the ID token for other requests if access token isn't specifically required
        if (idToken && !req.url.startsWith(environment.oidc.mydex.authority)) {
          return req.clone({
            headers: req.headers.set('Authorization', `Bearer ${idToken}`),
          });
        }
        return req; // Return the original request if no tokens are applicable
      }),
      switchMap((req) => next.handle(req)),
      catchError((error) => {
        if (error.status === 401) {
          const authService = this.injector.get(AuthService); //must be injected to prevent static circular dependency (as authService makes api calls)
          authService.logout();
        }
        // Handle any errors in fetching the token or processing the request
        return throwError(() => error);
      })
    );
  }
}
