import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { User } from 'src/app/models/user/user';
import { UserStateService } from 'src/app/services/user.state.service';
import { getAllServerMamPaths } from 'src/assets/about-me/serverMamPaths';

@Component({
  selector: 'app-account-welcome',
  templateUrl: './account-welcome.component.html',
  styleUrls: ['./account-welcome.component.css'],
})
export class AccountWelcomeComponent {
  protected user: User | undefined;

  constructor(protected userService: UserStateService) {}

  ngOnInit() {
    // user should be verified when on this page (has VerifiedGuard on route)
    // update user in case of any session storage data (that is why all MAM paths are passed)
    this.userService.updateUser(undefined, getAllServerMamPaths());
  }
}
