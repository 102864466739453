<!-- BACKGROUND -->
<div
  *ngFor="let contentBlock of contentHolder?.contentBlocks; let i = index"
  [ngSwitch]="contentBlock.type"
  [ngClass]="{ 'bg-white': i % 2 === 0, 'bg-light-grey': i % 2 !== 0 }"
  class="w-full"
>
  <div class="px-8 xs:pb-8 xs:pt-1 xs:px-28">
    <!-- CONNECTIONS CONTENT -->
    <div *ngSwitchCase="'connections'">
      <h1 class="text-theme">{{ contentBlock.title }}</h1>
      <p class="mb-8">{{ contentBlock.description }}</p>
      <app-connections
        [connections]="getConnectionsForContentBlock(contentBlock.orderId)"
      >
      </app-connections>
    </div>
    <!-- END CONNECTIONS CONTENT -->

    <!-- SERVICE MODULE CONTENT -->
    <div *ngSwitchCase="'serviceModule'"></div>
    <!-- END SERVICE MODULE CONTENT -->

    <!-- DETAILS BASE CONTENT -->
    <div *ngSwitchCase="'detailsBase'">
      <ng-container [ngSwitch]="contentBlock.layoutType">
        <!-- DETAILS BASE CONTENT - CASE TEXT-CTA -->
        <ng-container *ngSwitchCase="'text-cta'">
          <h1 class="text-theme mb-2">{{ contentBlock.title }}</h1>
          <h2 class="text-grey font-normal italic m-0">
            {{ contentBlock.subheading }}
          </h2>
          <div class="flex">
            <p
              *ngIf="contentBlock.description"
              [ngClass]="
                contentBlock.description?.length > 350 && !contentBlock.cta
                  ? 'columns-2 gap-6'
                  : ''
              "
            >
              {{ contentBlock.description }}
            </p>
            <app-cta
              *ngIf="contentBlock.cta"
              [cta]="contentBlock.cta"
              classForLink="btn btn-theme-outline outlined px-12 whitespace-nowrap"
              [ngClass]="contentBlock.description ? 'mx-12 my-auto' : 'mt-4'"
            >
            </app-cta>
          </div>
        </ng-container>
        <!-- END DETAILS BASE CONTENT - CASE TEXT-CTA -->

        <!-- DETAILS BASE CONTENT - CASE TEXT-CTA-IMAGES -->
        <div class="flex" *ngSwitchCase="'text-cta-image'">
          <div class="w-2/5 mr-8">
            <h1 class="text-theme mb-2">{{ contentBlock.title }}</h1>
            <h2 class="text-grey font-normal italic m-0">
              {{ contentBlock.subheading }}
            </h2>
            <p>{{ contentBlock.description }}</p>
            <app-cta
              *ngIf="contentBlock.cta"
              [cta]="contentBlock.cta"
              classForLink="btn btn-theme-outline outlined px-12 whitespace-nowrap mt-4"
            >
            </app-cta>
          </div>
          <div class="w-3/5 ml-8 relative mt-9">
            <div
              class="h-[300px]"
              [style.padding-bottom.%]="aspectRatioPercentage"
            >
              <div
                class="absolute inset-0 bg-cover bg-center px-10"
                [ngStyle]="{
                  'background-image': 'url(' + contentBlock.images[0] + ')',
                  'background-clip': 'content-box',
                  'min-height': '300px'
                }"
              ></div>
            </div>
          </div>
        </div>
        <!-- END DETAILS BASE CONTENT - CASE TEXT-CTA-IMAGES  -->

        <!-- DETAILS BASE CONTENT - CASE IMAGE-ONLY -->
        <ng-container *ngSwitchCase="'image-only'">
          <h1 class="text-theme mb-2">{{ contentBlock.title }}</h1>
          <h2 class="text-grey font-normal italic m-0">
            {{ contentBlock.subheading }}
          </h2>
          <div
            class="h-[500px]"
            [style.padding-bottom.%]="aspectRatioPercentage"
          >
            <div
              class="bg-cover bg-center px-10 pt-4"
              [ngStyle]="{
                'background-image': 'url(' + contentBlock.images[0] + ')',
                'background-clip': 'content-box',
                'min-height': '500px'
              }"
            ></div>
          </div>
        </ng-container>
        <!-- END DETAILS BASE CONTENT - CASE IMAGE-ONLY -->
      </ng-container>
    </div>
    <!-- END DETAILS BASE CONTENT -->

    <!-- DETAILS TEXT ONLY CONTENT -->
    <div *ngSwitchCase="'detailsTextOnly'">
      <h1 class="text-theme mb-2">{{ contentBlock.title }}</h1>
      <h2 class="text-grey font-normal italic m-0">
        {{ contentBlock.subheading }}
      </h2>
      <p
        [ngClass]="
          contentBlock.description.length < 350
            ? ''
            : contentBlock.description.length > 1000
            ? 'columns-3 gap-6'
            : 'columns-2 gap-6'
        "
      >
        {{ contentBlock.description }}
      </p>
    </div>
    <!-- END DETAILS TEXT ONLY CONTENT -->

    <!-- DEFAULT / NO CONTENT -->
    <div *ngSwitchDefault></div>
    <!-- END DEFAULT / NO CONTENT -->
  </div>
</div>
<!-- END BACKGROUND -->
