import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { User } from 'src/app/models/user/user';

@Injectable({
  providedIn: 'root',
})

// Has public/convenience methods for getting, setting, and removing items to ensure consistent use of keys
export class SessionStorageService {
  // PROPERTIES

  private readonly USER_DATA_KEY = 'userData';
  private readonly REGISTRATION_IN_PROGRESS_KEY = 'registrationInProgress';
  private readonly MYDEX_PRIVATE_KEY_CREATION_URL_KEY =
    'mydexPrivateKeyCreationUrl';

  private readonly OVERRIDE_REGISTRATION_KEY = 'overrideRegistration';
  private readonly SHOWN_REGISTRATION_ONBOARDING_KEY =
    'shownRegistrationOnboarding';
  private readonly REGISTRATION_FLOW_STARTING_POINT_KEY =
    'registrationFlowStartingPoint';

  // CONSTRUCTOR

  constructor() {}

  // PUBLIC API

  // General

  clearStorageForLogout(): void {
    this.popUserData();
    this.removeRegistrationInProgress();
    this.removeRegistrationFlowStartingPoint();
  }

  // User Data

  getUserData(): User | undefined {
    const userData = this.getItem(this.USER_DATA_KEY);

    if (!userData) {
      return undefined;
    }

    return new User(userData);
  }

  popUserData(): User | undefined {
    let userData = this.getUserData();
    this.removeItem(this.USER_DATA_KEY);

    if (!userData) {
      return undefined;
    }

    return new User(userData);
  }

  mergeUserData(newUserData: Partial<User>): void {
    let userData = this.getUserData();

    if (!userData) {
      userData = {};
    }

    const updatedUserData = User.mergeUserData(userData, newUserData);

    this.setItem(this.USER_DATA_KEY, updatedUserData);
  }

  removeUserData(): void {
    this.removeItem(this.USER_DATA_KEY);
  }

  // Registration

  getRegistrationInProgress(): boolean {
    return this.getItem(this.REGISTRATION_IN_PROGRESS_KEY) || false;
  }

  setRegistrationInProgress(value: boolean): void {
    this.setItem(this.REGISTRATION_IN_PROGRESS_KEY, value);
  }

  removeRegistrationInProgress(): void {
    this.removeItem(this.REGISTRATION_IN_PROGRESS_KEY);
  }

  // Mydex Private Key Creation URL

  getMydexPrivateKeyCreationUrl(): string | undefined {
    return this.getItem(this.MYDEX_PRIVATE_KEY_CREATION_URL_KEY) || undefined;
  }

  setMydexPrivateKeyCreationUrl(value: string): void {
    this.setItem(this.MYDEX_PRIVATE_KEY_CREATION_URL_KEY, value);
  }

  removeMydexPrivateKeyCreationUrl(): void {
    this.removeItem(this.MYDEX_PRIVATE_KEY_CREATION_URL_KEY);
  }

  // Override Registration

  getOverrideRegistration(): boolean {
    return this.getItem(this.OVERRIDE_REGISTRATION_KEY) || false;
  }

  setOverrideRegistration(value: boolean): void {
    this.setItem(this.OVERRIDE_REGISTRATION_KEY, value);
  }

  // Shown Registration Onboarding
  getShownRegistrationOnboarding(): boolean {
    return this.getItem(this.SHOWN_REGISTRATION_ONBOARDING_KEY) || false;
  }

  setShownRegistrationOnboarding(value: boolean): void {
    this.setItem(this.SHOWN_REGISTRATION_ONBOARDING_KEY, value);
  }

  // Registration Flow Starting Point
  getRegistrationFlowStartingPoint(): string | undefined {
    return this.getItem(this.REGISTRATION_FLOW_STARTING_POINT_KEY) || undefined;
  }

  setRegistrationFlowStartingPoint(value: string): void {
    this.setItem(this.REGISTRATION_FLOW_STARTING_POINT_KEY, value);
  }

  removeRegistrationFlowStartingPoint(): void {
    this.removeItem(this.REGISTRATION_FLOW_STARTING_POINT_KEY);
  }

  // PRIVATE API

  private getItem(key: string): any {
    const item = sessionStorage.getItem(key);
    return item ? JSON.parse(item) : undefined;
  }

  private setItem(key: string, value: any): void {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  private removeItem(key: string): void {
    sessionStorage.removeItem(key);
  }
}
