import { Component } from '@angular/core';
import { CookieConsentService } from '../services/cookie-consent/cookie-consent.service';
import { Subject, takeUntil } from 'rxjs';
import { set } from 'lodash';

@Component({
  selector: 'app-page-cookie-preferences',
  templateUrl: './page-cookie-preferences.component.html',
  styleUrl: './page-cookie-preferences.component.css',
})
export class PageCookiePreferencesComponent {
  // PROPERTIES

  vimeoConsent: boolean = false;
  googleAnalyticsConsent: boolean = false;

  showSuccessMessage: boolean = false;

  private destroy$: Subject<void> = new Subject<void>();

  // CONSTRUCTOR

  constructor(private cookieConsentService: CookieConsentService) {}

  // LIFE CYCLE HOOKS

  ngOnInit(): void {
    this.cookieConsentService.cookieConsent$
      .pipe(takeUntil(this.destroy$))
      .subscribe((cookieConsent) => {
        this.vimeoConsent = cookieConsent?.consentedVimeo || false;
        this.googleAnalyticsConsent =
          cookieConsent?.consentedGoogleAnalytics || false;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // METHODS

  savePreferences(): void {
    this.cookieConsentService.updateCookieConsent(
      this.vimeoConsent,
      this.googleAnalyticsConsent
    );

    this.showSuccessMessage = true;
  }
}
