<div class="container-xl">
  <!--LOADING SPINNER-->
  <app-loading-spinner
    *ngIf="(userService.loading$ | async) || (userService.saving$ | async)"
  ></app-loading-spinner>
  <!--END LOADING SPINNER-->

  <!-- SERVER ERROR: FAILED LOADING USER -->
  <app-no-items-error
    [isDisplayed]="
      !(userService.loading$ | async) && (userService.loadingError$ | async)
    "
    [errorMessage]="userService.loadingError$ | async"
  >
  </app-no-items-error>
  <!-- END SERVER ERROR: FAILED LOADING USER -->

  <!--CONTENT-->
  <ng-container
    *ngIf="
      !(userService.loading$ | async) &&
      !(userService.saving$ | async) &&
      !(userService.loadingError$ | async)
    "
    class="flex flex-col"
  >
    <h1>My Summary</h1>
    <p class="w-full max-w-3xl">
      A summary of your essential needs in the event you need support from
      people who you do not know well. For example a change in your care team,
      an emergency admission to hospital or care home.
    </p>

    <div
      class="card bg-white rounded-lg w-full max-w-3xl mt-8 box-border px-10 py-8"
    >
      <!-- BASIC DETAILS -->
      <h3 class="my-0 text-theme-darker">Basic Details</h3>
      <p>
        <span *ngIf="user?.me?.personalDetails?.firstName"
          >My name is
          {{
            user?.me?.personalDetails?.lastName
              ? user?.me?.personalDetails?.firstName +
                " " +
                user?.me?.personalDetails?.lastName
              : user?.me?.personalDetails?.firstName
          }}.
        </span>
        <span *ngIf="user?.me?.personalDetails?.dateOfBirth">
          I was born on
          {{ user?.me?.personalDetails?.dateOfBirth | date : "MMMM d, y" }}
          and am {{ computeAge(user?.me?.personalDetails?.dateOfBirth) }} years
          old.
        </span>
      </p>
      <p>
        <span *ngIf="user?.me?.personalDetails?.preferredName"
          >I prefer to be called {{ user?.me?.personalDetails?.preferredName }}.
        </span>
        <span *ngIf="user?.me?.personalDetails?.pronouns">
          The pronouns I use are:
          {{ user?.me?.personalDetails?.pronouns }}.</span
        >
      </p>
      <p *ngIf="!hasBasicDetails" class="italic">No details added.</p>
      <!-- END BASIC DETAILS -->

      <!-- MEDICATION -->
      <h3 class="title">Medication</h3>

      <ng-container
        *ngIf="user?.support?.medication?.listOfMedications?.length > 0"
      >
        <p>I take the following medication:</p>
        <ul class="mt-0">
          <li
            *ngFor="
              let medication of user?.support?.medication?.listOfMedications
            "
            class="mb-3"
          >
            <span class="font-bold">{{ medication.name }} </span>
            <ul>
              <li *ngIf="medication.intakeTime" class="mt-1">
                Intake Time: {{ medication.intakeTime }}
              </li>
              <li *ngIf="medication.intakeFrequency" class="mt-1">
                Frequency: {{ medication.intakeFrequency }}
              </li>
              <li *ngIf="medication.intakeMethod" class="mt-1">
                Intake Preferences: {{ medication.intakeMethod }}
              </li>
              <li *ngIf="medication.storageLocation" class="mt-1">
                Storage Location: {{ medication.storageLocation }}
              </li>
            </ul>
          </li>
        </ul>
      </ng-container>

      <p *ngIf="!hasMedicationsDetails" class="italic">No details added.</p>
      <!-- END MEDICATION -->

      <!-- EATING AND DRINKING -->
      <h3 class="title">Eating and Drinking</h3>

      <p *ngIf="user?.keyInformation?.medicalDetails?.allergies">
        I have the following allergy/allergies:
        {{ user?.keyInformation?.medicalDetails?.allergies | removeDot }}.
      </p>

      <p *ngIf="user?.support?.eatingAndDrinking?.diet">
        This is the specific diet I follow:
        {{ user?.support?.eatingAndDrinking?.diet | removeDot }}.
      </p>

      <p>
        <span *ngIf="user?.support?.eatingAndDrinking?.favouriteMeal">
          My favourite meal:
          {{ user?.support?.eatingAndDrinking?.favouriteMeal | removeDot }}.
        </span>
        <span *ngIf="user?.support?.eatingAndDrinking?.preferredFoodsAndDrinks">
          Furthermore, I enjoy to consume the following:
          {{
            user?.support?.eatingAndDrinking?.preferredFoodsAndDrinks
              | removeDot
          }}.
        </span>
        <span *ngIf="user?.support?.eatingAndDrinking?.avoidedFoodsAndDrinks">
          I do not like to eat/drink:
          {{
            user?.support?.eatingAndDrinking?.avoidedFoodsAndDrinks | removeDot
          }}.
        </span>
      </p>

      <p
        *ngIf="
          user?.support?.eatingAndDrinking?.areasWithNoSupportNeed?.length > 0
        "
      >
        I can do the following things without help:
        {{
          user?.support?.eatingAndDrinking?.areasWithNoSupportNeed | joinArray
        }}.
      </p>

      <p>
        <span *ngIf="user?.support?.eatingAndDrinking?.impairments?.length > 0">
          These impairments affect my eating and drinking:
          {{ user?.support?.eatingAndDrinking?.impairments | joinArray }}.
        </span>
        <span
          *ngIf="user?.support?.eatingAndDrinking?.impairmentsAdditionalInfo"
        >
          Please also note:
          {{
            user?.support?.eatingAndDrinking?.impairmentsAdditionalInfo
              | removeDot
          }}.
        </span>
      </p>

      <p>
        <span
          *ngIf="
            user?.support?.eatingAndDrinking?.equipmentOrPreferences?.length > 0
          "
        >
          Equipment I use for support:
          {{
            user?.support?.eatingAndDrinking?.equipmentOrPreferences
              | joinArray
          }}.
        </span>
        <span
          *ngIf="
            user?.support?.eatingAndDrinking
              ?.equipmentOrPreferencesAdditionalInfo
          "
        >
          Additionally, I would like to add:
          {{
            user?.support?.eatingAndDrinking
              ?.equipmentOrPreferencesAdditionalInfo | removeDot
          }}.
        </span>
      </p>

      <p *ngIf="user?.support?.eatingAndDrinking?.preferredDiningLocation">
        This is where I like to eat:
        {{
          user?.support?.eatingAndDrinking?.preferredDiningLocation | removeDot
        }}.
      </p>

      <p *ngIf="user?.support?.eatingAndDrinking?.generalAdditionalInfo">
        Some additional notes about what affects my eating and drinking:
        {{
          user?.support?.eatingAndDrinking?.generalAdditionalInfo | removeDot
        }}.
      </p>

      <p *ngIf="!hasEatingAndDrinkingDetails" class="italic">
        No details added.
      </p>
      <!--END EATING AND DRINKING-->

      <!-- COMMUNICATION -->
      <h3 class="title">Communication</h3>
      <p *ngIf="user?.support?.communication?.firstLanguage">
        My first language(s):
        {{ user?.support?.communication?.firstLanguage | removeDot }}.
      </p>
      <p>
        <span *ngIf="user?.support?.communication?.preferredTypes?.length > 0">
          These are the types of communication I prefer:
          {{ user?.support?.communication?.preferredTypes | joinArray }}.</span
        >
        <span
          *ngIf="user?.support?.communication?.preferredTypesAdditionalInfo"
        >
          Additionally, I would like to add:
          {{
            user?.support?.communication?.preferredTypesAdditionalInfo
              | removeDot
          }}.
        </span>
      </p>
      <p *ngIf="user?.support?.communication?.generalAids">
        Aid(s) I use for communication:
        {{ user?.support?.communication?.generalAids | removeDot }}.
      </p>
      <p>
        <span *ngIf="user?.support?.communication?.signLanguages?.length > 0">
          The sign language(s) I use:
          {{ user?.support?.communication?.signLanguages | joinArray }}.
        </span>
        <span *ngIf="user?.support?.communication?.signLanguagesAdditionalInfo">
          Please also note:
          {{
            user?.support?.communication?.signLanguagesAdditionalInfo
              | removeDot
          }}.
        </span>
      </p>
      <p *ngIf="user?.support?.communication?.hearingAids === true">
        I use hearing aids.
      </p>
      <p *ngIf="user?.support?.communication?.hearingAids === false">
        I do not use hearing aids.
      </p>
      <p *ngIf="user?.support?.communication?.existingSupport">
        I receive communication support from:
        {{ user?.support?.communication?.existingSupport | removeDot }}.
      </p>
      <p *ngIf="user?.support?.communication?.generalAdditionalInfo">
        Some additional notes about how I communicate:
        {{ user?.support?.communication?.generalAdditionalInfo | removeDot }}.
      </p>

      <p *ngIf="!hasCommunicationDetails" class="italic">No details added.</p>
      <!-- END COMMUNICATION -->

      <!--TOILET-->
      <h3 class="title">Toilet</h3>

      <ng-container
        *ngIf="
          user?.support?.movingAroundInside?.areasWithSupportNeed?.includes(
            'Getting to the toilet'
          )
        "
      >
        <p>I need support getting to the toilet.</p>
        <p
          *ngIf="
            user?.support?.movingAroundInside
              ?.areasWithSupportNeedAdditionalInfo
          "
        >
          Please note about support I need moving around inside:
          {{
            user?.support?.movingAroundInside
              ?.areasWithSupportNeedAdditionalInfo | removeDot
          }}.
        </p>
      </ng-container>

      <p *ngIf="!hasToiletDetails" class="italic">No details added.</p>
      <!--END TOILET-->

      <!--CONTINENCE-->
      <h3 class="title">Continence</h3>

      <p *ngIf="user?.support?.continence?.productsInUse">
        I use the following product(s):
        {{ user?.support?.continence?.productsInUse | removeDot }}.
      </p>

      <p *ngIf="user?.support?.continence?.routines">
        Routines that help me:
        {{ user?.support?.continence?.routines | removeDot }}.
      </p>

      <p *ngIf="user?.support?.continence?.supportNeededInCaseOfEmergency">
        This is the support I would need in an emergency:
        {{
          user?.support?.continence?.supportNeededInCaseOfEmergency | removeDot
        }}.
      </p>

      <p *ngIf="user?.support?.continence?.inContactWithCareTeam === false">
        I am not in touch with the continence care team.
      </p>
      <ng-container
        *ngIf="user?.support?.continence?.inContactWithCareTeam === true"
      >
        <p>
          I am in touch with the continence care team.

          <span
            *ngIf="
              user?.support?.continence?.careTeam?.name ||
              user?.support?.continence?.careTeam?.phone ||
              user?.support?.continence?.careTeam?.email ||
              user?.support?.continence?.careTeam?.address1
            "
          >
            These are their contact details:
          </span>
        </p>

        <div class="contact-details italic">
          <p>
            {{ user?.support?.continence?.careTeam?.name }}
          </p>
          <p>
            {{ user?.support?.continence?.careTeam?.address?.address1 }}
          </p>
          <p>
            {{ user?.support?.continence?.careTeam?.address?.address2 }}
          </p>
          <p>
            {{ user?.support?.continence?.careTeam?.address?.city }}
          </p>
          <p>
            {{ user?.support?.continence?.careTeam?.address?.state }}
          </p>
          <p>
            {{ user?.support?.continence?.careTeam?.address?.postcode }}
          </p>
          <p>
            {{ user?.support?.continence?.careTeam?.address?.country }}
          </p>
        </div>

        <div class="mt-2 contact-details">
          <p *ngIf="user?.support?.continence?.careTeam?.phone">
            Phone:
            <span class="italic">{{
              user?.support?.continence?.careTeam?.phone
            }}</span>
          </p>
          <p *ngIf="user?.support?.continence?.careTeam?.email">
            Email:
            <span class="italic">{{
              user?.support?.continence?.careTeam?.email
            }}</span>
          </p>
        </div>
      </ng-container>

      <p *ngIf="!hasContinenceDetails" class="italic">No details added.</p>
      <!--END CONTINENCE-->
    </div>
  </ng-container>
  <!--END CONTENT-->
</div>
