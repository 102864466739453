<div class="container-sm">
  <h1 class="font-semibold text-3xl md:text-4xl mb-6">
    Manage your Cookie Preferences
  </h1>

  <p>
    You can manage your cookie preferences for Community Connections below. Read
    our
    <a routerLink="/privacy-policy" class="text-theme">Privacy Policy</a> to
    find more about cookies and how and why we use them.
  </p>

  <div class="flex mb-0 mt-10">
    <div class="flex items-center mr-4">
      <label class="switch">
        <input type="checkbox" checked disabled />
        <span
          class="slider"
          [ngClass]="{ 'disabled-cookie-slider': true }"
        ></span>
      </label>
    </div>
    <h4 class="pb-1 my-2">Essential Cookies (Required)</h4>
  </div>

  <p class="mt-0">
    These cookies are essential for our website to function properly. They
    encompass cookies necessary for accessing secure sections of the website
    where contents of the Personal Data Store (PDS) can be managed.
  </p>

  <div class="flex mt-6">
    <div class="flex items-center mr-4">
      <label class="switch">
        <input type="checkbox" [(ngModel)]="googleAnalyticsConsent" />
        <span class="slider"></span>
      </label>
    </div>
    <h4 class="pb-1 my-2">Analytics Cookies (Optional)</h4>
  </div>

  <p class="mt-0">
    We use Google Analytics cookies to gather insights into how our website
    performs and how users interact with it. These cookies track metrics like
    page views, session durations and the usage of specific features.
  </p>

  <div class="flex mt-6">
    <div class="flex items-center mr-4">
      <label class="switch">
        <input type="checkbox" [(ngModel)]="vimeoConsent" />
        <span class="slider"></span>
      </label>
    </div>
    <h4 class="pb-1 my-2">Video Player Cookies (Optional)</h4>
  </div>

  <p class="mt-0">
    We utilize Vimeo's services to display embedded videos. Vimeo might employ
    cookies to gather data on the videos you watch, primarily for analytics and
    advertising reasons. For details regarding their cookie practices, please
    refer to Vimeo's website.
  </p>

  <div class="flex">
    <button
      class="btn btn-theme px-4 my-6"
      (click)="savePreferences()"
      aria-label="Accept cookies"
    >
      Save Preferences
    </button>

    <p *ngIf="showSuccessMessage" class="text-theme ml-4 my-auto">
      Success! Please refresh the page to ensure all changes take effect.
    </p>
  </div>
</div>
