<div class="card bg-white mx-auto max-w-[550px] w-full rounded-lg p-16">
  <h1 class="text-theme font-medium text-3xl mt-0 mb-8">Quarriers referral</h1>

  <p class="text-lg">
    Quarriers are experts on the support landscape in Moray and will help to
    identify your needs and help where possible. They'll learn a little more
    about your situation and are there to support you and the person you care
    for.
  </p>

  <!-- QUARRIERS GENERAL -->
  <ng-container *ngIf="!(authService.isAuthenticated$ | async)">
    <div class="flex mt-16 mb-4">
      <img
        class="mb-auto w-28 mr-4 mt-2"
        src="/assets/img/logomark.svg"
        alt=""
      />
      <div>
        <h2 class="text-theme font-medium m-0">
          <strong>About Me</strong>
        </h2>
        <p class="text-lg">
          Create your <strong>About Me</strong> and share your info with
          Quarriers, as well as other services you choose.
        </p>
        <a
          class="btn btn-theme btn-sm"
          routerLink="/register"
          [queryParams]="{ from: 'quarriers' }"
          >About Me</a
        >
      </div>
    </div>

    <hr class="my-12" />

    <h2 class="text-theme font-medium my-8">
      Don't want to set up an <strong>About Me</strong> now?
    </h2>
    <p class="text-lg">Call Quarriers on 01505 612224. Open 9am-6pm, Mon-Sat</p>
    <p class="text-lg">
      Send an email to
      <a class="text-theme" href="mailto:info@quarriers.co.uk"
        >info&#64;quarriers.co.uk</a
      >
      and someone will get back to you.
    </p>
  </ng-container>
  <!-- QUARRIERS SIGNED IN -->

  <!-- QUARRIERS SIGNED IN -->
  <!-- TODO: Discuss again with Chal as this is just a temporary solution for the demo -->
  <ng-container *ngIf="authService.isAuthenticated$ | async">
    <hr class="my-12" />

    <h2 class="text-theme font-medium">Contact Quarriers directly</h2>
    <p class="text-lg">
      Call Quarriers on 01505 612224. Open 9am-6pm, Mon-Sat.
    </p>
    <p class="text-lg">
      Send an email to
      <a class="text-theme" href="mailto:info@quarriers.co.uk"
        >info&#64;quarriers.co.uk</a
      >
      and someone will get back to you.
    </p>

    <div class="flex mt-12 mb-4">
      <div>
        <h2 class="text-theme font-medium m-0">Support via app</h2>
        <p class="text-lg mb-8">
          If you want to use the Quarriers Carer App, which will provide more
          tailored support, then click on the button below:
        </p>
        <a class="btn btn-theme btn-sm" href="https://cip-cfp.mydex.org/"
          >Carer App</a
        >
      </div>
    </div>
  </ng-container>
  <!-- END QUARRIERS SIGNED IN -->
</div>
