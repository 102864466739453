<ng-container *ngIf="cta">
  <a
    *ngIf="cta.type === 'routerlink'"
    [routerLink]="cta.link"
    [class]="classForLink"
  >
    {{ cta.label }}
  </a>
  <a
    *ngIf="cta.type === 'href'"
    [href]="cta.link"
    target="_blank"
    [class]="classForLink"
  >
    {{ cta.label }}
  </a>
</ng-container>
