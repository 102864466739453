import {
  FormItemButtonSelect,
  FormItemDate,
  FormItemDropdown,
  FormItemSubform,
  FormItemTextArea,
  FormItemTextbox,
} from 'src/app/models/form/form';
import { yesNoOptions, yesNoUnknownOptions } from '../item-options';
import { Validators } from '@angular/forms';
import { DefaultShortDisplayComponent } from 'src/app/components/form/displays/default-short-display/default-short-display.component';

const name = new FormItemTextbox({
  key: 'name',
  label: 'I care for',
  placeholder: 'Their Name',
  class: 'mb-5',
  required: true,
  validators: [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(2000),
  ],
});

const ageGroup = new FormItemButtonSelect({
  key: 'ageGroup',
  label: 'Their age group',
  class: 'mb-5',
  multiple: false,
  options: [
    { name: 'Under 18', label: 'Under 18' },
    { name: '19-24', label: '19-24' },
    { name: '25-34', label: '25-34' },
    { name: '35-44', label: '35-44' },
    { name: '45-54', label: '45-54' },
    { name: '55-64', label: '55-64' },
    { name: '65-74', label: '65-74' },
    { name: '75-84', label: '75-84' },
    { name: '85-94', label: '85-94' },
    { name: '95+', label: '95+' },
  ],
});

const residence = new FormItemButtonSelect({
  key: 'residence',
  label: 'Where they live',
  class: 'mb-5',
  multiple: false,
  options: [
    { name: 'With me', label: 'With me' },
    { name: 'In their own home', label: 'In their own home' },
    { name: 'Nursing Care', label: 'Nursing Care' },
    { name: 'Supported living', label: 'Supported living' },
  ],
});

// TODO: update once pds definition of ethnicity is available
const ethnicity = new FormItemTextbox({
  key: 'ethnicity',
  label: 'Their ethnicity',
  placeholder: 'White Scottish',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

const hasCareNeedsAssessment = new FormItemButtonSelect({
  key: 'hasCareNeedsAssessment',
  label: 'The person I care for has a care needs assessment',
  class: 'mb-5',
  multiple: false,
  options: yesNoUnknownOptions,
});

const careNeedsAssessmentDate = new FormItemDate({
  key: 'careNeedsAssessmentDate',
  label: 'The assessment was done',
  class: 'mb-5',
  width: 'half',
  minDate: new Date(1900, 0, 1),
  maxDate: new Date(),
  displayConditions: [
    {
      key: 'hasCareNeedsAssessment',
      values: ['Yes'],
    },
  ],
});

const careNeedsAssessmentDoneBy = new FormItemTextbox({
  key: 'careNeedsAssessmentDoneBy',
  label: 'The assessment was done by',
  class: 'mb-5',
  displayConditions: [
    {
      key: 'hasCareNeedsAssessment',
      values: ['Yes'],
    },
  ],
});

const careNeedsAssessmentNotDoneReason = new FormItemTextArea({
  key: 'careNeedsAssessmentNotDoneReason',
  label: 'The assessment was not done because...',
  class: 'mb-5',
  displayConditions: [
    {
      key: 'hasCareNeedsAssessment',
      values: ['No'],
    },
  ],
});

const hadOccupationalTherapyAssessment = new FormItemButtonSelect({
  key: 'hadOccupationalTherapyAssessment',
  label: 'The person I care for has had an Occupational Therapy assessment',
  class: 'mb-5',
  multiple: false,
  options: yesNoUnknownOptions,
});

const needs = new FormItemButtonSelect({
  key: 'needs',
  label: 'The person I care for has the following needs',
  class: 'mb-5',
  multiple: true,
  options: [
    { name: 'Dementia', label: 'Dementia' },
    { name: 'Learning Disability', label: 'Learning Disability' },
    { name: 'Physical Disability', label: 'Physical Disability' },
    { name: 'Palliative Care', label: 'Palliative Care' },
    { name: 'Alcohol problems', label: 'Alcohol problems' },
    { name: 'Drug problems', label: 'Drug problems' },
    { name: 'Frail older person', label: 'Frail older person' },
    { name: 'Mental health problems', label: 'Mental health problems' },
    { name: 'Autistic Spectrum Disorder', label: 'Autistic Spectrum Disorder' },
    { name: 'Neurological condition', label: 'Neurological condition' },
    { name: 'Not known', label: 'Not known' },
    { name: 'Other', label: 'Other' },
  ],
});

const careProvided = new FormItemButtonSelect({
  key: 'careProvided',
  label: 'The care I provide includes',
  class: 'mb-5',
  multiple: true,
  options: [
    { name: 'Help with Medication', label: 'Help with Medication' },
    { name: 'Help with personal care', label: 'Help with personal care' },
    { name: 'Help with transport', label: 'Help with transport' },
    { name: 'Help with domestic tasks', label: 'Help with domestic tasks' },
    { name: 'Supervision support', label: 'Supervision support' },
    { name: 'Emotional support', label: 'Emotional support' },
    { name: 'Other', label: 'Other' },
  ],
});

const situationSummary = new FormItemTextArea({
  key: 'situationSummary',
  label: 'Brief summary of the person I care for’s situation',
  class: 'mb-5',
});

const caringIsVoluntary = new FormItemButtonSelect({
  key: 'caringIsVoluntary',
  label: 'I feel I have a choice about providing care for this person',
  class: 'mb-5',
  multiple: false,
  options: yesNoOptions,
});

export const supportedPeopleListOfPeople = new FormItemSubform({
  key: 'supportedPeopleListOfPeople',
  path: 'supportingRole.supportedPeople.listOfPeople',
  multiple: true,
  addTitle: 'Add a person',
  displayComponent: DefaultShortDisplayComponent,
  display: 'modal',
  class: 'mb-5',
  label: 'People you care for',
  subform: [
    name,
    ageGroup,
    residence,
    ethnicity,
    hasCareNeedsAssessment,
    careNeedsAssessmentDate,
    careNeedsAssessmentDoneBy,
    careNeedsAssessmentNotDoneReason,
    hadOccupationalTherapyAssessment,
    needs,
    careProvided,
    situationSummary,
    caringIsVoluntary,
  ],
});

export const supportedPeopleSupportInYears = new FormItemDropdown({
  key: 'supportedPeopleSupportInYears',
  path: 'supportingRole.supportedPeople.supportInYears',
  label: 'I have provided care for',
  class: 'mb-5',
  placeholder: 'Please select...',
  options: [
    { name: 'Under one year', label: 'Under one year' },
    {
      name: 'more than 1 year but less than 5 years',
      label: 'more than 1 year but less than 5 years',
    },
    {
      name: 'more than 5 years but less than 10 years',
      label: 'more than 5 years but less than 10 years',
    },
    {
      name: 'more than 10 years but less than 20 years',
      label: '10 year but less than 20 years',
    },
    { name: '20 years or more', label: '20 years or more' },
    { name: 'Not known', label: 'Not known' },
  ],
});

export const supportedPeopleWeeklyHours = new FormItemDropdown({
  key: 'supportedPeopleWeeklyHours',
  path: 'supportingRole.supportedPeople.weeklyHours',
  label:
    'In an average week, my caring hours total (include evenings/overnight activity)',
  class: 'mb-5',
  placeholder: 'Please select...',
  options: [
    { name: '4 hours or less', label: '4 hours or less' },
    { name: '5-19 hours', label: '5-19 hours' },
    { name: '20-34 hours', label: '20-34 hours' },
    { name: '35-49 hours', label: '35-49 hours' },
    { name: '50+ hours', label: '50+ hours' },
    { name: 'Not known', label: 'Not known' },
  ],
});
