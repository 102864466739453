import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-default-short-display',
  templateUrl: './default-short-display.component.html',
  styleUrls: ['./default-short-display.component.css'],
})
export class DefaultShortDisplayComponent {
  @Input() value: any;

  get content(): any {
    const keys = Object.keys(this.value);

    return {
      title: this.value[keys[0]],
    };
  }
}
