import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SessionStorageService } from '../services/session-storage/session-storage.service';

export const RegisterGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const sessionStorageService = inject(SessionStorageService);

  const registerEnabled =
    environment.enableRegistration ||
    sessionStorageService.getOverrideRegistration();

  if (registerEnabled) {
    return true;
  }
  // the registration is disabled, redirect to login page
  return router.createUrlTree(['/login']);
};
