import { Validators } from '@angular/forms';
import { FormItemTextArea } from 'src/app/models/form/form';

// KEY INFORMATION - SUPPORT PACKAGE -----------------------------------------------

export const supportGeneralInfo = new FormItemTextArea({
  key: 'supportGeneralInfo',
  path: 'keyInformation.supportPackage.generalInfo',
  label: 'What support do you currently have in place?',
  placeholder: 'e.g. 3 visits a day: breakfast, lunch, evening',
  class: 'mb-5',
  width: 'half',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const supportOrganisations = new FormItemTextArea({
  key: 'supportOrganisations',
  path: 'keyInformation.supportPackage.organisations',
  label: 'What organisations do you use?',
  placeholder: 'e.g. Allied',
  class: 'mb-5',
  width: 'half',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const supportSchedule = new FormItemTextArea({
  key: 'supportSchedule',
  path: 'keyInformation.supportPackage.schedule',
  label: 'What is your schedule of support?',
  placeholder: 'e.g. 7 days a week, 3 visits each day',
  class: 'mb-5',
  width: 'half',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});
