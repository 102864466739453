import {
  FormItem,
  FormItemTextArea,
  FormItemTitle,
} from 'src/app/models/form/form';
import {
  communicationFirstLanguage,
  communicationGeneralAdditionalInfo,
  communicationGeneralAids,
  communicationHearingAids,
  communicationPreferredTypes,
  communicationPreferredTypesAdditionalInfo,
  communicationSignLanguages,
  communicationSignLanguagesAdditionalInfo,
  communicationExistingSupport,
} from './communication-items';
import {
  medicationListOfMedications,
  prescriptionFeatureComingSoonDescription,
  prescriptionFeatureComingSoonTitle,
} from './medication-items';
import { generalFitnessSelfAssessedLevel } from './general-fitness-items';
import {
  practicalHelpExistingSupport,
  practicalHelpAreasWithNoSupportNeed,
  practicalHelpAreasWithSupportNeed,
  practicalHelpGeneralAdditionalInfo,
} from './practical-help-items';
import {
  movingAroundInsideAids,
  movingAroundInsideAidsAdditionalInfo,
  movingAroundInsideAreasWithNoSupportNeed,
  movingAroundInsideAreasWithSupportNeed,
  movingAroundInsideAreasWithSupportNeedAdditionalInfo,
  movingAroundInsideExistingSupport,
  movingAroundInsideStairsManagement,
  movingAroundInsideUnsteadinessOccurrences,
} from './moving-around-inside-items';
import {
  movingAroundOutsideAids,
  movingAroundOutsideAidsAdditionalInfo,
  movingAroundOutsideAreasWithNoSupportNeed,
  movingAroundOutsideAreasWithSupportNeed,
  movingAroundOutsideAreasWithSupportNeedAdditionalInfo,
  movingAroundOutsideExistingSupport,
  movingAroundOutsideGeneralAdditionalInfo,
  movingAroundOutsideUnsteadinessOccurrences,
} from './moving-around-outside-items';
import {
  gettingWashedDentalDetails,
  gettingWashedFrequency,
  gettingWashedGeneralAdditionalInfo,
  gettingWashedHaircareDetails,
  gettingWashedMethods,
  gettingWashedShavingDetails,
  gettingWashedTimes,
} from './getting-washed-items';
import {
  personalAidsInUse,
  personalAidsInUseAdditionalInfo,
} from './personal-aids-items';
import {
  continenceCareTeamContactDetailsTitle,
  continenceCareTeamEmail,
  continenceCareTeamName,
  continenceCareTeamPhone,
  continenceInContactWithCareTeam,
  continenceProductsInUse,
  continenceRoutines,
  continenceSupportNeededInCaseOfEmergency,
} from './continence-items';
import {
  eatingAndDrinkingAreasWithNoSupportNeed,
  eatingAndDrinkingAvoidedFoodsAndDrinks,
  eatingAndDrinkingDiet,
  eatingAndDrinkingDietaryRestrictionsTitle,
  eatingAndDrinkingEquipmentOrPreferences,
  eatingAndDrinkingEquipmentOrPreferencesAdditionalInfo,
  eatingAndDrinkingFavouriteMeal,
  eatingAndDrinkingGeneralAdditionalInfo,
  eatingAndDrinkingImpairments,
  eatingAndDrinkingImpairmentsAdditionalInfo,
  eatingAndDrinkingPreferredDiningLocation,
  eatingAndDrinkingPreferredFoodsAndDrinks,
  eatingAndDrinkingSupportTitle,
} from './eating-and-drinking-items';
import { sleepRoutine, startDayTime } from '../me-items/routines-items';
import {
  bedtimeGettingToBedTitle,
  bedtimePreferredBedtime,
  bedtimeSleepPatternTitle,
  bedtimeGettingToBedSupportNeeded,
  bedtimeGettingToBedAreasWithSupportNeed,
  bedtimeGettingToBedEquipmentInUse,
  bedtimeGettingToBedSupportAdditionalInfo,
  bedtimeGettingToBedSupportPhoto,
  bedtimeGettingUpThroughNight,
  bedtimeGettingUpThroughNightSupportNeeded,
  bedtimeGettingUpTitle,
  bedtimeGettingUpSupportNeeded,
  bedtimeGettingUpAreasWithSupportNeed,
  bedtimeGettingUpEquipmentInUse,
  bedtimeGettingUpSupportAdditionalInfo,
} from './bedtime-items';
import { Validators } from '@angular/forms';
import { allergies } from '../key-info-items/medical-details-items';
import {
  fallsHadRecentFall,
  fallsMostRecentFall,
  fallsMostRecentFallDetails,
  fallsMostRecentFallDetailsTitle,
  fallsMostRecentFallMedicalCareProvider,
  fallsMostRecentFallSoughtMedicalCare,
  fallsPattern,
  fallsPresumedCauses,
  fallsRecentFallsFrequency,
} from './falls-items';
import {
  digitalDeviceMonitoredConditions,
  digitalDeviceMonitoredConditionsAdditionalInfo,
  digitalMostUsedDevices,
  digitalSharedRecordsInUse,
  digitalSharedRecordsInUseAdditionalInfo,
  digitalTechnologySupportInUse,
  digitalTechnologySupportInUseAdditionalInfo,
  digitalTechnologySupportPurposes,
  digitalTechnologySupportPurposesAdditionalInfo,
} from './digital-items';
import {
  actionsListOwnTasks,
  actionsListTasksWithSupportNeed,
  actionsListUrgentTasks,
} from './actions-list-items';
import {
  footCarePodiatristDetails,
  footCarePodiatristTreatmentDetails,
  footCareProblems,
  footCareProblemsAdditionalInfo,
  footCareRoutine,
  footCareWasTreatedByPodiatrist,
} from './foot-care-items';
import { medicalHistoryPresentConditions } from '../health-items/medical-history-items';
import { getFormItemsAddress } from '../../shared-items/address';

// All form items for user - my support
export function getFormItemsUserSupport(): FormItem<any>[] {
  return [
    new FormItemTitle({ label: 'Communication' }),
    ...getFormItemsUserSupportCommunication(),
    new FormItemTitle({ label: 'My Medication' }),
    ...getFormItemsUserSupportMedication(),
    new FormItemTitle({ label: 'General Fitness' }),
    ...getFormItemsUserSupportGeneralFitness(),
    new FormItemTitle({ label: 'Practical Help' }),
    ...getFormItemsUserSupportPracticalHelp(),
    new FormItemTitle({ label: 'Moving Around Inside' }),
    ...getFormItemsUserSupportMovingAroundInside(),
    new FormItemTitle({ label: 'Moving Around Outside' }),
    ...getFormItemsUserSupportMovingAroundOutside(),
    new FormItemTitle({ label: 'Getting washed' }),
    ...getFormItemsUserSupportGettingWashed(),
    new FormItemTitle({ label: 'Personal Aids' }),
    ...getFormItemsUserSupportPersonalAids(),
    new FormItemTitle({ label: 'Continence' }),
    ...getFormItemsUserSupportContinence(),
    new FormItemTitle({ label: 'Eating & Drinking' }),
    ...getFormItemsUserSupportEatingAndDrinking(),
    new FormItemTitle({ label: 'Bedtime' }),
    ...getFormItemsUserSupportBedtime(),
    new FormItemTitle({ label: 'Falls' }),
    ...getFormItemsUserSupportFalls(),
    new FormItemTitle({ label: 'Digital' }),
    ...getFormItemsUserSupportDigital(),
    new FormItemTitle({ label: 'Actions List' }),
    ...getFormItemsUserSupportActionsList(),
    new FormItemTitle({ label: 'Foot Care' }),
    ...getFormItemsUserSupportFootCare(),
  ];
}

// All form items for user - my support - communication
export function getFormItemsUserSupportCommunication(): FormItem<any>[] {
  return [
    communicationFirstLanguage,
    communicationPreferredTypes,
    communicationPreferredTypesAdditionalInfo,
    communicationGeneralAids,
    communicationSignLanguages,
    communicationSignLanguagesAdditionalInfo,
    communicationHearingAids,
    communicationExistingSupport,
    communicationGeneralAdditionalInfo,
    supportOtherAreasWithSupportNeed,
  ];
}

// All form items for user - my support - my medication
export function getFormItemsUserSupportMedication(): FormItem<any>[] {
  return [
    medicationListOfMedications,
    prescriptionFeatureComingSoonTitle,
    prescriptionFeatureComingSoonDescription,
  ];
}

// All form items for user - my support - general fitness
export function getFormItemsUserSupportGeneralFitness(): FormItem<any>[] {
  return [generalFitnessSelfAssessedLevel];
}

// All form items for user - my support - practical help
export function getFormItemsUserSupportPracticalHelp(): FormItem<any>[] {
  return [
    practicalHelpAreasWithNoSupportNeed,
    practicalHelpAreasWithSupportNeed,
    practicalHelpExistingSupport,
    practicalHelpGeneralAdditionalInfo,
  ];
}

// All form items for user - my support - moving around inside
export function getFormItemsUserSupportMovingAroundInside(): FormItem<any>[] {
  return [
    movingAroundInsideAreasWithNoSupportNeed,
    movingAroundInsideAreasWithSupportNeed,
    movingAroundInsideAreasWithSupportNeedAdditionalInfo,
    movingAroundInsideAids,
    movingAroundInsideAidsAdditionalInfo,
    movingAroundInsideStairsManagement,
    movingAroundInsideUnsteadinessOccurrences,
    movingAroundInsideExistingSupport,
    supportOtherAreasWithSupportNeed,
  ];
}

// All form items for user - my support - moving around outside
export function getFormItemsUserSupportMovingAroundOutside(): FormItem<any>[] {
  return [
    movingAroundOutsideAreasWithNoSupportNeed,
    movingAroundOutsideAreasWithSupportNeed,
    movingAroundOutsideAreasWithSupportNeedAdditionalInfo,
    movingAroundOutsideAids,
    movingAroundOutsideAidsAdditionalInfo,
    movingAroundOutsideUnsteadinessOccurrences,
    movingAroundOutsideExistingSupport,
    movingAroundOutsideGeneralAdditionalInfo,
    supportOtherAreasWithSupportNeed,
  ];
}

// All form items for user - my support - getting washed
export function getFormItemsUserSupportGettingWashed(): FormItem<any>[] {
  return [
    gettingWashedMethods,
    gettingWashedFrequency,
    gettingWashedTimes,
    gettingWashedShavingDetails,
    gettingWashedHaircareDetails,
    gettingWashedDentalDetails,
    gettingWashedGeneralAdditionalInfo,
    supportOtherAreasWithSupportNeed,
  ];
}

// All form items for user - my support - personal aids
export function getFormItemsUserSupportPersonalAids(): FormItem<any>[] {
  return [personalAidsInUse, personalAidsInUseAdditionalInfo];
}

// All form items for user - my support - continence
export function getFormItemsUserSupportContinence(): FormItem<any>[] {
  return [
    continenceProductsInUse,
    continenceRoutines,
    continenceSupportNeededInCaseOfEmergency,
    continenceInContactWithCareTeam,
    continenceCareTeamContactDetailsTitle,
    continenceCareTeamName,
    // address with display condition
    ...getFormItemsAddress('support.continence.careTeam.address', [
      { key: 'continenceInContactWithCareTeam', values: [true] },
    ]),
    continenceCareTeamPhone,
    continenceCareTeamEmail,
    supportOtherAreasWithSupportNeed,
  ];
}

// All form items for user - my support - eating and drinking
export function getFormItemsUserSupportEatingAndDrinking(): FormItem<any>[] {
  return [
    eatingAndDrinkingDietaryRestrictionsTitle,
    // Reusing item from Key Information - Medical Details // TODO: figure out storage in pds
    allergies,
    eatingAndDrinkingDiet,
    eatingAndDrinkingFavouriteMeal,
    eatingAndDrinkingPreferredFoodsAndDrinks,
    eatingAndDrinkingAvoidedFoodsAndDrinks,
    eatingAndDrinkingSupportTitle,
    eatingAndDrinkingAreasWithNoSupportNeed,
    eatingAndDrinkingImpairments,
    eatingAndDrinkingImpairmentsAdditionalInfo,
    eatingAndDrinkingEquipmentOrPreferences,
    eatingAndDrinkingEquipmentOrPreferencesAdditionalInfo,
    eatingAndDrinkingGeneralAdditionalInfo,
    eatingAndDrinkingPreferredDiningLocation,
    supportOtherAreasWithSupportNeed,
  ];
}

// All form items for user - my support - bedtime
export function getFormItemsUserSupportBedtime(): FormItem<any>[] {
  return [
    bedtimeSleepPatternTitle,
    // Reusing item from Key Information // TODO: figure out storage in pds
    sleepRoutine,
    bedtimePreferredBedtime,
    // Reusing item from Key Information // TODO: figure out storage in pds
    startDayTime,
    bedtimeGettingToBedTitle,
    bedtimeGettingToBedSupportNeeded,
    bedtimeGettingToBedAreasWithSupportNeed,
    bedtimeGettingToBedEquipmentInUse,
    bedtimeGettingToBedSupportAdditionalInfo,
    bedtimeGettingToBedSupportPhoto,
    bedtimeGettingUpThroughNight,
    bedtimeGettingUpThroughNightSupportNeeded,
    bedtimeGettingUpTitle,
    bedtimeGettingUpSupportNeeded,
    bedtimeGettingUpAreasWithSupportNeed,
    bedtimeGettingUpEquipmentInUse,
    bedtimeGettingUpSupportAdditionalInfo,
    supportOtherAreasWithSupportNeed,
  ];
}

// All form items for user - my support - falls
export function getFormItemsUserSupportFalls(): FormItem<any>[] {
  return [
    fallsHadRecentFall,
    fallsRecentFallsFrequency,
    fallsMostRecentFallDetailsTitle,
    fallsMostRecentFallDetails,
    fallsMostRecentFall,
    fallsMostRecentFallSoughtMedicalCare,
    fallsMostRecentFallMedicalCareProvider,
    fallsPattern,
    fallsPresumedCauses,
  ];
}

// All form items for user - my support - digital
export function getFormItemsUserSupportDigital(): FormItem<any>[] {
  return [
    digitalTechnologySupportInUse,
    digitalTechnologySupportInUseAdditionalInfo,
    digitalTechnologySupportPurposes,
    digitalTechnologySupportPurposesAdditionalInfo,
    digitalMostUsedDevices,
    digitalDeviceMonitoredConditions,
    digitalDeviceMonitoredConditionsAdditionalInfo,
    digitalSharedRecordsInUse,
    digitalSharedRecordsInUseAdditionalInfo,
  ];
}

// All form items for user - my support - support package
export function getFormItemsUserSupportActionsList(): FormItem<any>[] {
  return [
    actionsListOwnTasks,
    actionsListTasksWithSupportNeed,
    actionsListUrgentTasks,
  ];
}

// All form items for user - my support - support package
export function getFormItemsUserSupportFootCare(): FormItem<any>[] {
  return [
    footCareRoutine,
    footCareProblems,
    footCareProblemsAdditionalInfo,
    footCareWasTreatedByPodiatrist,
    footCarePodiatristTreatmentDetails,
    footCarePodiatristDetails,
    // Reusing item from Health & Wellbeing - Medical History // TODO: figure out storage in pds
    medicalHistoryPresentConditions,
  ];
}

// item is used in several support subsections
export const supportOtherAreasWithSupportNeed = new FormItemTextArea({
  key: 'supportOtherAreasWithSupportNeed',
  path: 'support.otherAreasWithSupportNeed',
  label:
    'Are there any other areas you need support with which are not being met?',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});
