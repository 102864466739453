<div class="container-xl">
  <div class="flex justify-between items-center">
    <h2 class="text-4xl mb-12">All Connections</h2>
    <button
      routerLink="/admin/add-connection"
      class="btn-theme-outline outlined px-12 ml-2"
    >
      Add a new connection
    </button>
  </div>
  <input-filter
    [value]="searchTerm"
    [placeholder]="'Search'"
    [clearable]="true"
    (onChanged)="filterList($event)"
  ></input-filter>
  <!-- TODO: implement filter
  <button
    class="btn me-1 px-8"
    [class.btn-theme]="filter === ''"
    (click)="setFilter('')"
  >
    All
  </button>
  <button
    [class.btn-theme]="filter === 'finderPath'"
    class="btn px-8 mb-8"
    (click)="setFilter('finderPath')"
  >
    Path "me.practical"
  </button>
  -->
  <div>
    <app-connections
      [connections]="connections"
      linkRoute="/admin/connections/"
      isAdminDisplay="true"
    ></app-connections>
  </div>
</div>
