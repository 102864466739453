import { Address } from './address';

export class ContactDetails {
  address?: Address;
  email?: string;
  phone?: string;

  constructor(data: Partial<ContactDetails | undefined>) {
    this.address = data?.address;
    this.email = data?.email;
    this.phone = data?.phone;
  }
}
