import { Component, Input, SimpleChanges, forwardRef } from '@angular/core';
import {
  FormGroup,
  FormControl,
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  NG_VALIDATORS,
  AbstractControl,
} from '@angular/forms';
import {
  computeCurrentAgeFromDateOfBirth,
  utcDateAtMidnightFromYmd,
} from 'src/app/helper/dateHelper';

@Component({
  selector: 'app-dob',
  templateUrl: './dob.component.html',
  styleUrls: ['./dob.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DobComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DobComponent),
      multi: true,
    },
  ],
})
export class DobComponent implements ControlValueAccessor {
  dobFormGroup = new FormGroup({
    day: new FormControl(''),
    month: new FormControl(''),
    year: new FormControl(''),
  });

  @Input() item: any; // Your item type here
  @Input() disabled = false;

  onChange = (value: any) => {};
  onTouched = () => {};
  validatorChange: any;

  get age(): number {
    const { day, month, year } = this.dobFormGroup.value;
    if (!day || !month || !year) {
      return 0;
    }

    const dob = utcDateAtMidnightFromYmd(
      parseInt(year),
      parseInt(month),
      parseInt(day)
    );

    if (!this.isValidDob(dob)) {
      return 0;
    }

    return computeCurrentAgeFromDateOfBirth(dob);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['disabled']) {
      if (this.disabled) {
        this.dobFormGroup.disable();
      } else {
        this.dobFormGroup.enable();
      }
    }
  }

  validate(control: AbstractControl): { [key: string]: any } | null {
    // handle value sometimes being an object of year, month, day; sometimes a date; sometimes a string
    const value = control.value;
    const required = this?.item?.required ? { required: true } : null;

    // case null
    if (!value) {
      return required;
    }

    var dob: undefined | Date = undefined;

    // case date
    if (value instanceof Date) {
      if (isNaN(value.getTime())) {
        return { invalidDOB: true };
      }

      dob = value;
    }
    // case object of year, month, day
    else if (typeof value == 'object') {
      if (!value.year || !value.month || !value.day) {
        return required;
      }

      dob = utcDateAtMidnightFromYmd(
        parseInt(value.year),
        parseInt(value.month),
        parseInt(value.day)
      );
    } else if (typeof value == 'string') {
      dob = new Date(value);
    }

    if (!this.isValidDob(dob)) {
      return { invalidDOB: true };
    }

    return null;
  }

  writeValue(value: Date | undefined): void {
    if (!value) {
      return;
    }

    const dob = new Date(value);

    if (isNaN(dob.getTime())) {
      return;
    }

    const day = dob.getUTCDate().toString();
    const month = dob.getUTCMonth().toString();
    const year = dob.getUTCFullYear().toString();

    this.dobFormGroup.setValue({
      day: day,
      month: month,
      year: year,
    });
    this.dobFormGroup.updateValueAndValidity();
  }

  registerOnValidatorChange(fn: any): void {
    this.validatorChange = fn;
    this.dobFormGroup.addValidators(() => this.validate(this.dobFormGroup));
    this.dobFormGroup.updateValueAndValidity();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
    this.dobFormGroup.valueChanges.subscribe((value) => {
      if (!value?.year && !value?.month && !value?.day) {
        // Account for case where user empties all fields and submit
        this.onChange(null);
      } else {
        let utcDate = utcDateAtMidnightFromYmd(
          parseInt(value?.year || ''),
          parseInt(value?.month || ''),
          parseInt(value?.day || '')
        );

        this.onChange(utcDate);
      }
      this.validatorChange();
    });
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  isValidDob(inputDateUtc: Date | undefined): boolean {
    if (!inputDateUtc) {
      return false;
    }

    // Check if the date is a valid date (e.g. 31st of February is invalid)
    if (isNaN(inputDateUtc.getTime())) {
      return false;
    }

    // Ensure that the date is the same or later than the minimum date (compare UTC dates)
    if (this.item.minDate && this.item.minDate > inputDateUtc) {
      return false;
    }

    // Ensure that the date is the same or before the maximum date (compare UTC dates)
    if (this.item.maxDate && this.item.maxDate < inputDateUtc) {
      return false;
    }

    return true;
  }
}
