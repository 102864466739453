import { Injectable } from '@angular/core';
import { Observable, from, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Connection } from '../models/connection/connection';
import { ConnectionListServerModel } from '../models/server-models/connectionListServerModel';
import { ConnectionServerModel } from '../models/server-models/connectionServerModel';
import { MrdConnectionRequest } from '../models/server-models/mrdConnectionRequest';

@Injectable({
  providedIn: 'root',
})
export class ConnectionsApiService {
  private readonly baseUrl: string;
  http: any;

  constructor(private httpClient: HttpClient) {
    this.baseUrl = environment.apiBaseUrl + '/community-connections';
    this.http = this.httpClient;
  }

  getAllConnections(): Observable<Connection[]> {
    return this.http.get(this.baseUrl + '/connections').pipe(
      map((response: ConnectionListServerModel) => {
        let connections: Connection[] = response.connections.map(
          (serverModel: ConnectionServerModel) =>
            Connection.fromConnectionServerModel(
              new ConnectionServerModel(serverModel)
            )
        );
        return connections;
      })
    );
  }

  addConnectionFromMrd(
    mrdConnectionRequest: MrdConnectionRequest
  ): Observable<Connection> {
    return this.http
      .post(this.baseUrl + '/connection-mrd', mrdConnectionRequest)
      .pipe(
        map((connectionServerModel: ConnectionServerModel) =>
          Connection.fromConnectionServerModel(connectionServerModel)
        )
      );
  }

  createConnection(connection: Connection): Observable<Connection> {
    return this.http
      .post(
        this.baseUrl + '/connection',
        ConnectionServerModel.fromConnection(connection)
      )
      .pipe(
        map((connectionServerModel: ConnectionServerModel) =>
          Connection.fromConnectionServerModel(connectionServerModel)
        )
      );
  }

  /**
   * Updates a connection with the given name.
   * @param previousConnectionName the name/slug the connection had before it was updated (to identify the connection)
   * @param connection the updated connection (can have but does not need to have the same name as previously)
   * @returns the updated connection
   */
  updateConnection(
    previousConnectionName: string,
    connection: Connection
  ): Observable<Connection> {
    return this.http
      .put(
        `${this.baseUrl}/connection/${previousConnectionName}`,
        ConnectionServerModel.fromConnection(connection)
      )
      .pipe(
        map((connectionServerModel: ConnectionServerModel) =>
          Connection.fromConnectionServerModel(connectionServerModel)
        )
      );
  }

  deleteConnection(connectionName: string): Observable<string> {
    return this.http.delete(`${this.baseUrl}/connection/${connectionName}`, {
      responseType: 'text',
    });
  }
}
