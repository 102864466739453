import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-stateful-loading-spinner',
  templateUrl: './stateful-loading-spinner.component.html',
  styleUrl: './stateful-loading-spinner.component.css',
})
export class StatefulLoadingSpinnerComponent {
  @Input() spinnerText: string = 'Loading';
  @Input() errorMessage: string = '';

  constructor() {}
}
