<div class="flex-1 max-h-screen overflow-hidden">
  <img
    src="/assets/img/auth-bg.jpg"
    alt=""
    class="w-full h-full object-cover object-center"
  />
  <h2
    class="text-4xl text-white absolute bottom-0 left-0 p-16 m-0 max-w-[200px] hidden lg:block"
  >
    Start your story now
  </h2>
</div>

<div class="h-full ml-auto bg-white lg:max-w-2xl w-full flex flex-col">
  <div class="flex-1 h-full flex w-full px-6 sm:px-12 md:px-24 box-border">
    <div class="my-auto w-full box-border">
      <h1 class="text-4xl font-semibold text-dark mb-2">Sign In</h1>
      <p class="mb-10 text-grey">
        Login to Community Connections using Mydex below.
      </p>

      <button class="btn-theme font-semibold w-full" (click)="submit()">
        <div *ngIf="loading" class="flex justify-center align-center">
          <app-loading-spinner
            class="font-medium opacity-90"
            text="Redirecting"
          ></app-loading-spinner>
        </div>
        <ng-container *ngIf="!loading">
          <span class="inline-block align-middle mb-1 mr-2">
            Sign in with
          </span>
          <img
            src="/assets/img/mydex-white.png"
            alt=""
            class="h-5 align-middle"
          />
        </ng-container>
      </button>

      <!-- Scot Account button - currently unused: -->
      <!-- <button class="btn-theme-outline w-full font-semibold mt-6">
        <span class="inline-block align-middle mb-1 mr-2"> or with </span>
        <img src="/assets/img/mygov.svg" alt="" class="h-4 align-middle" />
      </button> -->

      <p class="max-w-sm text-grey mb-16 mt-8">
        By signing in, you agree that you consent to our
        <a routerLink="/privacy-policy" target="_blank">Privacy Policy</a>.
      </p>
      <a
        class="text-theme text-left mt-[10vh] block"
        routerLink="/register/intro"
        >What are <strong>About Me</strong> & Mydex?</a
      >
    </div>
  </div>

  <div class="auth-footer lg:max-w-2xl ml-auto w-full h-20">
    <p class="text-center">
      Don't have an account? <br />
      <a
        class="text-theme cursor-pointer"
        [routerLink]="enableRegistration ? '/register' : null"
        (click)="enableRegistration || modal.open()"
        [queryParams]="{ from: registerFrom }"
        >Register with Mydex</a
      >
    </p>
  </div>
</div>

<app-modal>
  <h1 class="text-3xl font-semibold mb-6">
    Registration is currently disabled
  </h1>
  <p>
    We are currently not accepting new registrations but you can still sign in
    if you already have a Mydex account.
  </p>
  <div class="flex mt-24">
    <button class="btn btn-theme px-8 mr-4 outlined" (click)="modal.close()">
      Close
    </button>
  </div>
</app-modal>
