export class CookieConsent {
  creationDate: Date;
  consentedGoogleAnalytics: boolean;
  consentedVimeo: boolean;

  constructor(data?: Partial<CookieConsent>) {
    this.creationDate = data?.creationDate ?? new Date();
    this.consentedGoogleAnalytics = data?.consentedGoogleAnalytics ?? false;
    this.consentedVimeo = data?.consentedVimeo ?? false;
  }

  get hasConsentedVimeo(): boolean {
    return this.consentedVimeo;
  }

  get hasConsentedGoogleAnalytics(): boolean {
    return this.consentedGoogleAnalytics;
  }

  get isValid(): boolean {
    // check if the creation date is less than 90 days ago

    return (
      new Date().getTime() - new Date(this.creationDate).getTime() <
      90 * 24 * 60 * 60 * 1000
    );
  }
}
