import {
  FormItemButtonSelect,
  FormItemTextArea,
  FormItemTitle,
} from 'src/app/models/form/form';
import { yesNoOptions } from '../item-options';
import { Validators } from '@angular/forms';

export const livingWillHasAdvancedDirective = new FormItemButtonSelect({
  key: 'livingWillHasAdvancedDirective',
  path: 'planningAhead.livingWill.hasAdvancedDirective',
  label: 'Do you have an advanced directive?',
  class: 'mb-5',
  options: yesNoOptions,
});

export const livingWillAdvancedDirectiveLocationAndSharingInfo =
  new FormItemTextArea({
    key: 'livingWillAdvancedDirectiveLocationAndSharingInfo',
    path: 'planningAhead.livingWill.advancedDirectiveLocationAndSharingInfo',
    label: 'Where do you keep this and who have you shared it with?',
    class: 'mb-5',
    validators: [Validators.minLength(1), Validators.maxLength(2000)],
    displayConditions: [
      {
        key: 'livingWillHasAdvancedDirective',
        values: [true],
      },
    ],
  });

export const livingWillResuscitationTitle = new FormItemTitle({
  key: 'livingWillResuscitationTitle',
  label: 'Resuscitation',
  class: 'mt-2',
});

export const livingWillResuscitationThoughtsAndQuestions = new FormItemTextArea(
  {
    key: 'livingWillResuscitationThoughtsAndQuestions',
    path: 'planningAhead.livingWill.resuscitationThoughtsAndQuestions',
    label: 'Share here your thoughts or questions about resuscitation',
    help: "You may have discussed with people involved in your care cardio pulmonary resuscitation also known as CPR. CPR is carried out when a person's heart or breathing stops. While CPR is the right treatment for many people it may not be the right thing for you. If it is decided that CPR will not work or have a very poor outcome, the decision that CPR will not be given is recorded as DNACPR on a Do Not Attempt Cardiopulmonary Resuscitation form. This will be discussed with you if possible. If you are unable to discuss CPR, this will be discussed with your family, next-of-kin or welfare power of attorney.",
    class: 'mb-5',
    validators: [Validators.minLength(1), Validators.maxLength(2000)],
  }
);
