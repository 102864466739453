export class PdsConnectionStatus {
  value: 'valid' | 'no-connection' | 'expired';

  get isValid(): boolean {
    return this.value === 'valid';
  }

  constructor(data?: Partial<PdsConnectionStatus>) {
    this.value = data && data.value ? data.value : 'no-connection';
  }
}
