import { Component, Input } from '@angular/core';
import { CallToActionButton } from 'src/app/models/content/CallToActionButton';

@Component({
  selector: 'app-cta',
  templateUrl: './cta.component.html',
  styleUrl: './cta.component.css',
})
export class CtaComponent {
  @Input() cta?: CallToActionButton;
  @Input() classForLink: string = '';
}
