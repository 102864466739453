<!-- Layouts -->
<div class="layout-wrapper min-h-screen flex" [ngSwitch]="layout">
  <!-- AUTH LAYOUT -->
  <div
    class="auth-layout layout min-h-full flex bg-light-grey bg-opacity-40"
    *ngSwitchCase="'auth'"
  >
    <router-outlet></router-outlet>
  </div>
  <!-- END AUTH LAYOUT -->

  <!-- ADMIN LAYOUT -->
  <div
    class="dashboard-layout layout bg-light-grey flex flex-col min-h-screen"
    *ngSwitchCase="'admin'"
  >
    <app-header layout="admin"></app-header>
    <div class="spacer h-28 w-full relative">&nbsp;</div>
    <div class="flex h-full">
      <div
        [class.pt-[5em]]="sidebar"
        class="content p-8 px-6 xs:px-12 flex flex-col flex-1 box-border"
        role="main"
      >
        <router-outlet></router-outlet>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
  <!-- END ADMIN LAYOUT -->

  <!-- ORG LAYOUT -->
  <div class="layout flex flex-col min-h-screen" *ngSwitchCase="'org'">
    <app-header layout="home"></app-header>
    <div class="content flex flex-col flex-1" role="main">
      <div class="spacer h-28 w-full relative">&nbsp;</div>
      <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
  </div>
  <!-- END ORG LAYOUT -->

  <!-- HOME LAYOUT -->
  <div class="layout flex flex-col min-h-screen" *ngSwitchCase="'home'">
    <app-header layout="home"></app-header>
    <div class="content px-8 xs:py-8 xs:px-12 flex flex-col flex-1" role="main">
      <div class="spacer h-28 w-full relative">&nbsp;</div>
      <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
  </div>
  <!-- END HOME LAYOUT -->

  <!-- DASHBOARD LAYOUT -->
  <div
    class="dashboard-layout layout bg-light-grey flex flex-col overflow-hidden"
    [ngClass]="
      sidebar && (authService.isAuthenticated$ | async)
        ? 'h-screen'
        : 'min-h-screen'
    "
    *ngSwitchDefault
  >
    <app-header
      [type]="sidebar ? 'sidebar' : null"
      layout="dashboard"
    ></app-header>
    <div class="spacer h-28 w-full relative flex-shrink-0">&nbsp;</div>
    <div class="flex flex-grow box-border overflow-hidden">
      <app-sidebar
        class=""
        *ngIf="sidebar && (authService.isAuthenticated$ | async)"
      ></app-sidebar>
      <div
        [class.pt-[5em]]="sidebar"
        class="content xs:py-12 px-6 pb-16 xs:px-12 flex flex-col flex-1 box-border overflow-auto max-h-full"
        role="main"
      >
        <router-outlet></router-outlet>
      </div>
    </div>
    <!-- for dashboard layout: if sidebar should be shown but user is not authed and visits page: show footer -->
    <app-footer
      *ngIf="sidebar && !(authService.isAuthenticated$ | async)"
      class="mt-auto"
    ></app-footer>
  </div>
  <!-- END DASHBOARD LAYOUT -->

  <app-cookie-notice></app-cookie-notice>
</div>
