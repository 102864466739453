export class FinderNode {
  name: string; // unique ID
  title: string;
  parentPath: string;
  followUpQuestion?: string;
  icon?: string;
  description?: string;
  pdsKey?: string;
  childNodes: FinderNode[];

  constructor(finderNode: Partial<FinderNode>) {
    this.name = finderNode.name ?? '';
    this.title = finderNode.title ?? '';
    this.parentPath = finderNode.parentPath ?? '';
    this.followUpQuestion = finderNode.followUpQuestion;
    this.icon = finderNode.icon;
    this.description = finderNode.description;
    this.pdsKey = finderNode.pdsKey;
    this.childNodes = [];
  }

  public get fullPath(): string {
    if (this.parentPath == '') {
      return this.name;
    }

    return this.parentPath + '.' + this.name;
  }
}
