// THIS IS ME ------------------------------------------------------------------
// THIS IS ME - PERSONAL DETAILS -----------------------------------------------

import { Validators } from '@angular/forms';
import {
  getUtcYesterdayMidnight,
  utcDateAtMidnightFromYmd,
} from 'src/app/helper/dateHelper';
import { maxByteValidator } from 'src/app/helper/validators/maxByteValidator';
import {
  FormItemDob,
  FormItemDropdown,
  FormItemImageUpload,
  FormItemTextbox,
} from 'src/app/models/form/form';

export const firstName = new FormItemTextbox({
  key: 'firstName',
  path: 'me.personalDetails.firstName',
  label: 'First Name',
  class: 'mb-5',
  width: 'half',
  required: true,
  validators: [
    Validators.minLength(1),
    maxByteValidator(2000),
    Validators.required,
  ],
  guidanceForUsage: 'This is the first name of the user.',
});

export const middleName = new FormItemTextbox({
  key: 'middleName',
  path: 'me.personalDetails.middleName',
  label: 'Middle Name(s)',
  class: 'mb-5',
  width: 'half',
  validators: [Validators.minLength(1), maxByteValidator(2000)],
  guidanceForUsage: 'This is the middle name of the user.',
});

export const lastName = new FormItemTextbox({
  key: 'lastName',
  path: 'me.personalDetails.lastName',
  label: 'Last Name',
  class: 'mb-5',
  width: 'half',
  required: true,
  validators: [
    Validators.minLength(1),
    maxByteValidator(2000),
    Validators.required,
  ],
  guidanceForUsage: 'This is the last name of the user.',
});

export const picture = new FormItemImageUpload({
  key: 'picture',
  path: 'me.personalDetails.picture',
  label: 'Picture',
  class: 'mb-5',
  imageDownloadName: 'pds-profile-picture',
  placeholderIconName: 'person',
  validators: [Validators.minLength(1), Validators.maxLength(5000000)], // TODO: add compression library?
});

export const preferredName = new FormItemTextbox({
  key: 'preferredName',
  path: 'me.personalDetails.preferredName',
  label: 'What you like to be called',
  class: 'mb-5',
  width: 'half',
  validators: [Validators.minLength(1), maxByteValidator(2000)],
});

export const maritalStatus = new FormItemDropdown({
  key: 'maritalStatus',
  path: 'me.personalDetails.maritalStatus',
  label: 'Marital Status',
  placeholder: 'Please select...',
  options: [
    { name: 'Single', label: 'Single' },
    { name: 'Married / Civil Partner', label: 'Married / Civil Partner' },
    {
      name: 'Divorced / Civil Partnership Dissolved',
      label: 'Divorced / Civil Partnership Dissolved',
    },
    {
      name: 'Widowed / Surviving Civil Partner',
      label: 'Widowed / Surviving Civil Partner',
    },
    { name: 'Separated', label: 'Separated' },
    { name: 'Not Stated', label: 'Not Stated' },
  ],
  class: 'mb-5',
  width: 'half',
});

export const maidenName = new FormItemTextbox({
  key: 'maidenName',
  path: 'me.personalDetails.maidenName',
  label: 'Maiden Name',
  class: 'mb-5',
  width: 'half',
  validators: [Validators.minLength(1), maxByteValidator(2000)],
});

export const alsoKnownAs = new FormItemTextbox({
  key: 'alsoKnownAs',
  path: 'me.personalDetails.alsoKnownAs',
  label: 'Also known as (AKA)',
  class: 'mb-5',
  width: 'half',
  validators: [Validators.minLength(1), maxByteValidator(2000)],
});

export const gender = new FormItemDropdown({
  key: 'gender',
  label: 'Gender',
  path: 'me.personalDetails.gender',
  placeholder: 'Please select...',
  options: [
    { name: 'Female', label: 'Female' },
    { name: 'Intersex', label: 'Intersex' },
    {
      name: 'Male',
      label: 'Male',
    },
    {
      name: 'Non-binary',
      label: 'Non-binary',
    },
    { name: 'Transgender', label: 'Transgender' },
    { name: 'Prefer not to say', label: 'Prefer not to say' },
    { name: 'None', label: 'None' },
  ],
  class: 'mb-5',
  width: 'half',
});

export const genderAtBirth = new FormItemDropdown({
  key: 'genderAtBirth',
  label: 'Gender at Birth',
  path: 'me.personalDetails.genderAtBirth',
  placeholder: 'Please select...',
  options: [
    { name: 'Female', label: 'Female' },
    { name: 'Intersex', label: 'Intersex' },
    {
      name: 'Male',
      label: 'Male',
    },
    {
      name: 'Non-binary',
      label: 'Non-binary',
    },
    { name: 'Prefer not to say', label: 'Prefer not to say' },
    { name: 'None', label: 'None' },
  ],
  class: 'mb-5',
  width: 'half',
});

export const pronouns = new FormItemDropdown({
  key: 'pronouns',
  label: 'Pronouns',
  path: 'me.personalDetails.pronouns',
  placeholder: 'Please select...',
  options: [
    { name: 'He/Him', label: 'He/Him' },
    { name: 'She/Her', label: 'She/Her' },
    {
      name: 'They/Them',
      label: 'They/Them',
    },
    {
      name: 'Other',
      label: 'Other',
    },
  ],
  class: 'mb-5',
  width: 'half',
});

// TODO: remove Workaround for date being required, once Mydex allows null/empty values
export const dateOfBirth = new FormItemDob({
  key: 'dateOfBirth',
  path: 'me.personalDetails.dateOfBirth',
  placeholder: 'Select a date',
  label: 'Date of Birth',
  minDate: utcDateAtMidnightFromYmd(1900, 0, 1),
  maxDate: getUtcYesterdayMidnight(),
  required: true,
  validators: [Validators.required],
  class: 'mb-5',
});

export const ethnicity = new FormItemDropdown({
  key: 'ethnicity',
  path: 'me.personalDetails.ethnicity',
  label: 'Ethnicity',
  placeholder: 'Please select...',
  optionGroups: [
    {
      label: 'Asian, Scottish Asian or British Asian',
      options: [
        {
          name: 'Pakistani, Scottish Pakistani or British Pakistani',
          label: 'Pakistani, Scottish Pakistani or British Pakistani',
        },
        {
          name: 'Indian, Scottish Indian or British Indian',
          label: 'Indian, Scottish Indian or British Indian',
        },
        {
          name: 'Bangladeshi, Scottish Bangladeshi or British Bangladeshi',
          label: 'Bangladeshi, Scottish Bangladeshi or British Bangladeshi',
        },
        {
          name: 'Chinese, Scottish Chinese or British Chinese',
          label: 'Chinese, Scottish Chinese or British Chinese',
        },
        {
          name: 'Other Asian',
          label: 'Other Asian',
        },
      ],
    },
    {
      label: 'African',
      options: [
        {
          name: 'Scottish African',
          label: 'Scottish African',
        },
        {
          name: 'British African',
          label: 'British African',
        },
        {
          name: 'Other African',
          label: 'Other African',
        },
      ],
    },
    {
      label: 'Caribbean or Black',
      options: [
        {
          name: 'Black',
          label: 'Black',
        },
        {
          name: 'Black Caribbean',
          label: 'Black Caribbean',
        },
        {
          name: 'Other Caribbean',
          label: 'Other Caribbean',
        },
      ],
    },
    {
      label: 'Mixed or multiple ethnic group',
      options: [
        {
          name: 'White and Asian',
          label: 'White and Asian',
        },
        {
          name: 'White and Black African',
          label: 'White and Black African',
        },
        {
          name: 'White and Black Caribbean',
          label: 'White and Black Caribbean',
        },
        {
          name: 'Any other mixed or multiple ethnic background',
          label: 'Any other mixed or multiple ethnic background',
        },
      ],
    },
    {
      label: 'White',
      options: [
        {
          name: 'Scottish',
          label: 'Scottish',
        },
        {
          name: 'Other British',
          label: 'Other British',
        },
        {
          name: 'Irish',
          label: 'Irish',
        },
        {
          name: 'Polish',
          label: 'Polish',
        },
        {
          name: 'Gypsy or Traveller',
          label: 'Gypsy or Traveller',
        },
        {
          name: 'Roma',
          label: 'Roma',
        },
        {
          name: 'Showman or Showwoman',
          label: 'Showman or Showwoman',
        },
        {
          name: 'Other White',
          label: 'Other White',
        },
      ],
    },
    {
      label: 'Other ethnic group',
      options: [
        {
          name: 'Arab, Scottish Arab or British Arab',
          label: 'Arab, Scottish Arab or British Arab',
        },
        {
          name: 'Other (for example, Sikh, Jewish)',
          label: 'Other (for example, Sikh, Jewish)',
        },
      ],
    },
  ],
  class: 'mb-5',
  width: 'half',
});
