export const frequentlyAskedQuestions = [
  {
    question: 'What is a Personal Data Store (PDS)?',
    answer: `
      <p>
        A PDS is a digital storage location for your personal information. 
        The PDS that you can create and make use of through Community Connections is created and hosted by 
        <a href="https://mydex.org/" target="_blank" class="text-theme">Mydex CIC</a>. 
        A PDS stores the information you might want to share with service providers and associations. 
        This has three main advantages.
      </p>
       <ol>
        <li>
          You can decide who has access to your personal information. 
          Every service provider that needs to access your information will need to abide by the permissions you set on your PDS. 
          This way only you can decide who has access to what. 
          If you want to revoke permissions to access your information for a given service provider, you can do so.
        </li>
        <li>
          You won't need to send your personal information to each service separately. 
          This makes sharing your personal information with service providers much easier and more consistent.
        </li>
        <li>
          If you want to update your personal information for several service providers, you only have to change the information on your PDS.
          Each service provider will be able to access the updated information (if your permissions allow it).
        </li>
       </ol>
       <p>
          For more information on PDSs click <a href="https://dev.mydex.org/fyi/security-model.html" target="_blank" class="text-theme">here</a>. 
       </p>
    `,
  },
  {
    question: 'Can I delete my PDS?',
    answer: `
      <p>
        Yes you can, given the nature and sensitivity of the contents of a PDS we have a simple to follow process for requesting the deletion of a PDS 
        <a href="https://dev.mydex.org/mydex-terms-members.html#closing-your-account" target="_blank" class="text-theme">which can be found here</a>.
        The process is designed to protect you in case you change your mind after requesting deletion so we provided a staged approach that gives you a cooling off period in case you change your mind. 
      </p>
      <p>
        However, you can disable or delete any one or all of your PDS connections at any time.
        The minute you do so, those subscribing organizations can no longer access your personal data.  
      </p>
    `,
  },
  {
    question: 'What is a private key?',
    answer: `
      <p>
        A private key is a password or passphrase that secures your Personal Data Store.
        This key is <span class="underline">not stored</span> by the host of the PDS (Mydex CIC).
        For this reason, we suggest using a passphrase such as “<span class="italic">I wandered lonely as a cloud</span>”.
        This is because it is generally easier to remember a phrase with a meaning than it is a random sequence of letters and numbers.
        You can choose a passphrase of up to 128 characters.
      </p>
    `,
  },
  {
    question: 'Can I retrieve my private key if I lose it or forget it?',
    answer: `
      <p>
        Unfortunately, no.
        The private key you need to access your PDS is not stored by the hosts of the PDS (Mydex CIC).
        This is done so that the platform can be secure and independently certified.
        Only you will have access to the private key.
        We recommend choosing a passphrase (of up to 128 characters) rather than a password.
        This is because, generally, it is easier to remember a sentence with a meaning than a random sequence of letters and numbers.
      </p>
    `,
  },
  {
    question: 'What are Circles of Care?',
    answer: `
      <p>
        Circles of Care are a way for you to decide which people and service providers have access to your personal information through your PDS.
        On your PDS you will be able to create Circles of Care and assign people or associations to each one.
        Each member of a circle will be granted a different set of permissions to access your personal data.
        Only you can decide who is in which circle and can add and remove members at any time.
      </p>
    `,
  },
  {
    question: 'Who are people in a circle visible to?',
    answer: `
      <p>
      When you create your own circles only you can see them and who is in them.
      The people in the circle know they are in the circle and if you have given them permission they can also message the circle.
      This is your private network, and you are always in control, able to remove people, change what they can see and do.
      Service Providers may be interested in people who are important to you or assisting you.
      You can share this information with them via the About Me Feature we provided but service providers do not have visibility of your circles.
      If you have given a member of your circle the ability to act on your behalf, the service provider will know this.
      Anything a member of a circle does for you or updates on your behalf is fully logged and visible to you so there can be no doubt who has done what for you. 
      </p>
    `,
  },
  {
    question: 'Are there age restrictions to use Community Connections?',
    answer: `
      <p>
        Community Connections is open to everyone and you don't need to input any personal details to gain access. 
      </p>
    `,
  },
  {
    question: 'What devices is Community Connections compatible with?',
    answer: `
      <p>
        Community Connections is a “web app”.
        This means that it is, for all intents and purposes, a website which can offer application-like features.
        As such, it can be accessed through any modern browser (Chrome, Firefox, Edge, Safari) on a PC, Mac.
        The mobile experience (on iPhone or Android devices) has not currently been optimised.  
      </p>
    `,
  },
  {
    question:
      'I am participating in the project, can someone in my network outside Moray use that system?',
    answer: `
      <p>
        Yes, however the Community Connections website is built only for Moray at this stage so the person might not find information about their area.  
      </p>
    `,
  },
  {
    question:
      'How is my personal data managed between visits to the Community Connections app?',
    answer: `
      <p>
        Personal data you enter into forms (i.e. questionnaires or referrals) may be stored on your device while your internet browser is open.
        Once you close your browser this data becomes inaccessible, unless you restore or recover that particular browsing session. 
      </p>
      <p>
        If you grant Community Connections permission to connect to your Personal Data Store (PDS), only the necessary details to allow this access are kept.
        These PDS connection details are handled and stored by Community Connections (not on your device) and persist across browsing sessions.
        Your account credentials and the storage of your personal data in the PDS are managed by <a href="https://mydex.org/" target="_blank" class="text-theme">Mydex CIC</a>. Please refer to their website for more information.
      </p>
      <p>
        Community Connections also stores some general user preferences in your browser's local storage and uses essential and optional cookies to run smoothly. All of these may be kept on your device and / or in your web browser's storage system across browsing sessions.
    </p>
    <p>
      More information about how we store data can be found in our <a href="/privacy-policy" class="text-theme">Privacy Policy</a>. You can also update your <a href="/cookie-preferences" class="text-theme">Cookie Preferences</a> at any time.
  </p>
    `,
  },
];
