<div class="relative">
  <!--LOADING SPINNER-->
  <app-loading-spinner
    *ngIf="userService.loading$ | async"
  ></app-loading-spinner>
  <!--END LOADING SPINNER-->

  <!-- SERVER ERROR: FAILED LOADING USER -->
  <app-no-items-error
    [isDisplayed]="
      !(userService.loading$ | async) && (userService.loadingError$ | async)
    "
    [errorMessage]="userService.loadingError$ | async"
  >
  </app-no-items-error>
  <!-- END SERVER ERROR: FAILED LOADING USER -->

  <!--CONTENT-->
  <ng-container
    *ngIf="
      !(userService.loading$ | async) && !(userService.loadingError$ | async)
    "
  >
    <!-- BREADCRUMB -->
    <nav
      class="mb-8"
      aria-label="breadcrumb"
      *ngIf="breadcrumbSegments.length > 1"
    >
      <ul style="list-style-type: none; padding: 0">
        <li
          *ngFor="let segment of breadcrumbSegments; index as i"
          style="display: inline"
        >
          <span class="ms-2 me-1" *ngIf="i > 0">/</span>
          <a [routerLink]="segment.path" class="cursor-pointer p-0 border-0">
            {{ segment.label | titlecase }}</a
          >
        </li>
      </ul>
    </nav>
    <!-- END BREADCRUMB -->

    <div class="3xl:flex 3xl:flex-row-reverse 3xl:justify-end">
      <div
        class="card bg-white rounded-lg w-full p-16 pt-12 max-w-5xl box-border"
        [class.w-full]="form?.length"
      >
        <div class="mt-2">
          <div class="flex mb-4 justify-between">
            <div class="mt-2">
              <h1 class="text-3xl font-semibold m-0">
                {{ currentSection?.title }}
              </h1>
              <p class="m-0 mt-2 text-gray-500">
                {{
                  currentSection?.longDescription ?? currentSection?.description
                }}
              </p>
            </div>

            <div class="flex ml-5" *ngIf="form?.length">
              <span
                [class.text-theme]="
                  computeCompletedPercentageForSection(currentSection) == 100
                "
                class="font-light transition-colors mr-2 my-auto text-base block whitespace-nowrap pb-2"
                >{{ computeCompletedPercentageForSection(currentSection) }}%
                Complete</span
              >
              <app-piechart
                [borderWidth]="6"
                class="my-auto"
                [width]="40"
                [value]="computeCompletedPercentageForSection(currentSection)"
              >
                <img
                  [class.opacity-0]="
                    computeCompletedPercentageForSection(currentSection) < 100
                  "
                  class="m-auto w-5 transition-opacity"
                  src="/assets/img/icons/tick.svg"
                />
              </app-piechart>
            </div>
          </div>
        </div>

        <div *ngIf="form && form.length" class="mt-8">
          <app-form
            #formComponent
            [formData]="form"
            [formValues]="user"
            (submit)="handleSave($event)"
            submitText="Save"
            loadingText="Saving"
            [loading]="(userService.saving$ | async) === true"
            [success]="savingUserSuccess"
            [error]="(userService.savingError$ | async) || ''"
            [cancellable]="true"
            [cancelText]="
              'Back ' + (parentSection.title ? 'to ' + parentSection.title : '')
            "
            (cancel)="handleBack($event)"
            class="connection-form"
            [contactsItemOptions]="contacts"
            [contactsItemLoading]="
              (userContactsStateService.loading$ | async) === true
            "
            [contactsItemLoadingError]="
              (userContactsStateService.loadingError$ | async) || ''
            "
            [contactsItemSaving]="
              (userContactsStateService.saving$ | async) === true
            "
            [contactsItemSavingError]="
              (userContactsStateService.savingError$ | async) || ''
            "
            [contactsItemDeleting]="
              (userContactsStateService.deleting$ | async) === true
            "
            [contactsItemDeletionError]="
              (userContactsStateService.deletionError$ | async) || ''
            "
            (contactCreated)="handleContactCreated($event)"
            (contactUpdated)="handleContactUpdated($event)"
            (contactDeleted)="handleContactDeleted($event)"
            (contactsItemClearErrors)="handleClearContactsItemErrors($event)"
            [imagesItemOptions]="images"
            [imagesItemLoadingAll]="
              (userImagesStateService.loadingImageThumbnails$ | async) === true
            "
            [imagesItemLoadingAllError]="
              (userImagesStateService.loadingImageThumbnailsError$ | async) ||
              ''
            "
            [imagesItemLoadingSingleImage]="
              (userImagesStateService.loadingImageFullSize$ | async) === true
            "
            [imagesItemLoadingSingleImageError]="
              (userImagesStateService.loadingImageFullSizeError$ | async) || ''
            "
            [imagesItemSaving]="
              (userImagesStateService.savingImage$ | async) === true
            "
            [imagesItemSavingError]="
              (userImagesStateService.savingImageError$ | async) || ''
            "
            [imagesItemDeleting]="
              (userImagesStateService.deletingImage$ | async) === true
            "
            [imagesItemDeletionError]="
              (userImagesStateService.deletingImageError$ | async) || ''
            "
            (fullSizeImageRequested)="handleFullSizeImageRequested($event)"
            (imageAdded)="handleImageAdded($event)"
            (imageUpdated)="handleImageUpdated($event)"
            (imageDeleted)="handleImageDeleted($event)"
            (imagesItemClearErrors)="handleClearImagesItemErrors($event)"
          >
          </app-form>
        </div>

        <div class="mt-8" *ngIf="currentSection?.children?.length">
          <h2 class="mt-8 mb-6" *ngIf="form && form.length">
            Areas of {{ currentSection?.title.toLowerCase() }}
          </h2>
          <div class="grid gap-8 xl:grid-cols-2">
            <a
              class="rounded-lg p-8 hover:bg-theme hover:bg-opacity-5 hover:border-theme hover:border-opacity-25 bg-opacity-80 transition-colors bg-gray-50 border border-solid border-gray-200 flex flex-col"
              *ngFor="let childSection of currentSection?.children"
              [routerLink]="
                '/account/about-me/' +
                currentSection.routePath +
                '/' +
                childSection.routePath
              "
            >
              <div>
                <div class="flex justify-start">
                  <div class="flex mr-2">
                    <app-piechart
                      [borderWidth]="6"
                      [width]="50"
                      [value]="
                        computeCompletedPercentageForSection(childSection)
                      "
                    >
                      <div class="m-auto relative">
                        <img
                          *ngIf="
                            computeCompletedPercentageForSection(
                              childSection
                            ) == 100
                          "
                          class="m-auto w-5 transition-opacity"
                          src="/assets/img/icons/tick.svg"
                        />
                        <p
                          *ngIf="
                            (computeCompletedPercentageForSection(
                              childSection
                            ) ??
                              0) <
                            100
                          "
                          class="m-auto text-sm"
                        >
                          {{
                            computeCompletedPercentageForSection(childSection)
                          }}%
                        </p>
                      </div>
                    </app-piechart>
                  </div>
                  <div>
                    <h2 class="text-theme mt-1 mb-0 font-semibold text-lg">
                      {{ childSection?.title }}
                    </h2>
                    <p class="mt-0 text-sm mb-0 text-gray-600">
                      {{ childSection.description }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <!--END CONTENT-->
</div>
