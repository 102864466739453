import { AboutMeSection } from 'src/app/models/about-me/aboutMeSection';
import {
  getFormItemsUserHealthImportant,
  getFormItemsUserHealthMedicalHistory,
  getFormItemsUserHealthMemory,
  getFormItemsUserHealthStayingSafe,
  getFormItemsUserHealthWellbeing,
} from '../../forms/user/health-items/form-health';

export const healthSection: AboutMeSection = {
  title: 'Health & Wellbeing',
  description: 'Details about your health',
  longDescription:
    'In this section, you can tell us about your health and wellbeing. Click on an area to get started.',
  routePath: 'health-wellbeing',
  userPath: 'health',
  children: [
    {
      title: 'Medical History',
      description: 'Your medical history',
      routePath: 'medical-history',
      userPath: 'medicalHistory',
      getFormItems: getFormItemsUserHealthMedicalHistory,
    },
    {
      title: 'Wellbeing',
      description: 'How you are feeling',
      routePath: 'wellbeing',
      userPath: 'wellbeing',
      getFormItems: getFormItemsUserHealthWellbeing,
    },
    {
      title: 'Memory',
      description: 'Problems with memory',
      routePath: 'memory',
      userPath: 'memory',
      getFormItems: getFormItemsUserHealthMemory,
    },
    {
      title: 'Staying Safe',
      description: 'What makes you feel safe',
      routePath: 'staying-safe',
      userPath: 'stayingSafe',
      getFormItems: getFormItemsUserHealthStayingSafe,
    },
    {
      title: 'Important to Me',
      description: 'What is important to you',
      routePath: 'important',
      userPath: 'important',
      getFormItems: getFormItemsUserHealthImportant,
    },
  ],
};
