import { Component, Input } from '@angular/core';
import { FormItemSubform } from 'src/app/models/form/form';

@Component({
  selector: 'app-array-item',
  templateUrl: './array-item.component.html',
  styleUrls: ['./array-item.component.css'],
})
export class ArrayItemComponent {
  @Input() value!: any;
  @Input() titleField!: string;

  //getter for content, generated from value
  get content(): any {
    const otherContent = Object.keys(this.value)
      .filter((key) => key != this.titleField)
      .map((key) => this.value[key])
      .filter((x) => typeof x === 'string');
    return {
      title: this.value[this.titleField],
      other: otherContent,
    };
  }
}
