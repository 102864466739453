import { FormItem } from 'src/app/models/form/form';
import { aboutMeSectionsIncludingTheirChildren } from 'src/assets/about-me/sections/aboutMeSections';
import { getAllFormItemsUser } from 'src/assets/forms/user/form-user';

export function getFormItemPathOptions(): FormItem<any>[] {
  var formItemsUser: FormItem<any>[] = getAllFormItemsUser();
  // filter out duplications of paths
  const pathMap = new Map<string, FormItem<any>>();
  formItemsUser.forEach((formItem) => {
    // filter out title form items and form links (have no path)
    if (!(formItem.path === undefined || formItem.path === '')) {
      pathMap.set(formItem.path ?? '', formItem);
    }
  });
  formItemsUser = Array.from(pathMap.values());

  return formItemsUser;
}

export function getFormattedItemPath(path: string): string {
  let formattedPath = 'User';

  const pathSegments = path.split('.');

  pathSegments.forEach((segment, index) => {
    // do not add the last segment
    if (index === pathSegments.length - 1 || segment === '') {
      return;
    }

    // if the formatted path is not empty, add a ' / ' in front of the new segment
    if (formattedPath !== '') {
      formattedPath += ' / ';
    }

    let foundSectionPath = false;

    for (let section of aboutMeSectionsIncludingTheirChildren) {
      if (section.userPath === segment) {
        formattedPath += section.title;
        foundSectionPath = true;
        break;
      }
    }

    // this is for paths that are nested in a section
    if (!foundSectionPath) {
      const wordArray = segment.split(/(?=[A-Z])/);
      let joinedString = wordArray.join(' ');
      joinedString =
        joinedString.charAt(0).toUpperCase() + joinedString.slice(1);

      formattedPath += joinedString;
    }
  });

  return formattedPath;
}
