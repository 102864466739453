import { Validators } from '@angular/forms';
import {
  FormItemTextArea,
  FormItemTextbox,
  FormItemTitle,
} from 'src/app/models/form/form';

// KEY INFORMATION - MEDICAL DETAILS -----------------------------------------------

export const medicalHistory = new FormItemTextArea({
  key: 'medicalHistory',
  path: 'keyInformation.medicalDetails.history',
  label: 'Medical History',
  placeholder: 'e.g. diabetes',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const allergies = new FormItemTextArea({
  key: 'allergies',
  path: 'keyInformation.medicalDetails.allergies',
  label: 'Do you have allergies?',
  placeholder: 'e.g. nuts',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const emergencyMedication = new FormItemTextArea({
  key: 'emergencyMedication',
  path: 'keyInformation.medicalDetails.emergencyMedication',
  label: 'Tell us about any emergency medication that you require',
  placeholder: 'e.g. EpiPen',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const gpTitle = new FormItemTitle({
  key: 'gpTitle',
  label: 'GP Details',
  class: 'mt-2 mb-5',
});

export const gpName = new FormItemTextbox({
  key: 'gpName',
  path: 'keyInformation.medicalDetails.gp.name',
  label: 'Name of GP',
  class: 'mb-5',
  width: 'half',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const surgeryName = new FormItemTextbox({
  key: 'surgeryName',
  path: 'keyInformation.medicalDetails.surgeryName',
  label: 'Name of Surgery',
  class: 'mb-5',
  width: 'half',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const gpPhone = new FormItemTextbox({
  key: 'gpPhone',
  path: 'keyInformation.medicalDetails.gp.phone',
  label: 'Phone Number',
  class: 'mb-5',
  width: 'half',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const gpEmail = new FormItemTextbox({
  key: 'gpEmail',
  path: 'keyInformation.medicalDetails.gp.email',
  label: 'Email Address',
  class: 'mb-5',
  width: 'half',
  validators: [
    Validators.minLength(1),
    Validators.maxLength(2000),
    Validators.email, // use built-in validator because trying to copy PHP's validator (Mydex uses) is not recommended, as testing correctness is difficult
  ],
});
