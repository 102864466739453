// THIS IS ME - PERSONAL QUALITIES -------------------------------------------------------

import { Validators } from '@angular/forms';
import { maxByteValidator } from 'src/app/helper/validators/maxByteValidator';
import {
  FormItemButtonSelect,
  FormItemTextArea,
  FormItemTitle,
} from 'src/app/models/form/form';

export const personalQualitiesLikes = new FormItemButtonSelect({
  key: 'currentLifeSituation',
  path: 'me.personalQualities.likes',
  label: 'What do you like?',
  class: 'mb-5',
  multiple: true,
  options: [
    { name: 'like_big_groups', label: 'Big groups' },
    { name: 'like_small_groups', label: 'Small groups' },
    { name: 'like_one-to-one_events', label: 'One-to-one events' },
    { name: 'like_being_indoors', label: 'Being indoors' },
    { name: 'like_being_outdoors', label: 'Being outdoors' },
  ],
});

export const positiveCharacteristics = new FormItemTextArea({
  key: 'positiveCharacteristics',
  path: 'me.personalQualities.positiveCharacteristics',
  label: 'What are the positive things about you?',
  placeholder: 'e.g. I am friendly and easy going',
  class: 'mb-5',
  width: 'half',
  validators: [Validators.minLength(1), maxByteValidator(2000)],
});

export const challengingCharacteristics = new FormItemTextArea({
  key: 'challengingCharacteristics',
  path: 'me.personalQualities.challengingCharacteristics',
  label: 'What do people find challenging about you?',
  placeholder: 'e.g. I am sometimes impatient',
  class: 'mb-5',
  width: 'half',
  validators: [Validators.minLength(1), maxByteValidator(2000)],
});

export const goodDayDescription = new FormItemTextArea({
  key: 'goodDayDescription',
  path: 'me.personalQualities.goodDayDescription',
  label: 'What does a good day look like?',
  placeholder: 'good day description',
  class: 'mb-5',
  width: 'half',
  validators: [Validators.minLength(1), maxByteValidator(2000)],
});

export const badDayDescription = new FormItemTextArea({
  key: 'badDayDescription',
  path: 'me.personalQualities.badDayDescription',
  label: 'What does a bad day look like?',
  placeholder: 'bad day description',
  class: 'mb-5',
  width: 'half',
  validators: [Validators.minLength(1), maxByteValidator(2000)],
});

export const goalsTitle = new FormItemTitle({
  key: 'goalsTitle',
  label: 'My Goals',
  class: 'mt-5 mb-2',
});

export const goalsAreas = new FormItemButtonSelect({
  key: 'goalsAreas',
  label: 'What area do your goals relate to?',
  path: 'me.personalQualities.goals.areas',
  multiple: true,
  options: [
    { name: 'goal_more_active', label: 'More active' },
    {
      name: 'goal_time_with_family_friends',
      label: 'More time with family/friends',
    },
    { name: 'goal_education_learning', label: 'Education/learning' },
    { name: 'goal_healthy_eating', label: 'Healthy eating' },
    { name: 'goal_relaxation', label: 'Relaxation' },
    { name: 'goal_going_out', label: 'More time going out' },
    { name: 'goal_not_listed', label: 'Other' },
  ],
  class: 'mb-5',
});

export const goalsRevival = new FormItemTextArea({
  key: 'goalsRevival',
  path: 'me.personalQualities.goals.revival',
  label: 'Things you would like to get back to?',
  placeholder: 'e.g. hiking',
  class: 'mb-5',
  width: 'half',
  validators: [Validators.minLength(1), maxByteValidator(2000)],
});

export const goalsFuture = new FormItemTextArea({
  key: 'goalsFuture',
  path: 'me.personalQualities.goals.future',
  label: 'Things you would like to achieve in the future?',
  placeholder: 'e.g. reading more',
  class: 'mb-5',
  width: 'half',
  validators: [Validators.minLength(1), maxByteValidator(2000)],
});

export const goalsAdditionalInfo = new FormItemTextArea({
  key: 'goalsAdditionalInfo',
  path: 'me.personalQualities.goals.additionalInfo',
  label: 'Other things I would like to share about my goals',
  placeholder: 'e.g. what would help me achieve my goals',
  class: 'mb-5',
  width: 'half',
  validators: [Validators.minLength(1), maxByteValidator(2000)],
});
