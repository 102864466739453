<div class="home-container">
  <!-- <div class="flex"> -->
  <div class="flex h-full mb-32 xl:mt-16 justify-between">
    <div class="my-auto pb-[2vw] max-w-xl">
      <h1 class="font-semibold text-3xl md:text-5xl mb-10">
        Understanding the support you give
      </h1>
      <p class="mb-14 text-base">
        There are an estimated 15,000 carers in Moray and 800,000 unpaid carers
        in Scotland. Three out of five of us will become carers at some stage in
        our lives and 1 in 10 of us is already fulfilling some sort of caring
        role. <br /><br />
        Whilst you may be supporting someone very happily it can impact your
        life significantly and there may be support available to help meet your
        needs. <br /><br />
        Answer a few short questions to help understand what support might be on
        offer.
      </p>
      <a *ngIf="!completedBefore" routerLink="/carer-assessment/questions">
        <button class="btn btn-theme px-8 text-lg">Care Questionnaire</button>
      </a>
      <button
        (click)="modal.open(); test()"
        *ngIf="completedBefore"
        class="btn btn-theme px-8 text-lg"
      >
        Care Questionnaire
      </button>
    </div>
    <img
      class="ml-12 hidden xl:block"
      src="/assets/img/carer-header-bg.svg"
      alt=""
    />
  </div>

  <!-- </div> -->

  <div class="mb-64">
    <h2 class="text-4xl mb-0">How can we help?</h2>

    <div
      class="flex flex-col lg:grid grid-cols-1 lg:grid-cols-3 gap-x-16 help-grid mt-8"
    >
      <a routerLink="/finder" class="mb-8 lg:mb-0">
        <div class="card h-full box-border">
          <img src="/assets/img/icons/home-pin.svg" alt="" class="home-icon" />
          <h3 class="text-2xl text-theme font-medium">
            View available support
          </h3>
          <p class="mb-0">
            Search our directory for help and guidance on your support needs
          </p>
        </div>
      </a>

      <a routerLink="/register" class="mb-8 lg:mb-0">
        <div class="card box-border h-full">
          <img src="/assets/img/icons/home-user.svg" alt="" class="home-icon" />
          <h3 class="text-2xl text-theme font-medium">About Me</h3>
          <p class="mb-0">
            Build your own story and start your personal data store.
          </p>
        </div>
      </a>

      <a routerLink="/finder" class="mb-8 lg:mb-0">
        <div class="card h-full box-border">
          <img
            src="/assets/img/icons/home-group.svg"
            alt=""
            class="home-icon"
          />
          <h3 class="text-2xl text-theme font-medium">Unpaid carers advice</h3>
          <p class="mb-0">
            Search our directory for help and guidance on your support needs.
          </p>
        </div>
      </a>
    </div>
  </div>
</div>

<div class="w-full bg-theme text-white px-12 -mx-12 my-32 lg:my-40">
  <div class="flex mb-32 home-container py-16 lg:py-0">
    <div class="lg:w-2/4 kg:max-w-lg m-auto flex flex-col">
      <h2 class="text-4xl mb-2">
        "I wasn't aware how much it was asking of me and the impact of that"
      </h2>
      <p class="text-xl mb-14">
        Jean looks after her mum, she spoke to Quarriers to assess her needs and
        find out what support was available.
      </p>
    </div>
    <div class="lg:w-2/4 hidden lg:flex lg:ml-48 -my-10">
      <img
        class="mx-auto w-full max-w-2xl"
        src="/assets/img/carer-illustration.svg"
        alt=""
      />
    </div>
  </div>
</div>

<app-modal>
  <h1 class="text-4xl font-medium text-theme mb-6">
    Have you completed this before?
  </h1>
  <p>
    Our records show that you already completed the questionnaire and were
    refferred to Quarriers {{ completedBefore }}. <br />
    <br />
    Do you want to continue and do this again?
  </p>
  <div class="flex mt-24">
    <a routerLink="/carer-assessment/questions">
      <button class="btn btn-theme px-8 mr-4 outlined">Continue</button>
    </a>
    <button class="btn-theme-outline px-8 font-medium" (click)="modal.close()">
      Back
    </button>
  </div>
</app-modal>
