import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeDot',
})
export class RemoveDotPipe implements PipeTransform {
  transform(value: string): string {
    if (typeof value === 'string' && value.endsWith('.')) {
      return value.slice(0, -1);
    }
    return value;
  }
}
