<ng-container *ngIf="displayPage">
  <!-- HEADER -->
  <div
    class="org-navbar flex flex-col justify-center text-right h-24"
    [style.background-color]="organisation?.headerBackgroundColor"
  >
    <h1 [style.color]="organisation?.titleColor" class="m-0">
      {{ organisation?.title }}
    </h1>
    <h3 [style.color]="organisation?.titleColor" class="m-0 mb-3 font-normal">
      {{ organisation?.subheading }}
    </h3>
  </div>
  <div class="spacer h-24 w-full relative">&nbsp;</div>
  <!-- END HEADER -->

  <!----------HEADERS FOR DEMO PURPOSE ONLY, INTEGRATE INTO ONE HEADER BAR LATER ---------->
  <div class="w-full">
    <h4 class="ml-8 my-0 py-2">other headers</h4>
    <div class="spacer h-1 w-full relative">&nbsp;</div>
  </div>

  <div
    class="w-full bg-white px-6 xs:px-12 box-border flex flex-col justify-center text-right h-24"
    [style.background-color]="organisation?.headerBackgroundColor"
  >
    <h1 [style.color]="organisation?.titleColor" class="my-auto">
      {{ organisation?.title }}
    </h1>
  </div>

  <div
    class="w-full bg-white px-6 xs:px-12 box-border flex flex-col justify-center text-right h-24"
    [ngStyle]="{
      'background-image': 'url(' + organisation?.headerImage + ')',
      'background-size': 'cover',
      'background-position': 'center'
    }"
  >
    <h1 [style.color]="organisation?.headerBackgroundColor" class="my-auto">
      {{ organisation?.title }}
    </h1>
  </div>

  <div
    class="w-full bg-light-grey px-6 xs:px-12 box-border flex flex-col justify-center text-right h-24"
  >
    <img
      [src]="organisation?.titleLogo"
      alt="{{ organisation?.title }} Logo"
      [style.max-height]="'32%'"
      [style.height]="'auto'"
      class="my-auto ml-auto"
    />
  </div>

  <div class="w-full bg-white px-6 xs:px-12 box-border h-24 flex items-center">
    <div class="flex flex-col text-right w-full">
      <h1 [style.color]="organisation?.headerBackgroundColor" class="m-0">
        {{ organisation?.title }}
      </h1>
      <h3
        [style.color]="organisation?.headerBackgroundColor"
        class="m-0 mb-3 font-normal"
      >
        {{ organisation?.subheading }}
      </h3>
    </div>
    <img
      [src]="organisation?.logo"
      [style.max-height]="'50%'"
      alt="{{ organisation?.title }} Logo"
      class="my-auto ml-2"
    />
  </div>

  <div
    class="w-full bg-light-grey px-6 xs:px-12 box-border flex flex-col justify-center text-right h-24"
  >
    <img
      [src]="organisation?.titleLogo"
      alt="{{ organisation?.title }} Logo"
      [style.max-height]="'32%'"
      [style.height]="'auto'"
      class="my-auto ml-auto"
    />
    <h3
      [style.color]="organisation?.headerBackgroundColor"
      class="-mt-4 mb-4 font-normal"
    >
      {{ organisation?.subheading }}
    </h3>
  </div>

  <!----------END HEADER FOR DEMO PURPOSE ONLY, INTEGRATE INTO ONE HEADER BAR LATER ---------->

  <!-- CONTENT -->
  <app-content-holder
    [contentHolder]="organisation?.homepageContent"
  ></app-content-holder>

  <!-- CONTACT DETAILS -->
  <div
    [ngClass]="{
      'bg-light-grey':
        organisation?.homepageContent?.amountOfActiveContentBlocks % 2 !== 0
    }"
  >
    <div class="px-8 xs:pb-8 xs:pt-1 xs:px-28">
      <h1 *ngIf="organisation?.contactDetails" class="text-theme">
        Contact Details
      </h1>
      <p class="m-0 p-0">
        {{ organisation?.contactDetails?.address?.address1 }}
      </p>
      <p class="m-0 p-0">
        {{ organisation?.contactDetails?.address?.address2 }}
      </p>
      <p class="m-0 p-0">{{ organisation?.contactDetails?.address?.city }}</p>
      <p class="m-0 p-0">{{ organisation?.contactDetails?.state }}</p>
      <p class="m-0 p-0">
        {{ organisation?.contactDetails?.address?.postcode }}
      </p>
      <p class="m-0 p-0">{{ organisation?.contactDetails?.country }}</p>
      <p>Phone: {{ organisation?.contactDetails?.phone }}</p>
      <p>Email: {{ organisation?.contactDetails?.email }}</p>
    </div>
  </div>
</ng-container>
<!-- END CONTACT DETAILS -->
<!-- END CONTENT -->
