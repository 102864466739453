import { Validators } from '@angular/forms';
import {
  FormItemButtonSelect,
  FormItemTextArea,
  FormItemTextbox,
} from 'src/app/models/form/form';

export const practicalHelpAreasWithNoSupportNeed = new FormItemButtonSelect({
  key: 'practicalHelpAreasWithNoSupportNeed',
  path: 'support.practicalHelp.areasWithNoSupportNeed',
  label: 'What can you do for yourself?',
  class: 'mb-5',
  multiple: true,
  options: [
    { name: 'Getting washed', label: 'Getting washed' },
    { name: 'Food prep', label: 'Food prep' },
    { name: 'Food shopping', label: 'Food shopping' },
    { name: 'Getting to appointments', label: 'Getting to appointments' },
    { name: 'Getting to bed', label: 'Getting to bed' },
    { name: 'Getting dressed', label: 'Getting dressed' },
    { name: 'Getting up', label: 'Getting up' },
    { name: 'Clean the home', label: 'Clean the home' },
    { name: 'Paying bills', label: 'Paying bills' },
    { name: 'Gardening', label: 'Gardening' },
    { name: 'Cooking', label: 'Cooking' },
    { name: 'Laundry', label: 'Laundry' },
    { name: 'Making Appointments', label: 'Making Appointments' },
    { name: 'Other', label: 'Other' },
  ],
});

export const practicalHelpAreasWithSupportNeed = new FormItemButtonSelect({
  key: 'practicalHelpAreasWithSupportNeed',
  path: 'support.practicalHelp.areasWithSupportNeed',
  label: 'Tell us what you need help with?',
  class: 'mb-5',
  multiple: true,
  options: [
    { name: 'Getting washed', label: 'Getting washed' },
    { name: 'Food prep', label: 'Food prep' },
    { name: 'Food shopping', label: 'Food shopping' },
    { name: 'Getting to appointments', label: 'Getting to appointments' },
    { name: 'Getting to bed', label: 'Getting to bed' },
    { name: 'Getting dressed', label: 'Getting dressed' },
    { name: 'Getting up', label: 'Getting up' },
    { name: 'Clean the home', label: 'Clean the home' },
    { name: 'Paying bills', label: 'Paying bills' },
    { name: 'Gardening', label: 'Gardening' },
    { name: 'Cooking', label: 'Cooking' },
    { name: 'Laundry', label: 'Laundry' },
    { name: 'Making Appointments', label: 'Making Appointments' },
    { name: 'Other', label: 'Other' },
  ],
});

export const practicalHelpExistingSupport = new FormItemTextbox({
  key: 'practicalHelpExistingSupport',
  path: 'support.practicalHelp.existingSupport',
  label: 'Who helps you with managing your home, if that is required?',
  placeholder: 'e.g. son',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const practicalHelpGeneralAdditionalInfo = new FormItemTextArea({
  key: 'practicalHelpGeneralAdditionalInfo',
  path: 'support.practicalHelp.generalAdditionalInfo',
  label:
    'Is there anything else you would like to share with us about the support you need?',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});
