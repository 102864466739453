import { OutputSubformDisplayComponent } from 'src/app/components/form/displays/output-subform-display/output-subform-display.component';
import {
  OutputContact,
  OutputEmail,
  OutputRedirect,
  getOutputContactFromSource,
  getOutputEmailFromSource,
  getOutputRedirectFromSource,
  prepareOutputContactForPayload,
  prepareOutputEmailForPayload,
  prepareOutputRedirectForPayload,
} from 'src/app/models/connection/connectionOutput';
import {
  FormItem,
  FormItemEmailContent,
  FormItemRepeater,
  FormItemSubform,
  FormItemTextArea,
  FormItemTextbox,
  FormItemTitle,
} from 'src/app/models/form/form';
import { Validators } from '@angular/forms';
import { getFormItemsAddress } from '../../shared-items/address';

// -------------------------------------------- TITLE --------------------------------------------

export const outputsFormTitle = new FormItemTitle({
  label: 'Outputs (how users can get in touch)',
  help: 'You can configure your connection to help users get in touch with you. At the simplest level, this takes the form of showing your contact details to the user. However, you can receive emails from the community connections portal with user data so you can reach out to them. Finally, if you have an app or website that is connected to the Mydex PDS, you can redirect users to you.',
  class: 'mt-6 mb-6',
  displayConditions: [
    {
      key: 'type',
      values: ['connection'],
    },
  ],
});

// -------------------------------------------- ALL OUTPUTS --------------------------------------------

export const introduction = new FormItemTextbox({
  key: 'introduction',
  label: 'Introduction',
  help: 'Explain what the output will do for this connection.',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(100)],
  // width: 'half',
});

export const nextSteps = new FormItemTextArea({
  key: 'nextSteps',
  label: 'Next Steps',
  help: 'Explain what will happen next when the output is executed.',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(200)],
  // width: 'half',
});

// -------------------------------------------- OUTPUT CONTACT --------------------------------------------

export const phone = new FormItemTextbox({
  key: 'phone',
  label: 'Phone',
  class: 'mb-5',
  // width: 'half',
});

export const emailContact = new FormItemTextbox({
  key: 'email',
  label: 'Email',
  class: 'mb-5',
  // width: 'half',
  validators: [
    Validators.email,
    Validators.minLength(1),
    Validators.maxLength(100),
  ],
});

export const openingHours = new FormItemRepeater({
  label: 'Opening Hours',
  key: 'openingHours',
  formItems: [
    new FormItemTextbox({
      label: '',
      placeholder: 'Opening time',
      validators: [Validators.minLength(1), Validators.maxLength(50)],
    }),
  ],
});

export const website = new FormItemTextbox({
  key: 'website',
  label: 'Website',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(200)],
  // width: 'half',
});

export const facebook = new FormItemTextbox({
  key: 'facebook',
  label: 'Facebook',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(200)],
  // width: 'half',
});

export const outputContactAddress: FormItem<any>[] = [
  ...getFormItemsAddress('address'),
];

export const outputContactSubform = new FormItemSubform({
  key: 'outputContact',
  path: 'outputs',
  label: 'Contact Output',
  class: 'mb-5',
  addTitle: 'Include a contact output',
  help: 'Contact details for this connection.',
  display: 'modal',
  displayComponent: OutputSubformDisplayComponent,
  multiple: false,
  placeholder: '',
  subform: [
    introduction,
    nextSteps,
    phone,
    emailContact,
    openingHours,
    website,
    facebook,
    ...outputContactAddress,
  ],
  getValueFromSource(value) {
    return getOutputContactFromSource(value);
  },
  prepareValueForPayload(currentValueAtPath, newValue) {
    return prepareOutputContactForPayload(currentValueAtPath, newValue);
  },
  createValueWithParams(params) {
    return new OutputContact(params);
  },
  displayConditions: [
    {
      key: 'type',
      values: ['connection'],
    },
  ],
});

// -------------------------------------------- OUTPUT REDIRECT --------------------------------------------

export const destinationUrl = new FormItemTextbox({
  key: 'destinationUrl',
  label: 'URL',
  class: 'mb-5',
  // width: 'half',
  required: true,
  validators: [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(200),
  ],
});

export const title = new FormItemTextbox({
  key: 'title',
  label: 'Title',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(100)],
  // width: 'half',
});

export const outputRedirectSubform = new FormItemSubform({
  key: 'outputRedirect',
  path: 'outputs',
  label: 'Redirection Output',
  class: 'mb-5',
  addTitle: 'Include a redirection output',
  help: 'Redirects users to a specified URL after submitting the connection data form.',
  display: 'modal',
  displayComponent: OutputSubformDisplayComponent,
  multiple: false,
  placeholder: '',
  subform: [introduction, nextSteps, destinationUrl, title],
  getValueFromSource(value) {
    return getOutputRedirectFromSource(value);
  },
  prepareValueForPayload(currentValueAtPath, newValue) {
    return prepareOutputRedirectForPayload(currentValueAtPath, newValue);
  },
  createValueWithParams(params) {
    return new OutputRedirect(params);
  },
  displayConditions: [
    {
      key: 'type',
      values: ['connection'],
    },
  ],
});

// -------------------------------------------- OUTPUT EMAIL --------------------------------------------

export const email = new FormItemTextbox({
  key: 'email',
  label: 'Email',
  class: 'mb-5',
  // width: 'half',
  required: true,
  validators: [
    Validators.required,
    Validators.email,
    Validators.minLength(1),
    Validators.maxLength(100),
  ],
});

export const referralEmailText = new FormItemEmailContent({
  key: 'referralEmailText',
  label: 'Referral Email Content (Sent to You)',
  salutation: 'Dear Connection Manager,',
  introduction:
    'This is an email submission for [Organisation / Connection] from https://communityconnect.scot. A user is requesting to connect with the details listed below.',
  placeholder:
    'The following user from has submitted a contact form. Our standard process is for someone on the comms team to phone them within 48 hours and log the contact in the organisation CRM.',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const confirmationEmailText = new FormItemEmailContent({
  key: 'confirmationEmailText',
  label: 'Confirmation Email Content (Sent to User)',
  salutation: 'Dear [User],',
  introduction:
    'You requested to connect with [Organisation / Connection] on https://communityconnect.scot. You shared the details listed below.',
  placeholder:
    'Thanks for connecting with OUR CONNECTION. We will be in touch soon.',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const outputEmailSubform = new FormItemSubform({
  key: 'outputEmail',
  path: 'outputs',
  label: 'Email Output',
  class: 'mb-5',
  addTitle: 'Include an email output',
  help: "Sends user's details to the provided email address after submitting the connection data form.",
  display: 'modal',
  displayComponent: OutputSubformDisplayComponent,
  multiple: false,
  placeholder: '',
  subform: [
    introduction,
    nextSteps,
    email,
    referralEmailText,
    confirmationEmailText,
  ],
  getValueFromSource(value) {
    return getOutputEmailFromSource(value);
  },
  prepareValueForPayload(currentValueAtPath, newValue) {
    return prepareOutputEmailForPayload(currentValueAtPath, newValue);
  },
  createValueWithParams(params) {
    return new OutputEmail(params);
  },
  displayConditions: [
    {
      key: 'type',
      values: ['connection'],
    },
  ],
});
