import { UserContactData } from './userContactData';

export class UserContactPdsEntry extends UserContactData {
  contactId?: string;

  constructor(userContact?: Partial<UserContactPdsEntry>) {
    super(userContact);
    this.contactId = userContact?.contactId;
  }
}
