export class UserImageData {
  fileName?: string;
  description?: string;
  file?: string;
  thumbnail?: string;
  fileSize?: string;
  assetType?: string;

  constructor(userImageData?: Partial<UserImageData>) {
    this.fileName = userImageData?.fileName;
    this.description = userImageData?.description;
    this.file = userImageData?.file;
    this.thumbnail = userImageData?.thumbnail;
    this.fileSize = userImageData?.fileSize;
    this.assetType = userImageData?.assetType;
  }
}
