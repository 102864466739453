import { FileData } from '../base/fileData';
import { Address } from '../base/address';

export class UserPlanningAhead {
  attitudeToChange?: UserPlanningAheadAttitudeToChange;
  powerOfAttorney?: UserPlanningAheadPowerOfAttorney;
  futureCarePlan?: UserPlanningAheadFutureCarePlan;
  will?: UserPlanningAheadWill;
  unpaidCarer?: UserPlanningAheadUnpaidCarer;
  livingWill?: UserPlanningAheadLivingWill;

  constructor(planningAhead?: Partial<UserPlanningAhead>) {
    this.attitudeToChange = new UserPlanningAheadAttitudeToChange(
      planningAhead?.attitudeToChange
    );
    this.powerOfAttorney = new UserPlanningAheadPowerOfAttorney(
      planningAhead?.powerOfAttorney
    );
    this.futureCarePlan = new UserPlanningAheadFutureCarePlan(
      planningAhead?.futureCarePlan
    );
    this.will = new UserPlanningAheadWill(planningAhead?.will);
    this.unpaidCarer = new UserPlanningAheadUnpaidCarer(
      planningAhead?.unpaidCarer
    );
    this.livingWill = new UserPlanningAheadLivingWill(
      planningAhead?.livingWill
    );
  }
}

class UserPlanningAheadAttitudeToChange {
  summary?: string;
  continousCareHousingPreference?: string[];
  importantCareAspects?: string[];

  constructor(data: Partial<UserPlanningAheadAttitudeToChange | undefined>) {
    this.summary = data?.summary;
    this.continousCareHousingPreference = data?.continousCareHousingPreference;
    this.importantCareAspects = data?.importantCareAspects;
  }
}

class UserPlanningAheadPowerOfAttorney {
  currentSupport?: string;
  otherCompletedStatements?: string[];
  assignee?: UserPlanningAheadPowerOfAttorneyAssignee;
  documentLocation?: string;
  document?: FileData;
  isRequestingSupport?: boolean;

  constructor(data: Partial<UserPlanningAheadPowerOfAttorney | undefined>) {
    this.currentSupport = data?.currentSupport;
    this.otherCompletedStatements = data?.otherCompletedStatements;
    this.assignee = new UserPlanningAheadPowerOfAttorneyAssignee(
      data?.assignee
    );
    this.documentLocation = data?.documentLocation;
    this.document = data?.document;
    this.isRequestingSupport = data?.isRequestingSupport;
  }
}

class UserPlanningAheadPowerOfAttorneyAssignee {
  name?: string;
  relationship?: string;
  address?: Address;
  phone?: string;
  email?: string;

  constructor(
    data: Partial<UserPlanningAheadPowerOfAttorneyAssignee | undefined>
  ) {
    this.name = data?.name;
    this.relationship = data?.relationship;
    this.address = new Address(data?.address);
    this.phone = data?.phone;
    this.email = data?.email;
  }
}

class UserPlanningAheadFutureCarePlan {
  hasHadConversationAboutWishes?: boolean;
  hasChosenConfidant?: boolean;
  chosenConfidant?: UserPlanningAheadFutureCarePlanChosenConfidant;
  healthManagementNeeds?: string;
  concernsAboutFuture?: string;
  peopleInvolvedInCare?: string[];
  additionalInfo?: string;
  hospitalAdmissionRequests?: string;

  constructor(data: Partial<UserPlanningAheadFutureCarePlan | undefined>) {
    this.hasHadConversationAboutWishes = data?.hasHadConversationAboutWishes;
    this.hasChosenConfidant = data?.hasChosenConfidant;
    this.chosenConfidant = new UserPlanningAheadFutureCarePlanChosenConfidant(
      data?.chosenConfidant
    );
    this.healthManagementNeeds = data?.healthManagementNeeds;
    this.concernsAboutFuture = data?.concernsAboutFuture;
    this.peopleInvolvedInCare = data?.peopleInvolvedInCare;
    this.additionalInfo = data?.additionalInfo;
    this.hospitalAdmissionRequests = data?.hospitalAdmissionRequests;
  }
}

class UserPlanningAheadFutureCarePlanChosenConfidant {
  name?: string;
  relationship?: string;
  address?: Address;
  phone?: string;
  email?: string;

  constructor(
    data: Partial<UserPlanningAheadFutureCarePlanChosenConfidant | undefined>
  ) {
    this.name = data?.name;
    this.relationship = data?.relationship;
    this.address = new Address(data?.address);
    this.phone = data?.phone;
    this.email = data?.email;
  }
}

class UserPlanningAheadWill {
  documentLocation?: string;
  hasOrganDonationCard?: boolean;

  constructor(data: Partial<UserPlanningAheadWill | undefined>) {
    this.documentLocation = data?.documentLocation;
    this.hasOrganDonationCard = data?.hasOrganDonationCard;
  }
}

class UserPlanningAheadUnpaidCarer {
  wishesForCaredForPeople?: string;

  constructor(data: Partial<UserPlanningAheadUnpaidCarer | undefined>) {
    this.wishesForCaredForPeople = data?.wishesForCaredForPeople;
  }
}

class UserPlanningAheadLivingWill {
  hasAdvancedDirective?: boolean;
  advancedDirectiveLocationAndSharingInfo?: string;
  resuscitationThoughtsAndQuestions?: string;

  constructor(data: Partial<UserPlanningAheadLivingWill | undefined>) {
    this.hasAdvancedDirective = data?.hasAdvancedDirective;
    this.advancedDirectiveLocationAndSharingInfo =
      data?.advancedDirectiveLocationAndSharingInfo;
    this.resuscitationThoughtsAndQuestions =
      data?.resuscitationThoughtsAndQuestions;
  }
}
