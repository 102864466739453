import { FileData } from '../base/fileData';
import { Address } from '../base/address';

export class UserMe {
  personalDetails?: UserMePersonalDetails;
  contact?: UserMeContact;
  current?: UserMeCurrent;
  personalQualities?: UserMePersonalQualities;
  myPeople?: UserMeMyPeople;
  pastimes?: UserMePastimes;
  home?: UserMeHome;
  routines?: UserMeRoutines;
  reminiscence?: UserMeReminiscence;
  moneyMatters?: UserMeMoneyMatters;

  constructor(me?: Partial<UserMe>) {
    this.personalDetails = new UserMePersonalDetails(me?.personalDetails);
    this.contact = new UserMeContact(me?.contact);
    this.current = new UserMeCurrent(me?.current);
    this.personalQualities = new UserMePersonalQualities(me?.personalQualities);
    this.myPeople = new UserMeMyPeople(me?.myPeople);
    this.pastimes = new UserMePastimes(me?.pastimes);
    this.home = new UserMeHome(me?.home);
    this.routines = new UserMeRoutines(me?.routines);
    this.reminiscence = new UserMeReminiscence(me?.reminiscence);
    this.moneyMatters = new UserMeMoneyMatters(me?.moneyMatters);
  }
}

class UserMePersonalDetails {
  firstName: string;
  middleName: string;
  lastName?: string;
  picture?: string;
  preferredName?: string;
  maritalStatus?: string;
  maidenName?: string;
  alsoKnownAs?: string;
  gender?: string;
  genderAtBirth?: string;
  pronouns?: string;
  dateOfBirth?: Date;
  ethnicity?: string;

  constructor(data: Partial<UserMePersonalDetails | undefined>) {
    this.firstName = data?.firstName ?? '';
    this.middleName = data?.middleName ?? '';
    this.lastName = data?.lastName;
    this.picture = data?.picture;
    this.preferredName = data?.preferredName;
    this.maritalStatus = data?.maritalStatus;
    this.maidenName = data?.maidenName;
    this.alsoKnownAs = data?.alsoKnownAs;
    this.gender = data?.gender;
    this.genderAtBirth = data?.genderAtBirth;
    this.pronouns = data?.pronouns;
    this.dateOfBirth = data?.dateOfBirth;
    this.ethnicity = data?.ethnicity;
  }
}

export class UserMeContact {
  phoneHome?: string;
  phoneMobile?: string;
  email: string;
  address?: Address;
  propertyType?: string;
  tenancyType?: string;

  constructor(data: Partial<UserMeContact | undefined>) {
    this.phoneHome = data?.phoneHome;
    this.phoneMobile = data?.phoneMobile;
    this.email = data?.email ?? '';
    this.address = new Address(data?.address);
    this.propertyType = data?.propertyType;
    this.tenancyType = data?.tenancyType;
  }
}

class UserMeCurrent {
  lifeSituation?: string[];
  friendsAndFamily?: string;
  livingWith?: string[];
  supportedBy?: string[];
  supporting?: string[];
  interests?: string;
  additionalInfo?: string;
  photosTellingPersonalStory?: string[];

  constructor(data: Partial<UserMeCurrent | undefined>) {
    this.lifeSituation = data?.lifeSituation;
    this.friendsAndFamily = data?.friendsAndFamily;
    this.livingWith = data?.livingWith;
    this.supportedBy = data?.supportedBy;
    this.supporting = data?.supporting;
    this.interests = data?.interests;
    this.additionalInfo = data?.additionalInfo;
    this.photosTellingPersonalStory = data?.photosTellingPersonalStory;
  }
}

class UserMePersonalQualities {
  likes?: string[];
  positiveCharacteristics?: string;
  challengingCharacteristics?: string;
  goodDayDescription?: string;
  badDayDescription?: string;
  goals?: UserMePersonalQualitiesGoals;

  constructor(data: Partial<UserMePersonalQualities | undefined>) {
    this.likes = data?.likes;
    this.positiveCharacteristics = data?.positiveCharacteristics;
    this.challengingCharacteristics = data?.challengingCharacteristics;
    this.goodDayDescription = data?.goodDayDescription;
    this.badDayDescription = data?.badDayDescription;
    this.goals = new UserMePersonalQualitiesGoals(data?.goals);
  }
}

class UserMePersonalQualitiesGoals {
  areas?: string[];
  revival?: string;
  future?: string;
  additionalInfo?: string;

  constructor(data: Partial<UserMePersonalQualitiesGoals | undefined>) {
    this.areas = data?.areas;
    this.revival = data?.revival;
    this.future = data?.future;
    this.additionalInfo = data?.additionalInfo;
  }
}

class UserMeMyPeople {
  mainPeopleInLife?: string[];
  importantRelationships: string[];
  relationships?: UserMeMyPeopleRelationship[]; // TODO: remove and handle for referrals

  constructor(data: Partial<UserMeMyPeople | undefined>) {
    this.mainPeopleInLife = data?.mainPeopleInLife;
    this.importantRelationships = data?.importantRelationships ?? [];
    this.relationships = data?.relationships?.map(
      (r) => new UserMeMyPeopleRelationship(r)
    );
  }
}

export class UserMeMyPeopleRelationship {
  firstName: string;
  lastName?: string;
  nickname?: string;
  role?: string;
  phoneHome?: string;
  phoneMobile?: string;
  email?: string;
  additionalInfo?: string;
  isLostAndMissed?: boolean;
  image?: FileData;

  constructor(data: Partial<UserMeMyPeopleRelationship | undefined>) {
    this.firstName = data?.firstName ?? '';
    this.lastName = data?.lastName;
    this.nickname = data?.nickname;
    this.role = data?.role;
    this.phoneHome = data?.phoneHome;
    this.phoneMobile = data?.phoneMobile;
    this.email = data?.email;
    this.additionalInfo = data?.additionalInfo;
    this.isLostAndMissed = data?.isLostAndMissed;
    this.image = data?.image;
  }
}

class UserMePastimes {
  interestsNow?: string[];
  interestsNowAdditionalInfo?: string;
  interestsPast?: string[];
  interestsPastAdditionalInfo?: string;

  constructor(data: Partial<UserMePastimes | undefined>) {
    this.interestsNow = data?.interestsNow;
    this.interestsNowAdditionalInfo = data?.interestsNowAdditionalInfo;
    this.interestsPast = data?.interestsPast;
    this.interestsPastAdditionalInfo = data?.interestsPastAdditionalInfo;
  }
}

class UserMeHome {
  area?: string[];
  neighbourhood?: string;
  suitability?: string;
  additionalInfo?: string;

  constructor(data: Partial<UserMeHome | undefined>) {
    this.area = data?.area;
    this.neighbourhood = data?.neighbourhood;
    this.suitability = data?.suitability;
    this.additionalInfo = data?.additionalInfo;
  }
}

class UserMeRoutines {
  sleep?: string[];
  startDayTime?: string;
  breakfastTime?: string;
  regularOutings?: string;

  constructor(data: Partial<UserMeRoutines | undefined>) {
    this.sleep = data?.sleep;
    this.startDayTime = data?.startDayTime;
    this.breakfastTime = data?.breakfastTime;
    this.regularOutings = data?.regularOutings;
  }
}

class UserMeReminiscence {
  gallery?: string[];

  constructor(data: Partial<UserMeReminiscence | undefined>) {
    this.gallery = data?.gallery;
  }
}

class UserMeMoneyMatters {
  pensionCredit?: string;
  incapacityBenefit?: string;
  attendanceAllowance?: string;
  disabilityLivingAllowance?: string;
  personalIndependencePayment?: string;
  universalCredit?: string;
  privatePension?: string;
  statePension?: string;
  financesAdditionalInfo?: string;

  constructor(data: Partial<UserMeMoneyMatters | undefined>) {
    this.pensionCredit = data?.pensionCredit;
    this.incapacityBenefit = data?.incapacityBenefit;
    this.attendanceAllowance = data?.attendanceAllowance;
    this.disabilityLivingAllowance = data?.disabilityLivingAllowance;
    this.personalIndependencePayment = data?.personalIndependencePayment;
    this.universalCredit = data?.universalCredit;
    this.privatePension = data?.privatePension;
    this.statePension = data?.statePension;
    this.financesAdditionalInfo = data?.financesAdditionalInfo;
  }
}
