import { Validators } from '@angular/forms';
import {
  FormItemButtonSelect,
  FormItemTextArea,
} from 'src/app/models/form/form';

export const personalAidsInUse = new FormItemButtonSelect({
  key: 'personalAidsInUse',
  path: 'support.personalAids.aidsInUse',
  label: 'What personal aids do you use?',
  class: 'mb-5',
  multiple: true,
  options: [
    { name: 'Hearing aid', label: 'Hearing aid' },
    { name: 'Glasses / contact lenses', label: 'Glasses / contact lenses' },
    { name: 'Dentures', label: 'Dentures' },
    { name: 'Pressure stocking', label: 'Pressure stocking' },
    { name: 'Colostomy bag', label: 'Colostomy bag' },
    { name: 'Artificial limb', label: 'Artificial limb' },
    { name: 'Wig', label: 'Wig' },
    { name: 'Other', label: 'Other' },
  ],
});

export const personalAidsInUseAdditionalInfo = new FormItemTextArea({
  key: 'personalAidsInUseAdditionalInfo',
  path: 'support.personalAids.aidsInUseAdditionalInfo',
  label: "Anything else you'd like to share about the personal aids you use:",
  placeholder: 'e.g. I only wear my dentures when I go outside',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});
