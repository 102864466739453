export class FileData {
  base64StringValue?: string;
  type?: string;
  name?: string;
  description?: string;

  constructor(data: Partial<FileData | undefined>) {
    this.base64StringValue = data?.base64StringValue;
    this.type = data?.type;
    this.name = data?.name;
    this.description = data?.description;
  }
}
