export const yesNoOptions = [
  { label: 'Yes', name: true },
  { label: 'No', name: false },
];

export const yesNoSometimesOptions = [
  { name: 'Yes', label: 'Yes' },
  { name: 'No', label: 'No' },
  { name: 'Sometimes', label: 'Sometimes' },
];

export const yesNoMaybeOptions = [
  { name: 'Yes', label: 'Yes' },
  { name: 'No', label: 'No' },
  { name: 'Maybe', label: 'Maybe' },
];

export const yesNoUnknownOptions = [
  { name: 'Yes', label: 'Yes' },
  { name: 'No', label: 'No' },
  { name: 'Do not know', label: 'Do not know' },
];

export const yesNoPreferNotToSayOptions = [
  { name: 'Yes', label: 'Yes' },
  { name: 'No', label: 'No' },
  { name: 'Prefer not to say', label: 'Prefer not to say' },
];

export const yesNoInProgressOptions = [
  { name: 'Yes', label: 'Yes' },
  { name: 'No', label: 'No' },
  { name: 'In Progress', label: 'In Progress' },
];

export const yesNoInProgressLegacyOptions = [
  { name: 'Yes', label: 'Yes' },
  { name: 'No', label: 'No' },
  { name: 'In progress', label: 'In progress' },
];
