import { Validators } from '@angular/forms';
import {
  FormItemButtonSelect,
  FormItemTextArea,
  FormItemTextbox,
} from 'src/app/models/form/form';

// KEY INFORMATION - ACCESS -----------------------------------------------

export const howToEmergencyAccess = new FormItemButtonSelect({
  key: 'howToEmergencyAccess',
  label: 'How to access your home in an emergency?',
  path: 'keyInformation.access.howToInEmergency',
  multiple: true,
  options: [
    { name: 'Key with neighbour', label: 'Key with neighbour' },
    { name: 'Key safe', label: 'Key safe' },
    { name: 'Getting out to the garden', label: 'Getting out to the garden' },
    { name: 'Call key support person', label: 'Call key support person' },
    { name: 'Other', label: 'Other' },
  ],
  class: 'mb-5',
});

export const keySafeCode = new FormItemTextbox({
  key: 'keySafeCode',
  path: 'keyInformation.access.keySafeCode',
  placeholder: 'e.g. 1234',
  label: 'Key Safe Code',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const keyPersonName = new FormItemTextbox({
  key: 'keyPersonName',
  path: 'keyInformation.access.keyPersonName',
  label: 'Key Person Name',
  class: 'mb-5',
  type: 'string',
  width: 'half',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const keyPersonPhone = new FormItemTextbox({
  key: 'keyPersonPhone',
  path: 'keyInformation.access.keyPersonPhone',
  label: 'Key Person Phone Number',
  class: 'mb-5',
  width: 'half',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const neighbourName = new FormItemTextbox({
  key: 'neighbourName',
  path: 'keyInformation.access.neighbourName',
  label: 'Neighbour Name',
  class: 'mb-5',
  width: 'half',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const neighbourPhone = new FormItemTextbox({
  key: 'neighbourPhone',
  path: 'keyInformation.access.neighbourPhone',
  label: 'Neighbour Phone Number',
  class: 'mb-5',
  width: 'half',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const accessAdditionalInfo = new FormItemTextArea({
  key: 'accessAdditionalInfo',
  path: 'keyInformation.access.additionalInfo',
  label: 'Anything else you would like to share about access to your home:',
  placeholder: 'e.g. I have two dogs that may bark',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});
