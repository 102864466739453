<div class="my-auto pb-[4vw] home-container">
  <div class="flex mt-8 box-border">
    <div class="flex flex-col lg:w-1/2">
      <h1 class="text-2xl font-medium mt-0 mb-4">
        Permissions: Update required
      </h1>
      <p class="mb-12">
        We made changes to the way we store your data. To continue using your
        <strong>About Me</strong>, we need you to approve new permissions. We
        will take you to Mydex to do this and this is where you'll need your
        private key again to give access.
      </p>

      <p
        class="text-error-red form-error-text mb-4"
        *ngIf="
          (pdsService.awaitingUrlRedirectError$ | async) &&
          !(pdsService.awaitingUrlRedirect$ | async)
        "
      >
        {{
          (pdsService.awaitingUrlRedirectError$ | async) ||
            "An error occured. Please refresh the page and try again."
        }}
      </p>

      <button
        (click)="submit()"
        [disabled]="pdsService.awaitingUrlRedirect$ | async"
        class="btn-theme px-8 mr-auto"
      >
        <app-loading-spinner
          *ngIf="pdsService.awaitingUrlRedirect$ | async"
          class="m-auto"
          text="Redirecting"
        ></app-loading-spinner>
        <ng-container *ngIf="!(pdsService.awaitingUrlRedirect$ | async)"
          >Approve new Permissions</ng-container
        >
      </button>
    </div>
    <div class="-my-20 w-1/2 hidden lg:flex">
      <img
        class="h-2 ml-auto min-h-full"
        src="/assets/img/permissions-illustration.svg"
        alt=""
      />
    </div>
  </div>
</div>
