import { Validators } from '@angular/forms';
import { maxByteValidator } from 'src/app/helper/validators/maxByteValidator';
import {
  FormItemButtonSelect,
  FormItemTextArea,
} from 'src/app/models/form/form';

// THIS IS ME - HOME -------------------------------------------------------

export const homeArea = new FormItemButtonSelect({
  key: 'homeArea',
  label: 'What is the area that you live in like?',
  path: 'me.home.area',
  multiple: true,
  options: [
    { name: 'my_area_is_isolated', label: 'Isolated' },
    { name: 'my_area_is_near-houses', label: 'Near other houses' },
    { name: 'my_area_is_a_flat_complex', label: 'Flat/Complex' },
    { name: 'my_area_is_other', label: 'Other' },
  ],
  class: 'mb-3',
});

export const neighbourhood = new FormItemTextArea({
  key: 'neighbourhood',
  path: 'me.home.neighbourhood',
  label: 'Tell us about your neighbourhood',
  placeholder: 'e.g. quiet and peaceful',
  class: 'mb-5',
  validators: [Validators.minLength(1), maxByteValidator(2000)],
});

export const homeSuitability = new FormItemTextArea({
  key: 'suitability',
  path: 'me.home.suitability',
  label: 'Is your home suitable for you?',
  placeholder: 'e.g. the bathroom is far away from my bedroom',
  class: 'mb-5',
  validators: [Validators.minLength(1), maxByteValidator(2000)],
});

export const homeAdditionalInfo = new FormItemTextArea({
  key: 'homeAdditionalInfo',
  path: 'me.home.additionalInfo',
  label: 'Share more about your home',
  class: 'mb-5',
  validators: [Validators.minLength(1), maxByteValidator(2000)],
});
