import {
  Component,
  ElementRef,
  Input,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { getImageMimeTypeFromBase64String } from 'src/app/helper/imageHelper';

import { FormItemImageUpload } from 'src/app/models/form/form';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ImageUploadComponent),
    },
  ],
})
export class ImageUploadComponent implements ControlValueAccessor {
  // PROPERTIES

  @Input() item?: FormItemImageUpload;
  @Input() control: any;
  @Input() disabled = false;
  @ViewChild('fileInputRef') fileInputRef!: ElementRef<HTMLInputElement>;
  @ViewChild(ModalComponent, { static: true }) modal: ModalComponent =
    {} as ModalComponent;

  description: string = '';

  value: string | null = null;
  onChange = (selected: any) => {};
  onTouched = () => {};
  touched = false;

  inputMode = false;

  // LIFE CYCLE HOOKS

  ngOnInit() {
    if (!this.control.value) {
      this.inputMode = true;
    }
  }

  // CONTROL VALUE ACCESSOR

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // FILE HANDLING

  openFilePicker() {
    this.fileInputRef.nativeElement.click();
  }

  onCancelFilePicker(event: any) {
    event.stopPropagation();

    if (this.control.value) {
      this.inputMode = false;
    }
  }

  onFileSelected(event: any): void {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (readerEvt) => {
      this.handleReaderLoaded(readerEvt, file);
    };
    reader.readAsDataURL(file);
    this.inputMode = false;
  }

  handleReaderLoaded(readerEvt: any, file: File): void {
    const base64WithPrefix = readerEvt.target.result;
    // Remove the prefix (i.e. "data:image/png;base64,") because Mydex does not accept the prefix
    const base64 = base64WithPrefix.split(',')[1];

    this.value = base64;
    this.onChange(this.value);
  }

  onChangeFile() {
    this.inputMode = true;

    setTimeout(() => {
      this.openFilePicker();
    }, 1);
  }

  onDeleteFile(): void {
    this.value = null;
    this.onChange(this.value);
    this.inputMode = true;
  }

  // IMAGE ACCESS

  getImageSrc(): string | undefined {
    if (!this.control?.value) {
      return undefined;
    }

    const mimeType = getImageMimeTypeFromBase64String(this.control.value);

    if (!mimeType) {
      return undefined;
    }

    return `data:image/${mimeType};base64,${this.control.value}`;
  }

  // MODAL

  openModal(e: any) {
    if (!this.value) return;

    e.stopPropagation();
    e.preventDefault();
    this.modal.open();
  }

  closeModal() {
    this.modal.close();
  }

  downloadImage() {
    const link = document.createElement('a');
    link.href = this.getImageSrc() ?? '';
    link.download = this.item?.imageDownloadName ?? 'image';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
