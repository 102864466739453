import { Validators } from '@angular/forms';
import { FormItemImageUploadGallery } from 'src/app/models/form/form';

// THIS IS ME - REMINISCENCE GALLERY -------------------------------------------------------

export const reminiscenceGallery = new FormItemImageUploadGallery({
  key: 'reminiscenceGallery',
  path: 'me.reminiscence.gallery',
  label: 'Reminiscence',
  help: 'Select images from your image gallery that help you reminisce. Click on an image for a detailed view.',
  class: 'mb-5',
  imageDownloadName: 'pds-image',
  placeholderIconName: 'image',
  validators: [Validators.minLength(1), Validators.maxLength(5000000)], // TODO: add compression library?
});
