import { Validators } from '@angular/forms';
import {
  FormItemButtonSelect,
  FormItemTextArea,
  FormItemTextbox,
} from 'src/app/models/form/form';
import { yesNoOptions } from '../item-options';

export const communicationFirstLanguage = new FormItemTextbox({
  key: 'communicationFirstLanguage',
  path: 'support.communication.firstLanguage',
  label: 'What is your first language?',
  placeholder: 'e.g. English',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const communicationPreferredTypes = new FormItemButtonSelect({
  key: 'communicationPreferredTypes',
  path: 'support.communication.preferredTypes',
  label: 'My preferred types of communication',
  class: 'mb-5',
  multiple: true,
  options: [
    { name: 'Email', label: 'Email' },
    { name: 'Telephone', label: 'Telephone' },
    { name: 'Face-to-face', label: 'Face-to-face' },
    { name: 'Text messaging', label: 'Text messaging' },
    { name: 'Other', label: 'Other' },
  ],
});

export const communicationPreferredTypesAdditionalInfo = new FormItemTextArea({
  key: 'communicationPreferredTypesAdditionalInfo',
  path: 'support.communication.preferredTypesAdditionalInfo',
  label:
    'Anything else you would like to share about your preferred types of communicaton:',
  placeholder:
    'e.g. I speak slowly with pauses and I need people to be patient and let me finish',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const communicationGeneralAids = new FormItemTextbox({
  key: 'communicationGeneralAids',
  path: 'support.communication.generalAids',
  label: 'Your communication aids',
  placeholder: 'e.g. Phone',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const communicationSignLanguages = new FormItemButtonSelect({
  key: 'communicationSignLanguages',
  path: 'support.communication.signLanguages',
  label: 'Do you use sign language?',
  class: 'mb-5',
  multiple: true,
  options: [
    { name: 'BSL', label: 'BSL' },
    { name: 'Makaton', label: 'Makaton' },
    { name: 'Tactile Signing', label: 'Tactile Signing' },
    { name: 'Other', label: 'Other' },
  ],
});

export const communicationSignLanguagesAdditionalInfo = new FormItemTextArea({
  key: 'communicationSignLanguagesAdditionalInfo',
  path: 'support.communication.signLanguagesAdditionalInfo',
  label:
    'Is there anything else you would like to share about any sign language you use?',
  placeholder: "e.g. 'The interpreting service I use...",
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const communicationHearingAids = new FormItemButtonSelect({
  key: 'communicationHearingAids',
  label: 'Do you use hearing aids?',
  path: 'support.communication.hearingAids',
  class: 'mb-5',
  options: yesNoOptions,
});

export const communicationExistingSupport = new FormItemTextbox({
  key: 'communicationExistingSupport',
  path: 'support.communication.existingSupport',
  label: 'Who, if anyone, supports you with your communication?',
  placeholder: 'e.g. friend',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const communicationGeneralAdditionalInfo = new FormItemTextArea({
  key: 'communicationGeneralAdditionalInfo',
  path: 'support.communication.generalAdditionalInfo',
  label: 'Anything else you would like to share about how you communicate:',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});
