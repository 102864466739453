<div class="tabs flex w-full justify-between">
  <button
    class="text-2xl tab border-0 rounded-none border-theme border-solid font-medium mr-4 pb-2 tab"
    *ngFor="let tab of options"
    [ngClass]="{ active: selected == tab.value }"
    (click)="select(tab.value)"
  >
    {{ tab.name }}
  </button>
</div>
