<div class="flex mt-8 box-border">
  <div class="flex flex-col lg:w-1/2">
    <h1 class="text-2xl font-medium mt-0 mb-4">Step 3: Log in</h1>

    <!-- NOTE - Wording could probably do with some work here -->
    <p class="mb-12 max-w-3xl">
      Now that you have created your personal data store, you can log in to your
      account and start adding your personal details.
    </p>

    <button class="btn-theme font-semibold px-8 mr-auto" (click)="submit()">
      <span class="inline-block align-middle mb-1 mr-2"> Sign in with </span>
      <img src="/assets/img/mydex-white.png" alt="" class="h-5 align-middle" />
    </button>
  </div>
  <div class="-my-40 w-1/2 hidden lg:flex">
    <img
      class="h-2 ml-auto min-h-full"
      src="/assets/img/details-illustration.svg"
      alt=""
    />
  </div>
</div>
