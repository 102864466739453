export class UserContactData {
  firstName?: string;
  lastName?: string;
  nickname?: string;
  role?: string;
  phoneHome?: string;
  phoneMobile?: string;
  email?: string;
  additionalInfo?: string;
  lostAndMissed?: boolean; // TODO: update and include after Mydex update
  picture?: string;

  constructor(userContact?: Partial<UserContactData>) {
    this.firstName = userContact?.firstName;
    this.lastName = userContact?.lastName;
    this.nickname = userContact?.nickname;
    this.role = userContact?.role;
    this.phoneHome = userContact?.phoneHome;
    this.phoneMobile = userContact?.phoneMobile;
    this.email = userContact?.email;
    this.additionalInfo = userContact?.additionalInfo;
    this.lostAndMissed = userContact?.lostAndMissed;
    this.picture = userContact?.picture;
  }
}
