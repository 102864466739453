import { Component, Input, OnInit, forwardRef } from '@angular/core';
import {
  ControlValueAccessor,
  FormArray,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {
  ConnectionDisplayLogic,
  CriteriaGroup,
  Criterion,
} from 'src/app/models/connection/connectionDisplayLogic';
import { FormItemDisplayLogic } from 'src/app/models/form/form';

@Component({
  selector: 'app-display-logic',
  templateUrl: './display-logic.component.html',
  styleUrls: ['./display-logic.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DisplayLogicComponent),
      multi: true,
    },
  ],
})
export class DisplayLogicComponent implements ControlValueAccessor, OnInit {
  // PROPERTIES

  @Input() item?: FormItemDisplayLogic;
  @Input() disabled: boolean = false; // TODO: handle disabled state

  displayLogicFormGroup: FormGroup = new FormGroup({
    expression: new FormControl(''),
    criteriaGroups: new FormArray<CriteriaGroupFormGroup>([]),
  });

  private onChange: any = () => {};
  private onTouched: any = () => {};

  // LIFECYCLE

  ngOnInit(): void {
    this.displayLogicFormGroup.valueChanges.subscribe((value) => {
      this.onChange(value);
      this.onTouched();
    });
  }

  // CONTROL VALUE ACCESSOR

  writeValue(value: any): void {
    if (!(value instanceof ConnectionDisplayLogic)) {
      return;
    }

    // map the data to the form control values, so the structure represents a ConnectionDisplayLogic object
    value.criteriaGroups.forEach((criteriaGroup, criteriaGroupIndex) => {
      let criteriaGroupFormGroup =
        this.getNewCriteriaGroupFormGroup(criteriaGroup);
      this.criteriaGroupsFormArray.push(criteriaGroupFormGroup);

      const criteriaFormArray =
        this.criteriaFormArrayOfCriteriaGroup(criteriaGroupIndex);

      criteriaGroup.criteria.forEach((criterion) => {
        criteriaFormArray.push(this.getNewCriterionFormGroup(criterion));
      });
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // ACCESS FORM GROUP COMPONENTS

  get criteriaGroupsFormArray(): FormArray {
    return this.displayLogicFormGroup.get('criteriaGroups') as FormArray;
  }

  criteriaFormArrayOfCriteriaGroup(
    criteriaGroupIndex: number
  ): FormArray<CriterionFormGroup> {
    if (!this.criteriaGroupsFormArray.at(criteriaGroupIndex)) {
      return new FormArray<CriterionFormGroup>([]);
    }

    return this.criteriaGroupsFormArray
      .at(criteriaGroupIndex)
      .get('criteria') as FormArray;
  }

  // ADDING/REMOVING FORM CONTROLS

  getNewCriteriaGroupFormGroup(
    criteriaGroup?: CriteriaGroup
  ): CriteriaGroupFormGroup {
    return new FormGroup({
      expression: new FormControl(criteriaGroup?.expression ?? 'or'),
      criteria: new FormArray<CriterionFormGroup>([]),
    });
  }

  getNewCriterionFormGroup(criteron?: Criterion<any>): CriterionFormGroup {
    return new FormGroup({
      type: new FormControl(criteron?.type ?? ''),
      expression: new FormControl(criteron?.expression ?? 'or'),
      value: new FormControl(criteron?.value ?? ''),
    });
  }
}

interface CriteriaGroupFormGroup extends FormGroup {
  controls: {
    expression: FormControl;
    criteria: FormArray<CriterionFormGroup>;
  };
}

interface CriterionFormGroup extends FormGroup {
  controls: {
    type: FormControl;
    expression: FormControl;
    value: FormControl;
  };
}
