import { Validators } from '@angular/forms';
import { maxByteValidator } from 'src/app/helper/validators/maxByteValidator';
import { pdsPhoneNumberValidator } from 'src/app/helper/validators/pdsPhoneNumberValidator';
import {
  FormItem,
  FormItemImageUpload,
  FormItemTextArea,
  FormItemTextbox,
} from 'src/app/models/form/form';

// ITEMS ----------------------------------------------------------------

export const contactFirstName = new FormItemTextbox({
  key: 'firstName',
  label: 'First Name',
  class: 'mb-5',
  required: true,
  validators: [
    Validators.minLength(1),
    maxByteValidator(2000),
    Validators.required,
  ],
});

export const contactLastName = new FormItemTextbox({
  key: 'lastName',
  label: 'Last Name',
  class: 'mb-5',
  validators: [Validators.minLength(1), maxByteValidator(2000)],
});

export const contactNickname = new FormItemTextbox({
  key: 'nickname',
  label: 'How I refer to them',
  class: 'mb-5',
  validators: [Validators.minLength(1), maxByteValidator(2000)],
});

export const contactRole = new FormItemTextbox({
  key: 'role',
  label: 'Their role in your life',
  class: 'mb-5',
  validators: [Validators.minLength(1), maxByteValidator(2000)],
});

export const contactHomePhone = new FormItemTextbox({
  key: 'phoneHome',
  label: 'Home Phone',
  class: 'mb-5',
  validators: [
    Validators.minLength(1),
    maxByteValidator(2000),
    pdsPhoneNumberValidator(),
  ],
});

export const contactMobilePhone = new FormItemTextbox({
  key: 'phoneMobile',
  label: 'Mobile Phone',
  class: 'mb-5',
  validators: [
    Validators.minLength(1),
    maxByteValidator(2000),
    pdsPhoneNumberValidator(),
  ],
});

export const contactEmail = new FormItemTextbox({
  key: 'email',
  label: 'Email',
  class: 'mb-5',
  required: true,
  validators: [
    Validators.minLength(1),
    maxByteValidator(2000),
    Validators.email,
    Validators.required, // TODO: Update once Mydex changed to not required
  ],
});

export const contactAdditionalInfo = new FormItemTextArea({
  key: 'additionalInfo',
  label: 'Other information about this person',
  class: 'mb-5',
  validators: [Validators.minLength(1), maxByteValidator(2000)],
});

// export const contactIsLostAndMissed = new FormItemCheckbox({
//   key: 'isLostAndMissed',
//   label: 'This is someone I have lost and miss',
//   class: 'mb-5',
// });

export const contactPicture = new FormItemImageUpload({
  key: 'picture',
  label: 'Picture',
  class: 'mb-5',
  imageDownloadName: 'contact-picture',
  placeholderIconName: 'person',
  validators: [Validators.minLength(1), Validators.maxLength(5000000)], // TODO: add compression library?
});

// FORMS ----------------------------------------------------------------

export function getContactsFormBasicDetails(): FormItem<any>[] {
  return [
    contactFirstName,
    contactLastName,
    contactNickname,
    contactRole,
    contactHomePhone,
    contactMobilePhone,
    contactEmail,
    contactAdditionalInfo,
    // contactIsLostAndMissed, TODO: Add once ready on Mydex
    contactPicture,
  ];
}
