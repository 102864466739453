import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Connection } from 'src/app/models/connection/connection';
import { UserConnectionOpinion } from 'src/app/models/user/user';
import { UserStateService } from 'src/app/services/user.state.service';

@Component({
  selector: 'app-connection-opinion',
  templateUrl: './connection-opinion.component.html',
  styleUrls: ['./connection-opinion.component.css'],
})
export class ConnectionOpinionComponent {
  @Input() connection?: Connection;

  constructor(protected userService: UserStateService) {}

  markConnection(opinionType: 'interested' | 'not-interested' | 'unsure') {
    this.userService.markConnection(
      new UserConnectionOpinion({
        opinion: opinionType,
        connection: this.connection?.name,
      })
    );
  }
}
