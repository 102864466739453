import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { CookieConsent } from 'src/app/models/core/CookieConsent';

@Injectable({
  providedIn: 'root',
})
export class CookieConsentService {
  // PROPERTIES

  private _cookieConsentSource: CookieConsent | undefined = undefined;

  private _cookieConsent$: BehaviorSubject<CookieConsent | undefined> =
    new BehaviorSubject<CookieConsent | undefined>(this._cookieConsentSource);

  private _cookieConsentLoading$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);

  // PUBLIC GETTERS

  get cookieConsent$(): Observable<CookieConsent | undefined> {
    return this._cookieConsent$.asObservable();
  }

  get cookieConsentLoading$(): Observable<boolean> {
    return this._cookieConsentLoading$.asObservable();
  }

  // CONSTRUCTOR

  constructor(private localStorageService: LocalStorageService) {
    this.getAndHandleCookieConsent();
  }

  // PUBLIC API

  updateCookieConsent(
    consentVimeo: boolean,
    consentGoogleAnalytics: boolean
  ): void {
    this._cookieConsentLoading$.next(true);
    if (!this._cookieConsentSource) {
      this.createCookieConsentGranular(consentVimeo, consentGoogleAnalytics);
      return;
    }

    this._cookieConsentSource.consentedVimeo = consentVimeo;
    this._cookieConsentSource.consentedGoogleAnalytics = consentGoogleAnalytics;

    this.handleCookieConsentUpdate();
  }

  createCookieConsentGeneral(acceptAllCookies: boolean): void {
    this.createCookieConsentGranular(acceptAllCookies, acceptAllCookies);
  }

  createCookieConsentGranular(
    consentVimeo: boolean,
    consentGoogleAnalytics: boolean
  ): void {
    this._cookieConsentSource = new CookieConsent();

    this._cookieConsentSource.consentedGoogleAnalytics = consentVimeo;
    this._cookieConsentSource.consentedVimeo = consentGoogleAnalytics;

    this.handleCookieConsentUpdate();
  }

  // PRIVATE API

  private getAndHandleCookieConsent(): void {
    const localStorageCookieConsent =
      this.localStorageService.getCookieConsent();

    // case cookie consent given and still valid
    if (localStorageCookieConsent && localStorageCookieConsent.isValid) {
      this._cookieConsentSource = localStorageCookieConsent;
      this._cookieConsent$.next(this._cookieConsentSource);
      this._cookieConsentLoading$.next(false);
      return;
    }
    // case cookie consent given but not valid
    else if (localStorageCookieConsent) {
      this.localStorageService.removeCookieConsent();
    }

    this._cookieConsentLoading$.next(false);
  }

  private handleCookieConsentUpdate(): void {
    this.localStorageService.setCookieConsent(this._cookieConsentSource);
    this._cookieConsent$.next(this._cookieConsentSource);
    this._cookieConsentLoading$.next(false);
  }
}
