<footer
  class="px-14 py-10 lg:text-lg flex justify-between box-border gap-8 flex-col lg:flex-row"
>
  <a routerLink="/" class="my-auto mx-auto lg:mx-0">
    <img class="h-12" src="/assets/img/ea-logo-white.svg" alt=""
  /></a>
  <ul
    class="list-none p-0 m-0 [&_a]:text-white [&_a]:font-normal flex gap-6 lg:gap-12 my-auto mx-auto lg:mx-0 flex-wrap justify-center lg:justify-end"
  >
    <li><a routerLink="/faq">FAQ</a></li>
    <li><a routerLink="/report-problem">Report a Problem</a></li>
    <li><a routerLink="/cookie-preferences">Cookie Preferences</a></li>
    <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
  </ul>
</footer>
