import { Validators } from '@angular/forms';
import {
  FormItemButtonSelect,
  FormItemTextArea,
} from 'src/app/models/form/form';

export const digitalTechnologySupportInUse = new FormItemButtonSelect({
  key: 'digitalTechnologySupportInUse',
  path: 'support.digital.technologySupportInUse',
  label: 'Do you currently use technlogy support at home to support you?',
  multiple: true,
  class: 'mb-5',
  options: [
    { name: 'Community alarm', label: 'Community alarm' },
    { name: 'Falls sensor', label: 'Falls sensor' },
    { name: 'Buddy alert', label: 'Buddy alert' },
    { name: 'Alexa', label: 'Alexa' },
    { name: 'Fit bit', label: 'Fit bit' },
    { name: 'Door monitors', label: 'Door monitors' },
    { name: 'Monitors- carbon monoxide', label: 'Monitors- carbon monoxide' },
    { name: 'No support', label: 'No support' },
    { name: 'Other', label: 'Other' },
  ],
});

export const digitalTechnologySupportInUseAdditionalInfo = new FormItemTextArea(
  {
    key: 'digitalTechnologySupportInUseAdditionalInfo',
    path: 'support.digital.technologySupportInUseAdditionalInfo',
    label: 'Share more about the technology that you use.',
    class: 'mb-5',
    validators: [Validators.minLength(1), Validators.maxLength(2000)],
  }
);

export const digitalTechnologySupportPurposes = new FormItemButtonSelect({
  key: 'digitalTechnologySupportPurposes',
  path: 'support.digital.technologySupportPurposes',
  label: 'Why do you use digital support?',
  multiple: true,
  class: 'mb-5',
  options: [
    { name: 'To keep safe', label: 'To keep safe' },
    {
      name: 'To become fitter / more mobile',
      label: 'To become fitter / more mobile',
    },
    { name: 'Leisure / fun', label: 'Leisure / fun' },
    {
      name: 'Contact with family / friends',
      label: 'Contact with family / friends',
    },
    { name: 'Medication', label: 'Medication' },
    {
      name: 'Environment: lights, curtains, heating',
      label: 'Environment: lights, curtains, heating',
    },
    {
      name: 'Organising life: shopping / calendar / reminders',
      label: 'Organising life: shopping / calendar / reminders',
    },
    { name: 'Other', label: 'Other' },
  ],
});

export const digitalTechnologySupportPurposesAdditionalInfo =
  new FormItemTextArea({
    key: 'digitalTechnologySupportPurposesAdditionalInfo',
    path: 'support.digital.technologySupportPurposesAdditionalInfo',
    label: 'Share more about why you use digital support.',
    class: 'mb-5',
    validators: [Validators.minLength(1), Validators.maxLength(2000)],
  });

export const digitalMostUsedDevices = new FormItemButtonSelect({
  key: 'digitalMostUsedDevices',
  path: 'support.digital.mostUsedDevices',
  label: 'What digital devices do you use most?',
  multiple: true,
  class: 'mb-5',
  options: [
    { name: 'Smart phone', label: 'Smart phone' },
    { name: 'Tablet', label: 'Tablet' },
    { name: 'Laptop', label: 'Laptop' },
    { name: 'TV', label: 'TV' },
    { name: 'E-Reader / Kindle', label: 'E-Reader / Kindle' },
  ],
});

export const digitalDeviceMonitoredConditions = new FormItemButtonSelect({
  key: 'digitalDeviceMonitoredConditions',
  path: 'support.digital.deviceMonitoredConditions',
  label:
    'Do you use a device to monitor a long term illness, such as any of the following?',
  multiple: true,
  class: 'mb-5',
  options: [
    { name: 'Breathing difficulties', label: 'Breathing difficulties' },
    { name: 'heart problem', label: 'heart problem' },
    { name: 'diabetes', label: 'diabetes' },
    { name: 'cancer care', label: 'cancer care' },
    { name: 'kidney disease', label: 'kidney disease' },
    { name: 'Other', label: 'Other' },
  ],
});

export const digitalDeviceMonitoredConditionsAdditionalInfo =
  new FormItemTextArea({
    key: 'digitalDeviceMonitoredConditionsAdditionalInfo',
    path: 'support.digital.deviceMonitoredConditionsAdditionalInfo',
    label:
      'Share more about any devices you use to monitor a long term condition.',
    class: 'mb-5',
    validators: [Validators.minLength(1), Validators.maxLength(2000)],
  });

export const digitalSharedRecordsInUse = new FormItemButtonSelect({
  key: 'digitalSharedRecordsInUse',
  path: 'support.digital.sharedRecordsInUse',
  label:
    'Do you use any kind of shared record to support your long term condition?',
  class: 'mb-5',
  multiple: true,
  options: [
    { name: 'Patient view', label: 'Patient view' },
    { name: 'My diabetes My way', label: 'My diabetes My way' },
    { name: 'Flo or Florence', label: 'Flo or Florence' },
    { name: 'Other', label: 'Other' },
  ],
});

export const digitalSharedRecordsInUseAdditionalInfo = new FormItemTextArea({
  key: 'digitalSharedRecordsInUseAdditionalInfo',
  path: 'support.digital.sharedRecordsInUseAdditionalInfo',
  label:
    'Share more about the kinds of shared records that you use to support you.',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});
