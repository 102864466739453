import { FormItem, FormItemTitle } from 'src/app/models/form/form';
import {
  dateOfBirth,
  ethnicity,
  firstName,
  lastName,
  middleName,
} from '../me-items/personal-details-items';
import { email } from '../me-items/contact-details-items';
import { getFormItemsAddressForUserContactDetails } from '../../shared-items/address';
import {
  personalDetailsAbleToContinueProvidingCare,
  personalDetailsFeelingValuedAsCarer,
  personalDetailsRelationshipsToPeopleCaredFor,
  personalDetailsWillingToProvideCare,
} from './personal-details-items';
import {
  supportedPeopleListOfPeople,
  supportedPeopleSupportInYears,
  supportedPeopleWeeklyHours,
} from './supported-people-items';
import {
  caringRoleChallenges,
  caringRoleDetailsOfCaredForPeople,
  caringRoleHowOthersDoOrCouldSupport,
  caringRoleIsCaringForMultiplePeople,
  caringRolePastTraining,
  caringRoleDesiredTraining,
  caringRoleInvolvedInServicesDesign,
  caringRoleServicesInPlace,
  caringRoleIsSatisfiedWithServices,
  caringRoleServicesSatisfactionDetails,
  caringRoleIsSoleCarer,
} from './caring-role-items';
import {
  healthImpactCaringAffectsPhysicalHealth,
  healthImpactCaringAffectsPhysicalHealthDetails,
  healthImpactDiscussedWithGp,
  healthImpactHealthAffectsCaring,
  healthImpactSummary,
} from './health-impact-items';
import {
  emotionalWellbeingCaringAffectsMentalHealth,
  emotionalWellbeingCaringAffectsMentalHealthDetails,
  emotionalWellbeingCaringAffectsWellbeing,
  emotionalWellbeingCaringAffectsWellbeingDetails,
  emotionalWellbeingDiscussedWithGp,
  emotionalWellbeingHasImpactOnCaring,
  emotionalWellbeingSummary,
} from './emotional-wellbeing-items';
import {
  financialImpactCaringAffectsFinances,
  financialImpactHasFinancialDifficulties,
  financialImpactSummary,
} from './financial-impact-items';
import {
  lifeBalanceAreasAffectedByCaring,
  lifeBalanceAreasAffectedByCaringDetails,
  lifeBalanceSummary,
} from './life-balance-items';
import {
  arrangementsInPlaceTitle,
  futurePlansImpactArrangementInPlaceAppointee,
  futurePlansImpactArrangementInPlaceGuardianFinancial,
  futurePlansImpactArrangementInPlaceGuardianWelfare,
  futurePlansImpactArrangementInPlaceNamedPerson,
  futurePlansImpactArrangementInPlacePoaContinuingWelfare,
  futurePlansImpactArrangementInPlacePoaFinancial,
  futurePlansImpactArrangementInPlaceWill,
  futurePlansImpactArrangementsWillImpactRole,
  futurePlansImpactCaredForPersonHasCommunityAlarm,
  futurePlansImpactDesires,
  futurePlansImpactArrangementsForCareInPlace,
  futurePlansImpactSummary,
} from './future-plans-impact-items';
import {
  employmentImpactHasOrInSearchOfOccupation,
  employmentImpactCaringAffectsOccupation,
  employmentImpactEmploymentStatus,
  employmentImpactSummary,
  employmentImpactCaringAffectsOccupationDetails,
} from './employment-impact-items';
import {
  homeImpactCaringAffectsLivingEnvironment,
  homeImpactCaringAffectsLivingEnvironmentDetails,
  homeImpactDifficultDailyTasks,
  homeImpactHomeIsSuitableForCaredForPerson,
  homeImpactHomeIsSuitableForCaredForPersonDetails,
  homeImpactSummary,
} from './home-impact-items';
import {
  otherLifeAspectsBotheringCaringRoleAspects,
  otherLifeAspectsCaringAffectsRelationships,
  otherLifeAspectsCaringAffectingRelationshipsImpactsOnCaring,
  otherLifeAspectsCaringAffectingRelationshipsImpactsOnCaringDetails,
  otherLifeAspectsDesiredChangesAboutSituation,
  otherLifeAspectsRelationshipsImpactSummary,
} from './other-life-aspects-items';
import { skillsAndExperienceQualifications } from './skills-and-experience-items';
import { whatMattersImportanceOfRoleAsCarer } from './what-matters-items';

// All form items for user - supporting role
export function getFormItemsUserSupportingRole(): FormItem<any>[] {
  return [
    new FormItemTitle({ label: 'Personal Details' }),
    ...getFormItemsUserSupportingRolePersonalDetails(),
    new FormItemTitle({ label: 'The People I support' }),
    ...getFormItemsUserSupportingRoleSupportedPeople(),
    new FormItemTitle({ label: 'My Caring Role' }),
    ...getFormItemsUserSupportingRoleCaringRole(),
    new FormItemTitle({ label: 'Impact on my Health' }),
    ...getFormItemsUserSupportingRoleHealthImpact(),
    new FormItemTitle({ label: 'My Emotional Wellbeing' }),
    ...getFormItemsUserSupportingRoleEmotionalWellbeing(),
    new FormItemTitle({ label: 'Impact on my Finances' }),
    ...getFormItemsUserSupportingRoleFinancialImpact(),
    new FormItemTitle({ label: 'My Life Balance' }),
    ...getFormItemsUserSupportingRoleLifeBalance(),
    new FormItemTitle({ label: 'Impact on Future Plans' }),
    ...getFormItemsUserSupportingRoleFuturePlansImpact(),
    new FormItemTitle({ label: 'Impact on Employment' }),
    ...getFormItemsUserSupportingRoleEmploymentImpact(),
    new FormItemTitle({ label: 'Impact on My Home' }),
    ...getFormItemsUserSupportingRoleHomeImpact(),
    new FormItemTitle({ label: 'Other Aspects of Life' }),
    ...getFormItemsUserSupportingRoleOtherLifeAspects(),
    new FormItemTitle({ label: 'Skills and Experience' }),
    ...getFormItemsUserSupportingRoleSkillsAndExperience(),
    new FormItemTitle({ label: 'What Matters' }),
    ...getFormItemsUserSupportingRoleWhatMatters(),
  ];
}

// All form items for user - supporting role - personal details
export function getFormItemsUserSupportingRolePersonalDetails(): FormItem<any>[] {
  return [
    // Reusing items from This is Me // TODO: figure out storage in pds
    firstName,
    middleName,
    lastName,
    ethnicity,
    dateOfBirth,
    email,
    ...getFormItemsAddressForUserContactDetails(),
    personalDetailsRelationshipsToPeopleCaredFor,
    personalDetailsWillingToProvideCare,
    personalDetailsAbleToContinueProvidingCare,
    personalDetailsFeelingValuedAsCarer,
  ];
}

// All form items for user - supporting role - supported people
export function getFormItemsUserSupportingRoleSupportedPeople(): FormItem<any>[] {
  return [
    supportedPeopleListOfPeople,
    supportedPeopleSupportInYears,
    supportedPeopleWeeklyHours,
  ];
}

// All form items for user - supporting role - caring role
export function getFormItemsUserSupportingRoleCaringRole(): FormItem<any>[] {
  return [
    caringRoleIsCaringForMultiplePeople,
    caringRoleDetailsOfCaredForPeople,
    caringRoleIsSoleCarer,
    caringRoleHowOthersDoOrCouldSupport,
    caringRoleServicesInPlace,
    caringRoleInvolvedInServicesDesign,
    caringRoleIsSatisfiedWithServices,
    caringRoleServicesSatisfactionDetails,
    caringRoleChallenges,
    caringRolePastTraining,
    caringRoleDesiredTraining,
  ];
}

// All form items for user - supporting role - health impact
export function getFormItemsUserSupportingRoleHealthImpact(): FormItem<any>[] {
  return [
    healthImpactCaringAffectsPhysicalHealth,
    healthImpactCaringAffectsPhysicalHealthDetails,
    healthImpactHealthAffectsCaring,
    healthImpactDiscussedWithGp,
    healthImpactSummary,
  ];
}

// All form items for user - supporting role - emotional wellbeing
export function getFormItemsUserSupportingRoleEmotionalWellbeing(): FormItem<any>[] {
  return [
    emotionalWellbeingCaringAffectsWellbeing,
    emotionalWellbeingCaringAffectsWellbeingDetails,
    emotionalWellbeingCaringAffectsMentalHealth,
    emotionalWellbeingCaringAffectsMentalHealthDetails,
    emotionalWellbeingHasImpactOnCaring,
    emotionalWellbeingDiscussedWithGp,
    emotionalWellbeingSummary,
  ];
}

// All form items for user - supporting role - financial impact
export function getFormItemsUserSupportingRoleFinancialImpact(): FormItem<any>[] {
  return [
    financialImpactCaringAffectsFinances,
    financialImpactHasFinancialDifficulties,
    financialImpactSummary,
  ];
}

// All form items for user - supporting role - life balance
export function getFormItemsUserSupportingRoleLifeBalance(): FormItem<any>[] {
  return [
    lifeBalanceAreasAffectedByCaring,
    lifeBalanceAreasAffectedByCaringDetails,
    lifeBalanceSummary,
  ];
}

// All form items for user - supporting role - future plans impact
export function getFormItemsUserSupportingRoleFuturePlansImpact(): FormItem<any>[] {
  return [
    futurePlansImpactCaredForPersonHasCommunityAlarm,
    futurePlansImpactArrangementsForCareInPlace,
    arrangementsInPlaceTitle,
    futurePlansImpactArrangementInPlaceAppointee,
    futurePlansImpactArrangementInPlaceGuardianFinancial,
    futurePlansImpactArrangementInPlaceGuardianWelfare,
    futurePlansImpactArrangementInPlaceNamedPerson,
    futurePlansImpactArrangementInPlaceWill,
    futurePlansImpactArrangementInPlacePoaFinancial,
    futurePlansImpactArrangementInPlacePoaContinuingWelfare,
    futurePlansImpactArrangementsWillImpactRole,
    futurePlansImpactDesires,
    futurePlansImpactSummary,
  ];
}

// All form items for user - supporting role - employment impact
export function getFormItemsUserSupportingRoleEmploymentImpact(): FormItem<any>[] {
  return [
    employmentImpactEmploymentStatus,
    employmentImpactHasOrInSearchOfOccupation,
    employmentImpactCaringAffectsOccupation,
    employmentImpactCaringAffectsOccupationDetails,
    employmentImpactSummary,
  ];
}

// All form items for user - supporting role - home impact
export function getFormItemsUserSupportingRoleHomeImpact(): FormItem<any>[] {
  return [
    homeImpactCaringAffectsLivingEnvironment,
    homeImpactCaringAffectsLivingEnvironmentDetails,
    homeImpactHomeIsSuitableForCaredForPerson,
    homeImpactHomeIsSuitableForCaredForPersonDetails,
    homeImpactDifficultDailyTasks,
    homeImpactSummary,
  ];
}

// All form items for user - supporting role - other life aspects
export function getFormItemsUserSupportingRoleOtherLifeAspects(): FormItem<any>[] {
  return [
    otherLifeAspectsCaringAffectsRelationships,
    otherLifeAspectsCaringAffectingRelationshipsImpactsOnCaring,
    otherLifeAspectsCaringAffectingRelationshipsImpactsOnCaringDetails,
    otherLifeAspectsBotheringCaringRoleAspects,
    otherLifeAspectsDesiredChangesAboutSituation,
    otherLifeAspectsRelationshipsImpactSummary,
  ];
}

// All form items for user - supporting role - skills and experience
export function getFormItemsUserSupportingRoleSkillsAndExperience(): FormItem<any>[] {
  return [skillsAndExperienceQualifications];
}

// All form items for user - supporting role - what matters
export function getFormItemsUserSupportingRoleWhatMatters(): FormItem<any>[] {
  return [whatMattersImportanceOfRoleAsCarer];
}
