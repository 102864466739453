<div [formGroup]="textbowWithAffixFormGroup">
  <div class="relative">
    <input
      #inputField
      (input)="update()"
      formControlName="text"
      class="form-control"
      [class.is-invalid]="control.touched && control.errors"
      [id]="item?.key"
      [disabled]="disabled"
    />
    <div
      *ngIf="item?.prefix && item?.prefix.length > 0"
      #prefixElement
      class="form-control absolute flex items-center px-3 bg-gray-200 rounded-l-md text-gray-500"
      style="top: 0.09rem; bottom: 0.09rem; left: 0.1rem"
    >
      {{ item?.prefix }}
    </div>
  </div>
</div>
