<div class="home-container pb-12">
  <div
    class="flex h-full home-container mx-auto flex-col xl:flex-row box-border pb-20"
  >
    <div class="max-w-xl mr-auto box-border relative">
      <h1 class="font-medium mb-10">Well done - you're all setup.</h1>
      <p class="mb-5">
        Congratulations, that's the setup done and you have built your
        <strong>About Me</strong>. You can return back and add to it as often as
        you like. We recommend keeping it up to date so that you can save time
        and make things easier whenever you want to access a new service.
      </p>

      <p [hidden]="player" class="text-grey italic mt-8">
        Optional: Enable the video player cookies in your
        <a routerLink="/cookie-preferences" class="text-theme"
          >Cookie Preferences</a
        >
        to allow us to display the Vimeo video about Community Connections.
      </p>
    </div>
    <div [hidden]="!player" class="relative w-full xl:w-1/2 mx-4">
      <div class="relative" style="padding-top: 56.25%">
        <!-- 16:9 Aspect Ratio -->
        <div #vimeoPlayer class="absolute inset-0 w-full h-full"></div>
      </div>
    </div>
  </div>

  <h2 class="font-medium text-3xl -mt-5">Your next step</h2>

  <div
    class="w-full card bg-white flex flex-col md:flex-row p-0 rounded-lg overflow-hidden"
  >
    <div class="p-20">
      <h2 class="text-3xl text-theme mt-0 font-medium">Quarriers Carer App</h2>
      <p>
        The Carers App is a specialist tool for carers that will help you
        communicate with Quarriers and create a plan for the support that you,
        and the person you’re supporting, needs. <br /><br />
        It uses the same personal data store that you’ve just set up so you can
        access everything there.
      </p>
      <a
        class="btn btn-theme btn-sm mt-4 mb-0"
        href="https://cip-cfp.mydex.org/"
        >Carer App</a
      >
    </div>
    <div
      class="flex max-h-[300px] overflow-hidden md:max-h-none md:min-w-[40%] md:max-w-[50%] relative"
    >
      <img
        class="h-full w-full object-cover object-center absolute"
        src="/assets/img/services/app.jpg"
        alt=""
      />
    </div>
  </div>

  <h2 class="font-medium text-3xl mt-24 mb-8">Go to...</h2>
  <div class="flex mb-16">
    <a routerLink="/account/about-me" class="mr-8">
      <button class="btn-theme px-8 outlined">About Me</button>
    </a>
    <a routerLink="/finder" class="btn btn-theme-outline px-8">
      Find Services
    </a>
  </div>
</div>
