import { Component, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';  

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.css']
})
export class CarerAssessmentStartComponent {
  onboarded: Boolean = false;

  setOnboarded(e: Event, to: Boolean){
    e.stopPropagation()
    this.onboarded = true;
  }
}

