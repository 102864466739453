<ng-container *ngIf="isDisplayed">
  <p class="text-error-red form-error-text">
    {{ errorMessage || "An error occured. Please try again." }}
  </p>
  <button
    *ngIf="showBackButton"
    class="btn-inline underline opacity-50 hover:opacity-100 transition-opacity font-semibold p-0 mt-2"
    [routerLink]="navigationService.getPreviousUrl(backNavigationContext)"
  >
    Back
  </button>
</ng-container>
