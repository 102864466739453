<ng-container [ngSwitch]="name">
  <svg
    *ngSwitchCase="'edit'"
    [attr.width]="width ?? 16"
    [attr.height]="height ?? 16"
    viewBox="0 0 90 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M75.4 0H72.6L61.8 10.8L78.8 27.8L89.6 17V14.2L75.4 0ZM7.8 64.8L0 89.5L24.7 81.7L71.7 34.7L54.7 17.7L7.8 64.8Z"
      fill="currentColor"
    />
  </svg>

  <svg
    *ngSwitchCase="'delete'"
    xmlns="http://www.w3.org/2000/svg"
    [attr.width]="width ?? 16"
    [attr.height]="height ?? 16"
    fill="currentColor"
    class="bi bi-trash3-fill"
    viewBox="0 0 16 16"
  >
    <path
      d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5"
    />
  </svg>

  <svg
    *ngSwitchCase="'add-person'"
    xmlns="http://www.w3.org/2000/svg"
    [attr.width]="width ?? 16"
    [attr.height]="height ?? 16"
    fill="currentColor"
    class="bi bi-person-plus-fill"
    viewBox="0 0 16 16"
  >
    <path
      d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6"
    />
    <path
      fill-rule="evenodd"
      d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5"
    />
  </svg>

  <svg
    *ngSwitchCase="'image'"
    xmlns="http://www.w3.org/2000/svg"
    [attr.width]="width ?? 16"
    [attr.height]="height ?? 16"
    fill="currentColor"
    class="bi bi-image"
    viewBox="0 0 16 16"
  >
    <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
    <path
      d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1z"
    />
  </svg>

  <svg
    *ngSwitchCase="'images'"
    xmlns="http://www.w3.org/2000/svg"
    [attr.width]="width ?? 16"
    [attr.height]="height ?? 16"
    fill="currentColor"
    class="bi bi-images"
    viewBox="0 0 16 16"
  >
    <path d="M4.502 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
    <path
      d="M14.002 13a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2V5A2 2 0 0 1 2 3a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v8a2 2 0 0 1-1.998 2M14 2H4a1 1 0 0 0-1 1h9.002a2 2 0 0 1 2 2v7A1 1 0 0 0 15 11V3a1 1 0 0 0-1-1M2.002 4a1 1 0 0 0-1 1v8l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094l1.777 1.947V5a1 1 0 0 0-1-1z"
    />
  </svg>

  <svg
    *ngSwitchCase="'people'"
    xmlns="http://www.w3.org/2000/svg"
    [attr.width]="width ?? 16"
    [attr.height]="height ?? 16"
    fill="currentColor"
    class="bi bi-people-fill"
    viewBox="0 0 16 16"
  >
    <path
      d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5.784 6A2.24 2.24 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.3 6.3 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1zM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5"
    />
  </svg>

  <svg
    *ngSwitchCase="'person'"
    xmlns="http://www.w3.org/2000/svg"
    [attr.width]="width ?? 16"
    [attr.height]="height ?? 16"
    fill="currentColor"
    class="bi bi-person-fill"
    viewBox="0 0 16 16"
  >
    <path
      d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6"
    />
  </svg>

  <svg
    *ngSwitchCase="'document'"
    xmlns="http://www.w3.org/2000/svg"
    [attr.width]="width ?? 16"
    [attr.height]="height ?? 16"
    fill="currentColor"
    class="bi bi-file-text"
    viewBox="0 0 16 16"
  >
    <path
      d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5M5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z"
    />
    <path
      d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1"
    />
  </svg>

  <svg
    *ngSwitchCase="'text'"
    xmlns="http://www.w3.org/2000/svg"
    [attr.width]="width ?? 16"
    [attr.height]="height ?? 16"
    fill="currentColor"
    class="bi bi-card-text"
    viewBox="0 0 16 16"
  >
    <path
      d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2z"
    />
    <path
      d="M3 5.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5M3 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 8m0 2.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5"
    />
  </svg>

  <svg
    *ngSwitchCase="'calendar'"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    class="bi bi-calendar"
    viewBox="0 0 16 16"
  >
    <path
      d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z"
    />
  </svg>

  <svg
    *ngSwitchCase="'caret-right-fill'"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    class="bi bi-caret-right-fill"
    viewBox="0 0 16 16"
  >
    <path
      d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"
    />
  </svg>

  <svg
    *ngSwitchCase="'check'"
    xmlns="http://www.w3.org/2000/svg"
    [attr.width]="width ?? 16"
    [attr.height]="height ?? 16"
    fill="currentColor"
    viewBox="0 0 16 16"
  >
    <path
      d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
    />
  </svg>

  <svg
    *ngSwitchCase="'check-circle'"
    xmlns="http://www.w3.org/2000/svg"
    [attr.width]="width ?? 16"
    [attr.height]="height ?? 16"
    fill="currentColor"
    class="bi bi-check2-circle"
    viewBox="0 0 16 16"
  >
    <path
      d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0"
    />
    <path
      d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z"
    />
  </svg>

  <svg
    *ngSwitchCase="'check-circle-fill'"
    xmlns="http://www.w3.org/2000/svg"
    [attr.width]="width ?? 16"
    [attr.height]="height ?? 16"
    fill="currentColor"
    class="bi bi-check-circle-fill"
    viewBox="0 0 16 16"
  >
    <path
      d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
    />
  </svg>

  <svg
    *ngSwitchCase="'search'"
    xmlns="http://www.w3.org/2000/svg"
    [attr.width]="width ?? 16"
    [attr.height]="height ?? 16"
    fill="currentColor"
    class="bi bi-search"
    viewBox="0 0 16 16"
  >
    <path
      d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"
    />
  </svg>

  <svg
    *ngSwitchCase="'play'"
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 7L-6.52532e-07 13.9282L-4.68497e-08 0.0717964L12 7Z"
      fill="currentColor"
    />
  </svg>
  <svg
    *ngSwitchCase="'thumbs-up'"
    [attr.width]="width ?? 15"
    [attr.height]="height ?? 14"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.1277 14C12.6734 14 13.0979 13.55 13.0979 13.05C13.0979 12.5 12.6734 12.1 12.1277 12.1H12.6734C13.2192 12.1 13.6436 11.65 13.6436 11.15C13.6436 10.65 13.2798 10.2 12.734 10.2H13.2192C13.7649 10.2 14.1894 9.75 14.1894 9.25C14.1894 8.7 13.7649 8.3 13.2192 8.3H13.6436C14.1894 8.3 14.6138 7.85 14.6138 7.35C14.6138 6.8 14.1894 6.4 13.6436 6.4H9.58084L9.94467 5.95C10.6117 5.15 10.9149 4.15 10.9149 3.1V1.9C10.9149 1.3 10.4298 0.800003 9.76276 0.800003C9.27765 0.800003 8.85318 1.1 8.67127 1.5L8.06488 2.85C7.57977 4 6.6702 4.9 5.51807 5.4L4.54785 5.85V13.25C6.24573 13.6 7.15531 14 8.79254 14H12.1277Z"
      fill="currentColor"
    />
    <path
      d="M0.606385 13.8H2.85001C3.21384 13.8 3.45639 13.6 3.45639 13.3V5.8C3.45639 5.5 3.21384 5.3 2.85001 5.3H0.606385C0.242554 5.3 0 5.5 0 5.8V13.25C0 13.55 0.242554 13.8 0.606385 13.8Z"
      fill="currentColor"
    />
  </svg>
  <svg
    *ngSwitchCase="'thumbs-down'"
    [attr.width]="width ?? 16"
    [attr.height]="height ?? 14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.79572 7.65H5.8585L5.49467 8.1C4.16062 9.8 4.58509 11.45 4.52446 12.1C4.52446 12.7 5.00956 13.2 5.67659 13.2C6.16169 13.2 6.58616 12.9 6.76808 12.5L7.37446 11.15C7.85957 10 8.76915 9.1 9.92128 8.6L10.8915 8.15V0.75L8.82979 0.25C8.04149 0.05 7.31382 0 6.58616 0H3.37232C2.82658 0 2.40211 0.45 2.40211 0.95C2.40211 1.5 2.82658 1.9 3.37232 1.9H2.82658C2.28083 1.9 1.85636 2.35 1.85636 2.85C1.85636 3.35 2.22019 3.8 2.76594 3.8H2.28083C1.73509 3.8 1.31062 4.25 1.31062 4.75C1.31062 5.3 1.73509 5.7 2.28083 5.7H1.85636C1.31062 5.7 0.886148 6.15 0.886148 6.65C0.82551 7.2 1.24998 7.65 1.79572 7.65Z"
      fill="currentColor"
    />
    <path
      d="M12.6498 8.7H14.8935C15.2573 8.7 15.4998 8.5 15.4998 8.2V0.699997C15.4998 0.399997 15.2573 0.199997 14.8935 0.199997H12.6498C12.286 0.199997 12.0435 0.399997 12.0435 0.699997V8.15C12.0435 8.45 12.3466 8.7 12.6498 8.7Z"
      fill="currentColor"
    />
  </svg>
  <svg
    *ngSwitchCase="'opinion'"
    [attr.width]="width ?? 29"
    [attr.height]="height ?? 19"
    viewBox="0 0 29 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.1277 19C12.6734 19 13.0979 18.55 13.0979 18.05C13.0979 17.5 12.6734 17.1 12.1277 17.1H12.6734C13.2192 17.1 13.6436 16.65 13.6436 16.15C13.6436 15.65 13.2798 15.2 12.734 15.2H13.2192C13.7649 15.2 14.1894 14.75 14.1894 14.25C14.1894 13.7 13.7649 13.3 13.2192 13.3H13.6436C14.1894 13.3 14.6138 12.85 14.6138 12.35C14.6138 11.8 14.1894 11.4 13.6436 11.4H9.58084L9.94467 10.95C10.6117 10.15 10.9149 9.15 10.9149 8.1V6.9C10.9149 6.3 10.4298 5.8 9.76276 5.8C9.27765 5.8 8.85318 6.1 8.67127 6.5L8.06488 7.85C7.57977 9 6.6702 9.9 5.51807 10.4L4.54785 10.85V18.25C6.24573 18.6 7.15531 19 8.79254 19H12.1277Z"
      fill="currentcolor"
    />
    <path
      d="M0.606385 18.8H2.85001C3.21384 18.8 3.45639 18.6 3.45639 18.3V10.8C3.45639 10.5 3.21384 10.3 2.85001 10.3H0.606385C0.242554 10.3 0 10.5 0 10.8V18.25C0 18.55 0.242554 18.8 0.606385 18.8Z"
      fill="currentcolor"
    />
    <path
      d="M14.7957 7.65H18.8585L18.4947 8.1C17.1606 9.8 17.5851 11.45 17.5245 12.1C17.5245 12.7 18.0096 13.2 18.6766 13.2C19.1617 13.2 19.5862 12.9 19.7681 12.5L20.3745 11.15C20.8596 10 21.7691 9.1 22.9213 8.6L23.8915 8.15V0.75L21.8298 0.25C21.0415 0.05 20.3138 0 19.5862 0H16.3723C15.8266 0 15.4021 0.45 15.4021 0.95C15.4021 1.5 15.8266 1.9 16.3723 1.9H15.8266C15.2808 1.9 14.8564 2.35 14.8564 2.85C14.8564 3.35 15.2202 3.8 15.7659 3.8H15.2808C14.7351 3.8 14.3106 4.25 14.3106 4.75C14.3106 5.3 14.7351 5.7 15.2808 5.7H14.8564C14.3106 5.7 13.8861 6.15 13.8861 6.65C13.8255 7.2 14.25 7.65 14.7957 7.65Z"
      fill="currentcolor"
    />
    <path
      d="M25.6498 8.7H27.8935C28.2573 8.7 28.4998 8.5 28.4998 8.2V0.699997C28.4998 0.399997 28.2573 0.199997 27.8935 0.199997H25.6498C25.286 0.199997 25.0435 0.399997 25.0435 0.699997V8.15C25.0435 8.45 25.3466 8.7 25.6498 8.7Z"
      fill="currentcolor"
    />
  </svg>
  <div *ngSwitchDefault>X</div>
</ng-container>
