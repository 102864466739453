import { Validators } from '@angular/forms';
import { maxByteValidator } from 'src/app/helper/validators/maxByteValidator';
import { pdsPhoneNumberValidator } from 'src/app/helper/validators/pdsPhoneNumberValidator';
import { FormItemDropdown, FormItemTextbox } from 'src/app/models/form/form';

// THIS IS ME - CONTACT DETAILS -----------------------------------------------

export const phoneHome = new FormItemTextbox({
  key: 'phoneHome',
  path: 'me.contact.phoneHome',
  label: 'Home Phone',
  class: 'mb-5',
  width: 'half',
  validators: [
    Validators.minLength(1),
    maxByteValidator(2000),
    pdsPhoneNumberValidator(),
  ],
});

export const phoneMobile = new FormItemTextbox({
  key: 'phoneMobile',
  path: 'me.contact.phoneMobile',
  label: 'Mobile Phone',
  class: 'mb-5',
  width: 'half',
  validators: [
    Validators.minLength(1),
    maxByteValidator(2000),
    pdsPhoneNumberValidator(),
  ],
});

export const email = new FormItemTextbox({
  key: 'email',
  path: 'me.contact.email',
  label: 'Email Address',
  width: 'half',
  class: 'mb-5',
  required: true,
  validators: [
    Validators.required,
    Validators.minLength(1),
    maxByteValidator(2000),
    Validators.email, // use Angular's built-in email validator because trying to copy PHP's built-in email validator is not recommended, as testing correct implementation is v difficult
  ],
});

export const propertyType = new FormItemDropdown({
  key: 'propertyType',
  label: 'Property Type',
  path: 'me.contact.propertyType',
  placeholder: 'Please select...',
  options: [
    { name: 'House', label: 'House' },
    { name: 'Bungalow', label: 'Bungalow' },
    {
      name: 'Flat',
      label: 'Flat',
    },
    {
      name: 'Apartment',
      label: 'Apartment',
    },
  ],
  class: 'mb-5',
  width: 'half',
});

export const tenancyType = new FormItemDropdown({
  key: 'tenancyType',
  label: 'Tenancy Type',
  path: 'me.contact.tenancyType',
  placeholder: 'Please select...',
  options: [
    { name: 'Lease tenant', label: 'Lease tenant' },
    { name: 'Lodger', label: 'Lodger' },
    {
      name: 'Owner Occupier',
      label: 'Owner Occupier',
    },
    { name: 'Care home', label: 'Care home' },
    { name: 'Sheltered housing', label: 'Sheltered housing' },
    { name: 'Supported housing', label: 'Supported housing' },
    { name: 'Temporary accommodation', label: 'Temporary accommodation' },
  ],
  class: 'mb-5',
  width: 'half',
});
