<div class="m-auto text-center h-full justify-center flex">
  <div class="my-auto flex flex-col pb-20 opacity-80">
    <h1 class="font-medium text-4xl m-0">
      <app-loading-spinner
        class="inline-block align-middle mb-1"
        text=""
      ></app-loading-spinner>

      Signing in with

      <img
        src="/assets/img/mydex-dark.png"
        alt=""
        class="h-8 inline-block align-middle opacity-90"
      />
    </h1>
    <p class="ml-6 text-dark">
      If you're not redirected,
      <a class="underline cursor-pointer" routerLink="/account/about-me"
        >click here to continue</a
      >
    </p>
  </div>
</div>
