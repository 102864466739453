import { Validators } from '@angular/forms';
import { FormItemTextbox } from 'src/app/models/form/form';

// KEY INFORMATION - MAIN SUPPORT -----------------------------------------------

export const mainSupportPerson = new FormItemTextbox({
  key: 'mainSupportPerson',
  path: 'keyInformation.mainSupport.person',
  label: 'Who is your key support person?',
  width: 'half',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const mainSupportName = new FormItemTextbox({
  key: 'mainSupportName',
  path: 'keyInformation.mainSupport.name',
  label: 'Name of the support person',
  width: 'half',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const mainSupportPhone = new FormItemTextbox({
  key: 'mainSupportPhone',
  path: 'keyInformation.mainSupport.phone',
  label: 'Contact number of the support person',
  class: 'mb-5',
  width: 'half',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});
