import { Component } from '@angular/core';
import { Connection } from 'src/app/models/connection/connection';
import { ConnectionsStateService } from 'src/app/services/connections.state.service';

@Component({
  selector: 'app-next-steps',
  templateUrl: './next-steps.component.html',
  styleUrls: ['./next-steps.component.css'],
})
export class CarerAssessmentNextStepsComponent {
  protected connections: Connection[] = [];

  private connectionsSubscription: any;

  constructor(protected connectionsStateService: ConnectionsStateService) {}

  ngOnInit() {
    this.connectionsSubscription =
      this.connectionsStateService.connections$.subscribe((connections) => {
        this.connections =
          this.connectionsStateService.getConnectionsForFinderPath(
            'next.steps'
          );
      });
  }

  ngOnDestroy() {
    if (this.connectionsSubscription) {
      this.connectionsSubscription.unsubscribe();
    }

    this.connectionsStateService.resetErrors();
  }
}
