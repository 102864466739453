import {
  Component,
  ElementRef,
  Input,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormItemTextArea } from 'src/app/models/form/form';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaComponent),
      multi: true,
    },
  ],
})
export class TextareaComponent implements ControlValueAccessor {
  @Input() item!: FormItemTextArea;
  @Input() disabled = false;
  @Input() control: any;

  @ViewChild('suggestions') suggestionsEl!: ElementRef;
  @ViewChild('backdrop') backdropEl!: ElementRef;
  @ViewChild('highlights') highlightsEl!: ElementRef;

  value: string = '';

  onChange = (selected: any) => {};
  onTouched = () => {};
  touched = false;

  suggestions: Suggestion[] = [];

  get allSuggestions() {
    //avoids a render error, unsure of cause
    return this.suggestions;
  }

  writeValue(value: any): void {
    this.suggestions =
      this.item.suggestions?.map((v: string) => ({
        value: v,
        inText: false,
      })) ?? [];

    this.value = value ?? '';
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.matchSuggestions(this.value);
    });
  }

  update(): void {
    this.matchSuggestions(this.value);
    this.markAsTouched();
    this.onChange(this.value);
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  matchSuggestions(text: string) {
    // Initialize all suggestions as not in text
    this.suggestions.forEach((suggestion) => (suggestion.inText = false));

    const pattern =
      '\\b(' + this.suggestions.map(({ value }) => value).join('|') + ')\\b';
    const regex = new RegExp(pattern, 'gi');

    text = text.replace(/\n$/g, '\n\n').replace(regex, (match) => {
      // Find the suggestion corresponding to the match and mark it as inText
      const matchedSuggestion = this.suggestions.find(
        (suggestion) => suggestion.value.toLowerCase() === match.toLowerCase()
      );
      if (matchedSuggestion) {
        matchedSuggestion.inText = true;
      }
      return `<mark>${match}</mark>`;
    });

    this.highlightsEl.nativeElement.innerHTML = text;
  }

  handleInputChange() {
    this.matchSuggestions(this.value); //could alternatively be done in update() ie on blur
  }

  handleScroll(event: any) {
    this.backdropEl.nativeElement.scrollTop = event.target.scrollTop;
  }

  get textareaStyle() {
    if (!this.suggestionsEl) return { 'min-height': '60px' };
    const { height } = this.suggestionsEl.nativeElement.getBoundingClientRect();
    return {
      'padding-bottom': 16 + height + 'px',
      'min-height': 60 + height + 'px',
    };
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  handleSuggestion(suggestion: Suggestion) {
    if (suggestion.inText) return;
    suggestion.inText = true;
    this.value += (this.value.length ? ', ' : '') + suggestion.value;
    this.update();
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}

interface Suggestion {
  value: string;
  inText: boolean;
}
