import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-gallery-image-display',
  templateUrl: './gallery-image-display.component.html',
  styleUrls: ['./gallery-image-display.component.css'],
})
export class GalleryImageDisplayComponent {
  @Input() value: any;
}
