export class ReferralRequest {
  connectionName: string;
  outputType: string;
  userReferralName?: string;
  userReferralEmail?: string;
  UserReferralData: any;

  constructor(data?: Partial<ReferralRequest>) {
    this.connectionName = data?.connectionName ?? '';
    this.outputType = data?.outputType ?? '';
    this.userReferralName = data?.userReferralName;
    this.userReferralEmail = data?.userReferralEmail;
    this.UserReferralData = data?.UserReferralData;
  }
}
