<!-- LOADING SPINNER -->
<app-loading-spinner
  *ngIf="
    (connectionsStateService.loading$ | async) &&
    !(connectionsStateService.loadingError$ | async)
  "
></app-loading-spinner>
<!-- END LOADING SPINNER -->

<!-- SERVER ERROR: FAILED LOADING CONNECTIONS -->
<app-no-items-error
  [isDisplayed]="
    !(connectionsStateService.loading$ | async) &&
    (connectionsStateService.loadingError$ | async)
  "
  [errorMessage]="connectionsStateService.loadingError$ | async"
>
</app-no-items-error>
<!-- END SERVER ERROR: FAILED LOADING CONNECTIONS -->

<!-- CONTENT -->
<ng-container
  *ngIf="
    !(connectionsStateService.loading$ | async) &&
    !(connectionsStateService.loadingError$ | async)
  "
>
  <!-- ERROR MESSAGE: NO CONNECTIONS -->
  <app-no-items-error
    [isDisplayed]="connections.length == 0"
    [errorMessage]="errorMessageNoConnections"
  >
  </app-no-items-error>
  <!-- END ERROR MESSAGE: NO CONNECTIONS -->

  <!-- CONNECTIONS -->
  <div class="grid gap-12 lg:grid-cols-3" *ngIf="connections.length > 0">
    <div *ngFor="let connection of connections">
      <a
        class="card bg-white h-full p-0 flex flex-col"
        [routerLink]="
          connection.type === 'connection' || isAdminDisplay
            ? linkRoute + connection.name
            : null
        "
        (click)="
          connection.type === 'connection' && !isAdminDisplay
            ? onConnectionOpened(connection.name)
            : null
        "
      >
        <div class="h-48 w-full relative">
          <!-- USER VIEW: CONNECTION TYPE -->
          <div
            *ngIf="!isAdminDisplay"
            class="connection-type"
            [class.bg-theme]="connection.type === 'connection'"
            [class.bg-dark]="connection.type === 'signpost'"
          >
            {{ connection.type }}
          </div>
          <!-- END USER VIEW: CONNECTION TYPE -->
          <!-- ADMIN VIEW: CONNECTION SOURCE TYPE -->
          <div
            *ngIf="isAdminDisplay"
            class="connection-type"
            [class.bg-theme]="connection.source === 'mrd'"
            [class.bg-dark]="connection.source === 'manual-input'"
          >
            {{ connection.source === "mrd" ? "from MRD" : "added manually" }}
          </div>
          <!-- END ADMIN VIEW: CONNECTION SOURCE TYPE -->
          <img
            class="h-full w-full object-cover object-center"
            [src]="connection.image"
            alt=""
          />
        </div>
        <div class="p-10 flex flex-col flex-1">
          <h3 class="text-theme mt-0 text-2xl font-medium">
            <span class="align-middle mr-1">{{ connection.title }}</span>
            <!-- UNCOMMENT UNCOMMENT ONCE OPINION HANDLING IS READY
            <button
              *ngIf="!isAdminDisplay"
              class="float-right transition-all align-middle -mr-1 flex btn-inline w-11 p-0 h-11 text-gray-400 hover:text-theme-darker border border-solid bg-gray-100 hover:bg-theme hover:bg-opacity-10 hover:opacity-70 border-gray-200 hover:border-theme hover:border-opacity-50"
              (click)="openOpinionModal($event, connection)"
            >
              <app-icon class="m-auto" name="opinion"></app-icon>
            </button>
            -->
          </h3>
          <p class="mb-0 flex-1">
            {{ connection.excerpt }}
          </p>
          <a
            *ngIf="
              connection.type === 'signpost' &&
              connection.webLink?.url &&
              !isAdminDisplay
            "
            [href]="connection.webLink.url"
            target="_blank"
            (click)="
              !isAdminDisplay ? onSignpostClicked(connection.name) : null
            "
            class="btn mr-auto btn-theme outlined mt-12 btn-sm py-3"
          >
            {{ connection.webLink?.text || "Visit Website" }}
          </a>
          <app-connection-completeness
            *ngIf="connection.hasDataAndOutputs && !isAdminDisplay"
            [connection]="connection"
            class="block mt-auto pt-6"
          ></app-connection-completeness>
        </div>
      </a>
    </div>
  </div>
  <!-- END CONNECTIONS -->
</ng-container>
<!-- END CONTENT -->

<!-- OPINION MODAL -->
<app-modal>
  <app-connection-opinion
    [connection]="selectedConnection"
    (onAnswer)="closeOpinionModal()"
  ></app-connection-opinion>
</app-modal>
<!-- END OPINION MODAL -->
