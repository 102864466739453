<div
  class="lg:px-16 w-full box-border flex lg:pb-14"
  *ngIf="
    !(cookieConsentService.cookieConsent$ | async) &&
    !(cookieConsentService.cookieConsentLoading$ | async)
  "
>
  <div
    class="notice flex flex-col p-6 sm:p-10 py-6 sm:py-12 md:py-8 gap-6 bg-[#173a4d] text-white lg:rounded-xl w-full md:w-auto lg:ml-auto shadow-lg shadow-black/40"
  >
    <p class="lg:text-md flex-1 font-normal m-0 my-auto mr-8 lg:max-w-[800px]">
      Cookies help us improve our website and provide a better experience.
      Certain cookies are necessary to enable the core functionality of this
      site. You can manage your cookie preferences in detail
      <a class="text-white underline" routerLink="/cookie-preferences">here</a>.
      <br />
      For more information, please read our
      <a class="text-white underline" routerLink="/privacy-policy"
        >Privacy Policy</a
      >.
    </p>
    <div class="flex gap-6">
      <button
        class="btn bg-white hover:bg-gray-100 max-w-[20em] my-auto px-12 font-semibold"
        (click)="acceptAllCookies()"
        aria-label="Accept cookies"
      >
        Accept all cookies
      </button>
      <button
        class="btn bg-transparent text-white max-w-[20em] border-2 my-auto border-white border-solid px-12 hover:bg-opacity-5 hover:bg-white"
        (click)="acceptOnlyEssentialCookies()"
        aria-label="Accept cookies"
      >
        Accept essential cookies only
      </button>
    </div>
  </div>
</div>
