<div
  class="modal-wrapper fixed left-0 top-0 right-0 bottom-0 bg-dark bg-opacity-10 z-20 flex"
  [ngClass]="{ active: active, closing: closing }"
  (click)="onWrapperClick($event)"
>
  <div
    class="modal bg-white m-auto w-full max-w-2xl p-8 md:p-16 relative shadow-lg rounded-xl"
    #modal
    style="max-height: 80vh; overflow-y: auto"
  >
    <button
      class="absolute top-0 right-0 btn-inline text-theme text-2xl w-8 h-8 flex mr-2 pt-1"
      type="button"
      (click)="close()"
    >
      x
    </button>
    <ng-content selector=".child"></ng-content>
  </div>
</div>
