import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { Organisation } from 'src/app/models/organisation/organisation';
import { OrganisationsStateService } from 'src/app/services/organisations/organisations-state.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-page-organisation',
  templateUrl: './page-organisation.component.html',
  styleUrl: './page-organisation.component.css',
})

// TODO: Handle Loading Error, Handle Service Module Content Block, Clean Up Header Handling
export class PageOrganisationComponent {
  // PROPERTIES

  protected displayPage: boolean = false;
  protected organisation?: Organisation;

  private destroy$: Subject<void> = new Subject<void>();

  // CONSTRUCTOR

  constructor(
    protected organisationStateService: OrganisationsStateService,
    private route: ActivatedRoute
  ) {}

  // LIFECYCLE HOOKS

  ngOnInit() {
    // TODO: remove after prototype phase
    if (environment.environment && environment.environment !== 'production') {
      this.displayPage = true;
    }

    this.organisationStateService.organisations$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        let organisationName = this.route.snapshot.paramMap.get('slug') || '';
        this.organisation =
          this.organisationStateService.getOrganisationByName(organisationName);
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
