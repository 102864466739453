import { Component, ElementRef, ViewChild } from '@angular/core';
import Player from '@vimeo/player';
import { Subject, takeUntil } from 'rxjs';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { UserEvent } from 'src/app/models/user/user';
import { CookieConsentService } from 'src/app/services/cookie-consent/cookie-consent.service';
import { UserStateService } from 'src/app/services/user.state.service';
import { communityConnectionsIntroVimeoUrl } from 'src/assets/video/videoUrls';

@Component({
  selector: 'app-quarries-next-step',
  templateUrl: './quarries-next-step.component.html',
  styleUrls: ['./quarries-next-step.component.css'],
})
export class QuarriesNextStepComponent {
  @ViewChild('vimeoPlayer') vimeoPlayer?: ElementRef;

  player?: Player;
  private readonly _vimeoUrl = communityConnectionsIntroVimeoUrl;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private userService: UserStateService,
    private cookieConsentService: CookieConsentService
  ) {}

  ngOnInit(): void {
    let newEvent = new UserEvent({
      type: 'referral',
      context: 'quarriers',
    });

    // user should be verified when on this page (has VerifiedGuard on route)
    // will also update user with any session storage data (if relevant)
    this.userService.logEvent(newEvent);
  }

  ngAfterViewInit(): void {
    this.cookieConsentService.cookieConsent$
      .pipe(takeUntil(this.destroy$))
      .subscribe((cookieConsent) => {
        if (cookieConsent?.consentedVimeo) {
          this.player = new Player(this.vimeoPlayer?.nativeElement, {
            url: this._vimeoUrl,
            responsive: true,
          });
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
