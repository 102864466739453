<div class="flex home-container h-full flex-1">
    <div class="flex h-full" >
        <div class="my-auto pb-[2vw] max-w-xl">
            <h1 class="font-medium mb-10">Understanding the support you give</h1>
            <p class="mb-14">There are an estimated 15,000 carers in Moray and 800,000 unpaid carers in Scotland. Three out of five of us will become carers at some stage in our lives and 1 in 10 of us is already fulfilling some sort of caring role. <br><br>
                Whilst you may be supporting someone very happily it can impact your life significantly and there may be support available to help meet your needs. <br><br>
                Answer a few short questions to help understand what support might be on offer. </p>
            <a class="btn btn-theme px-8" routerLink="/carer-assessment/questions">
                Care Questionnaire
            </a>
        </div>
        <img class="my-auto ml-20 hidden xl:block" src="/assets/img/home-header-bg.svg" alt="">
    </div>

</div>


