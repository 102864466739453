<div class=" ">
  <h1 class="mt-2 text-3xl">My Connections</h1>
  <p class="text-lg">
    This is where you can manage the services you use and see what might be
    useful to you. <br />
    The more complete your <strong>About Me</strong> is, the more accurate your
    service recommendations become.
  </p>
  <div class="mt-20 flex w-full max-w-7xl flex-grow flex-1">
    <app-tabs (onSelect)="changeTab($event)" [options]="tabOptions"></app-tabs>
  </div>
</div>
