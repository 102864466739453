import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function pdsPhoneNumberValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    // Return null if the input is empty
    if (!value || value.length === 0) {
      return null;
    }

    // Step 1: Check that the input contains at least one digit (to ensure it's not just special characters)
    const containsDigitPattern = /\d/;
    if (!containsDigitPattern.test(value)) {
      return { invalidPdsPhoneNumber: { value: control.value } };
    }

    // Step 2: Check that the input contains only allowed characters
    const allowedCharsPattern = /^[+\d\s()-]*$/;
    if (!allowedCharsPattern.test(value)) {
      return { invalidPdsPhoneNumber: { value: control.value } };
    }

    // Step 3: Remove '()-' and spaces (sanitizing the value)
    const sanitizedValue = value.replace(/[\s()-]/g, '');

    // Step 4: Test the sanitized value against the final phone number pattern
    // Check that it matches either a) an empty string, b) a string starting with '+' or '00' followed by 1 to 31 digits,
    // c) a string not starting with '+' or '00' containing between 1 and 31 digits
    const emptyPattern = /^$/;
    const numberWithPrefixPattern = /^(?:\+|00)[\d]{1,31}$/;
    const numberWithoutPrefixPattern = /^(0|0[1-9]\d{0,29}|[1-9]\d{0,30})$/;
    if (
      !emptyPattern.test(sanitizedValue) &&
      !numberWithPrefixPattern.test(sanitizedValue) &&
      !numberWithoutPrefixPattern.test(sanitizedValue)
    ) {
      return { invalidPdsPhoneNumber: { value: control.value } };
    }

    // Return null if the value passes all the checks
    return null;
  };
}
