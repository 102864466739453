enum MamBasePaths {
  THIS_IS_ME = '/this-is-me',
}

export const ServerMamPaths = {
  ThisIsMe: {
    PersonalDetails: `${MamBasePaths.THIS_IS_ME}/personal-details`,
    ContactDetails: `${MamBasePaths.THIS_IS_ME}/contact-details`,
    Current: `${MamBasePaths.THIS_IS_ME}/current-support`,
    PersonalQualities: `${MamBasePaths.THIS_IS_ME}/personal-qualities`,
    MyPeople: `${MamBasePaths.THIS_IS_ME}/my-people`,
    Pastimes: `${MamBasePaths.THIS_IS_ME}/my-pastimes`,
    Home: `${MamBasePaths.THIS_IS_ME}/my-home`,
    Routines: `${MamBasePaths.THIS_IS_ME}/my-routines`,
    ReminiscenceGallery: `${MamBasePaths.THIS_IS_ME}/reminiscence-gallery`,
    MoneyMatters: `${MamBasePaths.THIS_IS_ME}/money-matters`,
  },
};

export const getAllServerMamPaths = (): string[] => {
  return Object.entries(ServerMamPaths).flatMap(([_, subPaths]) =>
    Object.values(subPaths)
  );
};
