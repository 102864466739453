import {
  Component,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChildren,
  forwardRef,
} from '@angular/core';
import { ModalComponent } from '../../modal/modal.component';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormItemSubform } from 'src/app/models/form/form';
import { DefaultComponent } from '../displays/default/default.component';
import { FormComponent } from '../form.component';

@Component({
  selector: 'app-subform',
  templateUrl: './subform.component.html',
  styleUrls: ['./subform.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SubformComponent),
    },
  ],
})
export class SubformComponent implements ControlValueAccessor {
  @ViewChildren('addModal') addModals!: QueryList<ModalComponent>;
  @ViewChildren('editModal') editModals!: QueryList<ModalComponent>;
  @ViewChildren('editForm') editForms!: QueryList<FormComponent>;

  @Input() item?: FormItemSubform<any>; // The FormItemSubform instance
  @Input() disabled: boolean = false;
  @Input() loading: boolean = false;

  @Input() control: any;

  @Output() submitParentForm = new EventEmitter();

  private onChange: (value: any) => void = () => {};
  private onTouched: () => void = () => {};
  touched = false;
  values: any = [];

  constructor() {}

  getDisplayComponent() {
    return this.item?.displayComponent ?? DefaultComponent;
  }

  writeValue(values: any[]): void {
    if (!values) {
      this.values = [];
      return;
    }

    // handle potential custom implementation getValueFromSource
    let valueFromSource: any = this.item?.getValueFromSource(values);
    if (!valueFromSource) {
      this.values = [];
      return;
    }

    // valueFromSource could be an array or a single value
    if (Array.isArray(valueFromSource)) {
      values = valueFromSource;
    } else {
      values = [valueFromSource];
    }

    this.values = values;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  openAddModal(key: string) {
    const modal = this.addModals
      .toArray()
      .find((modal) => modal?.modalId == 'add-modal-' + key);
    if (modal) {
      modal.open();
    }
  }

  openEditModal(index: number) {
    this.editForms.toArray()[index].init();
    const modal = this.editModals
      .toArray()
      .find(
        (modal) =>
          modal?.modalId == 'edit-modal-' + this.item?.key + '-' + index
      );
    if (modal) {
      modal.open();
    }
  }

  addItem(newValue: any, key: string): void {
    var newValue = this.item?.createValueWithParams
      ? this.item.createValueWithParams(newValue)
      : newValue;

    this.values.push(newValue);
    this.onChange(this.values);
    if (this.item?.saveOnChange) {
      this.submitParentForm.emit();
    }
    this.closeAddModal(key);
  }

  editItem(index: number, newValue: any): void {
    var newValue = this.item?.createValueWithParams
      ? this.item.createValueWithParams(newValue)
      : newValue;

    this.values[index] = newValue;
    this.onChange(this.values);
    if (this.item?.saveOnChange) {
      this.submitParentForm.emit();
    }
  }

  removeItem(index: number): void {
    this.values.splice(index, 1);
    this.onChange(this.values);
    if (this.item?.saveOnChange) {
      this.submitParentForm.emit();
    }
  }

  closeAddModal(key: string) {
    const modal = this.addModals
      .toArray()
      .find((modal) => modal?.modalId == 'add-modal-' + key);
    if (modal) {
      modal.close();
    }
  }
  closeEditModal(index: number) {
    const modal = this.editModals
      .toArray()
      .find(
        (modal) =>
          modal?.modalId == 'edit-modal-' + this.item?.key + '-' + index
      );
    if (modal) {
      modal.close();
    }
  }
}
