import { Component, Input, OnInit, forwardRef } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
  selector: 'app-email-content-control',
  templateUrl: './email-content-control.component.html',
  styleUrls: ['./email-content-control.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EmailContentControlComponent),
      multi: true,
    },
  ],
})
export class EmailContentControlComponent
  implements ControlValueAccessor, OnInit
{
  // PROPERTIES

  @Input() item?: any;
  @Input() control: any;
  @Input() disabled: boolean = false;

  emailContentFormGroup: FormGroup = new FormGroup({
    text: new FormControl(''),
  });

  private onChange: any = () => {};
  private onTouched: any = () => {};

  // LIFECYCLE

  ngOnInit(): void {
    this.emailContentFormGroup.valueChanges.subscribe((value) => {
      this.onChange(value);
      this.onTouched();
    });

    if (this.item.disabled) {
      this.textFormControl.disable();
    }
  }

  // CONTROL VALUE ACCESSOR

  writeValue(value: any): void {
    if (this.textFormControl.value !== value) {
      this.textFormControl.setValue(value);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  update(): void {
    this.onChange(this.textFormControl.value);
    this.onTouched();
  }

  // ACCESS FORM GROUP COMPONENTS

  get textFormControl(): FormControl {
    return this.emailContentFormGroup.get('text') as FormControl;
  }
}
