import { ContactDetails } from '../base/contactDetails';
import { FileData } from '../base/fileData';
import { ContentHolder } from '../content/ContentHolder';

export class Organisation {
  alissId?: string;
  name: string; // a unique name + used as the slug, regex: ^([a-z0-9]+-?)+$

  title: string; // max 40 characters
  subheading?: string; // max 80 characters

  titleColor?: string; // hex colour (will be used in the header for title if used)
  logo?: string; // svg icon (will be used in the header with title)
  titleLogo?: string; // svg icon (will be used in the header as title, prioritised over sole logo + title)

  headerImage?: string; // will be prioritised over background colour
  headerBackgroundColor?: string; // hex colour

  contactDetails?: ContactDetails;
  homepageContent?: ContentHolder;

  constructor(data: Partial<Organisation>) {
    this.alissId = data?.alissId;
    this.name = data?.name || '';
    this.title = data?.title || '';
    this.subheading = data?.subheading;
    this.titleColor = data?.titleColor;
    this.logo = data?.logo;
    this.titleLogo = data?.titleLogo;
    this.headerImage = data?.headerImage;
    this.headerBackgroundColor = data?.headerBackgroundColor;
    this.contactDetails = data?.contactDetails
      ? new ContactDetails(data.contactDetails)
      : undefined;
    this.homepageContent = data?.homepageContent
      ? new ContentHolder(data.homepageContent)
      : undefined;
  }

  get headerBrandingType(): 'titleLogo' | 'titleText' {
    return this.titleLogo ? 'titleLogo' : 'titleText';
  }

  get headerBackground(): 'image' | 'color' {
    return this.headerImage ? 'image' : 'color';
  }
}
