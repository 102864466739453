import { AboutMeSection } from 'src/app/models/about-me/aboutMeSection';
import {
  getFormItemsUserPlanningAheadAttitudeToChange,
  getFormItemsUserPlanningAheadFutureCarePlan,
  getFormItemsUserPlanningAheadLivingWill,
  getFormItemsUserPlanningAheadPowerOfAttorney,
  getFormItemsUserPlanningAheadUnpaidCarer,
  getFormItemsUserPlanningAheadWill,
} from '../../forms/user/planning-ahead-items/form-planning-ahead';

export const planningAheadSection: AboutMeSection = {
  title: 'Planning Ahead',
  description: 'Plans you have for the future',
  longDescription:
    'In this section, you can tell us about the plans you have for the future. Click on an area to get started.',
  routePath: 'planning-ahead',
  userPath: 'planningAhead',
  children: [
    {
      title: 'Attitude to Change',
      description: 'Your thoughts and wishes in regards to change',
      routePath: 'attitude-to-change',
      userPath: 'attitudeToChange',
      getFormItems: getFormItemsUserPlanningAheadAttitudeToChange,
    },
    {
      title: 'Power of Attorney',
      description: 'Tell us about your arrangements for power of attorney',
      routePath: 'power-of-attorney',
      userPath: 'powerOfAttorney',
      getFormItems: getFormItemsUserPlanningAheadPowerOfAttorney,
    },
    {
      title: 'Future Care Plan',
      description: 'Thinking ahead and understanding your health',
      longDescription:
        "Future care planning is about thinking ahead and understanding your health. It's about knowing how to use services better and it helps you make choices about your future.",
      routePath: 'future-care-plan',
      userPath: 'futureCarePlan',
      getFormItems: getFormItemsUserPlanningAheadFutureCarePlan,
    },
    {
      title: 'Will',
      description: 'Details about your will',
      routePath: 'will',
      userPath: 'will',
      getFormItems: getFormItemsUserPlanningAheadWill,
    },
    {
      title: 'Unpaid Carer',
      description: 'Your thoughts and wishes for people in your care',
      longDescription:
        'If you are a carer for someone else, have you thought about who will look after them when you are no longer able to? This might include children or grandchildren you are close to, someone with a mental health problem or others who you think need support. To avoid worry for you or distress for the person you care for it can be helpful to discuss the options for care in advance.',
      routePath: 'unpaid-carer',
      userPath: 'unpaidCarer',
      getFormItems: getFormItemsUserPlanningAheadUnpaidCarer,
    },
    {
      title: 'Living Will',
      description: 'Tell us about your living will',
      longDescription:
        'You may want to let others know about your personal wishes and decisions about your care. A living will allows you to provide a written statement signed by you that sets out how you would prefer to be treated (or not treated) if you were to become unwell.',
      routePath: 'living-will',
      userPath: 'livingWill',
      getFormItems: getFormItemsUserPlanningAheadLivingWill,
    },
  ],
};
