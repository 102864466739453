import {
  CanActivateFn,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { inject } from '@angular/core';
import { filter, first, map, switchMap, tap } from 'rxjs/operators';

export const AuthGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const blockIfAuthed = route.data['blockIfAuthed'] ?? false;

  const redirectAuthed = () => router.createUrlTree(['/account/about-me']);
  const redirectUnauthed = () => router.createUrlTree(['/login']);

  return authService.isAuthenticated$.pipe(
    filter((authChecked) => authChecked !== undefined),
    map((isAuthenticated) => {
      // user is not authenticated and may not access the route
      if (!isAuthenticated && !blockIfAuthed) {
        return redirectUnauthed();
        // user is authenticated and may not access the route
      } else if (isAuthenticated && blockIfAuthed) {
        return redirectAuthed();
      }
      return true;
    }),
    first()
  );
};
