import { Component } from '@angular/core';
import { PdsService } from 'src/app/services/pds/pds.service';
import { SessionStorageService } from 'src/app/services/session-storage/session-storage.service';

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.css'],
})
export class RegisterSetupPermissionsComponent {
  protected agreementPrivacyPolicy: boolean = false;
  protected agreementPrivacyPolicyError: string = '';

  constructor(
    protected pdsService: PdsService,
    protected sessionStorageService: SessionStorageService
  ) {}

  submit() {
    if (
      (!this.sessionStorageService.getRegistrationInProgress() &&
        this.agreementPrivacyPolicy) ||
      this.sessionStorageService.getRegistrationInProgress()
    ) {
      this.pdsService.getAccessApprovalUrlAndRedirect();
      return;
    }

    this.agreementPrivacyPolicyError =
      'Please ensure that you consent to the Privacy Policy.';

    setTimeout(() => {
      this.agreementPrivacyPolicyError = '';
    }, 5000);
  }

  ngOnDestroy() {
    this.pdsService.resetErrors();
  }
}
