import { Validators } from '@angular/forms';
import { FormItemTextArea } from 'src/app/models/form/form';

// KEY INFORMATION - MEDICATION LOCATION -----------------------------------------------

export const medicationStorage = new FormItemTextArea({
  key: 'medicationStorage',
  path: 'keyInformation.medicationLocation.storage',
  label: 'Where do you keep your medication?',
  placeholder: 'Your medication storage',
  class: 'mb-5',
  width: 'half',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const medicationTimes = new FormItemTextArea({
  key: 'medicationTimes',
  path: 'keyInformation.medicationLocation.times',
  label: 'When do you take your medication?',
  placeholder: 'Your medication intake times',
  class: 'mb-5',
  width: 'half',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});
