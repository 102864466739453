import { Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { loginForm } from 'src/assets/forms/register-and-login/form-login';
import { AuthService } from '../../services/auth/auth.service';
import { environment } from 'src/environments/environment';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { SessionStorageService } from 'src/app/services/session-storage/session-storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent {
  @ViewChild(ModalComponent, { static: true }) modal: ModalComponent =
    {} as ModalComponent;
  form = loginForm;
  user = {};
  error = '';
  loading = false;

  success = false;

  logoutLoading = false;

  registerFrom: string | undefined;

  constructor(
    private authService: AuthService,
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.registerFrom =
      this.sessionStorageService.getRegistrationFlowStartingPoint();
  }

  loginForm = new FormGroup({
    user: new FormControl(''),
    password: new FormControl(''),
  });

  enableRegistration =
    environment.enableRegistration ||
    this.sessionStorageService.getOverrideRegistration();

  submit() {
    this.loading = true;
    this.authService.login();
  }
}
