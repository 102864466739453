import { Component, forwardRef, Input } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  FormArray,
  FormBuilder,
  FormGroup,
} from '@angular/forms';
import { FormItemRepeater } from 'src/app/models/form/form';
import * as _ from 'lodash';

@Component({
  selector: 'app-repeater',
  templateUrl: './repeater.component.html',
  styleUrls: ['./repeater.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RepeaterComponent),
      multi: true,
    },
  ],
})
export class RepeaterComponent implements ControlValueAccessor {
  @Input() item?: FormItemRepeater<any>; // Receive the FormItemRepeater
  @Input() control: any;
  @Input() disabled: boolean = false;
  @Input() loading: boolean = false;
  @Input() containerClass: string = 'bg-white bg-opacity-30';

  formArray: FormArray;

  onChange = (value: any) => {};
  onTouched = () => {};

  constructor(private fb: FormBuilder) {
    this.formArray = this.fb.array(
      this.item ? [this.createItemFormGroup()] : []
    );
  }

  writeValue(value: any): void {
    if (value) {
      this.formArray?.clear();
      value.forEach((item: any) => {
        if (!item) {
          return;
        }
        this.formArray?.push(this.createItemFormGroup(item));
      });
    } else {
      this.formArray?.clear();
      this.onAddItem();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
    this.formArray?.valueChanges.subscribe((values) => {
      let res = [];

      if (this.item?.formItems.length == 1 && !this.item?.formItems[0].key) {
        res = values.map((item: any) => {
          return item[Object.keys(item)[0]];
        });
      } else {
        res = values;
      }

      //Filter out any null values (& objects with null values) - src gpt
      const filterArray = (arr: object) =>
        _.map(arr, (item) =>
          _.isObject(item) ? _.omitBy(item, _.isNull) : item
        ).filter((item) => !_.isNull(item) && !_.isEmpty(item));

      this.onChange(filterArray(res));
    });
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onAddItem() {
    if (this.item?.formItems) {
      this.formArray?.push(this.createItemFormGroup());
    }
  }

  updateValue(value: any, index: number) {
    this.formArray?.at(index).patchValue(value);
  }

  onRemoveItem(index: number) {
    this.formArray?.removeAt(index);
  }

  createItemFormGroup(value?: any): FormGroup {
    this.item?.formItems?.forEach((formItem) => {
      formItem.showLabel = this.item?.showLabels ?? true;
    });

    let fromEntries = Object.fromEntries(
      this.item
        ? this.item.formItems?.map((formItem) => [
            formItem.key,
            value ?? formItem.value,
          ])
        : []
    );
    let group = this.fb.group(fromEntries);

    return group;
  }
}
