import { UserImageData } from './userImageData';

export class UserImagePdsEntry extends UserImageData {
  imageId: string;

  constructor(data?: Partial<UserImagePdsEntry>) {
    super(data);
    this.imageId = data?.imageId ?? '';
  }
}
