import { AboutMeSection } from 'src/app/models/about-me/aboutMeSection';
import {
  getFormItemsUserKeyInfoAccess,
  getFormItemsUserKeyInfoMainSupport,
  getFormItemsUserKeyInfoMedicalDetails,
  getFormItemsUserKeyInfoMedicationLocation,
  getFormItemsUserKeyInfoPreferences,
  getFormItemsUserKeyInfoPriorities,
  getFormItemsUserKeyInfoSupportPackage,
} from '../../forms/user/key-info-items/form-key-info';

export const keyInfoSection: AboutMeSection = {
  title: 'Key Information',
  longDescription:
    'In this section, you can tell us about your key information. Click on an area to get started.',
  description: 'All your key information',
  routePath: 'key-info',
  userPath: 'keyInformation',
  children: [
    {
      title: 'Medical Details',
      description: 'Your basic medical details',
      routePath: 'medical-details',
      userPath: 'medicalDetails',
      getFormItems: getFormItemsUserKeyInfoMedicalDetails,
    },
    {
      title: 'Medication Location',
      description: 'Where you keep your medication',
      routePath: 'medication-location',
      userPath: 'medicationLocation',
      getFormItems: getFormItemsUserKeyInfoMedicationLocation,
    },
    {
      title: 'Main Support',
      description: 'Details about your main support person',
      routePath: 'main-support',
      userPath: 'mainSupport',
      getFormItems: getFormItemsUserKeyInfoMainSupport,
    },
    {
      title: 'Access',
      description: 'Emergency access to your home',
      routePath: 'access',
      userPath: 'access',
      getFormItems: getFormItemsUserKeyInfoAccess,
    },
    {
      title: 'Priorities',
      description: 'Your priorities',
      routePath: 'priorities',
      userPath: 'priorities',
      getFormItems: getFormItemsUserKeyInfoPriorities,
    },
    {
      title: 'Support Package',
      description: 'All about your support package',
      routePath: 'support-package',
      userPath: 'supportPackage',
      getFormItems: getFormItemsUserKeyInfoSupportPackage,
    },
    {
      title: 'Preferences',
      description: 'Your general preferences',
      routePath: 'preferences',
      userPath: 'preferences',
      getFormItems: getFormItemsUserKeyInfoPreferences,
    },
  ],
};
