<div class="flex mt-8 box-border">
  <div class="flex flex-col lg:w-1/2">
    <h1 class="text-2xl font-medium mt-0 mb-4">
      Step 1: Setting up your private key
    </h1>
    <p class="mb-12">
      This record is yours to share as you like and so we ask you to create a
      Private Key. Just like accessing your house, or your internet banking, you
      need to have the key to get in. <br /><br />
      If anyone wants to see your data, then you will need to use this key. This
      is how we keep your data safe and private. This means that if you lose
      your key, you lose access to your data forever. <br /><br />
      The first step, we’ll take you to Mydex to set that up. It’s a very
      important piece of information so make sure you make a note of it and
      store it somewhere you can access it again in the future.
    </p>
    <button (click)="submit()" class="btn-theme px-8 mr-auto">
      Create a private key
    </button>
  </div>
  <div class="-my-20 w-1/2 hidden lg:flex">
    <img
      class="h-2 ml-auto min-h-full"
      src="/assets/img/private-key-illustration.svg"
      alt=""
    />
  </div>
</div>
