<div class="container-sm">
  <h1>Report a problem with the Community Connections app</h1>
  <p>
    We appreciate your feedback and value your input. If you've encountered any
    issues with this website, please let us know. We're only requesting the
    essential information needed to investigate and resolve your concern.
  </p>
  <p>
    To report a problem, please click on the button below. This will open the
    <span class="font-bold"
      >Moray Care in Place Pilot feedback and issue reporting form</span
    >
    in Google Forms in a new tab.
  </p>
  <a
    href="https://docs.google.com/forms/d/e/1FAIpQLScRmhgmflCP9u5MnRY6Zpn8ZvEKms7r-Z-KCHCu_SRrThJJzg/viewform"
    target="_blank"
    class="btn btn-theme mt-4 px-12"
  >
    Report a Problem
  </a>
</div>
