import { Validators } from '@angular/forms';
import { FormItemTextArea } from 'src/app/models/form/form';

// KEY INFORMATION - PREFERENCES -----------------------------------------------

export const preferencesPositive = new FormItemTextArea({
  key: 'preferencesPositive',
  path: 'keyInformation.preferences.positive',
  label: 'Please do',
  placeholder:
    'e.g. please call me Jean rather than Mrs Hendry. Please acknowledge I was previously a physiotherapist and know a good deal about the care of older people.',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const preferencesAvoid = new FormItemTextArea({
  key: 'preferencesAvoid',
  path: 'keyInformation.preferences.avoid',
  label: 'Please do not',
  placeholder:
    "e.g. please don't call me before 10am as I provide personal support until then",
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});
