import { CanActivateFn, Router, ActivatedRouteSnapshot } from '@angular/router';
import { combineLatest, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { AuthService } from '../services/auth/auth.service';
import { PdsService } from '../services/pds/pds.service';
import { inject } from '@angular/core';

export const VerifiedGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const authService = inject(AuthService);
  const pdsService = inject(PdsService);
  const router = inject(Router);
  const blockIfVerified = route.data['blockIfVerified'] ?? false;
  const exemptConnectionStatus =
    route.data['exemptConnectionStatus'] ?? undefined;

  return authService.isAuthenticated$.pipe(
    filter((isAuthenticated) => isAuthenticated !== undefined),
    take(1),
    switchMap((isAuthenticated) => {
      if (!isAuthenticated) {
        return of(router.createUrlTree(['/login']));
      } else {
        return pdsService.pdsConnectionStatus$.pipe(
          filter((pdsConnectionStatus) => pdsConnectionStatus !== undefined),
          take(1),
          map((pdsConnectionStatus) => {
            if (pdsConnectionStatus === undefined) {
              return router.createUrlTree(['/']);
            }

            switch (pdsConnectionStatus.value) {
              case 'valid':
                // user has a valid connection (= is verified) and may not access the route -> redirect to about me
                if (blockIfVerified) {
                  return router.createUrlTree(['/account/about-me']);
                }
                // user has a valid connection and may access the route
                return true;
              case 'expired':
                // user has an expired connection and may not access the route -> redirected to update it
                if (exemptConnectionStatus !== 'expired') {
                  return router.createUrlTree(['/setup/update-permissions']);
                }
                // user has an expired connection and may access the route (is exempt)
                return true;
              case 'no-connection':
                // user has an no connection and may not access the route -> redirected to set it up
                if (exemptConnectionStatus !== 'no-connection') {
                  return router.createUrlTree(['/register/setup/permissions']);
                }
                // user has no connection and may access the route (is exempt)
                return true;
            }
          }),
          catchError(() => {
            // usually 401 though handled by interceptor
            return of(router.createUrlTree(['/']));
          })
        );
      }
    }),
    catchError(() => {
      // In case of an error with the authentication check, redirect to login
      return of(router.createUrlTree(['/login']));
    })
  );
};
