import { Component } from '@angular/core';
import { Connection } from 'src/app/models/connection/connection';
import { FormItem } from 'src/app/models/form/form';
import { User } from 'src/app/models/user/user';
import { ConnectionsStateService } from 'src/app/services/connections.state.service';
import { UserStateService } from 'src/app/services/user.state.service';

@Component({
  selector: 'app-connection-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css'],
})
export class ConnectionFormComponent {
  protected connection?: Connection;
  protected loading: boolean = true; //if connection is loading
  protected error: string = ''; //if connection has error
  protected user: User | undefined;
  protected form: FormItem<any>[] = []; // getUserFormItems();

  constructor(
    protected userService: UserStateService,
    protected connectionService: ConnectionsStateService
  ) {}
}
