export interface AboutMeSection {
  title: string;
  description: string;
  longDescription?: string;
  routePath: string;
  userPath: string;
  serverMamPaths?: string[];
  children?: AboutMeSection[];
  getFormItems?: Function;
}

export function flattenAboutMeSections(
  sections: AboutMeSection[]
): AboutMeSection[] {
  const flattenedSections: AboutMeSection[] = [];

  function flatten(section: AboutMeSection) {
    flattenedSections.push(section);
    if (section.children && section.children.length > 0) {
      section.children.forEach((child) => flatten(child));
    }
  }

  sections.forEach((section) => flatten(section));

  return flattenedSections;
}
