import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
  selector: 'app-textbox-with-affix',
  templateUrl: './textbox-with-affix.component.html',
  styleUrls: ['./textbox-with-affix.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextboxWithAffixComponent),
      multi: true,
    },
  ],
})

// note: currently only implenting prefix, but suffix can be added in the future
export class TextboxWithAffixComponent implements ControlValueAccessor, OnInit {
  // PROPERTIES

  @ViewChild('inputField') inputField?: ElementRef;
  @ViewChild('prefixElement') prefixElement?: ElementRef;

  textbowWithAffixFormGroup: FormGroup = new FormGroup({
    text: new FormControl(''),
  });

  @Input() item?: any;
  @Input() control: any;
  @Input() disabled: boolean = false;

  private onChange: any = () => {};
  private onTouched: any = () => {};

  // LIFECYCLE

  ngOnInit(): void {
    this.textbowWithAffixFormGroup.valueChanges.subscribe((value) => {
      this.onChange(value);
      this.onTouched();
    });

    if (this.item.disabled) {
      this.textFormControl.disable();
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.setPadding();
    }, 0);
  }

  // CONTROL VALUE ACCESSOR

  writeValue(value: any): void {
    if (value === undefined || value === null) {
      value = '';
    }

    // remove the prefix if it's there
    if (value.startsWith(this.item.prefix)) {
      value = value.slice(this.item.prefix.length);
    }

    if (this.textFormControl.value === value) {
      return;
    }

    this.textFormControl.setValue(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // FORM GROUP

  setPadding() {
    if (!this.inputField?.nativeElement.style) {
      return;
    }

    const prefixWidth = this.prefixElement?.nativeElement.offsetWidth;

    const paddingValue = prefixWidth + 10;
    this.inputField.nativeElement.style.paddingLeft = `${paddingValue}px`;
  }

  update(): void {
    let newValue = this.textFormControl.value;

    // add the prefix if it's not already there
    if (
      this.item.prefix &&
      newValue.length > 0 &&
      !newValue.startsWith(this.item.prefix)
    ) {
      newValue = this.item.prefix + this.textFormControl.value;
    }
    this.onChange(newValue);

    this.onTouched();
  }

  // ACCESS FORM GROUP COMPONENTS

  get textFormControl(): FormControl {
    return this.textbowWithAffixFormGroup.get('text') as FormControl;
  }
}
