import {
  FormItemButtonSelect,
  FormItemDropdown,
  FormItemTextArea,
  FormItemTitle,
} from 'src/app/models/form/form';
import { yesNoInProgressLegacyOptions, yesNoOptions } from '../item-options';
import { Validators } from '@angular/forms';

export const futurePlansImpactCaredForPersonHasCommunityAlarm =
  new FormItemButtonSelect({
    key: 'futurePlansImpactCaredForPersonHasCommunityAlarm',
    path: 'supportingRole.futurePlansImpact.caredForPersonHasCommunityAlarm',
    label: 'The person I care for has a community alarm',
    class: 'mb-5',
    options: yesNoOptions,
  });

export const futurePlansImpactArrangementsForCareInPlace =
  new FormItemButtonSelect({
    key: 'futurePlansImpactArrangementsForCareInPlace',
    path: 'supportingRole.futurePlansImpact.arrangementsForCareInPlace',
    label:
      'I have plans in place should something unexpected happen to prevent me providing care',
    class: 'mb-5',
    options: yesNoOptions,
  });

export const arrangementsInPlaceTitle = new FormItemTitle({
  key: 'arrangementsInPlaceTitle',
  label: 'Do you have these arrangements in place already?',
  class: 'mb-2',
});

export const futurePlansImpactArrangementInPlaceAppointee =
  new FormItemButtonSelect({
    key: 'futurePlansImpactArrangementInPlaceAppointee',
    path: 'supportingRole.futurePlansImpact.arrangementInPlaceAppointee',
    label: 'Arrangement in place: Appointee',
    class: 'mb-5',
    options: yesNoInProgressLegacyOptions,
  });

export const futurePlansImpactArrangementInPlaceGuardianFinancial =
  new FormItemButtonSelect({
    key: 'futurePlansImpactArrangementInPlaceGuardianFinancial',
    path: 'supportingRole.futurePlansImpact.arrangementInPlaceGuardianFinancial',
    label: 'Arrangement in place: Guardian, Financial',
    class: 'mb-5',
    options: yesNoInProgressLegacyOptions,
  });

export const futurePlansImpactArrangementInPlaceGuardianWelfare =
  new FormItemButtonSelect({
    key: 'futurePlansImpactArrangementInPlaceGuardianWelfare',
    path: 'supportingRole.futurePlansImpact.arrangementInPlaceGuardianWelfare',
    label: 'Arrangement in place: Guardian, Welfare',
    class: 'mb-5',
    options: yesNoInProgressLegacyOptions,
  });

export const futurePlansImpactArrangementInPlaceNamedPerson =
  new FormItemButtonSelect({
    key: 'futurePlansImpactArrangementInPlaceNamedPerson',
    path: 'supportingRole.futurePlansImpact.arrangementInPlaceNamedPerson',
    label: 'Arrangement in place: Named Person',
    class: 'mb-5',
    options: yesNoInProgressLegacyOptions,
  });

export const futurePlansImpactArrangementInPlaceWill = new FormItemButtonSelect(
  {
    key: 'futurePlansImpactArrangementInPlaceWill',
    path: 'supportingRole.futurePlansImpact.arrangementInPlaceWill',
    label: 'Arrangement in place: Will',
    class: 'mb-5',
    options: yesNoInProgressLegacyOptions,
  }
);

export const futurePlansImpactArrangementInPlacePoaFinancial =
  new FormItemButtonSelect({
    key: 'futurePlansImpactArrangementInPlacePoaFinancial',
    path: 'supportingRole.futurePlansImpact.arrangementInPlacePoaFinancial',
    label: 'Arrangement in place: Power of Attorney, Financial',
    class: 'mb-5',
    options: yesNoInProgressLegacyOptions,
  });

export const futurePlansImpactArrangementInPlacePoaContinuingWelfare =
  new FormItemButtonSelect({
    key: 'futurePlansImpactArrangementInPlacePoaContinuingWelfare',
    path: 'supportingRole.futurePlansImpact.arrangementInPlacePoaContinuingWelfare',
    label: 'Arrangement in place: Power of Attorney, Continuing Welfare',
    class: 'mb-5',
    options: yesNoInProgressLegacyOptions,
  });

export const futurePlansImpactArrangementsWillImpactRole = new FormItemTextArea(
  {
    key: 'futurePlansImpactArrangementsWillImpactRole',
    path: 'supportingRole.futurePlansImpact.arrangementsWillImpactRole',
    label: 'I know these things will impact my role in the future',
    class: 'mb-5',
    validators: [Validators.minLength(1), Validators.maxLength(2000)],
  }
);

export const futurePlansImpactDesires = new FormItemTextArea({
  key: 'futurePlansImpactDesires',
  path: 'supportingRole.futurePlansImpact.desires',
  label: 'I know these things will impact my role in the future',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const futurePlansImpactSummary = new FormItemDropdown({
  key: 'futurePlansImpactSummary',
  label:
    'Thinking about the future, the selected statement best matches how you currently feel.',
  path: 'supportingRole.futurePlansImpact.summary',
  placeholder: 'Please select...',
  class: 'mb-5',
  options: [
    {
      name: '1',
      label:
        'I mostly have the skills, understanding and information I need and can plan ahead. I have no concerns.',
    },
    {
      name: '2',
      label:
        'I have a good idea about my caring role, but still would like to learn more. I feel confident about the future, but have some concerns.',
    },
    {
      name: '3',
      label:
        "Things are difficult, but I'm starting to get to grips with my caring role. I don't feel confident about the future, and have some concerns.",
    },
    {
      name: '4',
      label:
        "I'm finding it hard to cope. I get some support but am not confident it'll really help. I feel anxious about the future, and have significant concerns.",
    },
    {
      name: '5',
      label:
        "It's hard. I don't know if I can carry on as things are, or how anyone could help. I'm very anxious about the future, and have severe concerns.",
    },
  ],
});
