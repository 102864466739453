export class Address {
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  postcode?: string;
  country?: string;

  constructor(data: Partial<Address | undefined>) {
    this.address1 = data?.address1;
    this.address2 = data?.address2;
    this.city = data?.city;
    this.state = data?.state;
    this.postcode = data?.postcode;
    this.country = data?.country;
  }
}
