import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { UserStateService } from 'src/app/services/user.state.service';
import { User, UserEvent } from 'src/app/models/user/user';
import { SessionStorageService } from './session-storage/session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class UserEventsService {
  constructor(
    private userService: UserStateService,
    private sessionStorageService: SessionStorageService
  ) {}

  checkEventCompletion(
    eventToCheck: UserEvent | any
  ): Observable<string | false> {
    return this.userService.user$.pipe(
      take(1),
      map((user) => {
        // check user events for event completion
        let refEvents = this.filterEventsForCompletion(
          eventToCheck,
          user?.events
        );

        // if there is no user event, check session storage for event completion
        if (refEvents === undefined || refEvents.length === 0) {
          refEvents = this.filterEventsForCompletion(
            eventToCheck,
            this.sessionStorageService.getUserData()?.events
          );
        }

        // handle case that eventToCheck has been completed
        if (refEvents?.length) {
          const lastEvent = refEvents.at(-1);
          if (lastEvent?.timestamp) {
            return this.formatDateString(lastEvent.timestamp);
          }
          return 'before';
        }
        return false;
      })
    );
  }

  private filterEventsForCompletion(
    event: any,
    events?: UserEvent[]
  ): UserEvent[] | undefined {
    return events?.filter(
      ({ type, context, connection }) =>
        type === event.type &&
        context === event.context &&
        (connection && event.connection
          ? connection === event.connection
          : true)
    );
  }

  private formatDateString(timestamp: number): string {
    const eventDate = new Date(timestamp);
    const today = new Date();
    if (
      eventDate.getDate() === today.getDate() &&
      eventDate.getMonth() === today.getMonth() &&
      eventDate.getFullYear() === today.getFullYear()
    ) {
      return 'earlier today at ' + eventDate.toLocaleTimeString();
    } else {
      return 'on ' + eventDate.toLocaleDateString();
    }
  }
}
