import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, filter, first, takeUntil } from 'rxjs';
import { Connection } from 'src/app/models/connection/connection';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { ConnectionsStateService } from 'src/app/services/connections.state.service';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-page-finder-search',
  templateUrl: './page-finder-search.component.html',
  styleUrl: './page-finder-search.component.css',
})
export class PageFinderSearchComponent {
  // PROPERTIES
  protected connectionsAll: Connection[] = [];
  protected connections: Connection[] = [];
  protected errorMessageNoConnections = '';

  protected searchTerm = '';

  private destroy$: Subject<void> = new Subject<void>();

  private readonly ERROR_MESSAGE_NO_CONNECTIONS_GENERIC =
    'Failed loading connections. Please try again later.';
  private readonly ERROR_MESSAGE_NO_CONNECTIONS_MATCH =
    'No connections were found that match your search term. Please try something else.';

  // CONSTRUCTOR
  constructor(
    protected navigationService: NavigationService,
    private connectionsStateService: ConnectionsStateService,
    private route: ActivatedRoute,
    private router: Router,
    private analyticsService: AnalyticsService
  ) {}

  // LIFE CYCLE HOOKS

  ngOnInit() {
    this.errorMessageNoConnections = this.ERROR_MESSAGE_NO_CONNECTIONS_GENERIC;

    this.connectionsStateService.connections$
      .pipe(takeUntil(this.destroy$))
      .subscribe((connections) => {
        if (!connections) {
          return;
        }

        this.connectionsAll = connections;
        this.connections = connections;
        this.errorMessageNoConnections =
          this.ERROR_MESSAGE_NO_CONNECTIONS_MATCH;

        this.filterList();
      });
  }

  ngOnDestory(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  protected filterList(searchTerm?: string) {
    // use new search term if triggered by input, otherwise use existing if triggered by connections update
    if (searchTerm !== undefined) {
      this.searchTerm = searchTerm;
    }

    if (this.searchTerm === '') {
      this.connections = this.connectionsAll;
      return;
    }

    var searchTermLowerCase = this.searchTerm.toLowerCase();

    this.connections = this.connectionsAll.filter((connection) => {
      return (
        connection.title.toLowerCase().includes(searchTermLowerCase) ||
        connection.excerpt.toLowerCase().includes(searchTermLowerCase) ||
        connection.summary?.title
          ?.toLowerCase()
          .includes(searchTermLowerCase) ||
        connection.summary?.headline
          ?.toLowerCase()
          .includes(searchTermLowerCase) ||
        connection.summary?.description
          ?.toLowerCase()
          .includes(searchTermLowerCase) ||
        connection.finderPaths?.some((path) =>
          path.includes(searchTermLowerCase)
        )
      );
    });
  }

  protected handleConnectionOpened(connectionName: string | undefined) {
    this.analyticsService.trackConnectionOpenedInSearch(
      connectionName ?? 'connection-name-unknown'
    );
  }

  protected handleSignpostClicked(connectionName: string | undefined) {
    this.analyticsService.trackSignpostClickedInSearch(
      connectionName ?? 'connection-name-unknown'
    );
  }
}
