<div class="mb-5" [formGroup]="emailContentFormGroup">
  <div
    class="rounded-lg pt-4 pl-8 pr-8 pb-8 mb-5"
    style="border: 1px solid #979797; background-color: #f1f1f1"
  >
    <p class="mb-1">{{ item?.salutation }}</p>
    <p class="mb-3">
      {{ item?.introduction }}
    </p>
    <textarea
      type="text"
      (change)="update()"
      formControlName="text"
      class="form-control"
      [rows]="item?.rows ?? 4"
      [placeholder]="item.placeholder ? item.placeholder : 'Enter some text...'"
      [class.is-invalid]="control.touched && control.errors"
      [id]="item.key"
      [disabled]="disabled"
    ></textarea>
    <p class="mb-1">{{ item?.closing }}</p>
    <p class="mb-1">{{ item?.signature }}</p>
  </div>
</div>
