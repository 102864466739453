<div class="flex justify-center w-full">
  <img
    class="max-w-[460px] w-[26vw] mr-12 hidden xl:block 3xl:-ml-[10%]"
    src="/assets/img/about-you-illustration.svg"
    alt=""
  />

  <div class="my-auto pb-6">
    <h1 class="mb-0">Welcome!</h1>

    <p class="md:text-lg mt-2 max-w-[700px] mb-12">
      You've now set up a Personal Data Store with Mydex and can access your
      <strong>About Me</strong>. It provides a secure and private place to store
      your personal information and allows you to share this with organisations
      and services you trust.
    </p>

    <div class="flex flex-wrap gap-6">
      <a routerLink="/account/about-me" class="btn btn-theme px-12">
        Continue to About Me
      </a>

      <a routerLink="/services" class="btn btn-theme-outline px-12">
        Find Services
      </a>
    </div>
  </div>
</div>
