<div class="mb-5 flex flex-col xl:flex-row">
  <div
    class="flex-grow w-full xl:w-auto border-gray-300 border border-solid rounded-lg p-4"
    [ngClass]="{
      'grid grid-cols-3 sm:grid-cols-4 md:grid-cols-6 xl:grid-cols-5 gap-4':
        !imagesThumbnailsSourceLoading && !imagesThumbnailsSourceLoadingError
    }"
  >
    <!-- SELECTED IMAGES STATEFUL LOADING SPINNER -->
    <app-stateful-loading-spinner
      *ngIf="
        imagesThumbnailsSourceLoading || imagesThumbnailsSourceLoadingError
      "
      spinnerText="Loading Images"
      [errorMessage]="imagesThumbnailsSourceLoadingError"
    ></app-stateful-loading-spinner>
    <!-- END SELECTED IMAGES STATEFUL LOADING SPINNER -->

    <!-- SELECTED IMAGES -->
    <ng-container
      *ngIf="
        !imagesThumbnailsSourceLoading && !imagesThumbnailsSourceLoadingError
      "
    >
      <button
        class="w-full aspect-square bg-gray-200 text-gray-500 rounded-lg flex items-center justify-center text-sm p-2 truncate"
        type="button"
        (click)="openImagePickerModal($event)"
      >
        <app-icon name="check-circle" class="w-5"></app-icon>
        <span>Update<br />selection</span>
      </button>
      <div *ngFor="let image of selectedImages" class="relative cursor-pointer">
        <div
          class="w-full aspect-square rounded-lg overflow-hidden flex items-center justify-center"
        >
          <img
            [src]="getImageSrc(image.thumbnail)"
            alt="Selected Image"
            class="object-cover transform rounded-lg"
            (click)="openLargeImageDisplayModal($event, image, 'view')"
          />
        </div>
      </div>
    </ng-container>
    <!-- END SELECTED IMAGES -->
  </div>

  <!-- IMAGE GALLERY BUTTON -->
  <button
    type="button"
    (click)="openImageGalleryModal($event)"
    class="btn-theme-outline flex justify-center items-center px-4 py-3 mt-4 xl:mt-0 xl:ml-4 w-auto self-start whitespace-nowrap"
    [disabled]="
      disabled ||
      imagesThumbnailsSourceLoading ||
      imagesThumbnailsSourceLoadingError
    "
  >
    <app-icon name="images" class="w-5"></app-icon>
    <span class="ml-1">Update my gallery</span>
  </button>
  <!-- END IMAGE GALLERY BUTTON -->
</div>

<!-- IMAGE GALLERY MODAL-->
<app-modal #imageGalleryModal (onClose)="onImageGalleryModalClosed()">
  <div
    class="mt-2 mb-4 py-6 px-12 border-gray-300 border border-solid rounded-lg bg-white bg-opacity-30"
  >
    <div>
      <div
        *ngFor="let image of imagesThumbnailsSource; let i = index"
        class="flex w-full mb-0 border-solid border-t-0 border-l-0 border-r-0 border-gray-300"
        [ngClass]="{
          'border-b': i != imagesThumbnailsSource.length - 1,
          'border-b-0': i == imagesThumbnailsSource.length - 1,
          'pt-3': i !== 0,
          'pb-3': i !== imagesThumbnailsSource.length - 1
        }"
      >
        <!-- THUMBNAIL PLACEHOLDER -->
        <app-icon
          *ngIf="!getImageSrc(image.thumbnail)"
          name="image"
          class="h-24 p-1"
          style="color: #979797"
          width="100%"
          height="100%"
          [ngClass]="{
            'opacity-50': imageSaving || imageDeleting
          }"
        ></app-icon>
        <!-- END THUMBNAIL PLACEHOLDER -->

        <!-- THUMBNAIL IMAGE -->
        <img
          *ngIf="getImageSrc(image.thumbnail)"
          [src]="getImageSrc(image.thumbnail)"
          alt="Image Gallery Thumbnail"
          class="my-auto w-24 max-h-24 rounded-sm object-contain"
          [ngClass]="{
            'opacity-50': imageSaving || imageDeleting
          }"
        />
        <!-- END THUMBNAIL IMAGE -->

        <!-- IMAGE DESCRIPTION -->
        <p
          *ngIf="!imageDeletionError || currentImageToBeDeleted !== image"
          class="flex-1 ml-6 italic truncate pt-5 text-gray-700"
          [ngClass]="{
            'opacity-50': imageSaving || imageDeleting
          }"
        >
          {{ image?.fileName || "No File Name" }}
        </p>
        <!-- END IMAGE DESCRIPTION -->

        <!-- IMAGE DELETION ERROR -->
        <p
          *ngIf="imageDeletionError && currentImageToBeDeleted === image"
          class="flex-1 ml-6 truncate pt-5 text-error-red"
        >
          {{ imageDeletionError }}
        </p>
        <!-- END IMAGE DELETION ERROR -->

        <!-- IMAGE ACTION BUTTONS -->
        <button
          class="btn-theme-outline h-full my-auto ml-4 p-4 flex items-center"
          type="button"
          (click)="openLargeImageDisplayModal($event, image, 'edit')"
          [disabled]="disabled || imageSaving || imageDeleting"
        >
          <app-icon name="edit" class="w-5"></app-icon>
          <span class="ml-1">Edit</span>
        </button>
        <button
          class="btn-red-outline h-full my-auto ml-4 p-4 flex items-center"
          type="button"
          (click)="onImageDeletionClicked($event, image)"
          [disabled]="disabled || imageSaving || imageDeleting"
        >
          <ng-container
            *ngIf="!imageDeleting || currentImageToBeDeleted !== image"
          >
            <app-icon name="delete" class="w-5"></app-icon>
            <span class="ml-1">Remove</span>
          </ng-container>
          <app-loading-spinner
            *ngIf="imageDeleting && currentImageToBeDeleted === image"
            class="opacity-75"
            text="Removing"
          ></app-loading-spinner>
        </button>
        <!-- END IMAGE ACTION BUTTONS -->
      </div>
    </div>
  </div>

  <!-- ADD NEW IMAGE BUTTON -->
  <app-stateful-button
    (click)="onAddNewImageClicked($event)"
    [disabled]="disabled || imageSaving || imageDeleting"
    [text]="'Add new image'"
    [textProgress]="'Adding new image'"
    [isInProgress]="imageSaving"
    [iconName]="'image'"
    [type]="'outline'"
    [errorMessage]="imageSavingError"
  >
  </app-stateful-button>
  <!-- END ADD NEW IMAGE BUTTON -->

  <!-- FILE INPUT -->
  <input
    #fileInputRef
    type="file"
    class="border-none my-auto flex-0 w-64"
    (change)="onFileSelected($event)"
    (cancel)="onCancelFilePicker($event)"
    accept="image/*"
    [class.is-invalid]="control.touched && control.errors"
    style="display: none"
    [disabled]="disabled"
  />
  <!-- END FILE INPUT -->
</app-modal>
<!-- END IMAGE GALLERY MODAL-->

<!--LARGE IMAGE DISPLAY MODAL-->
<app-modal #largeImageDisplayModal (onClose)="onLargeImageDisplayModalClosed()">
  <!-- STATEFUL LOADING SPINNER -->
  <app-stateful-loading-spinner
    *ngIf="imageFullSizeLoading || imageFullSizeLoadingError"
    spinnerText="Loading Image"
    [errorMessage]="imageFullSizeLoadingError"
  ></app-stateful-loading-spinner>
  <!-- END STATEFUL LOADING SPINNER -->

  <ng-container *ngIf="!imageFullSizeLoading && currentFullSizeImage">
    <!-- FULL SIZE IMAGE -->
    <img class="w-full" [src]="getImageSrc(currentFullSizeImage.file)" alt="" />
    <!-- END FULL SIZE IMAGE -->

    <!-- IMAGE DESCRIPTION EDITOR -->
    <div *ngIf="largeImageModalDisplayMode === 'edit'">
      <p>Write something about this image:</p>

      <input
        type="text"
        [(ngModel)]="currentEditableImageDescription"
        placeholder="e.g. description"
        [disabled]="disabled"
      />
    </div>
    <!-- END IMAGE DESCRIPTION EDITOR -->

    <!-- IMAGE DESCRIPTION VIEW -->
    <p
      *ngIf="largeImageModalDisplayMode === 'view'"
      [ngClass]="{
        italic: !currentFullSizeImage.description
      }"
    >
      {{ currentFullSizeImage.description || "No Description added." }}
    </p>
    <!-- END IMAGE DESCRIPTION VIEW -->

    <!-- IMAGE ACTION BUTTONS -->
    <div class="md:flex md:flex-row md:items-center mt-4">
      <button
        (click)="downloadImage(currentFullSizeImage)"
        class="btn-theme btn-sm px-12 mr-4"
        type="button"
        [ngClass]="{
          'btn-theme-outline': largeImageModalDisplayMode === 'edit'
        }"
      >
        Download Image
      </button>

      <button
        *ngIf="largeImageModalDisplayMode === 'view'"
        (click)="closeLargeImageDisplayModal()"
        type="button"
        class="btn-theme-outline btn-sm px-12 mt-4 md:mt-0"
      >
        Close
      </button>

      <app-stateful-button
        *ngIf="largeImageModalDisplayMode === 'edit'"
        (click)="onSaveImageDescriptionClicked($event, currentFullSizeImage)"
        [disabled]="disabled || imageSaving || imageDeleting"
        [text]="'Save & Close'"
        [textProgress]="'Saving'"
        [isInProgress]="imageSaving"
        [errorMessage]="imageSavingError"
        class="block mt-4 md:mt-0"
      >
      </app-stateful-button>
    </div>
    <!-- END IMAGE ACTION BUTTONS -->
  </ng-container>
</app-modal>
<!--END LARGE IMAGE DISPLAY MODAL-->

<!--IMAGE PICKER MODAL-->
<app-modal #imagePickerModal>
  <p>Click on an image from your gallery to update its selected status.</p>
  <div
    *ngIf="imagesThumbnailsSource && imagesThumbnailsSource.length > 0"
    class="flex-grow grid grid-cols-3 xs:grid-cols-4 sm:grid-cols-6 gap-4 w-full border-gray-300 border border-solid rounded-lg p-4"
  >
    <!-- ALL IMAGES -->
    <div
      *ngFor="let image of imagesThumbnailsSource"
      (click)="toggleImageSelection(image.imageId)"
      class="relative cursor-pointer"
    >
      <!-- IMAGE THUMBNAIL -->
      <div
        [ngClass]="{
          'outline outline-4 outline-theme scale-90': isImageSelected(
            image.imageId
          ),
          'scale-100': !isImageSelected(image.imageId)
        }"
        class="w-full aspect-square rounded-lg overflow-hidden flex items-center justify-center transition-transform duration-200"
      >
        <img
          [src]="getImageSrc(image.thumbnail)"
          alt="Thumbnail"
          class="object-cover transform rounded-lg"
        />
      </div>
      <!-- END IMAGE THUMBNAIL -->

      <!-- CHECKMARK -->
      <div
        *ngIf="isImageSelected(image.imageId)"
        class="absolute bottom-0 right-0 mb-2 mr-1 bg-primary text-theme rounded-full p-1"
      >
        <app-icon name="check-circle-fill" class="w-5"></app-icon>
      </div>
      <!-- END CHECKMARK -->
    </div>
    <!-- END ALL IMAGES -->
  </div>
  <p *ngIf="!imagesThumbnailsSource || imagesThumbnailsSource.length == 0">
    There are no images in your gallery. Please add some images to your gallery
    first.
  </p>
  <button
    (click)="closeImagePickerModal($event)"
    type="button"
    class="mt-4 btn-theme-outline btn-sm px-12"
  >
    Close
  </button>
</app-modal>

<!--END IMAGE PICKER MODAL-->
