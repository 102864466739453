<div
  class="flex gap-6 flex-wrap"
  [ngClass]="{ 'mb-2': values.length < 1, 'mb-8': values.length > 0 }"
>
  <div
    class="card bg-white rounded flex items-center max-w-xs [&_*]:text-ellipsis [&_*]:overflow-hidden"
    *ngFor="let val of values; let i = index"
  >
    <div class="bg-red-100" #dynamicComponentContainer></div>

    <ng-container
      *ngComponentOutlet="getDisplayComponent(); inputs: { value: val }"
    />

    <!-- Open edit modal (same as add but pre-filled and with different title + a delete button) -->
    <button
      class="btn-theme-outline text-theme btn-square p-2 my-auto ml-6 flex h-12 w-12 flex-shrink-0"
      type="button"
      (click)="openEditModal(i)"
      [disabled]="disabled"
    >
      <app-icon class="w-4 h-4 m-auto" name="edit"></app-icon>
    </button>

    <app-modal #editModal [modalId]="'edit-modal-' + item.key + '-' + i">
      <h2 class="text-3xl mb-2">{{ item.editTitle ?? "Edit" }}</h2>
      <app-form
        [formData]="item.subform"
        [formValues]="val"
        (submit)="editItem(i, $event)"
        [loading]="loading"
        (cancel)="closeEditModal(i)"
        submitText="Save Changes"
        class="-mb-8"
        #editForm
        [removable]="true"
        (remove)="removeItem(i)"
        [disabledForm]="disabled"
      />
      <!-- <button class="btn-red-outline" (click)="removeItem(item, i)">
          Delete
        </button> -->
    </app-modal>
  </div>
  <p
    class="opacity-30"
    *ngIf="values?.length === 0 && item?.placeholder.length > 0"
  >
    {{ item?.placeholder }}
  </p>
</div>

<ng-container
  *ngIf="
    item?.display == 'modal' &&
    (item?.multiple === true ||
      (item?.multiple === false && values.length === 0))
  "
>
  <button
    class="btn-theme-outline px-8 py-3 mt-2 mb-4"
    type="button"
    (click)="openAddModal(item.key)"
    [disabled]="disabled"
  >
    {{ item.addTitle ?? "Add" }}
  </button>
  <app-modal #addModal [modalId]="'add-modal-' + item.key">
    <h2 class="text-3xl mb-2">{{ item.addTitle ?? "Add" }}</h2>
    <!-- {{item.subformItems | json}} -->
    <app-form
      [formData]="item.subform"
      (submit)="addItem($event, item.key)"
      [loading]="loading"
      (cancel)="closeAddModal(item.key)"
      submitText="Add"
      [disabledForm]="disabled"
    />
  </app-modal>
</ng-container>

<!-- Inline subforms -->
<ng-container *ngIf="item?.display == 'inline'">
  <div
    class="p-12 border border-solid border-grey rounded-lg bg-white inline-subform"
    [class.is-invalid]="control.touched && control.errors"
  >
    <h2 class="text-xl mb-2 mt-0">{{ item.addTitle ?? "Add" }}</h2>
    <app-form
      [formData]="item.subform"
      (submit)="addItem($event, item, item.key)"
      [cancellable]="false"
      [loading]="loading"
      submitText="Add"
      [disabledForm]="disabled"
    />
  </div>
</ng-container>
