import { Validators } from '@angular/forms';
import { FormItemTextArea } from 'src/app/models/form/form';

export const importantThings = new FormItemTextArea({
  key: 'importantThings',
  path: 'health.important.importantThings',
  label: 'Tell us the things that are important to you',
  placeholder: 'e.g. Seeing family',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const importantPreferredLifestyle = new FormItemTextArea({
  key: 'importantPreferredLifestyle',
  path: 'health.important.preferredLifestyle',
  label: 'Your preferred lifestyle',
  placeholder: 'e.g. specific routines',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const importantSupportNetwork = new FormItemTextArea({
  key: 'importantSupportNetwork',
  path: 'health.important.supportNetwork',
  label: 'Your support network',
  placeholder: 'e.g. close relationships',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const importantAdditionalInfo = new FormItemTextArea({
  key: 'importantAdditionalInfo',
  path: 'health.important.additionalInfo',
  label:
    'Anything else you would like to share about what is important to you:',
  placeholder: 'e.g. pets',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});
