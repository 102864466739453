import {
  Component,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css'],
})
export class ModalComponent {
  active: Boolean = false;
  closing: Boolean = true; //Enabling a close transition

  @ViewChild('modal') modal: ElementRef = {} as ElementRef;

  @Input() modalId: string = '';
  @Output() onClose: EventEmitter<void> = new EventEmitter<void>();

  open() {
    this.active = true;
    setTimeout(() => {
      // Wait for the modal to be added to the DOM
      this.closing = false;

      // Reset scroll position to the top
      if (this.modal && this.modal.nativeElement) {
        this.modal.nativeElement.scrollTop = 0;
      }
    }, 0);
  }
  close() {
    this.closing = true;
    setTimeout(() => {
      //Wait for the transition to end
      this.active = false;
    }, 150);
    this.onClose.emit();
  }
  onWrapperClick(e: Event) {
    const { target } = e;
    if (
      target instanceof HTMLElement &&
      target.classList.contains('modal-wrapper')
    ) {
      this.close();
    }
  }
}
