import {
  FormItemButtonSelect,
  FormItemTextArea,
  FormItemTitle,
} from 'src/app/models/form/form';
import { yesNoOptions } from '../item-options';
import { Validators } from '@angular/forms';

export const fallsHadRecentFall = new FormItemButtonSelect({
  key: 'fallsHadRecentFall',
  path: 'support.falls.hadRecentFall',
  label: 'Have you had a fall recently (last 3 months)?',
  class: 'mb-5',
  options: yesNoOptions,
});

export const fallsRecentFallsFrequency = new FormItemButtonSelect({
  key: 'fallsRecentFallsFrequency',
  path: 'support.falls.recentFallsFrequency',
  label: 'How frequently have you fallen over the last 3 months?',
  class: 'mb-5',
  options: [
    { name: 'Just once', label: 'Just once' },
    { name: 'Several times', label: 'Several times' },
    { name: 'Most days', label: 'Most day' },
  ],
  displayConditions: [
    {
      key: 'fallsHadRecentFall',
      values: [true],
    },
  ],
});

export const fallsMostRecentFallDetailsTitle = new FormItemTitle({
  key: 'fallsMostRecentFallDetailsTitle',
  label:
    'Share more about your most recent fall to help your GP or another professional understand what happened:',
  class: 'mt-2 mb-5',
  titleType: 'h4',
});

export const fallsMostRecentFallDetails = new FormItemTextArea({
  key: 'fallsMostRecentFallDetails',
  path: 'support.falls.mostRecentFallDetails',
  label: 'Tell us what happened when you had a fall',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const fallsMostRecentFall = new FormItemTextArea({
  key: 'fallsMostRecentFallDetails',
  path: 'support.falls.mostRecentFallRecovery',
  label: 'How did you get up from the fall?',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const fallsMostRecentFallSoughtMedicalCare = new FormItemButtonSelect({
  key: 'fallsMostRecentFallSoughtMedicalCare',
  path: 'support.falls.mostRecentFallSoughtMedicalCare',
  label: 'Did you seek medical attention?',
  class: 'mb-5',
  options: yesNoOptions,
});

export const fallsMostRecentFallMedicalCareProvider = new FormItemButtonSelect({
  key: 'fallsMostRecentFallMedicalCareProvider',
  path: 'support.falls.mostRecentFallMedicalCareProvider',
  label: 'Who did you contact?',
  multiple: true,
  class: 'mb-5',
  options: [
    { name: 'GP', label: 'GP' },
    { name: 'Community nurse', label: 'Community nurse' },
    { name: 'Minor injuries unit', label: 'Minor injuries unit' },
    { name: 'A&E', label: 'A&E' },
    { name: 'Called an ambulance', label: 'Called an ambulance' },
  ],
  displayConditions: [
    {
      key: 'fallsMostRecentFallSoughtMedicalCare',
      values: [true],
    },
  ],
});

export const fallsPattern = new FormItemButtonSelect({
  key: 'fallsPattern',
  path: 'support.falls.pattern',
  label: 'Is there a pattern to your falls',
  class: 'mb-5',
  multiple: true,
  options: [
    { name: 'Usually in the same place', label: 'Usually in the same place' },
    {
      name: 'Usually around the same time of day',
      label: 'Usually around the same time of day',
    },
    { name: 'Rising from a chair', label: 'Rising from a chair' },
    { name: 'Rising from bed', label: 'Rising from bed' },
    {
      name: 'Getting in or out of the shower',
      label: 'Getting in or out of the shower',
    },
    {
      name: 'Getting in and out of the bath',
      label: 'Getting in and out of the bath',
    },
    { name: 'Entering the house', label: 'Entering the house' },
    { name: 'Exiting the house', label: 'Exiting the house' },
  ],
});

export const fallsPresumedCauses = new FormItemButtonSelect({
  key: 'fallsPresumedCauses',
  path: 'support.falls.presumedCauses',
  label: 'What do you think is causing you to fall?',
  multiple: true,
  class: 'mb-5',
  options: [
    { name: 'Dizziness', label: 'Dizziness' },
    { name: 'Medication', label: 'Medication' },
    { name: 'Hurrying', label: 'Hurrying' },
    { name: 'Rugs', label: 'Rugs' },
    { name: 'Feeling weak', label: 'Feeling weak' },
  ],
});
