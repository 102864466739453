<div class="max-w-4xl mx-auto">
  <h2>Form Testing</h2>
  <app-form
    [formData]="form"
    [formValues]="sampleFormValues"
    (submit)="handleSave($event)"
    submitText="Save"
    [cancellable]="false"
    [loading]="false"
  >
  </app-form>

  <h2 class="mb-4">Conditional form testing</h2>
  <app-form
    [formData]="conditionalForm"
    (submit)="handleSave($event)"
    submitText="Save"
    [cancellable]="false"
    [loading]="false"
  >
  </app-form>

  <h2 class="mb-6">Google Places Testing</h2>
  <app-form
    [formData]="addressForm"
    (submit)="handleSave($event)"
    submitText="Save"
    [cancellable]="false"
    [loading]="false"
  >
  </app-form>

  <!-- <app-form
    [formData]="form"
    (submit)="handleSave($event)"
    submitText="Save"
    [cancellable]="false"
    [loading]="false"
    >
    </app-form> -->
</div>
