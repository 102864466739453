import { Validators } from '@angular/forms';
import { FormItemCheckbox } from 'src/app/models/form/form';
import { environment } from 'src/environments/environment';

export const privacyPolicyUrl = `${environment.url}/privacy-policy`;

export const dataProcessingConsent = new FormItemCheckbox({
  label: `I consent to the processing of this data in accordance with
    Community Connections'
    <a class="text-theme underline" href="${privacyPolicyUrl}"
      >Privacy Policy</a
    >.`,
  key: 'dataProcessingConsent',
  class: 'mb-5',
  labelIsHtml: true,
  required: true,
  validators: [Validators.required],
});
