import { Injectable } from '@angular/core';
import { CookieConsent } from 'src/app/models/core/CookieConsent';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  // PROPERTIES

  private readonly COOKIE_CONSENT_KEY = 'cookieConsent';

  // CONSTRUCTOR

  constructor() {}

  // PUBLIC API

  // Cookie Consent

  getCookieConsent(): CookieConsent | undefined {
    let item = this.getItem(this.COOKIE_CONSENT_KEY) || undefined;

    if (!item) {
      return undefined;
    }

    return new CookieConsent(item);
  }

  setCookieConsent(value: CookieConsent | undefined): void {
    this.setItem(this.COOKIE_CONSENT_KEY, value);
  }

  removeCookieConsent(): void {
    this.removeItem(this.COOKIE_CONSENT_KEY);
  }

  // PRIVATE API

  private getItem(key: string): any {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : undefined;
  }

  private setItem(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  private removeItem(key: string): void {
    localStorage.removeItem(key);
  }
}
