<div class="mb-5 flex flex-col md:flex-row" [formGroup]="contactsFormGroup">
  <!-- CONTACTS SELECT -->
  <ng-select
    *ngIf="!contactsSourceLoadingError"
    [loading]="contactsSourceLoading"
    formControlName="select"
    [multiple]="item?.multiple"
    (change)="update()"
    [closeOnSelect]="false"
    [id]="item?.key"
    [placeholder]="item?.placeholder || 'Choose from your contacts'"
    [class.is-invalid]="control.touched && control.errors"
    class="form-control flex-grow w-full md:w-auto"
  >
    <!-- DROPDOWN OPTIONS -->
    <ng-option
      *ngFor="let contact of contactsSource"
      [value]="contact?.contactId"
    >
      <div class="flex my-auto">
        <app-icon
          *ngIf="!contact?.picture"
          name="person"
          class="h-4 rounded-sm p-1 bg-light-grey"
          style="color: #979797"
          width="100%"
          height="100%"
        ></app-icon>

        <img
          *ngIf="contact?.picture"
          [src]="getImageSrc(contact)"
          [alt]="contact.firstName + 's profile picture'"
          class="my-auto w-5 max-h-5 rounded-sm object-contain"
        />
        <div class="my-auto ml-2 flex">
          <p class="my-auto mr-2 font-semibold">
            {{
              contact?.firstName && contact?.lastName
                ? contact.firstName + " " + contact.lastName
                : contact?.firstName
                ? contact?.firstName
                : "No Name"
            }}
          </p>
        </div>
      </div>
    </ng-option>
    <!-- END DROPDOWN OPTIONS -->
  </ng-select>
  <!-- CONTACTS SELECT -->

  <!-- CONTACTS LOADING ERROR -->
  <p
    *ngIf="contactsSourceLoadingError"
    class="text-error-red form-error-text flex-grow w-full my-auto mt-2 border-solid border-gray-100 bg-gray-100 rounded-lg p-2"
  >
    {{ contactsSourceLoadingError }}
  </p>

  <!-- CONTACT OVERVIEW BUTTON -->
  <button
    type="button"
    (click)="openContactsOverviewModal($event)"
    class="btn-theme-outline flex justify-center items-center px-4 py-2 mt-4 md:mt-0 md:ml-4 w-auto"
    [disabled]="contactsSourceLoading || contactsSourceLoadingError || disabled"
  >
    <app-icon name="people" class="w-5"></app-icon>
    <span class="ml-1">Update my contacts</span>
  </button>
  <!-- END CONTACT OVERVIEW BUTTON -->
</div>

<!--CONTACTS OVERVIEW MODAL-->
<app-modal #contactsOverviewModal>
  <div
    class="mt-2 mb-4 py-6 px-12 border-gray-300 border border-solid rounded-lg bg-white bg-opacity-30"
  >
    <div>
      <div
        *ngFor="let contact of contactsSource; let i = index"
        class="flex w-full mb-0 border-solid border-t-0 border-l-0 border-r-0 border-gray-300"
        [ngClass]="{
          'border-b': i != contactsSource.length - 1,
          'border-b-0': i == contactsSource.length - 1,
          'pt-3': i !== 0,
          'pb-3': i !== contactsSource.length - 1
        }"
      >
        <app-icon
          *ngIf="!contact?.picture"
          name="person"
          class="h-12 rounded-sm p-1 bg-light-grey"
          style="color: #979797"
          width="100%"
          height="100%"
        ></app-icon>

        <img
          *ngIf="contact?.picture"
          [src]="getImageSrc(contact)"
          [alt]="contact.firstName + 's profile picture'"
          class="my-auto w-12 max-h-12 rounded-sm object-contain"
        />

        <p class="flex-1 ml-6 truncate">
          {{
            contact?.firstName && contact?.lastName
              ? contact.firstName + " " + contact.lastName
              : contact?.firstName
              ? contact?.firstName
              : "No Name"
          }}
        </p>
        <button
          class="btn-theme-outline h-full my-auto ml-4 p-4 flex items-center"
          type="button"
          (click)="onEditContactClicked($event, contact)"
          [disabled]="disabled"
        >
          <app-icon name="edit" class="w-5"></app-icon>
          <span class="ml-1">Edit</span>
        </button>
        <button
          class="btn-red-outline h-full my-auto ml-4 p-4 flex items-center"
          type="button"
          (click)="onContactDeletionClicked($event, contact)"
          [disabled]="disabled"
        >
          <app-icon name="delete" class="w-5"></app-icon>
          <span class="ml-1">Remove</span>
        </button>
      </div>
    </div>
  </div>

  <button
    class="btn btn-theme-outline p-4 mt-2 flex items-center"
    type="button"
    (click)="onAddNewContactClicked($event)"
    [disabled]="disabled"
  >
    <app-icon name="add-person" class="w-5"></app-icon>
    <span class="ml-1">Add new contact</span>
  </button>
</app-modal>
<!--END CONTACTS OVERVIEW MODAL-->

<!--CONTACT EDITOR MODAL-->
<app-modal #contactEditorModel (onClose)="onContactEditorClosed()">
  <div
    *ngIf="item?.contactEditorForm && item?.contactEditorForm.length"
    class="mt-8"
  >
    <app-form
      [formData]="item?.contactEditorForm"
      [formValues]="currentUserContact"
      (submit)="handleContactEditorFormSave($event)"
      [submitText]="contactEditorType === 'add' ? 'Add Contact' : 'Update'"
      [loading]="contactsSourceSaving"
      [loadingText]="contactEditorType === 'add' ? 'Adding' : 'Updating'"
      [success]="contactsSourceSavingSuccess"
      [successText]="contactEditorType === 'add' ? 'Added!' : 'Updated!'"
      [error]="contactsSourceSavingError"
      [cancellable]="true"
      [cancelText]="'Back'"
      (cancel)="handleContactEditorFormCancel($event)"
      [disabledForm]="
        disabled || (contactsSourceSavingSuccess && contactEditorType === 'add')
      "
    >
    </app-form>
    <div
      *ngIf="
        !contactsSourceSaving &&
        contactsSourceSavingSuccess &&
        contactEditorType === 'add'
      "
      class="text-theme my-auto mt-2 text-right"
    >
      Contact added successfully! Window will close shortly.
    </div>
  </div>
</app-modal>
<!--END CONTACT EDITOR MODAL-->

<!--CONTACT DELETION MODAL-->
<app-modal #contactDeletionModal (onClose)="onContactDeletionModalClosed()">
  <h3 class="mt-0">Proceed with removal?</h3>
  <p>
    Are you sure that you would like to remove this contact entry from your
    Personal Data Store?
  </p>

  <div class="md:flex md:flex-row md:items-center mt-4">
    <app-stateful-button
      (click)="onProceedContactDeletionClicked()"
      [disabled]="
        disabled || contactsSourceDeleting || contactsSourceDeletionSuccess
      "
      [text]="'Remove Contact'"
      [textProgress]="'Deleting'"
      [isInProgress]="contactsSourceDeleting"
    >
    </app-stateful-button>

    <button
      (click)="onCancelContactDeletionClicked()"
      type="button"
      [disabled]="contactsSourceDeleting || contactsSourceDeletionSuccess"
      class="btn btn-theme-outline p-4 md:ml-2 mt-2 md:mt-0"
    >
      Cancel
    </button>
  </div>

  <div
    *ngIf="!contactsSourceDeleting && contactsSourceDeletionError"
    class="text-error-red form-error-text my-auto mt-2"
  >
    {{ contactsSourceDeletionError }}
  </div>

  <div
    *ngIf="!contactsSourceDeleting && contactsSourceDeletionSuccess"
    class="text-theme my-auto mt-2"
  >
    Contact removed successfully! Window will close shortly.
  </div>
</app-modal>
<!--END CONTACT DELETION MODAL-->
