import { FileData } from '../base/fileData';
import { Address } from '../base/address';

export class UserSupport {
  communication?: UserSupportCommunication;
  medication?: UserSupportMedication;
  generalFitness?: UserSupportGeneralFitness;
  practicalHelp?: UserSupportPracticalHelp;
  movingAroundInside?: UserSupportMovingAroundInside;
  movingAroundOutside?: UserSupportMovingAroundOutside;
  gettingWashed?: UserSupportGettingWashed;
  personalAids?: UserSupportPersonalAids;
  continence?: UserSupportContinence;
  eatingAndDrinking?: UserSupportEatingAndDrinking;
  bedtime?: UserSupportBedtime;
  falls?: UserSupportFalls;
  digital?: UserSupportDigital;
  supportPackage?: UserSupportPackage;
  actionsList?: UserSupportActionsList;
  footCare?: UserSupportFootCare;
  otherAreasWithSupportNeed?: string;

  constructor(support?: Partial<UserSupport>) {
    this.communication = new UserSupportCommunication(support?.communication);
    this.medication = new UserSupportMedication(support?.medication);
    this.generalFitness = new UserSupportGeneralFitness(
      support?.generalFitness
    );
    this.practicalHelp = new UserSupportPracticalHelp(support?.practicalHelp);
    this.movingAroundInside = new UserSupportMovingAroundInside(
      support?.movingAroundInside
    );
    this.movingAroundOutside = new UserSupportMovingAroundOutside(
      support?.movingAroundOutside
    );
    this.gettingWashed = new UserSupportGettingWashed(support?.gettingWashed);
    this.personalAids = new UserSupportPersonalAids(support?.personalAids);
    this.continence = new UserSupportContinence(support?.continence);
    this.eatingAndDrinking = new UserSupportEatingAndDrinking(
      support?.eatingAndDrinking
    );
    this.bedtime = new UserSupportBedtime(support?.bedtime);
    this.falls = new UserSupportFalls(support?.falls);
    this.digital = new UserSupportDigital(support?.digital);
    this.supportPackage = new UserSupportPackage(support?.supportPackage);
    this.actionsList = new UserSupportActionsList(support?.actionsList);
    this.footCare = new UserSupportFootCare(support?.footCare);
    this.otherAreasWithSupportNeed = support?.otherAreasWithSupportNeed;
  }
}

class UserSupportCommunication {
  firstLanguage?: string;
  preferredTypes?: string[];
  preferredTypesAdditionalInfo?: string;
  generalAids?: string;
  signLanguages?: string[];
  signLanguagesAdditionalInfo?: string;
  hearingAids?: boolean;
  existingSupport?: string;
  generalAdditionalInfo?: string;

  constructor(data: Partial<UserSupportCommunication | undefined>) {
    this.firstLanguage = data?.firstLanguage;
    this.preferredTypes = data?.preferredTypes;
    this.preferredTypesAdditionalInfo = data?.preferredTypesAdditionalInfo;
    this.generalAids = data?.generalAids;
    this.signLanguages = data?.signLanguages;
    this.signLanguagesAdditionalInfo = data?.signLanguagesAdditionalInfo;
    this.hearingAids = data?.hearingAids;
    this.existingSupport = data?.existingSupport;
    this.generalAdditionalInfo = data?.generalAdditionalInfo;
  }
}

class UserSupportMedication {
  listOfMedications?: UserSupportMedicationItem[];

  constructor(data: Partial<UserSupportMedication | undefined>) {
    this.listOfMedications = data?.listOfMedications?.map(
      (r) => new UserSupportMedicationItem(r)
    );
  }
}

export class UserSupportMedicationItem {
  name: string;
  intakeTime?: string;
  intakeFrequency?: string;
  intakeMethod?: string;
  storageLocation?: string;
  pharmacy?: string;
  collectionMethod?: string;

  constructor(data: Partial<UserSupportMedicationItem | undefined>) {
    this.name = data?.name ?? '';
    this.intakeTime = data?.intakeTime;
    this.intakeFrequency = data?.intakeFrequency;
    this.intakeMethod = data?.intakeMethod;
    this.storageLocation = data?.storageLocation;
    this.pharmacy = data?.pharmacy;
    this.collectionMethod = data?.collectionMethod;
  }
}

class UserSupportGeneralFitness {
  selfAssessedLevel?: string;

  constructor(data: Partial<UserSupportGeneralFitness | undefined>) {
    this.selfAssessedLevel = data?.selfAssessedLevel;
  }
}

class UserSupportPracticalHelp {
  areasWithNoSupportNeed?: string[];
  areasWithSupportNeed?: string[];
  existingSupport?: string;
  generalAdditionalInfo?: string;

  constructor(data: Partial<UserSupportPracticalHelp | undefined>) {
    this.areasWithNoSupportNeed = data?.areasWithNoSupportNeed;
    this.areasWithSupportNeed = data?.areasWithSupportNeed;
    this.existingSupport = data?.existingSupport;
    this.generalAdditionalInfo = data?.generalAdditionalInfo;
  }
}

class UserSupportMovingAroundInside {
  areasWithNoSupportNeed?: string[];
  areasWithSupportNeed?: string[];
  areasWithSupportNeedAdditionalInfo?: string;
  aids?: string[];
  aidsAdditionalInfo?: string;
  stairsManagement?: string;
  unsteadinessOccurrences?: string;
  existingSupport?: string;

  constructor(data: Partial<UserSupportMovingAroundInside | undefined>) {
    this.areasWithNoSupportNeed = data?.areasWithNoSupportNeed;
    this.areasWithSupportNeed = data?.areasWithSupportNeed;
    this.areasWithSupportNeedAdditionalInfo =
      data?.areasWithSupportNeedAdditionalInfo;
    this.aids = data?.aids;
    this.aidsAdditionalInfo = data?.aidsAdditionalInfo;
    this.stairsManagement = data?.stairsManagement;
    this.unsteadinessOccurrences = data?.unsteadinessOccurrences;
    this.existingSupport = data?.existingSupport;
  }
}

class UserSupportMovingAroundOutside {
  areasWithNoSupportNeed?: string[];
  areasWithSupportNeed?: string[];
  areasWithSupportNeedAdditionalInfo?: string;
  aids?: string[];
  aidsAdditionalInfo?: string;
  unsteadinessOccurrences?: string;
  existingSupport?: string;
  generalAdditionalInfo?: string;

  constructor(data: Partial<UserSupportMovingAroundOutside | undefined>) {
    this.areasWithNoSupportNeed = data?.areasWithNoSupportNeed;
    this.areasWithSupportNeed = data?.areasWithSupportNeed;
    this.areasWithSupportNeedAdditionalInfo =
      data?.areasWithSupportNeedAdditionalInfo;
    this.aids = data?.aids;
    this.aidsAdditionalInfo = data?.aidsAdditionalInfo;
    this.unsteadinessOccurrences = data?.unsteadinessOccurrences;
    this.existingSupport = data?.existingSupport;
    this.generalAdditionalInfo = data?.generalAdditionalInfo;
  }
}

class UserSupportGettingWashed {
  methods?: string[];
  frequency?: string;
  times?: string;
  shavingDetails?: string;
  haircareDetails?: string;
  dentalDetails?: string;
  generalAdditionalInfo?: string;

  constructor(data: Partial<UserSupportGettingWashed | undefined>) {
    this.methods = data?.methods;
    this.frequency = data?.frequency;
    this.times = data?.times;
    this.shavingDetails = data?.shavingDetails;
    this.haircareDetails = data?.haircareDetails;
    this.dentalDetails = data?.dentalDetails;
    this.generalAdditionalInfo = data?.generalAdditionalInfo;
  }
}

class UserSupportPersonalAids {
  aidsInUse?: string[];
  aidsInUseAdditionalInfo?: string;

  constructor(data: Partial<UserSupportPersonalAids | undefined>) {
    this.aidsInUse = data?.aidsInUse;
    this.aidsInUseAdditionalInfo = data?.aidsInUseAdditionalInfo;
  }
}

class UserSupportContinence {
  productsInUse?: string;
  routines?: string;
  supportNeededInCaseOfEmergency?: string;
  inContactWithCareTeam?: boolean;
  careTeam?: UserSupportContinenceCareTeam;

  constructor(data: Partial<UserSupportContinence | undefined>) {
    this.productsInUse = data?.productsInUse;
    this.routines = data?.routines;
    this.supportNeededInCaseOfEmergency = data?.supportNeededInCaseOfEmergency;
    this.inContactWithCareTeam = data?.inContactWithCareTeam;
    this.careTeam = new UserSupportContinenceCareTeam(data?.careTeam);
  }
}

class UserSupportContinenceCareTeam {
  name?: string;
  phone?: string;
  email?: string;
  address?: Address;

  constructor(data: Partial<UserSupportContinenceCareTeam | undefined>) {
    this.name = data?.name;
    this.phone = data?.phone;
    this.email = data?.email;
    this.address = new Address(data?.address);
  }
}

class UserSupportEatingAndDrinking {
  diet?: string;
  favouriteMeal?: string;
  preferredFoodsAndDrinks?: string;
  avoidedFoodsAndDrinks?: string;
  areasWithNoSupportNeed?: string[];
  impairments?: string[];
  impairmentsAdditionalInfo?: string;
  equipmentOrPreferences?: string[];
  equipmentOrPreferencesAdditionalInfo?: string;
  generalAdditionalInfo?: string;
  preferredDiningLocation?: string;

  constructor(data: Partial<UserSupportEatingAndDrinking | undefined>) {
    this.diet = data?.diet;
    this.favouriteMeal = data?.favouriteMeal;
    this.preferredFoodsAndDrinks = data?.preferredFoodsAndDrinks;
    this.avoidedFoodsAndDrinks = data?.avoidedFoodsAndDrinks;
    this.areasWithNoSupportNeed = data?.areasWithNoSupportNeed;
    this.impairments = data?.impairments;
    this.impairmentsAdditionalInfo = data?.impairmentsAdditionalInfo;
    this.equipmentOrPreferences = data?.equipmentOrPreferences;
    this.equipmentOrPreferencesAdditionalInfo =
      data?.equipmentOrPreferencesAdditionalInfo;
    this.generalAdditionalInfo = data?.generalAdditionalInfo;
    this.preferredDiningLocation = data?.preferredDiningLocation;
  }
}

class UserSupportBedtime {
  preferredBedtime?: string;
  gettingToBedSupportNeeded?: boolean;
  gettingToBedAreasWithSupportNeed?: string[];
  gettingToBedEquipmentInUse?: string[];
  gettingToBedSupportAdditionalInfo?: string;
  gettingToBedSupportPhoto?: FileData;
  gettingToBedSupportPhotoTemporary?: string;
  gettingUpThroughNight?: string;
  gettingUpThroughNightSupportNeeded?: boolean;
  gettingUpSupportNeeded?: boolean;
  gettingUpAreasWithSupportNeed?: string[];
  gettingUpEquipmentInUse?: string[];
  gettingUpSupportAdditionalInfo?: string;

  constructor(data: Partial<UserSupportBedtime | undefined>) {
    this.preferredBedtime = data?.preferredBedtime;
    this.gettingToBedSupportNeeded = data?.gettingToBedSupportNeeded;
    this.gettingToBedAreasWithSupportNeed =
      data?.gettingToBedAreasWithSupportNeed;
    this.gettingToBedEquipmentInUse = data?.gettingToBedEquipmentInUse;
    this.gettingToBedSupportAdditionalInfo =
      data?.gettingToBedSupportAdditionalInfo;
    this.gettingToBedSupportPhoto = data?.gettingToBedSupportPhoto;
    this.gettingToBedSupportPhotoTemporary =
      data?.gettingToBedSupportPhotoTemporary;
    this.gettingUpThroughNight = data?.gettingUpThroughNight;
    this.gettingUpThroughNightSupportNeeded =
      data?.gettingUpThroughNightSupportNeeded;
    this.gettingUpSupportNeeded = data?.gettingUpSupportNeeded;
    this.gettingUpAreasWithSupportNeed = data?.gettingUpAreasWithSupportNeed;
    this.gettingUpEquipmentInUse = data?.gettingUpEquipmentInUse;
    this.gettingUpSupportAdditionalInfo = data?.gettingUpSupportAdditionalInfo;
  }
}

class UserSupportFalls {
  hadRecentFall?: boolean;
  recentFallsFrequency?: string;
  mostRecentFallDetails?: string;
  mostRecentFallRecovery?: string;
  mostRecentFallSoughtMedicalCare?: boolean;
  mostRecentFallMedicalCareProvider?: string[];
  pattern?: string[];
  presumedCauses?: string[];

  constructor(data: Partial<UserSupportFalls | undefined>) {
    this.hadRecentFall = data?.hadRecentFall;
    this.recentFallsFrequency = data?.recentFallsFrequency;
    this.mostRecentFallDetails = data?.mostRecentFallDetails;
    this.mostRecentFallRecovery = data?.mostRecentFallRecovery;
    this.mostRecentFallSoughtMedicalCare =
      data?.mostRecentFallSoughtMedicalCare;
    this.mostRecentFallMedicalCareProvider =
      data?.mostRecentFallMedicalCareProvider;
    this.pattern = data?.pattern;
    this.presumedCauses = data?.presumedCauses;
  }
}

class UserSupportDigital {
  technologySupportInUse?: string[];
  technologySupportInUseAdditionalInfo?: string;
  technologySupportPurposes?: string[];
  technologySupportPurposesAdditionalInfo?: string;
  mostUsedDevices?: string[];
  deviceMonitoredConditions?: string[];
  deviceMonitoredConditionsAdditionalInfo?: string;
  sharedRecordsInUse?: string[];
  sharedRecordsInUseAdditionalInfo?: string;

  constructor(data: Partial<UserSupportDigital | undefined>) {
    this.technologySupportInUse = data?.technologySupportInUse;
    this.technologySupportInUseAdditionalInfo =
      data?.technologySupportInUseAdditionalInfo;
    this.technologySupportPurposes = data?.technologySupportPurposes;
    this.technologySupportPurposesAdditionalInfo =
      data?.technologySupportPurposesAdditionalInfo;
    this.mostUsedDevices = data?.mostUsedDevices;
    this.deviceMonitoredConditions = data?.deviceMonitoredConditions;
    this.deviceMonitoredConditionsAdditionalInfo =
      data?.deviceMonitoredConditionsAdditionalInfo;
    this.sharedRecordsInUse = data?.sharedRecordsInUse;
    this.sharedRecordsInUseAdditionalInfo =
      data?.sharedRecordsInUseAdditionalInfo;
  }
}

class UserSupportPackage {
  constructor(data: Partial<UserSupportPackage | undefined>) {}
}

class UserSupportActionsList {
  ownTasks?: string;
  tasksWithSupportNeed?: string;
  urgentTasks?: string;

  constructor(data: Partial<UserSupportActionsList | undefined>) {
    this.ownTasks = data?.ownTasks;
    this.tasksWithSupportNeed = data?.tasksWithSupportNeed;
    this.urgentTasks = data?.urgentTasks;
  }
}

class UserSupportFootCare {
  routine?: string;
  problems?: string[];
  problemsAdditionalInfo?: string;
  wasTreatedByPodiatrist?: boolean;
  podiatristTreatmentDetails?: string;
  podiatristDetails?: string;

  constructor(data: Partial<UserSupportFootCare | undefined>) {
    this.routine = data?.routine;
    this.problems = data?.problems;
    this.problemsAdditionalInfo = data?.problemsAdditionalInfo;
    this.wasTreatedByPodiatrist = data?.wasTreatedByPodiatrist;
    this.podiatristTreatmentDetails = data?.podiatristTreatmentDetails;
    this.podiatristDetails = data?.podiatristDetails;
  }
}
