import {
  ContentBlock,
  ContentBlockConnections,
  ContentBlockServiceModule,
  ContentBlockUndefined,
  ContentBlockDetailsBase,
  ContentBlockDetailsTextOnly,
} from './ContentBlock';

export class ContentHolder {
  contentBlocks: ContentBlock<any>[];

  constructor(data: Partial<ContentHolder>) {
    this.contentBlocks = mapContentBlocks(data?.contentBlocks);
  }

  get allContentBlocksConnections(): ContentBlockConnections[] {
    return this.contentBlocks.filter(
      (c) => c.type === 'connections'
    ) as ContentBlockConnections[];
  }

  get amountOfActiveContentBlocks(): number {
    return this.contentBlocks.filter((c) => !c.isDeleted).length;
  }
}

// HELPER FUNCTIONS

function mapContentBlocks(blocks?: ContentBlock<any>[]): ContentBlock<any>[] {
  if (!blocks) {
    return [];
  }

  blocks = blocks.map((c) => {
    switch (c.type) {
      case 'connections':
        return new ContentBlockConnections(c);
      case 'serviceModule':
        return new ContentBlockServiceModule(c);
      case 'detailsBase':
        return new ContentBlockDetailsBase(c);
      case 'detailsTextOnly':
        return new ContentBlockDetailsTextOnly(c);
      default:
        return new ContentBlockUndefined(c);
    }
  });

  return blocks.sort((a, b) => a.orderId - b.orderId);
}
