export function getImageMimeTypeFromBase64String(
  base64String?: string
): string | undefined {
  if (!base64String) {
    return undefined;
  }

  // Heuristic based on the starting characters of the base64 string
  if (base64String.startsWith('/9j')) {
    return 'jpeg'; // JPEG/JPG
  } else if (base64String.startsWith('iVBORw0KGgo')) {
    return 'png'; // PNG
  } else if (base64String.startsWith('UklGR')) {
    return 'webp'; // WebP
  } else if (
    base64String.startsWith('AAAAIGZ0eXBIZWlj') ||
    base64String.startsWith('AAAAGGZ0eXBmZWhl')
  ) {
    return 'heic'; // HEIC/HEIF
  }

  return undefined;
}
