<input
  [id]="item.key"
  [type]="item.type"
  [placeholder]="item.placeholder"
  class="form-control transition-colors"
  [autocomplete]="item.autocomplete"
  [place]="item.place"
  [disabled]="disabled || loading"
  [class.is-invalid]="isInvalid"
  *ngIf="!item.hide"
  (input)="update($event)"
  [(ngModel)]="addressValue"
  #addressInput
/>
