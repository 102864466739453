<!-- <p class="opacity-75">Register for PDS</p> -->

<div class="flex-1 max-h-screen overflow-hidden">
  <img
    src="/assets/img/auth-bg.jpg"
    alt=""
    class="w-full h-full object-cover object-center"
  />
  <!-- <div class="gradient-b">&nbsp;</div> -->
  <h2
    class="text-4xl text-white absolute bottom-0 left-0 p-16 m-0 max-w-[200px] hidden lg:block"
  >
    Start your story now
  </h2>
</div>

<div class="h-full ml-auto bg-white lg:max-w-2xl w-full flex flex-col">
  <div class="flex-1 h-full flex w-full px-6 sm:px-12 md:px-24 box-border">
    <div class="my-auto w-full box-borde">
      <h1 class="text-4xl font-semibold text-dark mb-2">Get Started</h1>
      <p class="mb-8 text-grey">
        Fill in the details below and we'll create a Mydex account for you.
      </p>

      <app-form
        class="w-full pb-20"
        [formData]="form"
        [formValues]="user"
        (submit)="submit($event)"
        submitText="Create Mydex Account"
        loadingText="Creating"
        [success]="success"
        successText="Account Created"
        [loading]="loading"
        [error]="error"
        [inlineSubmitError]="false"
        [cancellable]="false"
      >
        <p class="mb-8"></p>
      </app-form>

      <a
        class="text-theme text-left mt-8 mb-4 block"
        routerLink="/register/intro"
        >What are <strong>About Me</strong> & Mydex?</a
      >
    </div>
  </div>

  <div class="auth-footer lg:max-w-2xl ml-auto w-full">
    <p class="text-center">
      Already have an account? <br />
      <a class="text-theme" routerLink="/login">Sign-in</a>
    </p>
  </div>
</div>
