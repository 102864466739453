import { Component } from '@angular/core';
import { CookieConsentService } from 'src/app/services/cookie-consent/cookie-consent.service';

@Component({
  selector: 'app-cookie-notice',
  templateUrl: './cookie-notice.component.html',
  styleUrls: ['./cookie-notice.component.css'],
})
export class CookieNoticeComponent {
  constructor(protected cookieConsentService: CookieConsentService) {}

  acceptAllCookies() {
    this.cookieConsentService.createCookieConsentGeneral(true);
  }

  acceptOnlyEssentialCookies() {
    this.cookieConsentService.createCookieConsentGeneral(false);
  }
}
