import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  AuthModule,
  EventTypes,
  OidcSecurityService,
  PublicEventsService,
} from 'angular-auth-oidc-client';
import { tap, catchError, map, filter } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SessionStorageService } from '../session-storage/session-storage.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private isAuthenticatedSubject = new BehaviorSubject<boolean | undefined>(
    undefined
  );
  isAuthenticated$ = this.isAuthenticatedSubject.asObservable();

  private isAdminSubject = new BehaviorSubject<boolean | undefined>(undefined);
  isAdmin$ = this.isAdminSubject.asObservable();

  constructor(
    private _http: HttpClient,
    private oidcSecurityService: OidcSecurityService,
    private eventService: PublicEventsService,
    private router: Router,
    private sessionStorageService: SessionStorageService
  ) {
    this.initializeAuthState();
  }

  private initializeAuthState(): void {
    this.oidcSecurityService.checkAuth().subscribe(); //init oidc module

    //Wait for the oidc module to finish checking auth before verifying the token with our api
    this.eventService
      .registerForEvents()
      .pipe(
        filter(
          (notification) =>
            notification.type === EventTypes.CheckingAuthFinished
        )
      )
      .pipe(
        tap(() => {
          this.verifyAuthStatus(); // Verify with api
          this.verifyAdminStatus(); // Also verify admin status
        })
      )
      .subscribe();
  }

  private verifyAuthStatus(): void {
    // Verify auth status via api
    this._http
      .get(`${environment.apiBaseUrl}/auth/test-authorized`)
      .pipe(
        tap(() => this.isAuthenticatedSubject.next(true)),
        catchError(() => {
          this.isAuthenticatedSubject.next(false);
          return of(false); // Swallow the error to keep the stream alive
        })
      )
      .subscribe();
  }

  private verifyAdminStatus(): void {
    // Verify admin via api
    this._http
      .get<boolean>(`${environment.apiBaseUrl}/auth/test-admin-authorized`)
      .pipe(
        tap(() => {
          return this.isAdminSubject.next(true);
        }),
        catchError(() => {
          this.isAdminSubject.next(false);
          return of(false); // Swallow the error to keep the stream alive
        })
      )
      .subscribe();
  }

  login(): void {
    this.oidcSecurityService.authorize();
  }

  logout() {
    this.oidcSecurityService.getIdToken().subscribe((token) => {
      // ID token check to see if a full oidc logout can be done
      if (token) {
        this.oidcSecurityService.logoff().subscribe(); // proper oidc logout
        this.sessionStorageService.clearStorageForLogout();
      } else {
        this.oidcSecurityService.logoffLocal(); // no id token so just clear the auth state locally
        this.isAuthenticatedSubject.next(false);
      }
    });
  }

  //Register method, taking a username, email and password and POSTing to /pds/register
  register(mydexid: string, email: string, password: string): Observable<any> {
    let returnTo = `${environment.url}/register/setup/record`;

    return this._http
      .post(`${environment.apiBaseUrl}/pds/register`, {
        mydexid,
        email,
        password,
        returnTo,
      })
      .pipe(
        map((response: any) => {
          this.sessionStorageService.setRegistrationInProgress(true);
          this.sessionStorageService.setMydexPrivateKeyCreationUrl(
            response?.url
          );
          this.sessionStorageService.mergeUserData({
            me: { contact: { email } },
          });
          return response;
        })
      );
  }
}
