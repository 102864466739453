import * as _ from 'lodash';
import { Connection } from '../models/connection/connection';
import { User } from '../models/user/user';
import { AboutMeSection } from '../models/about-me/aboutMeSection';

export function computeCompletedRequiredReferralFormItemsPercentage(
  user: User,
  connection: Connection
): number {
  // total amount of form items
  const totalAmountOfFormItems = connection.allRequiredFormItemPaths.length;
  if (totalAmountOfFormItems === 0) {
    return 0;
  }

  // number of completed form items
  const completedItems = computeNumberOfCompletedItems(
    connection.allRequiredFormItemPaths,
    user
  );

  // calculate percentage
  return calculatePercentage(completedItems, totalAmountOfFormItems);
}

export function computeCompletedAboutMeSectionItemsPercentage(
  user: User | undefined,
  section: AboutMeSection
): number {
  if (!user || !section) {
    return 0;
  }

  // get all form item functions from the section and all children
  const formItemsFunctions: Function[] = [];

  function getFormItemFunctions(currentSection: AboutMeSection) {
    if (currentSection.getFormItems) {
      formItemsFunctions.push(currentSection.getFormItems);
    }

    if (currentSection.children) {
      currentSection.children.forEach((child) => getFormItemFunctions(child));
    }
  }

  getFormItemFunctions(section);

  // get all form items from the section and all children
  let formItems: any[] = [];
  formItemsFunctions.forEach((func) => {
    let newFormItems = func();
    formItems = formItems.concat(newFormItems);
  });

  // filter out form items that do not have a path set (titles etc.)
  formItems = formItems.filter((formItem) => formItem.path);

  // calculate the total number of form items with relevant paths
  const totalAmountOfFormItems = formItems.length;

  // map to get an array of just the paths
  let formItemPaths = formItems.map((formItem) => formItem.path);

  // number of completed form items
  const completedItems = computeNumberOfCompletedItems(formItemPaths, user);

  // calculate percentage
  return calculatePercentage(completedItems, totalAmountOfFormItems);
}

function computeNumberOfCompletedItems(
  formItemPaths: any[],
  user: User
): number {
  let completedItems: number = 0;

  formItemPaths.forEach((path) => {
    const value = _.get(user, path, undefined);
    if (value === undefined || value === null || value === '') {
      return;
    }
    if (Array.isArray(value) && value.length === 0) {
      return;
    }
    completedItems++;
  });

  return completedItems;
}

function calculatePercentage(
  completedItems: number,
  totalAmountOfItems: number
) {
  let completedPercentage = 100 * (completedItems / totalAmountOfItems);

  if (completedPercentage) {
    completedPercentage = Math.floor(completedPercentage);
  } else {
    completedPercentage = 0;
  }

  return completedPercentage;
}
