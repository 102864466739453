import { Component, ViewChild } from '@angular/core';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { UserEventsService } from 'src/app/services/user.event.service';
import { UserStateService } from 'src/app/services/user.state.service';

@Component({
  selector: 'app-carer',
  templateUrl: './carer.component.html',
  styleUrls: ['./carer.component.css'],
})
export class CarerComponent {
  @ViewChild(ModalComponent, { static: true }) modal: ModalComponent =
    {} as ModalComponent;
  completedBefore?: string | false = false;

  constructor(
    private eventService: UserEventsService,
    private userService: UserStateService
  ) {}

  ngOnInit(): void {
    this.eventService
      .checkEventCompletion({ type: 'referral', context: 'quarriers' })
      .subscribe((result) => {
        if (result) {
          this.completedBefore = result; // result should be the formatted date string
        }
      });
  }
}
