import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-button-select',
  templateUrl: './button-select.component.html',
  styleUrls: ['./button-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ButtonSelectComponent,
    },
  ],
})
export class ButtonSelectComponent implements ControlValueAccessor {
  @Input() options!: any[];
  @Input() classes: string = '';
  @Input() styleType: string = 'button';
  @Input() multiple: boolean = false;
  @Input() disabled = false;
  @Input() requireOneSelection: boolean = false; // Default to false to allow deselection

  allSelectedValues: any[] = [];
  onChange = (selected: any) => {};
  onTouched = () => {};
  touched = false;

  constructor() {
    this.allSelectedValues = [];
  }

  update(value: any) {
    // filter out any null or undefined or empty values
    this.allSelectedValues = this.allSelectedValues.filter(
      (x) => x !== null && x !== undefined && x !== ''
    );

    // Check if the value was already selected (= is now tried to be unselected)
    const wasSelectedBefore: boolean = this.allSelectedValues?.some(
      (x) => x === value
    );

    // Check if more than one value is selected
    const severalSelected: boolean = this.allSelectedValues.length > 1;

    // Value was selected before (= is now tried to be unselected)
    if (wasSelectedBefore) {
      // If at least one value is required to be selected and no value is selected otherwise, return
      if (this.requireOneSelection && !severalSelected) {
        return;
      }
      // Otherwise deselect the value if already selected
      this.allSelectedValues = this.allSelectedValues.filter(
        (x) => x !== value
      );
      // Value was not selected before (= is now tried to be initially selected)
    } else {
      // Reset selection first if multiple selections are not allowed
      if (!this.multiple) {
        this.allSelectedValues = [];
      }
      // Select the new value
      this.allSelectedValues.push(value);
    }

    this.markAsTouched();
    this.onChange(
      this.multiple ? this.allSelectedValues : this.allSelectedValues[0]
    );
  }

  writeValue(selected: any) {
    this.allSelectedValues = [];

    if (Array.isArray(selected)) {
      this.allSelectedValues = selected;
    } else {
      this.allSelectedValues = [selected];
    }

    // filter out any null or undefined values or empty values
    this.allSelectedValues = this.allSelectedValues.filter(
      (x) => x !== null && x !== undefined && x !== ''
    );
  }

  checkActive(value: any) {
    if (Array.isArray(this.allSelectedValues)) {
      return this.allSelectedValues?.some((x) => x === value);
    }
    return false;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
}
