import { FormItemDropdown } from 'src/app/models/form/form';

export const generalFitnessSelfAssessedLevel = new FormItemDropdown({
  key: 'generalFitnessSelfAssessedLevel',
  path: 'support.generalFitness.selfAssessedLevel',
  label: 'What do you think about your general fitness and support needs?',
  class: 'mb-5',
  placeholder: 'Please select...',
  options: [
    {
      name: '1',
      label: 'I am fit for my age, exercise regularly.',
    },
    {
      name: '2',
      label: 'I keep well, exercise now and then.',
    },
    {
      name: '3',
      label:
        'I have health issues which slow me down but I manage without help.',
    },
    {
      name: '4',
      label:
        'I have health issues and now need help with shopping and housework.',
    },
    {
      name: '5',
      label:
        "I need help with home tasks and bathing, and I don't go out alone.",
    },
    {
      name: '6',
      label:
        'I require daily assistance with waking up, dressing, and meal preparation.',
    },
  ],
});
