<div class="mx-auto container-xl">
  <!-- BREADCRUMB -->
  <nav aria-label="breadcrumb" *ngIf="breadcrumbSegments.length > 1">
    <ul style="list-style-type: none; padding: 0">
      <li
        *ngFor="let segment of breadcrumbSegments; index as i"
        style="display: inline"
      >
        <span class="ms-2 me-1" *ngIf="i > 0">/</span>
        <a (click)="routeToNode(segment)" class="cursor-pointer p-0 border-0">
          {{ segment | titlecase }}</a
        >
      </li>
    </ul>
  </nav>
  <!-- END BREADCRUMB -->

  <!-- DISPLAY OF CHILDREN OF CURRENT NODE -->
  <ng-container *ngIf="!currentNode || currentNode.childNodes.length > 0">
    <h1>{{ currentNode?.followUpQuestion }}</h1>
    <div class="grid gap-x-12 gap-y-8 md:grid-cols-2 lg:grid-cols-3 mt-10">
      <a
        [routerLink]="urlPath + '/' + childNode.name"
        *ngFor="let childNode of currentNode?.childNodes"
      >
        <div class="card box-border bg-white mt-2 h-full">
          <div class="h-12 mb-8">
            <img
              *ngIf="childNode.icon"
              [src]="childNode.icon"
              alt=""
              class="icon"
            />
          </div>

          <h3 class="text-2xl text-theme font-medium mb-2">
            {{ childNode.title }}
          </h3>
          <p *ngIf="childNode.description" class="m-0">
            {{ childNode.description }}
          </p>
        </div>
      </a>
    </div>
  </ng-container>

  <!-- END DISPLAY OF CHILDREN OF CURRENT NODE -->

  <!-- DISPLAY CONNECTIONS OF FINAL NODE -->
  <ng-container *ngIf="currentNode && currentNode.childNodes.length == 0">
    <h1>Available Connections</h1>
    <!-- CONNECTIONS -->
    <app-connections
      [connections]="connections"
      errorMessageNoConnections="No connections were found for this path. Please try again later."
      (connectionOpened)="handleConnectionOpened($event)"
      (signpostClicked)="handleSignpostClicked($event)"
    >
    </app-connections>
    <!-- END CONNECTIONS -->

    <!-- BACK BUTTON -->
    <button
      class="btn-theme-outline outlined mt-12 px-12"
      (click)="routeToNode('')"
    >
      Back to Start
    </button>
    <!-- END BACK BUTTON -->
  </ng-container>
</div>

<!-- END DISPLAY CONNECTIONS OF FINAL NODE -->
