import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Connection } from 'src/app/models/connection/connection';
import { ConnectionsStateService } from 'src/app/services/connections.state.service';
import { User } from 'src/app/models/user/user';
import { UserStateService } from 'src/app/services/user.state.service';
import * as _ from 'lodash';
import { NavigationService } from 'src/app/services/navigation.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { FinderService } from 'src/app/services/finder/finder.service';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { UserEventsService } from 'src/app/services/user.event.service';
import { Subject, takeUntil } from 'rxjs';
import { AnalyticsService } from 'src/app/services/analytics.service';

@Component({
  selector: 'app-connection',
  templateUrl: './connection.component.html',
  styleUrls: ['./connection.component.css'],
})
export class ConnectionComponent {
  // PROPERTIES

  @ViewChild(ModalComponent, { static: true }) modal: ModalComponent =
    {} as ModalComponent;

  protected connection?: Connection;
  protected user: User | undefined;

  protected completedReferralBefore: string | false = false;

  private referralSubjectType: 'me' | 'them' | undefined =
    this.finderService.audience == 'them' ? 'them' : 'me';
  private destroy$: Subject<void> = new Subject<void>();

  // GETTERS

  get referralCta() {
    switch (this.referralSubjectType) {
      case 'me':
        return 'Refer me';
      case 'them':
        return 'Refer them';
      default:
        return 'Get referred';
    }
  }

  // CONSTRUCTOR

  constructor(
    protected userService: UserStateService,
    protected connectionsStateService: ConnectionsStateService,
    protected navigationService: NavigationService,
    protected authService: AuthService,
    protected finderService: FinderService,
    protected eventService: UserEventsService,
    private route: ActivatedRoute,
    private router: Router,
    private analyticsService: AnalyticsService
  ) {}

  // LIFECYCLE HOOKS

  ngOnInit() {
    this.connectionsStateService.connections$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        let connectionName = this.route.snapshot.paramMap.get('slug') || '';
        this.connection =
          this.connectionsStateService.getConnectionByName(connectionName);
      });

    this.userService.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.user = user;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();

    this.userService.resetErrors();
    this.connectionsStateService.resetErrors();
  }

  // METHODS

  handleEmailReferralClicked() {
    this.eventService
      .checkEventCompletion({
        type: 'referral',
        context: this.finderService.audience,
        connection: this.connection?.name,
      })
      .subscribe((result) => {
        if (result) {
          this.completedReferralBefore = result; // result should be the formatted date string
          this.modal.open();
        } else {
          this.navigateToReferral();
        }
      });
  }

  handleHyperlinkClicked(hyperlinkType: 'website' | 'facebook') {
    this.analyticsService.trackConnectionHyperlinkClicked(
      this.connection?.name ?? 'connection-name-unknown',
      hyperlinkType
    );
  }

  handleRedirectReferralClicked() {
    if (this.connection?.redirectOutput?.destinationUrl) {
      this.analyticsService.trackRedirectReferralStarted(
        this.connection?.name ?? 'connection-name-unknown'
      );

      window.location.href = this.connection?.redirectOutput?.destinationUrl;
    }
  }

  navigateToReferral() {
    this.analyticsService.trackEmailReferralStarted(
      this.connection?.name ?? 'connection-name-unknown'
    );
    this.router.navigate([this.router.url + '/referral']);
  }
}
