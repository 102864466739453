<div
  class="mt-2 p-4 border-gray-300 border border-solid rounded-lg mb-4"
  [class]="containerClass"
  *ngIf="formArray"
>
  <div>
    <div
      *ngFor="let subitem of formArray.controls; let i = index"
      class="flex w-full mb-0 pb-2 border-solid border-t-0 border-l-0 border-r-0 border-gray-300"
      [ngClass]="{
        'border-b': i != formArray.controls.length - 1,
        'border-b-0': i == formArray.controls.length - 1,
        'pt-2': !item.showLabels && i !== 0
      }"
    >
      <app-form
        (update)="updateValue($event, i)"
        [noSubmit]="true"
        [formData]="item.formItems"
        [formValues]="subitem.value"
        [disabledForm]="disabled"
        [loading]="loading"
        class="flex-1"
      ></app-form>
      <button
        class="btn-red-outline h-full my-auto ml-4 px-6"
        type="button"
        *ngIf="!subitem.value.disabled"
        (click)="onRemoveItem(i)"
        [disabled]="disabled"
      >
        X
      </button>
    </div>
  </div>
  <button
    class="btn btn-theme-outline px-6 py-2 mt-2"
    type="button"
    (click)="onAddItem()"
    [disabled]="disabled"
  >
    Add new
  </button>
</div>
