import {
  AboutMeSection,
  flattenAboutMeSections,
} from 'src/app/models/about-me/aboutMeSection';
import { keyInfoSection } from './keyInfoSection';
import { healthSection } from './healthSection';
import { supportSection } from './supportSection';
import { planningAheadSection } from './planningAheadSection';
import { thisIsMeSection } from './thisIsMeSection';
import { supportingRoleSection } from './supportingRoleSection';

export const aboutMeSections: AboutMeSection[] = [
  thisIsMeSection,
  keyInfoSection,
  healthSection,
  supportingRoleSection,
  supportSection,
  planningAheadSection,
];

export const aboutMeSectionsIncludingTheirChildren =
  flattenAboutMeSections(aboutMeSections);
