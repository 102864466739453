<div class="container-xl">
  <!--LOADING SPINNER-->
  <app-loading-spinner
    *ngIf="(userService.loading$ | async) || (userService.saving$ | async)"
  ></app-loading-spinner>
  <!--END LOADING SPINNER-->

  <!-- SERVER ERROR: FAILED LOADING USER -->
  <app-no-items-error
    [isDisplayed]="
      !(userService.loading$ | async) && (userService.loadingError$ | async)
    "
    [errorMessage]="userService.loadingError$ | async"
  >
  </app-no-items-error>
  <!-- END SERVER ERROR: FAILED LOADING USER -->

  <!--CONTENT-->
  <ng-container
    *ngIf="
      !(userService.loading$ | async) &&
      !(userService.saving$ | async) &&
      !(userService.loadingError$ | async)
    "
  >
    <h1>
      {{
        user?.me?.personalDetails?.firstName
          ? "Hi " + user?.me?.personalDetails?.firstName + ", "
          : "About Me"
      }}
    </h1>
    <p class="text-lg">
      Thank you for setting up your <strong>About Me</strong>. It's a useful
      place to help access services and organise your information.
    </p>

    <div
      class="about-me-sections w-full mt-20 grid gap-16 lg:grid-cols-2 2xl:grid-cols-3 3xl:grid-cols-4 justify-start"
    >
      <a
        *ngFor="let section of sections"
        [routerLink]="'/account/about-me/' + section.routePath"
        routerLinkActive="router-link-active"
        class="card bg-white flex-col flex"
      >
        <h2 class="text-theme m-0 text-xl">{{ section.title }}</h2>
        <p class="mt-2 mb-10 text-lg">{{ section.description }}</p>

        <app-piechart
          [width]="170"
          [value]="computeCompletedPercentageForSection(section)"
        >
          <span class="font-light text-center text-base"
            >{{ computeCompletedPercentageForSection(section) }}% <br />
            Complete</span
          >
        </app-piechart>
      </a>
    </div>
  </ng-container>
  <!--END CONTENT-->
</div>
