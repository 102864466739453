import { Component, ElementRef, ViewChild } from '@angular/core';
import Player from '@vimeo/player';
import { Subject, takeUntil } from 'rxjs';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { CookieConsentService } from 'src/app/services/cookie-consent/cookie-consent.service';
import { SessionStorageService } from 'src/app/services/session-storage/session-storage.service';
import { communityConnectionsIntroVimeoUrl } from 'src/assets/video/videoUrls';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.css'],
})
export class RegisterIntroComponent {
  @ViewChild(ModalComponent, { static: true }) modal: ModalComponent =
    {} as ModalComponent;
  @ViewChild('vimeoPlayer') vimeoPlayer?: ElementRef;

  player?: Player;
  private readonly _vimeoUrl = communityConnectionsIntroVimeoUrl;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private sessionStorageService: SessionStorageService,
    private cookieConsentService: CookieConsentService
  ) {}

  ngOnInit() {
    this.sessionStorageService.setShownRegistrationOnboarding(true);
  }

  ngAfterViewInit(): void {
    this.cookieConsentService.cookieConsent$
      .pipe(takeUntil(this.destroy$))
      .subscribe((cookieConsent) => {
        if (cookieConsent?.consentedVimeo) {
          this.player = new Player(this.vimeoPlayer?.nativeElement, {
            url: this._vimeoUrl,
            responsive: true,
          });
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openModal() {
    this.modal.open();
  }
}
