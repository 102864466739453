import { Component } from '@angular/core';

@Component({
  selector: 'app-account-services',
  templateUrl: './account-services.component.html',
  styleUrls: ['./account-services.component.css'],
})
export class AccountServicesComponent {
  tabOptions = [
    { name: "Services you've accessed", value: 'accessed' },
    { name: 'Recommended services', value: 'recommended' },
    { name: 'Saved services', value: 'saved' },
  ];

  changeTab(value: string) {}
}
