import {
  FormItemButtonSelect,
  FormItemDropdown,
  FormItemTextArea,
} from 'src/app/models/form/form';
import { yesNoOptions } from '../item-options';
import { Validators } from '@angular/forms';

export const homeImpactCaringAffectsLivingEnvironment =
  new FormItemButtonSelect({
    key: 'homeImpactCaringAffectsLivingEnvironment',
    label: 'Caring impacts my living environment',
    path: 'supportingRole.homeImpact.caringAffectsLivingEnvironment',
    class: 'mb-5',
    options: yesNoOptions,
  });

export const homeImpactCaringAffectsLivingEnvironmentDetails =
  new FormItemTextArea({
    key: 'homeImpactCaringAffectsLivingEnvironmentDetails',
    path: 'supportingRole.homeImpact.caringAffectsLivingEnvironmentDetails',
    label: 'How it impacts',
    placeholder:
      "e.g. perhaps you've had to change the use of a room, or restrict use to only part of the house, you may have to store and use equipment and aids...",
    class: 'mb-5',
    validators: [Validators.minLength(1), Validators.maxLength(2000)],
    displayConditions: [
      {
        key: 'homeImpactCaringAffectsLivingEnvironment',
        values: [true],
      },
    ],
  });

export const homeImpactHomeIsSuitableForCaredForPerson =
  new FormItemButtonSelect({
    key: 'homeImpactHomeIsSuitableForCaredForPerson',
    label: "The person I care for's home is suitable for their needs",
    path: 'supportingRole.homeImpact.homeIsSuitableForCaredForPerson',
    class: 'mb-5',
    options: yesNoOptions,
  });

export const homeImpactHomeIsSuitableForCaredForPersonDetails =
  new FormItemTextArea({
    key: 'homeImpactHomeIsSuitableForCaredForPersonDetails',
    path: 'supportingRole.homeImpact.homeIsSuitableForCaredForPersonDetails',
    label: 'Their home is not suitable because...',
    placeholder:
      'e.g. consider difficulties with climbing stairs, using the bath or shower, or moving around the home',
    class: 'mb-5',
    validators: [Validators.minLength(1), Validators.maxLength(2000)],
    displayConditions: [
      {
        key: 'homeImpactHomeIsSuitableForCaredForPerson',
        values: [false],
      },
    ],
  });

export const homeImpactDifficultDailyTasks = new FormItemTextArea({
  key: 'homeImpactDifficultDailyTasks',
  path: 'supportingRole.homeImpact.difficultDailyTasks',
  label: 'These daily tasks cause me problems',
  placeholder:
    'e.g. consider things like shopping, reading labels, cleaning, cooking etc',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const homeImpactSummary = new FormItemDropdown({
  key: 'homeImpactSummary',
  label:
    "Thinking about how I'm managing at home, the selected statement best matches how I currently feel.",
  path: 'supportingRole.homeImpact.summary',
  class: 'mb-5',
  placeholder: 'Please select...',
  options: [
    {
      name: '1',
      label:
        'Our/my home is suitable and we can manage day-to-day tasks well enough. There are no risks to me or the person/people I care for.',
    },
    {
      name: '2',
      label:
        'Mostly we can manage day-to-day tasks at home, but some areas need to be addressed. There could be risks for me or the person/people I care for in the future.',
    },
    {
      name: '3',
      label:
        "We're getting by, but it's hard to stay on top of day-to-day tasks or changes are needed to our/their home, but there are no immediate risks to me or the person/people I care for.",
    },
    {
      name: '4',
      label:
        "We're not coping with many of the day-to-day tasks or our/their home isn't suitable. This poses immediate risks to me or the person/people I care for, and I need help to sort this out.",
    },
    {
      name: '5',
      label:
        "We're not coping with many of the day-to-day tasks or our/their home isn't suitable. This poses immediate risk to me or the person/people I care for. I have no help.",
    },
  ],
});
