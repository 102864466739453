<div class="flex rounded-md border border-solid border-gray-300 mr-auto">
  <div
    class="h-24 w-24 rounded-md border-0 border-r border-solid border-gray-300 flex items-center justify-center"
    [ngClass]="{ 'w-56 cursor-pointer': control.value?.base64StringValue }"
    (click)="downloadFile()"
  >
    <app-icon
      *ngIf="!control.value?.base64StringValue"
      name="document"
      class="p-6"
      width="100%"
      height="100%"
    ></app-icon>

    <p
      *ngIf="control.value?.base64StringValue"
      class="text-center truncate p-4 text-theme-darker"
    >
      {{ control.value.name }}
    </p>
  </div>

  <div class="flex flex-col justify-evenly" *ngIf="!inputMode">
    <button
      type="button"
      class="btn-inline mx-6 my-0 rounded-none border-0 border-b border-gray-600 text-gray-600 hover:text-theme hover:border-theme transition-color border-solid py-0 font-semibold px-1 my-auto flex"
      (click)="onChangeFile()"
      [disabled]="disabled"
    >
      <app-icon name="edit" class="my-auto mr-1 w-3"></app-icon>
      Change
    </button>

    <button
      type="button"
      class="btn-inline mx-6 my-0 rounded-none border-0 border-b border-gray-600 text-gray-600 hover:text-theme hover:border-theme transition-color border-solid py-0 font-semibold px-1 my-auto flex"
      (click)="onDeleteFile()"
      [disabled]="disabled"
    >
      <app-icon name="delete" class="my-auto mr-1 w-3"></app-icon>
      Remove
    </button>
  </div>

  <button
    class="btn-inline mx-6 rounded-none border-0 border-b border-gray-600 text-gray-600 hover:text-theme hover:border-theme transition-color border-solid py-0 font-semibold px-1 my-auto flex"
    type="button"
    *ngIf="inputMode"
    (click)="openFilePicker()"
    [disabled]="disabled"
  >
    Choose File
  </button>

  <input
    #fileInputRef
    *ngIf="inputMode"
    type="file"
    class="border-none my-auto flex-0 w-64"
    (change)="onFileSelected($event)"
    (cancel)="onCancelFilePicker($event)"
    [accept]="item.allowedExtensions"
    [id]="item.key"
    [class.is-invalid]="control.touched && control.errors"
    style="display: none"
    [disabled]="disabled"
  />
</div>
<small
  *ngIf="!control.value?.base64StringValue"
  class="text-gray-500 italic mt-1"
  >Allowed file types: {{ item?.allowedExtensions }}
</small>
<small
  *ngIf="control.value?.base64StringValue"
  class="text-gray-500 italic mt-1"
  >Click on the file's name to download it.
</small>
