import { Component } from '@angular/core';
import { PdsService } from 'src/app/services/pds/pds.service';
import { SessionStorageService } from 'src/app/services/session-storage/session-storage.service';

@Component({
  selector: 'app-private-key',
  templateUrl: './private-key.component.html',
  styleUrls: ['./private-key.component.css'],
})
export class RegisterSetupPrivateKeyComponent {
  //on submit, call the pds service createPds method

  constructor(private sessionStorageService: SessionStorageService) {}
  submit() {
    let redirectUrl =
      this.sessionStorageService.getMydexPrivateKeyCreationUrl();
    if (redirectUrl) {
      window.location.href = redirectUrl;
      this.sessionStorageService.removeMydexPrivateKeyCreationUrl();
    }
  }
}
