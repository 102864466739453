<h3 class="font-semibold m-0 mb-2">
  Is {{ connection?.title }} of interest to you?
</h3>
<p class="mb-8 text-sm mt-0">
  We'll use this info to show you connections that are more relevant to you.
  You'll also beable to change this later.
</p>

<div class="grid grid-cols-1 xs:grid-cols-3 gap-2">
  <button
    class="rounded-full btn-theme-outline py-2 flex justify-center"
    (click)="markConnection('interested')"
  >
    <app-icon name="thumbs-up" class="my-auto mr-1"></app-icon>
    <span class="my-auto pb-[1px]"> Yes, I'm interested </span>
  </button>

  <button
    class="rounded-full btn-theme-outline py-2 flex justify-center"
    (click)="markConnection('not-interested')"
  >
    <app-icon name="thumbs-down" class="mt-2 mr-1"></app-icon>
    <span class="my-auto"> No, I'm not </span>
  </button>

  <button
    class="rounded-full btn-theme-outline py-2"
    (click)="markConnection('unsure')"
  >
    Maybe later
  </button>
</div>
