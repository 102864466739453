import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Connection } from 'src/app/models/connection/connection';
import { ConnectionDisplayType } from 'src/app/models/connection/connectionDisplayType';
import { FinderNode } from 'src/app/models/finder/finderNode';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { ConnectionsStateService } from 'src/app/services/connections.state.service';
import { FinderService } from 'src/app/services/finder/finder.service';

@Component({
  selector: 'app-finder',
  templateUrl: './finder.component.html',
  styleUrls: ['./finder.component.css'],
})
export class FinderComponent {
  readonly baseUrl: string = '/finder';

  protected currentNode: FinderNode | undefined;
  protected connections: Connection[] = [];
  protected searchTerm = '';

  private routerSubscription: any;
  private connectionsSubscription: any;

  constructor(
    protected connectionsStateService: ConnectionsStateService,
    private finderService: FinderService,
    private router: Router,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit() {
    // get node on page refresh
    this.updateCurrentNode();

    // get node on backward/forward navigation + child node selection
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.updateCurrentNode();
      }
    });

    this.connectionsSubscription =
      this.connectionsStateService.connections$.subscribe(() => {
        this.displayConnectionsIfNeeded();
      });
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }

    if (this.connectionsSubscription) {
      this.connectionsSubscription.unsubscribe();
    }

    this.connectionsStateService.resetErrors();
  }

  get urlPath() {
    // ignore query params
    return this.router.url.split('?')[0];
  }

  get nodePath() {
    // ignore'/finder/' prefix and turn into node path, connected by '.'
    return this.urlPath.split('/').slice(2).join('.');
  }

  get breadcrumbSegments() {
    // ignore empty prefix
    return this.urlPath.split('/').slice(1);
  }

  protected handleConnectionOpened(connectionName: string | undefined) {
    this.analyticsService.trackConnectionOpenedInFinder(
      connectionName ?? 'connection-name-unknown',
      this.currentNode?.fullPath ?? ''
    );
  }

  protected handleSignpostClicked(connectionName: string | undefined) {
    this.analyticsService.trackSignpostClickedInFinder(
      connectionName ?? 'connection-name-unknown',
      this.currentNode?.fullPath ?? ''
    );
  }

  protected routeToNode(nodeName: string) {
    if (nodeName === '') {
      this.router.navigate([this.baseUrl]);
      return;
    }

    let nodePath = this.finderService.getNodePathForNode(nodeName);

    if (nodePath === '') {
      this.router.navigate([this.baseUrl]);
      return;
    }

    this.router.navigate([this.baseUrl + '/' + nodePath.split('.').join('/')]);
  }

  private updateCurrentNode() {
    this.currentNode = this.finderService.getNodeForNodePath(this.nodePath);
    this.displayConnectionsIfNeeded();

    if (!this.currentNode) {
      this.router.navigate([this.baseUrl]);
    }
  }

  private displayConnectionsIfNeeded() {
    // if there are no child nodes, current node is leaf node - in this case show connections
    if (this.currentNode?.childNodes.length != 0) {
      this.connections = [];
      return;
    }

    this.connections = this.connectionsStateService.getConnectionsForFinderPath(
      this.currentNode?.fullPath ?? ''
    );

    // TODO: update display types according to needs
    if (this.connections.length > 1) {
      this.connections[0].displayType = ConnectionDisplayType.Feature;
    }
  }
}
