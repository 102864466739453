import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';

export function maxByteValidator(maxBytes: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (value) {
      const byteLength = new TextEncoder().encode(value).length;

      if (byteLength > maxBytes) {
        return {
          maxByteLength: {
            requiredByteLength: maxBytes,
            actualByteLength: byteLength,
          },
        };
      }
    }

    return null;
  };
}
