import { CallToActionButton } from './CallToActionButton';

export class ContentBlock<T> {
  type:
    | 'connections'
    | 'serviceModule'
    | 'detailsBase'
    | 'detailsTextOnly'
    | 'undefined';
  orderId: number;
  isDeleted: boolean;
  // TODO: add property 'display critera'

  constructor(data: Partial<ContentBlock<any>>) {
    this.type = data?.type || 'undefined';
    this.orderId = data?.orderId ?? -1;
    this.isDeleted = data?.isDeleted || false;
  }
}

export class ContentBlockConnections extends ContentBlock<ContentBlockConnections> {
  title: string;
  description?: string;
  connectionNames: string[]; // slugs that match the connection slugs/ids

  constructor(data: Partial<ContentBlockConnections>) {
    super(data);
    this.type = 'connections';
    this.title = data.title || '';
    this.description = data.description;
    this.connectionNames = data.connectionNames || [];
  }
}

export class ContentBlockServiceModule extends ContentBlock<ContentBlockServiceModule> {
  title: string;
  description: string;
  serviceModuleSlug: string;

  constructor(data: Partial<ContentBlockServiceModule>) {
    super(data);
    this.type = 'serviceModule';
    this.title = data.title || '';
    this.description = data.description || '';
    this.serviceModuleSlug = data.serviceModuleSlug || '';
  }
}

export class ContentBlockDetailsBase extends ContentBlock<ContentBlockDetailsBase> {
  title?: string; // max 40 characters
  subheading?: string; // max 80 characters
  description?: string; // max 1000 characters
  images: string[]; // URLs of images, max 5
  cta?: CallToActionButton;

  constructor(data: Partial<ContentBlockDetailsBase>) {
    super(data);
    this.type = 'detailsBase';
    this.title = data.title;
    this.subheading = data.subheading;
    this.description = data.description;
    this.images = data.images || [];
    this.cta = data.cta ? new CallToActionButton(data.cta) : undefined;
  }

  get isEmpty(): boolean {
    return (
      !this.title &&
      !this.subheading &&
      !this.description &&
      !this.images.length &&
      !this.cta
    );
  }

  get layoutType(): 'text-cta-image' | 'image-only' | 'text-cta' {
    if ((this.description || this.cta) && this.images.length) {
      return 'text-cta-image';
    }

    if (!this.description && !this.cta && this.images.length) {
      return 'image-only';
    }

    return 'text-cta';
  }
}

export class ContentBlockDetailsTextOnly extends ContentBlock<ContentBlockDetailsTextOnly> {
  title?: string; // max 40 characters
  subheading?: string; // max 80 characters
  description?: string; // max 5000 characters

  constructor(data: Partial<ContentBlockDetailsTextOnly>) {
    super(data);
    this.type = 'detailsTextOnly';
    this.title = data.title;
    this.subheading = data.subheading;
    this.description = data.description;
  }

  get isEmpty(): boolean {
    return !this.title && !this.subheading && !this.description;
  }
}

export class ContentBlockUndefined extends ContentBlock<ContentBlockUndefined> {
  constructor(data: Partial<ContentBlockUndefined>) {
    super(data);
  }
}
