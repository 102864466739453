import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IndexComponent } from './pages/index/index.component';
import { RegisterComponent } from './pages/register/register.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonSelectComponent } from './components/form/controls/button-select/button-select.component';
import { FormComponent } from './components/form/form.component';
import { ModalComponent } from './components/modal/modal.component';
import { CarerAssessmentNextStepsComponent } from './pages/carer-assessment/next-steps/next-steps.component';
import { CarerAssessmentQuestionsComponent } from './pages/carer-assessment/questions/questions.component';
import { CarerAssessmentStartComponent } from './pages/carer-assessment/start/start.component';
import { CarerComponent } from './pages/carer/carer.component';
import { LoginComponent } from './pages/login/login.component';
import { QuarriersComponent } from './pages/quarriers/quarriers.component';
import { RegisterIntroComponent } from './pages/register/intro/intro.component';
import { RegisterSetupPermissionsComponent } from './pages/register/setup/permissions/permissions.component';
import { RegisterSetupPrivateKeyComponent } from './pages/register/setup/private-key/private-key.component';
import { RegisterSetupComponent } from './pages/register/setup/setup.component';

import { AuthInterceptor } from './auth.interceptor';
import { ConnectionCompletenessComponent } from './components/connection-completeness/connection-completeness.component';
import { ConnectionsComponent } from './components/connections/connections.component';
import { ArrayItemComponent } from './components/form/array-item/array-item.component';
import { HeaderComponent } from './components/header/header.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { PiechartComponent } from './components/piechart/piechart.component';
import { SidebarLinksComponent } from './components/sidebar-links/sidebar-links.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { AccountAboutMeSectionComponent } from './pages/account/account-about-me/account-about-me-section/account-about-me-section.component';
import { AccountAboutMeComponent } from './pages/account/account-about-me/account-about-me.component';
import { AccountServicesComponent } from './pages/account/account-services/account-services.component';
import { AdminConnectionComponent } from './pages/admin-connection/admin-connection.component';
import { AdminConnectionsComponent } from './pages/admin-connections/admin-connections.component';
import { AdminFormItemsComponent } from './pages/admin-form-items/admin-form-items.component';
import { ConnectionComponent } from './pages/connection/connection.component';
import { ConnectionFormComponent } from './pages/connection/form/form.component';
import { FinderComponent } from './pages/finder/finder.component';
import { QuarriesNextStepComponent } from './pages/quarries-next-step/quarries-next-step.component';
import { RegisterSetupRecordComponent } from './pages/register/setup/record/record.component';

import { NgSelectModule } from '@ng-select/ng-select';
import { AddressComponent } from './components/form/controls/address/address.component';
import { DobComponent } from './components/form/controls/dob/dob.component';

import { ConnectionOpinionComponent } from './components/connection-opinion/connection-opinion.component';
// import { AccountConnectionsComponent } from './pages/account/account-connections/account-connections.component';
import { AuthConfigModule } from './auth/auth-config.module';
import { AccordionItemComponent } from './components/accordion-item/accordion-item.component';
import { CookieNoticeComponent } from './components/cookie-notice/cookie-notice.component';
import { FooterComponent } from './components/footer/footer.component';
import { EmailContentControlComponent } from './components/form/controls/email-content-control/email-content-control.component';
import { FileUploadComponent } from './components/form/controls/file-upload/file-upload.component';
import { ImageUploadComponent } from './components/form/controls/image-upload/image-upload.component';
import { RepeaterComponent } from './components/form/controls/repeater/repeater.component';
import { SelectComponent } from './components/form/controls/select/select.component';
import { TextareaComponent } from './components/form/controls/textarea/textarea.component';
import { TextboxWithAffixComponent } from './components/form/controls/textbox-with-affix/textbox-with-affix.component';
import { DisplayLogicComponent } from './components/form/display-logic/display-logic.component';
import { DefaultShortDisplayComponent } from './components/form/displays/default-short-display/default-short-display.component';
import { DefaultComponent } from './components/form/displays/default/default.component';
import { GalleryImageDisplayComponent } from './components/form/displays/gallery-image-display/gallery-image-display.component';
import { OutputSubformDisplayComponent } from './components/form/displays/output-subform-display/output-subform-display.component';
import { PersonComponent } from './components/form/displays/person/person.component';
import { FormSectionsComponent } from './components/form/form-sections/form-sections.component';
import { SubformComponent } from './components/form/subform/subform.component';
import { IconComponent } from './components/icon/icon.component';
import { InputFilterComponent } from './components/input-filter/input-filter.component';
import { NoItemsErrorComponent } from './components/no-items-error/no-items-error.component';
import { AccountWelcomeComponent } from './pages/account/account-welcome/account-welcome.component';
import { ConnectionReferralComponent } from './pages/connection-referral/connection-referral.component';
import { MydexLoginComponent } from './pages/mydex-login/mydex-login.component';
import { PageFaqComponent } from './pages/page-faq/page-faq.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { RegisterSetupLoginComponent } from './pages/register/setup/login/login.component';
import { UpdatePermissionsComponent } from './pages/update-permissions/update-permissions.component';
import { PagePrivacyPolicyComponent } from './pages/page-privacy-policy/page-privacy-policy.component';
import { FlatpickrModule } from 'angularx-flatpickr';
import { PageCookiePreferencesComponent } from './page-cookie-preferences/page-cookie-preferences.component';
import { PageOrganisationComponent } from './pages/page-organisation/page-organisation.component';
import { CtaComponent } from './components/cta/cta.component';
import { ContentHolderComponent } from './components/content-holder/content-holder.component';
import { PageFinderSearchComponent } from './pages/page-finder-search/page-finder-search.component';
import { PageProblemReportComponent } from './pages/page-problem-report/page-problem-report.component';
import { PageAccountSummaryComponent } from './pages/account/page-account-summary/page-account-summary.component';
import { JoinArrayPipe } from './helper/pipes/joinArrayPipe';
import { RemoveDotPipe } from './helper/pipes/removeDotPipe';
import { ContactsComponent } from './components/form/controls/contacts/contacts.component';
import { ImageUploadGalleryComponent } from './components/form/controls/image-upload-gallery/image-upload-gallery.component';
import { StatefulButtonComponent } from './components/stateful-button/stateful-button.component';
import { StatefulLoadingSpinnerComponent } from './components/stateful-loading-spinner/stateful-loading-spinner.component';

@NgModule({
  declarations: [
    AppComponent,
    RegisterComponent,
    IndexComponent,
    FormComponent,
    ButtonSelectComponent,
    CarerComponent,
    CarerAssessmentStartComponent,
    CarerAssessmentQuestionsComponent,
    CarerAssessmentNextStepsComponent,
    QuarriersComponent,
    RegisterIntroComponent,
    ModalComponent,
    RegisterSetupComponent,
    RegisterSetupPrivateKeyComponent,
    RegisterSetupPermissionsComponent,
    LoginComponent,
    RegisterSetupRecordComponent,
    AdminConnectionsComponent,
    ConnectionComponent,
    ConnectionFormComponent,
    FinderComponent,
    ConnectionsComponent,
    LoadingSpinnerComponent,
    HeaderComponent,
    ConnectionCompletenessComponent,
    AdminConnectionComponent,
    SidebarComponent,
    PiechartComponent,
    TabsComponent,
    AccountAboutMeComponent,
    AccountServicesComponent,
    SidebarLinksComponent,
    QuarriesNextStepComponent,
    ConnectionCompletenessComponent,
    AccountAboutMeSectionComponent,
    AdminFormItemsComponent,
    ArrayItemComponent,
    AddressComponent,
    DobComponent,
    ConnectionOpinionComponent,
    // AccountConnectionsComponent,
    FormSectionsComponent,
    SubformComponent,
    PersonComponent,
    DefaultComponent,
    IconComponent,
    RepeaterComponent,
    ConnectionOpinionComponent,
    PageNotFoundComponent,
    DisplayLogicComponent,
    OutputSubformDisplayComponent,
    SelectComponent,
    EmailContentControlComponent,
    AccordionItemComponent,
    TextareaComponent,
    InputFilterComponent,
    NoItemsErrorComponent,
    StatefulButtonComponent,
    StatefulLoadingSpinnerComponent,
    TextboxWithAffixComponent,
    MydexLoginComponent,
    RegisterSetupLoginComponent,
    AccountWelcomeComponent,
    UpdatePermissionsComponent,
    GalleryImageDisplayComponent,
    CookieNoticeComponent,
    ConnectionReferralComponent,
    DefaultShortDisplayComponent,
    ImageUploadComponent,
    ImageUploadGalleryComponent,
    FileUploadComponent,
    PageFaqComponent,
    FooterComponent,
    PagePrivacyPolicyComponent,
    PageCookiePreferencesComponent,
    PageOrganisationComponent,
    CtaComponent,
    ContentHolderComponent,
    PageFinderSearchComponent,
    PageProblemReportComponent,
    PageAccountSummaryComponent,
    ContactsComponent,
    JoinArrayPipe,
    RemoveDotPipe,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgSelectModule,
    FormsModule,
    AuthConfigModule,
    FlatpickrModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
