<div class="container-sm">
  <h1 class="font-semibold text-3xl md:text-4xl mb-6">
    Frequently Asked Questions
  </h1>

  <p class="mt-0 mb-14">
    Welcome to our FAQ section! Quickly find answers to common questions about
    Community Connections and the Personal Data Store. If you have a question
    that's not listed here, feel free to reach out to us.
  </p>

  <div *ngFor="let item of frequentlyAskedQuestions" class="mb-5">
    <div class="bg-light-grey rounded-lg w-full">
      <app-accordion-item #accordionItem>
        <div
          class="header flex justify-between items-center p-2 text-theme-darker cursor-pointer w-full"
        >
          <app-icon
            name="caret-right-fill"
            class="p-2 transition-transform duration-100 ease-in-out"
            [ngClass]="{
          'rotate-90': accordionItem.isOpen,
        }"
          ></app-icon>
          <h3 class="flex-1 font-normal text-lg my-2">{{ item.question }}</h3>
        </div>
        <div
          [innerHTML]="item.answer"
          class="body pb-5 px-10 mt-0 [&>p]:mt-0"
        ></div>
      </app-accordion-item>
    </div>
  </div>
</div>
