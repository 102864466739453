<div class="flex">
  <img
    [src]="value?.galleryImage ?? '/assets/img/profile-placeholder.png'"
    alt=""
    class="w-96 rounded-lg"
  />
  <div class="my-auto ml-4">
    <p
      class="m-0 -mt-1 max-w-[16rem] whitespace-nowrap overflow-hidden overflow-ellipsis"
    >
      {{ value?.galleryImageDescription }}
    </p>
  </div>
</div>
