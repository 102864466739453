import { Component, EventEmitter, Injectable, Input, Output } from '@angular/core';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.css']
})

export class TabsComponent{
  @Input() options: {name: string, value: string}[] = [];
  @Output() onSelect = new EventEmitter<string>();
  
  selected: string = '';

  select(value: string){
    this.selected = value;
    this.onSelect.emit(value);
  }
  ngOnInit(){
    this.selected = this.options[0]?.value;
  }
}
