<div class="my-auto pb-[2vw] home-container">
  <div
    *ngIf="sessionStorageService.getRegistrationInProgress()"
    class="flex -mr-2 my-auto"
  >
    <div
      class="w-5 h-5 mr-2 flex rounded-full bg-grey bg-opacity-25 step"
      *ngFor="let index of steps.keys()"
      [ngClass]="{ active: index <= step }"
    ></div>
  </div>

  <router-outlet></router-outlet>
</div>
