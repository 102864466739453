<div class="flex rounded-md border border-solid border-gray-300 mr-auto">
  <div
    class="h-24 w-24 rounded-md border-0 border-r border-solid border-gray-300 flex items-center justify-center"
    (click)="control.value && getImageSrc() ? openModal($event) : {}"
    [ngClass]="{
      'cursor-pointer': control.value && getImageSrc()
    }"
  >
    <app-icon
      *ngIf="!control.value"
      [name]="item.placeholderIconName"
      class="p-5"
      style="color: #979797"
      width="100%"
      height="100%"
    ></app-icon>

    <img
      *ngIf="control.value && getImageSrc()"
      class="w-full h-full object-cover"
      [src]="getImageSrc()"
      alt="User's Profile Picture Small View"
    />

    <p
      *ngIf="control.value && !getImageSrc()"
      class="flex text-center italic text-gray-400 text-sm p-1"
    >
      Image cannot be displayed.
    </p>
  </div>

  <div class="flex flex-col justify-evenly" *ngIf="!inputMode">
    <button
      type="button"
      class="btn-inline mx-6 rounded-none border-0 border-b border-gray-600 text-gray-600 hover:text-theme hover:border-theme transition-color border-solid py-0 font-semibold px-1 flex"
      (click)="onChangeFile()"
      [disabled]="disabled"
    >
      <app-icon name="edit" class="my-auto mr-1 w-3"></app-icon>
      Change
    </button>

    <button
      type="button"
      class="btn-inline mx-6 rounded-none border-0 border-b border-gray-600 text-gray-600 hover:text-red-500 hover:border-red-500 transition-color border-solid py-0 font-semibold px-1 flex"
      (click)="onDeleteFile()"
      [disabled]="disabled"
    >
      <app-icon name="delete" class="my-auto mr-1 w-3"></app-icon>
      Remove
    </button>
  </div>

  <button
    class="btn-inline mx-6 rounded-none border-0 border-b border-gray-600 text-gray-600 hover:text-theme hover:border-theme transition-color border-solid py-0 font-semibold px-1 my-auto flex"
    type="button"
    *ngIf="inputMode"
    (click)="openFilePicker()"
    [disabled]="disabled"
  >
    Choose Image
  </button>

  <input
    #fileInputRef
    *ngIf="inputMode"
    type="file"
    class="border-none my-auto flex-0 w-64"
    (change)="onFileSelected($event)"
    (cancel)="onCancelFilePicker($event)"
    accept="image/*"
    [id]="item.key"
    [class.is-invalid]="control.touched && control.errors"
    style="display: none"
    [disabled]="disabled"
  />
</div>
<small *ngIf="control?.value && getImageSrc()" class="text-gray-500 italic mt-1"
  >Click on the image for a detailed view.</small
>

<!--LARGE IMAGE DISPLAY MODAL-->
<app-modal (onClose)="({})">
  <img
    class="w-full"
    [src]="getImageSrc()"
    alt="User's Profile Picture Large View"
  />

  <button
    (click)="downloadImage()"
    class="mt-4 btn-theme btn-sm px-12 mr-4"
    type="button"
  >
    Download Image
  </button>

  <button
    (click)="closeModal()"
    type="button"
    class="mt-4 btn-theme-outline outlined btn-sm px-12"
  >
    Close
  </button>
</app-modal>
<!--END LARGE IMAGE DISPLAY MODAL-->
