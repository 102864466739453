<div
  class="flex h-full home-container mx-auto flex-col xl:flex-row box-border pb-[6vw]"
>
  <div class="max-w-xl mr-auto box-border relative">
    <h1 class="font-medium mb-10">Welcome to your About Me.</h1>

    <p [hidden]="player" class="text-grey italic mb-8">
      Optional: enable the video player cookies in your
      <a routerLink="/cookie-preferences" class="text-theme"
        >Cookie Preferences</a
      >
      to allow us to display the Vimeo video about Community Connections.
    </p>

    <p class="mb-14">
      <strong>About Me</strong> is a place to tell your story and share it with
      the people and services you choose. Allowing you to tell your story once
      and use it to help organise and manage your support. <br />
      The term we use for it is a Personal Data Store. It's provided by Mydex*,
      a Scottish Community Interest Company dedicated to empowering individuals
      with their data. It's your record to use as and when you want. <br />
      This should take you 5-10 minutes to setup and we can take you through the
      stages and explain as we go.
    </p>

    <a
      class="btn btn-theme px-8"
      routerLink="/register"
      [queryParams]="{ from: 'onboarding' }"
    >
      Let's get started
    </a>
    <div>
      <button (click)="openModal()" class="mt-8 text-theme btn-inline">
        * learn more about Mydex
      </button>
    </div>
  </div>
  <div [hidden]="!player" class="relative w-full m-8 pt-[56.25%]">
    <div #vimeoPlayer class="absolute inset-0 w-full h-full"></div>
  </div>
</div>

<app-modal>
  <h1 class="text-2xl">About MyDex and your Personal Data Store</h1>
  <p>
    Using and accessing personal data is essential to delivering safe, secure
    and effective services in a modern digital economy. To achieve our mission
    as a community interest company, Mydex provide any citizen with a Personal
    Data Store hosted safely and securely in the cloud. <br />
    The Personal Data Store enables citizens to collect, receive, and store
    personal data about them and their life. Organisations can deliver personal
    data into them as part of normal service delivery or collect data from the
    Personal Data Store with the consent of the citizen. This approach
    eradicates friction, effort, risk, and cost in both accessing and providing
    services.
  </p>
</app-modal>
