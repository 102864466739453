import { Validators } from '@angular/forms';
import { FormItem, FormItemTextbox } from 'src/app/models/form/form';

export function getMrdConnectionAdminForm(): FormItem<any>[] {
  return [alissId];
}

const alissId = new FormItemTextbox({
  key: 'alissIdRequest',
  path: 'alissId',
  label: 'ALLIS ID',
  help: 'The ID (slug) of this connection on ALISS.',
  placeholder: 'e.g. b25f5b26-4fed-4560-9b90-6dd573f526bb',
  required: true,
  validators: [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(128),
  ],
  class: 'mb-6',
});
