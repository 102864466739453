import { Component, ElementRef, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AboutMeSection } from 'src/app/models/about-me/aboutMeSection';
import { FormItem } from 'src/app/models/form/form';
import { UserStateService } from 'src/app/services/user.state.service';
import * as _ from 'lodash';
import { User } from 'src/app/models/user/user';
import { Subject, takeUntil } from 'rxjs';
import { computeCompletedAboutMeSectionItemsPercentage } from 'src/app/helper/formCompleteness';
import { aboutMeSections } from 'src/assets/about-me/sections/aboutMeSections';
import { UserContactsStateService } from 'src/app/services/user-contacts-state/user.contacts.state.service';
import { UserContactPdsEntry } from 'src/app/models/user/contact/userContactPdsEntry';
import { UserContactData } from 'src/app/models/user/contact/userContactData';
import { FormComponent } from 'src/app/components/form/form.component';
import { UserImagesStateService } from 'src/app/services/user-images-state/user.images.state.service';
import { UserImagePdsEntry } from 'src/app/models/user/image/userImagePdsEntry';
import { UserImageData } from 'src/app/models/user/image/userImageData';

@Component({
  selector: 'app-account-about-me-section',
  templateUrl: './account-about-me-section.component.html',
  styleUrls: ['./account-about-me-section.component.css'],
})
export class AccountAboutMeSectionComponent {
  @ViewChild('formComponent') formComponent?: FormComponent;

  protected currentSection: AboutMeSection | undefined;
  protected parentSection: AboutMeSection | undefined;
  protected sections = aboutMeSections;

  protected user: User | undefined;
  protected contacts: UserContactPdsEntry[] = [];
  protected images: UserImagePdsEntry[] = [];

  protected form: FormItem<any>[] = [];
  protected savingUserSuccess: boolean = false;

  protected breadcrumbSegments: {
    label: string;
    path: string;
  }[] = [];

  protected subsections = [];

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    protected userService: UserStateService,
    protected userContactsStateService: UserContactsStateService,
    protected userImagesStateService: UserImagesStateService
  ) {}

  get urlPath() {
    // ignore query params
    return this.router.url.split('?')[0];
  }

  get nodePath() {
    return this.urlPath.split('/').slice(3);
  }

  get nodeName() {
    return this.urlPath.split('/').slice(3).at(-1);
  }

  ngOnInit() {
    this.currentSection = this.findCurrentSection(this.sections);

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      this.currentSection = this.findCurrentSection(this.sections);
      if (event instanceof NavigationEnd) {
        this.updateForm();
      }
    });

    this.userService.user$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (user) => {
        this.user = user;
        this.updateForm();
      },
    });

    // show success message for 2 seconds when user data is saved successfully
    this.userService.savingSuccess$.pipe(takeUntil(this.destroy$)).subscribe({
      next: () => {
        this.savingUserSuccess = true;
        setTimeout(() => {
          this.savingUserSuccess = false;
        }, 2000);
      },
    });

    this.userContactsStateService.userContacts$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (contacts) => {
          this.contacts = contacts;
        },
      });

    this.userContactsStateService.savingSuccess$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.formComponent?.handleContactSavingSuccess();
      });

    this.userContactsStateService.deletionSuccess$
      .pipe(takeUntil(this.destroy$))
      .subscribe((contactId) => {
        this.formComponent?.handleContactDeletionSuccess(contactId);
      });

    this.userImagesStateService.userImageThumbnails$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (images) => {
          this.images = images;
        },
      });

    this.userImagesStateService.loadingImageFullSizeSuccess$
      .pipe(takeUntil(this.destroy$))
      .subscribe((imageFullSize) => {
        this.formComponent?.handleLoadingImageFullSizeSuccess(imageFullSize);
      });

    this.userImagesStateService.savingImageSuccess$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.formComponent?.handleImageSavingSuccess();
      });

    this.userImagesStateService.deletingImageSuccess$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (imageId) => {
          this.formComponent?.handleContactDeletionSuccess(imageId);
        },
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();

    this.userService.resetErrors();
    this.userContactsStateService.resetErrors();
    this.userImagesStateService.resetErrors();
  }

  handleSave(data: Partial<User>) {
    var serverMamPaths = this.currentSection?.serverMamPaths ?? [];
    this.userService.updateUser(data, serverMamPaths);
  }

  handleBack() {
    const lastPath = this.breadcrumbSegments?.at(-2)?.path;
    if (lastPath) {
      this.router.navigate([lastPath]);
    }
  }

  findCurrentSection(
    sections: AboutMeSection[],
    depth = 0
  ): AboutMeSection | undefined {
    if (depth == 0) {
      this.breadcrumbSegments = [
        { label: 'About Me', path: '/account/about-me' },
      ];
    }
    let section = sections.find(
      (section) => this.nodePath[depth] === section.routePath
    );
    this.breadcrumbSegments.push({
      label: section?.title ?? '',
      path: this.breadcrumbSegments?.at(-1)?.path
        ? this.breadcrumbSegments?.at(-1)?.path +
          '/' +
          (section?.routePath ? section.routePath : '')
        : '',
    });
    if (depth < this.nodePath.length - 1 && section) {
      this.parentSection = section;
      //if not at the end of a branch, recurse
      return this.findCurrentSection(section.children ?? [], depth + 1);
    }
    return section;
  }

  updateForm() {
    if (!this.user) {
      return;
    }

    // Common logic extracted into a helper function
    const processFormItems = (items: FormItem<any>[]) =>
      items.map((item) => {
        item.validators = item.validators.filter(
          ({ name }) => name !== 'required'
        );
        return item;
      });

    this.form = processFormItems(this.currentSection?.getFormItems?.() ?? []);
  }

  computeCompletedPercentageForSection(section: AboutMeSection) {
    return computeCompletedAboutMeSectionItemsPercentage(this.user, section);
  }

  // Contact Item Functions
  handleContactCreated(userContactData: UserContactData) {
    this.userContactsStateService.createUserContact(userContactData);
  }

  handleContactUpdated(userContact: UserContactPdsEntry) {
    this.userContactsStateService.updateUserContact(userContact);
  }

  handleContactDeleted(userContact: UserContactPdsEntry) {
    this.userContactsStateService.deleteUserContact(userContact);
  }

  handleClearContactsItemErrors() {
    this.userContactsStateService.resetErrors();
  }

  // Image Item Functions
  handleFullSizeImageRequested(imageId: string) {
    this.userImagesStateService.getUserImageFullSize(imageId);
  }

  handleImageAdded(userImageData: UserImageData) {
    this.userImagesStateService.addUserImage(userImageData);
  }

  handleImageUpdated(updatedUserImage: UserImagePdsEntry) {
    this.userImagesStateService.updateUserImage(updatedUserImage);
  }

  handleImageDeleted(userImageToBeDeleted: UserImagePdsEntry) {
    this.userImagesStateService.deleteUserImage(userImageToBeDeleted);
  }

  handleClearImagesItemErrors() {
    this.userImagesStateService.resetErrors();
  }
}
