import { FormItem, FormItemCheckbox } from 'src/app/models/form/form';
import {
  password,
  registrationEmail,
  registrationMydexId,
} from './register-and-login-items';
import { environment } from 'src/environments/environment';
import { Validators } from '@angular/forms';

export const privacyPolicyUrl = `${environment.url}/privacy-policy`;

export const privacyPolicyAgreement = new FormItemCheckbox({
  label: `I confirm that I have read, understood and consent to the
    <a class="text-theme underline" href="${privacyPolicyUrl}"
      >Privacy Policy</a
    > of Community Connections.`,
  key: 'dataProcessingConsent',
  class: 'mt-5',
  labelIsHtml: true,
  required: true,
  validators: [Validators.required],
});

export const registerForm: FormItem<any>[] = [
  registrationMydexId,
  registrationEmail,
  password,
  privacyPolicyAgreement,
];
