import { Validators } from '@angular/forms';
import {
  FormItemButtonSelect,
  FormItemLink,
  FormItemTextArea,
  FormItemTextbox,
} from 'src/app/models/form/form';

export const movingAroundInsideAreasWithNoSupportNeed =
  new FormItemButtonSelect({
    key: 'movingAroundInsideAreasWithNoSupportNeed',
    path: 'support.movingAroundInside.areasWithNoSupportNeed',
    label: 'Tell us what you can manage without support',
    class: 'mb-5',
    multiple: true,
    options: [
      { name: 'Making a cup of tea', label: 'Making a cup of tea' },
      { name: 'Getting to the toilet', label: 'Getting to the toilet' },
      {
        name: 'Getting in/out of my chair',
        label: 'Getting in/out of my chair',
      },
      { name: 'Getting in/out of bed', label: 'Getting in/out of bed' },
      { name: 'Getting up/down stairs', label: 'Getting up/down stairs' },
      { name: 'Reaching cupboards', label: 'Reaching cupboards' },
      { name: 'Other', label: 'Other' },
    ],
  });

export const movingAroundInsideAreasWithSupportNeed = new FormItemButtonSelect({
  key: 'movingAroundInsideAreasWithSupportNeed',
  path: 'support.movingAroundInside.areasWithSupportNeed',
  label: 'Share the things that you do need support with',
  class: 'mb-5',
  multiple: true,
  options: [
    { name: 'Making a cup of tea', label: 'Making a cup of tea' },
    { name: 'Getting to the toilet', label: 'Getting to the toilet' },
    {
      name: 'Getting in/out of my chair',
      label: 'Getting in/out of my chair',
    },
    { name: 'Getting in/out of bed', label: 'Getting in/out of bed' },
    { name: 'Getting up/down stairs', label: 'Getting up/down stairs' },
    { name: 'Reaching cupboards', label: 'Reaching cupboards' },
    { name: 'Other', label: 'Other' },
  ],
});

export const movingAroundInsideAreasWithSupportNeedAdditionalInfo =
  new FormItemTextArea({
    key: 'movingAroundInsideAreasWithSupportNeedAdditionalInfo',
    path: 'support.movingAroundInside.areasWithSupportNeedAdditionalInfo',
    label:
      'Anything else you would like to share about the support you need moving around inside?',
    class: 'mb-5',
    validators: [Validators.minLength(1), Validators.maxLength(2000)],
  });

export const movingAroundInsideAids = new FormItemButtonSelect({
  key: 'movingAroundInsideAids',
  path: 'support.movingAroundInside.aids',
  label: 'Do you use an aid to move around?',
  class: 'mb-5',
  multiple: true,
  options: [
    { name: 'Rollator', label: 'Rollator' },
    { name: 'Zimmer', label: 'Zimmer' },
    { name: 'Walking Stick', label: 'Walking Stick' },
    { name: 'Crutches', label: 'Crutches' },
    { name: 'Wheelchair', label: 'Wheelchair' },
    { name: 'Other', label: 'Other' },
  ],
});

export const movingAroundInsideAidsAdditionalInfo = new FormItemTextArea({
  key: 'movingAroundInsideAidsAdditionalInfo',
  path: 'support.movingAroundInside.aidsAdditionalInfo',
  label: 'Anything else you would like to share about the aids that you use?',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const movingAroundInsideStairsManagement = new FormItemTextbox({
  key: 'movingAroundInsideStairsManagement',
  path: 'support.movingAroundInside.stairsManagement',
  label: 'How do you manage stairs?',
  placeholder: 'e.g. Stair lift',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const movingAroundInsideUnsteadinessOccurrences = new FormItemTextbox({
  key: 'movingAroundInsideUnsteadinessOccurrences',
  path: 'support.movingAroundInside.unsteadinessOccurrences',
  label: 'Are you sometimes unsteady on your feet and when?',
  placeholder: 'e.g. yes, in the morning',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const movingAroundInsideExistingSupport = new FormItemTextbox({
  key: 'movingAroundInsideExistingSupport',
  path: 'support.movingAroundInside.existingSupport',
  label: 'Tell us who, if anyone, helps you with these things?',
  placeholder: 'e.g. son',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const movingAroundInsideLink = new FormItemLink({
  key: 'movingAroundInsideLink',
  linkLabel: 'My Support - Moving Around Inside',
  description: 'You can share more about any support you need at home here:',
  linkType: 'routerlink',
  link: '/account/about-me/my-support/moving-around-inside',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});
