<div class="home-container">
  <h2 class="text-2xl font-medium mb-10">Available Support</h2>
  <a
    routerLink="/quarriers"
    class="card cursor-pointer bg-white flex w-full p-0 rounded-lg"
  >
    <div class="flex flex-col p-16 lg:w-1/2">
      <div class="h-6">
        <img
          class="h-full object-contain"
          src="/assets/img/quarriers-logo.png"
          alt=""
        />
      </div>
      <h3 class="text-2xl font-medium text-theme mt-3">Speak to Quarriers</h3>
      <p>
        Quarriers is one of Scotland's leading social care charities. They
        provide practical care and support for those caring for others. Speaking
        to them could really benefit you and connect you with useful services
        and support.
      </p>

      <button class="btn-theme btn-sm mt-auto mr-auto">Learn more</button>
    </div>
    <div class="h-full overflow-hidden lg:w-1/2 -m-1">
      <img
        src="/assets/img/quarriers-bg.png"
        alt=""
        class="object-cover h-full object-center"
      />
    </div>
  </a>

  <div class="my-28">
    <h2 class="text-2xl font-medium mb-4">Other recommendations</h2>
    <p class="mb-14">Some other services that may be useful to you</p>

    <app-connections [connections]="connections"></app-connections>
  </div>
</div>
