import { ConnectionDisplayType } from './connectionDisplayType';
import {
  ConnectionOutput,
  OutputContact,
  OutputEmail,
  OutputRedirect,
} from './connectionOutput';
import { ConnectionDisplayLogic } from './connectionDisplayLogic';
import { OutputFormSection } from './outputFormSection';
import { ConnectionServerModel } from '../server-models/connectionServerModel';

export class Connection {
  // PROPERTIES

  // information
  source: 'manual-input' | 'mrd';
  type: 'connection' | 'signpost';
  alissId?: string;
  name: string; // a unique name + used as the slug, regex: ^([a-z0-9]+-?)+$
  title: string; // 80 character title
  excerpt: string; // 200 character intro to the connection
  image: string; // URL to an image for the connection
  icon?: string; // Name of an icon or the SVG. The icon component will determine which it is by the <svg> tag
  priority: number; // used for ordering 1-5 from org perspective (1 is highest, 5 is lowest)
  priorityUser: number; // used for ordering 1-5 from user perspective (1 is highest, 5 is lowest)
  postcode?: string; // used for postcode lookup
  organisation?: string; // used for organisation lookup
  dataUsageStatement?: string; // What the connection intends to do with the data

  appLink?: {
    url: string;
    text: string;
  };

  webLink?: {
    url: string;
    text: string;
  };

  // content
  summary?: ConnectionSummary;

  // display config
  displayInPublicFinder: boolean; // Whether the connection should be displayed in the public finder
  displayLogic?: ConnectionDisplayLogic;
  // finder
  finderPaths: string[];

  // output data / form
  outputForm: OutputFormSection[];
  outputFormThem?: OutputFormSection[];
  useSeparateOutputFormThem?: boolean; // used as display condition for outputFormThem in connection editor

  outputs: ConnectionOutput<OutputEmail | OutputRedirect | OutputContact>[];

  // optional display
  displayType?: ConnectionDisplayType; // how the connection shall be displayed in the app

  // PUBLIC GETTERS
  get emailOutput(): OutputEmail | undefined {
    return this.outputs.find((output) => output.type === 'email') as
      | OutputEmail
      | undefined;
  }

  get redirectOutput(): OutputRedirect | undefined {
    return this.outputs.find((output) => output.type === 'redirect') as
      | OutputRedirect
      | undefined;
  }

  // CONSTRUCTOR
  constructor(connection: Partial<Connection>) {
    this.source = connection.source ?? 'manual-input';
    this.alissId = connection.alissId;
    this.name = connection.name ?? 'no-connection-name';
    this.type = connection.type === 'signpost' ? 'signpost' : 'connection';
    this.title = connection.title ?? 'Connection Title missing';
    this.excerpt = connection.excerpt ?? '';
    this.image =
      connection.image ??
      'https://images.pexels.com/photos/5825696/pexels-photo-5825696.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2';
    this.icon = connection.icon;
    this.priority = connection.priority ?? 3;
    this.priorityUser = connection.priorityUser ?? 5;
    this.postcode = connection.postcode;
    this.organisation = connection.organisation;
    this.dataUsageStatement = connection.dataUsageStatement;

    this.appLink = connection.appLink;
    this.webLink = connection.webLink;
    this.summary = new ConnectionSummary(connection.summary);
    this.finderPaths = connection.finderPaths ?? [];
    this.displayInPublicFinder = connection.displayInPublicFinder ?? true;
    this.displayLogic = connection.displayLogic;

    this.outputs = mapConnectionOutputs(connection.outputs);
    this.outputForm = mapData(connection.outputForm) ?? [];
    this.outputFormThem = mapData(connection.outputFormThem);
    this.useSeparateOutputFormThem =
      connection.useSeparateOutputFormThem ?? false;
  }

  get hasDataAndOutputs(): boolean {
    const hasRelevantOutput = this.outputs.some(
      (output) => output.type === 'email' || output.type === 'redirect'
    );

    return this.outputForm.length > 0 && hasRelevantOutput;
  }

  get allFormItemPaths(): string[] {
    return this.outputForm.flatMap(
      (formSection) => formSection.allFormSectionItemPaths
    );
  }

  get allRequiredFormItemPaths(): string[] {
    return this.outputForm.flatMap(
      (formSection) => formSection.allRequiredFormSectionItemPaths
    );
  }

  get allThemFormItemPaths(): string[] | undefined {
    return this.outputFormThem?.flatMap(
      (formSection) => formSection.allFormSectionItemPaths
    );
  }

  public static fromConnectionServerModel(
    serverModel: ConnectionServerModel
  ): Connection {
    let connection = new Connection({});

    connection.source = serverModel.source || 'manual-input';
    connection.alissId = serverModel.alissId;
    connection.name = serverModel.name || 'no-connection-name';
    connection.type =
      serverModel.type === 'signpost' ? 'signpost' : 'connection';
    connection.title = serverModel.title || 'Connection Title missing';
    connection.excerpt = serverModel.excerpt || '';
    connection.image =
      serverModel.image ||
      'https://images.pexels.com/photos/5825696/pexels-photo-5825696.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2';
    connection.icon = serverModel.icon;
    connection.priority = serverModel.priority || 3;
    connection.priorityUser = serverModel.priorityUser || 5;
    connection.postcode = serverModel.postcode;
    connection.organisation = serverModel.organisation;
    connection.dataUsageStatement = serverModel.dataUsageStatement;

    connection.appLink = serverModel.connectionData.appLink;
    connection.webLink = serverModel.connectionData.webLink;
    connection.summary = new ConnectionSummary(
      serverModel.connectionData.summary
    );
    connection.finderPaths = serverModel.connectionData.finderPaths;
    connection.outputForm = mapData(serverModel.connectionData.data) ?? [];
    connection.outputFormThem = mapData(
      serverModel.connectionData.outputFormThem
    );
    connection.useSeparateOutputFormThem =
      serverModel.connectionData.useSeparateOutputFormThem ?? false;

    connection.displayInPublicFinder =
      serverModel.connectionData.displayInPublicFinder;
    connection.displayLogic = serverModel.connectionData.displayLogic;
    connection.outputs = mapConnectionOutputs(
      serverModel.connectionData.outputs
    );

    return connection;
  }
}

export class ConnectionSummary {
  title?: string; // 80 character title
  headline?: string; // 200 characters
  description?: string; // 3000 characters
  highlights?: string[]; // displays as bulleted list of text after the description
  images?: string[]; // additional images to display

  constructor(data?: Partial<ConnectionSummary>) {
    this.title = data && data.title;
    this.headline = data && data.headline;
    this.description =
      data && data.description
        ? this.cleanUpDescriptionFormatting(data.description)
        : undefined;
    this.images = data && data.images ? data.images : [];
    this.highlights = data && data.highlights ? data.highlights : [];
  }

  cleanUpDescriptionFormatting(description: string): string {
    return description
      .replace(/&amp;/g, '&')
      .replace(/&nbsp;/g, ' ')
      .replace(/(\r\n){3,}/g, '\r\n\r\n');
  }
}

// HELPER FUNCTIONS

function mapConnectionOutputs(
  outputs?:
    | ConnectionOutput<OutputEmail | OutputRedirect | OutputContact>[]
    | undefined
): ConnectionOutput<OutputEmail | OutputRedirect | OutputContact>[] {
  if (!outputs) {
    return [];
  }

  return outputs.map((c) => {
    switch (c.type) {
      case 'contact':
        return new OutputContact(c);
      case 'email':
        return new OutputEmail(c);
      default:
        return new OutputRedirect(c);
    }
  });
}

function mapData(data?: OutputFormSection[]): OutputFormSection[] | undefined {
  if (!data) {
    return undefined;
  }

  return data.map((d) => new OutputFormSection(d));
}
