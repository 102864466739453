export class UserHealth {
  medicalHistory?: UserHealthMedicalHistory;
  //wellbeing?: UserHealthWellbeing; // TODO: Implement UserHealthWellbeing once requirements are clear
  memory?: UserHealthMemory;
  stayingSafe?: UserHealthStayingSafe;
  important?: UserHealthImportant;

  constructor(health?: Partial<UserHealth>) {
    this.medicalHistory = new UserHealthMedicalHistory(health?.medicalHistory);
    //this.wellbeing = new UserHealthWellbeing(health?.wellbeing);
    this.memory = new UserHealthMemory(health?.memory);
    this.stayingSafe = new UserHealthStayingSafe(health?.stayingSafe);
    this.important = new UserHealthImportant(health?.important);
  }
}

class UserHealthMedicalHistory {
  presentConditions?: string;
  pastConditions?: string;
  surgicalInterventions?: UserHealthMedicalHistorySurgicalIntervention[];
  disabilityPerception?: boolean;
  disabilityPerceptionAffect?: string;
  disabilityRegistered?: boolean;
  disabilityRegisteredNumber?: string;

  constructor(data: Partial<UserHealthMedicalHistory | undefined>) {
    this.presentConditions = data?.presentConditions;
    this.pastConditions = data?.pastConditions;
    this.surgicalInterventions = data?.surgicalInterventions?.map(
      (s) => new UserHealthMedicalHistorySurgicalIntervention(s)
    );
    this.disabilityPerception = data?.disabilityPerception;
    this.disabilityPerceptionAffect = data?.disabilityPerceptionAffect;
    this.disabilityRegistered = data?.disabilityRegistered;
    this.disabilityRegisteredNumber = data?.disabilityRegisteredNumber;
  }
}

class UserHealthMedicalHistorySurgicalIntervention {
  name: string;
  date?: string;

  constructor(
    data: Partial<UserHealthMedicalHistorySurgicalIntervention | undefined>
  ) {
    this.name = data?.name ?? '';
    this.date = data?.date;
  }
}

// TODO: Implement UserHealthWellbeing once requirements are clear
// class UserHealthWellbeing {
//   conditions?: string;

//   constructor(data: Partial<UserHealthWellbeing | undefined>) {
//     this.conditions = data?.conditions;
//   }
// }

class UserHealthMemory {
  problemAwareness?: string;
  easyToRemember?: string[];
  hardToRemember?: string[];
  concernedSocialCircle?: boolean;
  concernedSocialCircleDetails?: string;
  dementiaDiagnosisDate?: string;
  areasRequiringSupport?: string[];
  supportingAids?: string[];
  condition?: string;
  poaInPlace?: boolean;
  additionalInfo?: string;

  constructor(data: Partial<UserHealthMemory | undefined>) {
    this.problemAwareness = data?.problemAwareness;
    this.easyToRemember = data?.easyToRemember;
    this.hardToRemember = data?.hardToRemember;
    this.concernedSocialCircle = data?.concernedSocialCircle;
    this.concernedSocialCircleDetails = data?.concernedSocialCircleDetails;
    this.dementiaDiagnosisDate = data?.dementiaDiagnosisDate;
    this.areasRequiringSupport = data?.areasRequiringSupport;
    this.supportingAids = data?.supportingAids;
    this.condition = data?.condition;
    this.poaInPlace = data?.poaInPlace;
    this.additionalInfo = data?.additionalInfo;
  }
}

class UserHealthStayingSafe {
  impactsFeelingSafe?: string;
  impactsFeelingUnsafe?: string;
  homeAdjustments?: string;

  constructor(data: Partial<UserHealthStayingSafe | undefined>) {
    this.impactsFeelingSafe = data?.impactsFeelingSafe;
    this.impactsFeelingUnsafe = data?.impactsFeelingUnsafe;
    this.homeAdjustments = data?.homeAdjustments;
  }
}

class UserHealthImportant {
  importantThings?: string;
  preferredLifestyle?: string;
  supportNetwork?: string;
  additionalInfo?: string;

  constructor(data: Partial<UserHealthImportant | undefined>) {
    this.importantThings = data?.importantThings;
    this.preferredLifestyle = data?.preferredLifestyle;
    this.supportNetwork = data?.supportNetwork;
    this.additionalInfo = data?.additionalInfo;
  }
}
