import { Validators } from '@angular/forms';
import {
  FormItemButtonSelect,
  FormItemTextArea,
  FormItemTextbox,
  FormItemTitle,
} from 'src/app/models/form/form';
import { yesNoOptions } from '../item-options';

export const continenceProductsInUse = new FormItemTextbox({
  key: 'continenceProductsInUse',
  path: 'support.continence.productsInUse',
  label: 'What kind of products do you use?',
  placeholder: 'e.g. I use continence pads and usually go through 2 or 3 a day',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const continenceRoutines = new FormItemTextArea({
  key: 'continenceRoutines',
  path: 'support.continence.routines',
  label: 'What kind of routines help you?',
  placeholder:
    'e.g. I like to be close to the toilet in the house and whenever I go out I always find out where the toilets are beforehand. I like to go to the toilet every 2 hours, it makes me feel safer.',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const continenceSupportNeededInCaseOfEmergency = new FormItemTextArea({
  key: 'continenceSupportNeededInCaseOfEmergency',
  path: 'support.continence.supportNeededInCaseOfEmergency',
  label: 'What kind of support do you need in an emergency?',
  placeholder:
    'e.g. Occasionally I have been doubly-incontinent and needed to call the community alarm team for assistance. So far this has not happened to me outdoors. If it did happen outdoors, I would want to get in a taxi and come straight home.',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const continenceInContactWithCareTeam = new FormItemButtonSelect({
  key: 'continenceInContactWithCareTeam',
  path: 'support.continence.inContactWithCareTeam',
  label: 'Are you in touch with the continence care team?',
  class: 'mb-5',
  options: yesNoOptions,
});

export const continenceCareTeamContactDetailsTitle = new FormItemTitle({
  key: 'continenceCareTeamContactDetailsTitle',
  label: 'Care Team Contact Details',
  class: 'mt-2 mb-5',
  displayConditions: [
    {
      key: 'continenceInContactWithCareTeam',
      values: [true],
    },
  ],
});

export const continenceCareTeamName = new FormItemTextbox({
  key: 'continenceCareTeamName',
  path: 'support.continence.careTeam.name',
  label: 'Name of Care Team',
  class: 'mb-5',
  width: 'half',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
  displayConditions: [
    {
      key: 'continenceInContactWithCareTeam',
      values: [true],
    },
  ],
});

export const continenceCareTeamPhone = new FormItemTextbox({
  key: 'continenceCareTeamPhone',
  path: 'support.continence.careTeam.phone',
  label: 'Phone Number',
  class: 'mb-5',
  width: 'half',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
  displayConditions: [
    {
      key: 'continenceInContactWithCareTeam',
      values: [true],
    },
  ],
});

export const continenceCareTeamEmail = new FormItemTextbox({
  key: 'continenceCareTeamEmail',
  path: 'support.continence.careTeam.email',
  label: 'Email Address',
  class: 'mb-5',
  width: 'half',
  validators: [
    Validators.minLength(1),
    Validators.maxLength(2000),
    Validators.email, // use built-in validator because trying to copy PHP's validator (Mydex uses) is not recommended, as testing correctness is difficult
  ],
  displayConditions: [
    {
      key: 'continenceInContactWithCareTeam',
      values: [true],
    },
  ],
});
