<div class="mx-auto container-xl">
  <div class="lg:grid lg:grid-cols-3 lg:gap-12">
    <div class="col-span-2">
      <h1>Search Services</h1>
      <div class="mt-8 mb-0">
        <input-filter
          [value]="searchTerm"
          placeholder="Search for specific services here"
          [clearable]="true"
          (onChanged)="filterList($event)"
        ></input-filter>
      </div>
    </div>
    <div
      class="col-span-1 bg-white px-6 lg:mb-6 lg:mt-10 rounded-lg shadow-md py-2 lg:py-0 flex flex-col justify-center"
    >
      <h4 class="mt-4 mb-0">Not sure what you're looking for?</h4>
      <p class="mt-2">
        Use our <a class="text-theme" routerLink="/finder">Service Finder</a> to
        help identify what would benefit you.
      </p>
    </div>
  </div>

  <div class="mt-8">
    <app-connections
      [connections]="connections"
      [errorMessageNoConnections]="errorMessageNoConnections"
      (connectionOpened)="handleConnectionOpened($event)"
      (signpostClicked)="handleSignpostClicked($event)"
    ></app-connections>
  </div>
</div>
