import {
  FormItemButtonSelect,
  FormItemDropdown,
} from 'src/app/models/form/form';
import { yesNoPreferNotToSayOptions } from '../item-options';

export const financialImpactCaringAffectsFinances = new FormItemButtonSelect({
  key: 'financialImpactCaringAffectsFinances',
  label: 'My caring role impacts on my finances',
  path: 'supportingRole.financialImpact.caringAffectsFinances',
  class: 'mb-5',
  options: yesNoPreferNotToSayOptions,
});

export const financialImpactHasFinancialDifficulties = new FormItemButtonSelect(
  {
    key: 'financialImpactHasFinancialDifficulties',
    label: 'I find it difficult to manage financially',
    path: 'supportingRole.financialImpact.hasFinancialDifficulties',
    class: 'mb-5',
    options: yesNoPreferNotToSayOptions,
  }
);

export const financialImpactSummary = new FormItemDropdown({
  key: 'financialImpactSummary',
  label:
    'Thinking about your financial position, the selected statement best matches how I currently feel.',
  path: 'supportingRole.financialImpact.summary',
  class: 'mb-5',
  placeholder: 'Please select...',
  options: [
    {
      name: '1',
      label:
        "I'm managing financially and know where to go if I need support, or I do not want any help with my finances.",
    },
    {
      name: '2',
      label:
        'My financial situation is mostly OK but caring makes it difficult to meet some costs such as housing or utilities.',
    },
    {
      name: '3',
      label:
        "I'm trying to sort out financial matters related to caring, but it's hard. I struggle to meet costs such as housing or utilities.",
    },
    {
      name: '4',
      label:
        'I have some help to address the financial problems resulting from my caring role, but they present significant difficulties and I often cannot pay bills.',
    },
    {
      name: '5',
      label:
        "There are financial problems as a result of my caring role but I don't want people to know/prefer not to discuss them/don't know where to start to address them. Caring is causing severe financial hardship.",
    },
  ],
});
