import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-record',
  templateUrl: './record.component.html',
  styleUrls: ['./record.component.css'],
})
export class RegisterSetupRecordComponent {
  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params['success'] === '0') {
        this.router.navigate(['/register'], {
          queryParams: { error: 'failedPrivateKeyStep' },
        });
      }
    });
  }
}
