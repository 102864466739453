<div class="max-w-5xl mx-auto">
  <!--LOADING SPINNER-->
  <app-loading-spinner
    *ngIf="connectionsStateService.loading$ | async"
  ></app-loading-spinner>
  <!--END LOADING SPINNER-->

  <!-- SERVER ERROR: FAILED LOADING CONNECTIONS -->
  <app-no-items-error
    [isDisplayed]="
      !(connectionsStateService.loading$ | async) &&
      (connectionsStateService.loadingError$ | async)
    "
    [errorMessage]="connectionsStateService.loadingError$ | async"
    showBackButton="true"
    backNavigationContext="../"
  >
  </app-no-items-error>
  <!-- END SERVER ERROR: FAILED LOADING CONNECTIONS -->

  <!--CONTENT-->
  <ng-container
    *ngIf="
      !(connectionsStateService.loading$ | async) &&
      !(connectionsStateService.loadingError$ | async)
    "
  >
    <!-- ERROR MESSAGE: CONNECTION NOT FOUND -->
    <app-no-items-error
      [isDisplayed]="!connection"
      errorMessage="Unable to find a connection with this name."
      showBackButton="true"
      backNavigationContext="../"
    >
    </app-no-items-error>
    <!--END ERROR MESSAGE: CONNECTION NOT FOUND -->

    <!--CONNECTION EDITOR-->
    <ng-container *ngIf="connection">
      <div class="flex justify-between items-center">
        <!--TITLE-->
        <h1>Connection Editor - {{ connection.title || "New Connection" }}</h1>
        <!--END TITLE-->
        <!--DELETE BUTTON FOR EXISTING CONNECTION-->
        <button
          (click)="openDeletionModal($event)"
          class="btn-theme-outline outlined px-12 ml-2"
          *ngIf="!isNewConnection"
        >
          Delete Connection
        </button>
        <!--END DELETE BUTTON FOR EXISTING CONNECTION-->
      </div>

      <!-- MRD CONNECTION HINT -->
      <ng-container *ngIf="connection.source === 'mrd'">
        <h4 class="text-theme-darker">Important Note</h4>
        <p class="text-theme-darker">
          These connection details were fetched at least partially from MRD. The
          properties 'Name / Slug', 'Title', 'Postcode', 'Organisation',
          'Details - Description' and some of the 'Contact Output Details' can
          therefore not be altered. Instead, these will be updated Tuesdays at
          midnight based on the latest data from MRD.
        </p>
      </ng-container>
      <!-- END MRD CONNECTION HINT -->

      <!-- MRD CONNECTION FORM-->
      <h2 *ngIf="isNewConnection" class="text-theme-dark">
        Add a connection from ALISS via MRD (Master Reference Data Services)
      </h2>
      <div *ngIf="isNewConnection" class="mt-8 mb-12 p-8 rounded-lg bg-white">
        <app-form
          [formData]="mrdForm"
          [formValues]="mrdConnectionRequest"
          (submit)="handleSaveMrdForm($event)"
          submitText="Submit"
          [loading]="(connectionsStateService.loadingFromMrd$ | async) === true"
          [error]="(connectionsStateService.loadingFromMrdError$ | async) || ''"
        >
        </app-form>
      </div>
      <!-- END MRD CONNECTION FORM-->

      <!-- MANUAL INPUT CONNECTION FORM-->
      <h2 *ngIf="isNewConnection" class="text-theme-dark mb-0 mt-8">
        Add a connection manually
      </h2>
      <app-form
        [formData]="manualInputForm"
        [formValues]="connection"
        (submit)="handleSaveManualInputForm($event)"
        submitText="Save and Close"
        [loading]="(connectionsStateService.saving$ | async) === true"
        [error]="(connectionsStateService.savingError$ | async) || ''"
        [cancellable]="true"
        cancelText="Back"
        (cancel)="navigateBack()"
      >
      </app-form>
      <!-- END MANUAL INPUT CONNECTION FORM-->
    </ng-container>
    <!--END CONNECTION EDITOR-->
  </ng-container>
  <!--END CONTENT-->
</div>

<!--DELETE CONNECTION MODAL-->
<app-modal (onClose)="onClosingDeletionModal()">
  <h3 class="mt-0">Proceed with deletion?</h3>
  <p>Are you sure you want to permanently delete this connection?</p>
  <button
    class="btn btn-theme btn-sm mt-4"
    (click)="handleDelete()"
    [disabled]="connectionsStateService.deleting$ | async"
    [ngClass]="{
      'pointer-events-none': (connectionsStateService.deleting$ | async),
    }"
  >
    <ng-container
      class="opacity-75"
      *ngIf="connectionsStateService.deleting$ | async"
    >
      <app-loading-spinner text="Deleting"></app-loading-spinner>
    </ng-container>

    <ng-container *ngIf="!(connectionsStateService.deleting$ | async)">
      Delete Connection
    </ng-container>
  </button>

  <button (click)="closeDeletionModal()" class="btn btn-theme btn-sm ml-2">
    Cancel
  </button>

  <div
    *ngIf="
      !(connectionsStateService.deleting$ | async) &&
      (connectionsStateService.deletionError$ | async)
    "
    class="text-error-red form-error-text my-auto mt-2"
  >
    {{ connectionsStateService.deletionError$ | async }}
  </div>
</app-modal>
<!--END DELETE CONNECTION MODAL-->
