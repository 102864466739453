import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.css'],
})
export class DefaultComponent {
  @Input() value: any;

  get content(): any {
    const keys = Object.keys(this.value);

    const otherContent = Object.keys(this.value)
      .splice(1)
      .map((key) => this.value[key])
      .filter((x) => typeof x === 'string');
    return {
      title: this.value[keys[0]],
      other: otherContent,
    };
  }
}
