import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'input-filter',
  templateUrl: './input-filter.component.html',
  styleUrls: ['./input-filter.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputFilterComponent),
      multi: true,
    },
  ],
})
export class InputFilterComponent implements OnInit, ControlValueAccessor {
  @Input() value!: string;
  @Input() clearable!: boolean;
  @Input() placeholder!: string;
  @Input() loading!: boolean;
  @Output() onChanged = new EventEmitter<string>();
  @Output() onSearchButtonClicked = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {}

  handleOnChanged(event: any) {
    this.value = event.target.value;
    this.onChanged.emit(event.target.value);
  }

  clear() {
    this.value = '';
    this.onChanged.emit(this.value);
  }

  handleSearchButtonClicked() {
    this.onSearchButtonClicked.emit(this.value);
  }

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChanged.subscribe(fn);
  }

  registerOnTouched(fn: any): void {}

  setDisabledState?(isDisabled: boolean): void {}
}
