import { Component } from '@angular/core';
import { PdsService } from 'src/app/services/pds/pds.service';

@Component({
  selector: 'app-update-permissions',
  templateUrl: './update-permissions.component.html',
  styleUrls: ['./update-permissions.component.css'],
})
export class UpdatePermissionsComponent {
  constructor(protected pdsService: PdsService) {}
  submit() {
    this.pdsService.getUpdateAccessApprovalUrlAndRedirect();
  }

  ngOnDestroy() {
    this.pdsService.resetErrors();
  }
}
