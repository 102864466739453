import { FormGroup, ValidatorFn } from '@angular/forms';
import { Action } from './action';
import { Type } from '@angular/core';
import { OutputFormSection } from '../connection/outputFormSection';
import { ConnectionDisplayLogic } from '../connection/connectionDisplayLogic';
import { FileData } from '../base/fileData';

export class Form {
  id: string;
  title: string;
  type: string;
  formItems: FormItem<any>[];
  formGroup?: FormGroup;
  description?: string;

  constructor(
    id: string = '',
    title: string = '',
    formItems: FormItem<any>[] = [],
    description: string = '',
    formGroup?: FormGroup,
    type: string = 'form'
  ) {
    this.id = id;
    this.title = title;
    this.formItems = formItems;
    this.description = description;
    this.formGroup = formGroup;
    this.type = type;
  }
}

export class FormItem<T> {
  value: T | undefined;
  key: string;
  label: string;
  required: boolean;
  order: number;
  controlType: string;
  type: string;
  options: FormItemOption[];
  optionGroups: FormItemOptionGroup[];
  disabled: boolean;
  path?: string;
  addTitle?: string;
  validators: ValidatorFn[];
  placeholder: string;
  payloadIgnore: boolean;
  displayConditions: FormItemDisplayCondition[];
  unit: string;
  help: string;
  currency: string;
  titleType: string;
  settings?: { [key: string]: string }[];
  class?: string;
  width: 'half' | 'full';
  action?: Action;
  hide?: boolean;
  validatorsErrorMessages: validatorsErrorMessages[] = [];
  guidanceForUsage?: string;
  icon?: string;
  showLabel: boolean;

  constructor(options: Partial<FormItem<T>>) {
    this.value = options.value ? options.value : undefined;
    this.key = options.key || '';
    this.label = options.label || '';
    this.required = options.required ? options.required : false;
    this.order = options.order === undefined ? 1 : options.order;
    this.controlType = options.controlType || '';
    this.type = options.type || '';
    this.icon = options.icon || '';
    this.options = options.options || [];
    this.optionGroups = options.optionGroups || [];
    this.disabled = options.disabled || false;
    this.addTitle = options.addTitle || '';
    this.path = options.path || '';
    this.validators = options.validators || [];
    this.placeholder = options.placeholder || '';
    this.payloadIgnore = options.payloadIgnore || false;
    this.unit = options.unit || '';
    this.help = options.help || '';
    this.currency = options.currency || '';
    this.titleType = options.titleType || '';
    this.settings = options.settings || [];
    this.displayConditions = options.displayConditions || [];
    this.class = options.class || '';
    this.width = options.width || 'full';
    this.action = options.action || undefined;
    this.hide = options.hide || false;
    this.showLabel = options.showLabel || true;
    this.validatorsErrorMessages = options.validatorsErrorMessages || [];
    this.guidanceForUsage = options.guidanceForUsage || '';
  }
}

export interface FormItemOption {
  name: string | number | boolean;
  label?: string;
}

export interface FormItemOptionGroup {
  label: string;
  options: FormItemOption[];
}

export interface validatorsErrorMessages {
  type: string; // e.g. pattern
  message: string;
}

export interface FormItemDisplayCondition {
  key: string;
  values: any[];
}

interface CustomPathLogic {
  getValueFromSource: (sourceValue: any) => any;
  prepareValueForPayload: (currentValueAtPath: any, newValue: any) => any;
}

export function implementsCustomPathLogicInterface(
  obj: any
): obj is CustomPathLogic {
  return obj.customLogic !== undefined;
}

interface SourceValueTransformer {
  getValueFromSource: (sourceValue: any) => any;
  prepareValueForPayload: (currentValueAtPath: any, newValue: any) => any;
}

export function implementsSourceValueTransformerInterface(
  obj: any
): obj is SourceValueTransformer {
  return (
    obj.getValueFromSource !== undefined &&
    obj.prepareValueForPayload !== undefined
  );
}

export class FormItemTitle extends FormItem<string> {
  override controlType = 'title';
  override type = 'title';
}

export class FormItemLink extends FormItem<string> {
  override controlType = 'link';
  override type = 'link';
  linkType: 'routerlink' | 'href';
  linkLabel: string;
  link: string;
  description: string;

  constructor(options: Partial<FormItemLink>) {
    super(options);
    this.linkType = options.linkType || 'routerlink';
    this.linkLabel = options.linkLabel || '';
    this.link = options.link || '';
    this.description = options.description || '';
  }
}

export class FormItemParagraph extends FormItem<string> {
  override controlType = 'paragraph';
  override type = 'paragraph';
}

export class FormItemTextbox extends FormItem<string> {
  override controlType = 'textbox';
  override type = 'textbox';

  constructor(options: Partial<FormItemTextbox>) {
    super(options);
  }
}

export class FormItemTextboxWithAffix extends FormItem<string> {
  override controlType = 'textboxwithaffix';
  override type = 'textboxwithaffix';
  prefix: string;

  constructor(options: Partial<FormItemTextboxWithAffix>) {
    super(options);
    this.prefix = options.prefix ?? '';
  }
}

export class FormItemAddress extends FormItem<string> {
  override controlType = 'address';
  override type = 'address';
  autofill: any;

  constructor(options: Partial<FormItemAddress>) {
    super(options);
    this.autofill = options.autofill ?? []; // Default to false if not provided
  }
}

export class FormItemTextArea extends FormItem<string> {
  override controlType = 'textarea';
  override type = 'textarea';
  suggestions?: string[];
  rows?: number;
  constructor(options: Partial<FormItemTextArea>) {
    super(options);
    this.suggestions = options.suggestions;
    this.rows = options.rows;
  }
}
export class FormItemNumber extends FormItem<number> {
  override controlType = 'number';
  override type = 'number';
}
export class FormItemButtonSelect extends FormItem<string | number> {
  override controlType = 'buttonselect';
  override type = 'buttonselect';
  styleType: 'tab' | 'button';
  multiple: boolean;
  requireOneSelection: boolean;

  constructor(options: Partial<FormItemButtonSelect>) {
    super(options);
    this.styleType = options.styleType ?? 'button'; // Default to buttons
    this.multiple = options.multiple ?? false;
    this.requireOneSelection = options.requireOneSelection ?? false; // Default to false to allow deselection
  }
}

export class FormItemDropdown extends FormItem<string> {
  override controlType = 'dropdown';
  override type = 'dropdown';
}

export class FormItemDob extends FormItem<Date> {
  override controlType = 'dob';
  override type = 'dob';
  minDate?: Date;
  maxDate?: Date;

  constructor(data: Partial<FormItemDate>) {
    super(data);
    this.minDate = data.minDate;
    this.maxDate = data.maxDate;
  }
}

export class FormItemEmailContent extends FormItem<string> {
  override controlType = 'emailcontent';
  override type = 'emailcontent';
  salutation: string;
  introduction: string;
  closing: string;
  signature: string;

  constructor(data: Partial<FormItemEmailContent>) {
    super(data);
    this.salutation = data.salutation ?? 'Dear Sir or Madam,';
    this.introduction = data.introduction ?? 'Please see the details below:';
    this.closing = data.closing ?? 'Thanks,';
    this.signature = data.signature ?? 'Community Connections @ Moray';
  }
}

export class FormItemContacts extends FormItem<string[]> {
  override controlType = 'contacts';
  override type = 'contacts';
  multiple: boolean;
  contactEditorForm: FormItem<any>[] = [];

  constructor(data: Partial<FormItemContacts>) {
    super(data);
    this.multiple = data.multiple ?? false;
    this.contactEditorForm = data.contactEditorForm ?? [];
  }
}

export class FormItemFormSections extends FormItem<OutputFormSection[]> {
  override controlType = 'formsections';
  override type = 'formsections';
  formItemPathOptions: FormItem<any>[];
  getFormattedItemPath: (path: string) => string;
  maxLengthTitle: number;
  maxLengthDescription: number;
  maxNumberOfSections: number;
  maxNumberOfItemsPerSection: number;

  constructor(options: Partial<FormItemFormSections>) {
    super(options);
    this.formItemPathOptions = options.formItemPathOptions ?? [];
    this.getFormattedItemPath =
      options.getFormattedItemPath ?? ((path) => path);
    this.maxLengthTitle = options.maxLengthTitle || 100;
    this.maxLengthDescription = options.maxLengthDescription || 500;
    this.maxNumberOfSections = options.maxNumberOfSections || 10;
    this.maxNumberOfItemsPerSection = options.maxNumberOfItemsPerSection || 20;
  }
}

export class FormItemDisplayLogic extends FormItem<ConnectionDisplayLogic> {
  override controlType = 'displaylogic';
  override type = 'displaylogic';
}

export class FormItemSelect extends FormItem<string[]> {
  override controlType = 'select';
  override type = 'select';
  multiple: boolean;

  constructor(options: Partial<FormItemSelect>) {
    super(options);
    this.multiple = options.multiple ?? false;
  }
}

export class FormItemDate extends FormItem<Date> {
  override controlType = 'date';
  override type = 'date';
  minDate?: Date;
  maxDate?: Date;

  constructor(data: Partial<FormItemDate>) {
    super(data);
    this.minDate = data.minDate;
    this.maxDate = data.maxDate;
  }
}

export class FormItemCheckbox extends FormItem<boolean> {
  override controlType = 'checkbox';
  override type = 'checkbox';
  labelIsHtml: boolean;

  constructor(options: Partial<FormItemCheckbox>) {
    super(options);
    this.labelIsHtml = options.labelIsHtml ?? false;
  }
}

export class FormItemPassword extends FormItem<string> {
  override controlType = 'password';
  override type = 'password';
  showPasswordToggle: boolean;
  showPassword: boolean = false;

  constructor(options: Partial<FormItemPassword>) {
    super(options);
    this.showPasswordToggle = options.showPasswordToggle ?? true;
  }
}

export class FormItemImageUpload extends FormItem<string> {
  override controlType = 'image-upload';
  override type = 'image-upload';
  placeholderIconName: string;
  imageDownloadName: string;

  constructor(options: Partial<FormItemImageUpload>) {
    super(options);
    this.placeholderIconName = options.placeholderIconName ?? 'person';
    this.imageDownloadName = options.imageDownloadName ?? 'image';
  }
}

export class FormItemImageUploadGallery extends FormItem<string[]> {
  override controlType = 'image-upload-gallery';
  override type = 'image-upload-gallery';
  placeholderIconName: string;
  imageDownloadName: string;
  editableDescription: boolean;

  constructor(options: Partial<FormItemImageUploadGallery>) {
    super(options);
    this.placeholderIconName = options.placeholderIconName ?? 'image';
    this.imageDownloadName = options.imageDownloadName ?? 'image';
    this.editableDescription = options.editableDescription ?? false;
  }
}

export class FormItemFileUpload extends FormItem<FileData> {
  override controlType = 'file-upload';
  override type = 'file-upload';
  allowedExtensions: string;

  constructor(options: Partial<FormItemFileUpload>) {
    super(options);
    this.allowedExtensions =
      options.allowedExtensions ?? '.pdf, .doc, .docx, .jpg, .jpeg, .png, .gif';
  }
}

export class FormItemSubform<T>
  extends FormItem<T>
  implements SourceValueTransformer
{
  override controlType = 'subform';
  override type = 'subform';
  subform: FormItem<any>[] = [];
  display: 'inline' | 'modal';
  titleField: string | null;
  multiple: boolean;
  displayComponent?: Type<any>;
  saveOnChange: boolean;
  createValueWithParams?: (params: any) => any;
  getValueFromSource: (sourceValue: any) => any;
  prepareValueForPayload: (currentValueAtPath: any, newValue: any) => any;

  constructor(options: Partial<FormItemSubform<T>>) {
    super(options);
    this.subform = options.subform ?? [];
    this.titleField = options.titleField || null;
    this.addTitle = options.addTitle || 'Add a new item';
    this.display = options.display || 'inline'; // Default display is inline
    this.multiple = options.multiple ?? true;
    this.displayComponent = options.displayComponent;
    this.saveOnChange = options.saveOnChange ?? false;
    this.getValueFromSource =
      options.getValueFromSource ?? ((sourceValue: any) => sourceValue); // Default to returning the source value
    this.prepareValueForPayload =
      options.prepareValueForPayload ??
      ((currentValueAtPath: any, newValue: any) => newValue); // Default to returning the new value
    this.createValueWithParams = options.createValueWithParams;
    this.placeholder =
      options.placeholder ?? 'No ' + this.label + ' added yet.'; // pass empty string to remove placeholder
  }
}

export class FormItemRepeater<T> extends FormItem<T> {
  override controlType = 'repeater';
  override type = 'repeater';
  formItems: FormItem<any>[] = [];
  showLabels: boolean;
  constructor(options: Partial<FormItemRepeater<T>>) {
    super(options);
    this.formItems = options.formItems ?? [];
    this.showLabels = options.showLabels ?? false;
  }
}
