<div class="relative rounded-lg">
  <!-- For highlighting selected suggestions in text: -->
  <div
    #backdrop
    class="overflow-auto backdrop px-6 py-[18px] bg-white rounded-lg absolute bottom-0 top-0 left-0 right-0"
  >
    <div
      #highlights
      class="highlights text-transparent whitespace-pre-wrap"
      [style]="textareaStyle"
    ></div>
  </div>

  <textarea
    [(ngModel)]="value"
    (change)="update()"
    [id]="item.key"
    (ngModelChange)="handleInputChange()"
    [placeholder]="item.placeholder ? item.placeholder : 'Enter some text...'"
    class="form-control relative bg-transparent"
    [rows]="item?.rows ?? 3"
    (scroll)="handleScroll($event)"
    [class.is-invalid]="control.touched && control.errors"
    [style]="textareaStyle"
    [disabled]="disabled"
  ></textarea>

  <div
    *ngIf="suggestions?.length"
    #suggestions
    class="absolute suggestions bottom-[1px] px-6 pb-4 pt-2 bg-white rounded-lg left-[2px] right-[10px] box-border flex flex-col"
  >
    <p class="italic text-gray-400 text-sm mb-2 mt-0">Suggestions:</p>
    <div class="grid grid-flow-col gap-3 mr-auto">
      <button
        class="px-4 py-[6px] border border-solid flex transition-all"
        [class]="
          suggestion.inText
            ? 'opacity-70 pointer-events-none bg-theme bg-opacity-10 border-opacity-10 border-theme text-theme-darker'
            : 'btn-grey'
        "
        (click)="handleSuggestion(suggestion)"
        type="button"
        *ngFor="let suggestion of allSuggestions"
      >
        {{ suggestion?.value }}

        <app-icon
          name="check"
          class="ml-1 my-auto pt-[1px] -mr-[3px]"
          *ngIf="suggestion.inText"
        ></app-icon>
      </button>
    </div>
  </div>
</div>
