import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { registerForm } from 'src/assets/forms/register-and-login/form-register';

//import auth service
import { AuthService } from '../../services/auth/auth.service';
import { getErrorMessage } from 'src/app/helper/getErrorMessage';
import { SessionStorageService } from 'src/app/services/session-storage/session-storage.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
})
export class RegisterComponent {
  form = registerForm;
  user = {};
  loading = false;
  success = false;
  errorText = '';

  constructor(
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute,
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnInit(): void {
    const hasOnboarded =
      this.sessionStorageService.getShownRegistrationOnboarding();

    this.route.queryParams.subscribe((params) => {
      // remember 'from' (unless it's onboarding) so no previous 'from' is overwritten because of onboarding
      if (params['from'] && params['from'] !== 'onboarding') {
        this.sessionStorageService.setRegistrationFlowStartingPoint(
          params['from']
        );
      }
      // if there is no 'from' query parameter, remove from the session storage
      else if (!params['from']) {
        this.sessionStorageService.removeRegistrationFlowStartingPoint();
      }

      if (params['error'] === 'failedPrivateKeyStep') {
        this.router.navigate([], {
          queryParams: { error: null },
          queryParamsHandling: 'merge',
        });
        this.error =
          'Something went wrong while creating a private key. Please try to register again.';
      }

      if (!hasOnboarded) {
        this.router.navigate(['/register/intro']);
      }
    });
  }

  set error(err) {
    this.errorText = err;
    setTimeout(() => {
      this.errorText = ''; //clear error after 8 seconds
    }, 8000);
  }
  get error() {
    return this.errorText;
  }

  registerForm = new FormGroup({
    mydexid: new FormControl(''),
    email: new FormControl(''),
    password: new FormControl(''),
  });

  submit(data: any) {
    this.loading = true;

    //Call the auth service register method
    this.authService
      .register(data.mydexid, data.email, data.password)
      .subscribe({
        next: () => {
          this.loading = false;
          this.success = true;
          // navigate to the next step after 0.5 seconds (to allow for the success message to be displayed)
          setTimeout(() => {
            this.router.navigate(['/register/setup/private-key']);
          }, 500);
        },
        error: (error) => {
          this.loading = false;
          this.error = getErrorMessage(error, 'Unable to register');
        },
      });
  }
}
