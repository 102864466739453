import { Component } from '@angular/core';
import { User } from 'src/app/models/user/user';
import * as _ from 'lodash';
import { UserStateService } from 'src/app/services/user.state.service';
import { Subject, takeUntil } from 'rxjs';
import { computeCompletedAboutMeSectionItemsPercentage } from 'src/app/helper/formCompleteness';
import { AboutMeSection } from 'src/app/models/about-me/aboutMeSection';
import { aboutMeSections } from 'src/assets/about-me/sections/aboutMeSections';

@Component({
  selector: 'app-account-about-me',
  templateUrl: './account-about-me.component.html',
  styleUrls: ['./account-about-me.component.css'],
})
export class AccountAboutMeComponent {
  // PROPERTIES

  protected sections = aboutMeSections;
  protected user: User | undefined;

  private destroy$: Subject<void> = new Subject<void>();

  // CONSTRUCTOR

  constructor(protected userService: UserStateService) {}

  // LIFECYCLE HOOKS

  ngOnInit() {
    this.userService.user$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (user) => {
        this.user = user;
      },
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();

    this.userService.resetErrors();
  }

  // METHODS

  computeCompletedPercentageForSection(section: AboutMeSection) {
    return computeCompletedAboutMeSectionItemsPercentage(this.user, section);
  }
}
