<div class="flex">
  <svg
    class="my-auto"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <style>
      .spinner_b2T7 {
        animation: spinner_xe7Q 0.8s linear infinite;
      }
      .spinner_YRVV {
        animation-delay: -0.65s;
      }
      .spinner_c9oY {
        animation-delay: -0.5s;
      }
      @keyframes spinner_xe7Q {
        93.75%,
        100% {
          r: 3px;
        }
        46.875% {
          r: 0.2px;
        }
      }
    </style>
    <circle fill="currentColor" class="spinner_b2T7" cx="4" cy="12" r="3" />
    <circle
      fill="currentColor"
      class="spinner_b2T7 spinner_YRVV"
      cx="12"
      cy="12"
      r="3"
    />
    <circle
      fill="currentColor"
      class="spinner_b2T7 spinner_c9oY"
      cx="20"
      cy="12"
      r="3"
    />
  </svg>
  <span class="inline-block ml-4" *ngIf="text?.length">{{ text }}</span>
</div>
