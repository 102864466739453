<!--LOADING SPINNER-->
<app-loading-spinner
  *ngIf="
    (connectionsStateService.loading$ | async) &&
    !(connectionsStateService.loadingError$ | async)
  "
></app-loading-spinner>
<!--END LOADING SPINNER-->

<!-- SERVER ERROR: FAILED LOADING CONNECTIONS -->
<app-no-items-error
  [isDisplayed]="
    !(connectionsStateService.loading$ | async) &&
    (connectionsStateService.loadingError$ | async)
  "
  [errorMessage]="connectionsStateService.loadingError$ | async"
  showBackButton="true"
>
</app-no-items-error>
<!-- END SERVER ERROR: FAILED LOADING CONNECTIONS -->

<!-- SERVER ERROR: FAILED LOADING USER -->
<!-- Connections error has higher priority - so only that is shown if both fail -->
<!-- Only show user error if user is authenticated -->
<app-no-items-error
  [isDisplayed]="
    !(userService.loading$ | async) &&
    (userService.loadingError$ | async) &&
    (authService.isAuthenticated$ | async) &&
    !(connectionsStateService.loadingError$ | async)
  "
  [errorMessage]="userService.loadingError$ | async"
  showBackButton="true"
>
</app-no-items-error>
<!-- END SERVER ERROR: FAILED LOADING USER -->

<!-- CONTENT -->
<ng-container
  *ngIf="
    !(connectionsStateService.loading$ | async) &&
    !(connectionsStateService.loadingError$ | async)
  "
>
  <!-- ERROR MESSAGE: CONNECTION NOT FOUND -->
  <app-no-items-error
    [isDisplayed]="!connection"
    errorMessage="Unable to find a connection with this name."
    showBackButton="true"
  >
  </app-no-items-error>
  <!--END ERROR MESSAGE: CONNECTION NOT FOUND -->

  <!-- CONNECTION -->
  <div *ngIf="connection">
    <div class="mx-auto max-w-[90em]">
      <!-- BACK BUTTON -->
      <a
        class="mb-4 block opacity-50 hover:opacity-100 font-semibold"
        [routerLink]="navigationService.getPreviousUrl('/finder', false)"
      >
        Back
      </a>
      <!-- END BACK BUTTON -->

      <div
        class="w-full bg-white flex flex-col md:flex-row p-0 rounded-lg overflow-hidden"
      >
        <!-- CONNECTION TITLE CARD -->
        <div
          class="flex h-96 max-h-[300px] md:h-auto overflow-hidden md:max-h-none md:min-w-[33%] md:max-w-[40%] relative"
        >
          <img
            class="h-full w-full object-cover object-center absolute"
            [src]="connection?.image"
            alt=""
          />
        </div>

        <div class="p-12 md:p-16 flex flex-col justify-between">
          <div>
            <h1 class="font-semibold mt-0">
              {{ connection?.summary?.title || connection?.title }}
            </h1>
            <p style="white-space: pre-line">
              {{ connection?.summary?.description || connection?.excerpt }}
            </p>
            <ul class="p-0 mt-10">
              <li
                class="list-none flex mt-3"
                *ngFor="let highlight of connection?.summary?.highlights"
              >
                <img class="mr-2" src="/assets/img/icons/tick.svg" alt="" />
                {{ highlight }}
              </li>
            </ul>
            <a
              [href]="connection.appLink.url"
              *ngIf="
                connection.type === 'connection' && connection.appLink?.url
              "
              class="btn mr-auto btn-theme outlined mt-12 btn-sm py-3"
            >
              {{ connection.appLink?.text || "Read More" }}
            </a>
            <a
              [href]="connection.webLink?.url"
              target="_blank"
              *ngIf="connection.type === 'signpost' && connection.webLink?.url"
              class="btn mr-auto btn-theme outlined mt-12 btn-sm py-3"
            >
              {{ connection.webLink?.text || "Visit Website" }}
            </a>
          </div>
        </div>
      </div>
      <!-- END CONNECTION TITLE CARD -->

      <!-- CONNECTION DETAILS -->
      <div
        *ngIf="connection?.outputs?.length && connection.type !== 'signpost'"
      >
        <hr class="border-0 border-t border-grey border-opacity-30 my-12" />

        <h2 class="text-2xl italic font-thin opacity-80">
          Get started with {{ connection?.title }}
        </h2>
        <div class="grid lg:grid-cols-2 gap-12">
          <div
            class="bg-white w-full rounded-lg p-12 md:p-16 box-border"
            *ngFor="let output of connection?.outputs"
            [ngSwitch]="output.type"
          >
            <!-- CONTACT OUTPUT -->
            <div *ngSwitchCase="'contact'">
              <h2 class="text-theme text-3xl font-semibold mt-0 mb-2">
                Contact directly
              </h2>
              <p class="mb-8">
                You can contact {{ connection?.title }} directly using one of
                the following methods:
              </p>
              <table>
                <tr *ngIf="output.phone">
                  <td>Phone</td>
                  <td>{{ output.phone }}</td>
                </tr>
                <tr *ngIf="output.email">
                  <td>Email</td>
                  <td>
                    <a class="underline" [href]="'mailto:' + output.email">{{
                      output.email
                    }}</a>
                  </td>
                </tr>
                <tr *ngIf="output.website">
                  <td>Website</td>
                  <td>
                    <a
                      class="underline"
                      [href]="output.website"
                      target="_blank"
                      (click)="handleHyperlinkClicked('website')"
                    >
                      link (opens in a new window)
                    </a>
                  </td>
                </tr>
                <tr *ngIf="output.facebook">
                  <td>Facebook</td>
                  <td>
                    <a
                      class="underline"
                      [href]="output.facebook"
                      target="_blank"
                      (click)="handleHyperlinkClicked('facebook')"
                    >
                      link (opens in a new window)
                    </a>
                  </td>
                </tr>
                <tr *ngIf="output.address && output.hasAddressData">
                  <td>Address</td>
                  <td>
                    <div *ngIf="output.address.address1">
                      {{ output.address.address1 }}
                    </div>
                    <div *ngIf="output.address.address2">
                      {{ output.address.address2 }}
                    </div>
                    <div *ngIf="output.address.city">
                      {{ output.address.city }}
                    </div>
                    <div *ngIf="output.address.state">
                      {{ output.address.state }}
                    </div>
                    <div *ngIf="output.address.postcode">
                      {{ output.address.postcode }}
                    </div>
                    <div *ngIf="output.address.country">
                      {{ output.address.country }}
                    </div>
                  </td>
                </tr>
                <tr
                  *ngIf="output.openingHours && output.openingHours.length > 0"
                >
                  <td>Opening Hours</td>
                  <td>
                    <div *ngFor="let item of output.openingHours">
                      {{ item }}
                    </div>
                  </td>
                </tr>
              </table>
            </div>
            <!-- END CONTACT OUTPUT -->

            <!-- EMAIL OUTPUT -->
            <div *ngSwitchCase="'email'">
              <h2 class="text-theme text-3xl font-semibold mt-0 mb-2">
                Let us contact them for you
              </h2>
              <p class="mt-0">
                Click "{{ referralCta }}" below and we'll collect the details
                that {{ connection?.organisation ?? "they" }} needs to email
                them on behalf of you.
              </p>
              <app-connection-completeness
                [connection]="connection"
              ></app-connection-completeness>
              <button
                (click)="handleEmailReferralClicked()"
                class="btn-theme mt-6 px-8 py-4"
              >
                {{ referralCta }}
              </button>
            </div>
            <!-- END EMAIL OUTPUT -->

            <!-- REDIRECT OUTPUT -->
            <div *ngSwitchCase="'redirect'">
              <h2 class="text-theme text-3xl font-semibold mt-0 mb-2">
                {{
                  output.title
                    ? output.title
                    : "Visit " + connection?.organisation
                    ? connection.organisation + "'s"
                    : "their"
                }}
                website
              </h2>
              <p>
                Click the button below to be redirected to
                {{
                  connection?.organisation
                    ? connection.organisation + "'s"
                    : "their"
                }}
                website.
              </p>

              <button
                (click)="handleRedirectReferralClicked()"
                class="btn-theme mt-5 px-8 py-4"
              >
                Continue
              </button>
            </div>
            <!-- END REDIRECT OUTPUT -->
          </div>

          <!-- UNCOMMENT ONCE OPINION HANDLING IS READY
            <div>
              <div class="bg-white p-12 rounded-lg">
                <app-connection-opinion
                  [connection]="connection"
                ></app-connection-opinion>
              </div>
            </div>
            -->
        </div>
      </div>
      <!-- END CONNECTION DETAILS -->
    </div>
  </div>
  <!-- END CONNECTION -->
</ng-container>
<!-- END CONTENT -->

<!-- MODAL: REFERRAL -->
<app-modal>
  <h1 class="text-4xl font-medium text-theme mb-6">
    Have you already been referred?
  </h1>
  <p>
    Our records show that you were already referred
    {{ connection?.organisation ? "to " + connection?.organisation : "" }}
    {{ completedReferralBefore }}. <br />
    Do you want to continue with this referral?
  </p>
  <div class="flex mt-24">
    <button
      class="btn btn-theme px-8 mr-4 outlined"
      (click)="navigateToReferral()"
    >
      Continue
    </button>
    <a routerLink="/finder">
      <button class="btn-theme-outline px-8 font-medium">
        Return to Find Services
      </button>
    </a>
  </div>
</app-modal>
<!-- END MODAL: REFERRAL -->
