import {
  Component,
  ElementRef,
  Input,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import {
  base64StringFromUint8Array,
  uInt8ArrayFromBase64String,
} from 'src/app/helper/converter';
import { FileData } from 'src/app/models/base/fileData';
import { FormItemFileUpload } from 'src/app/models/form/form';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FileUploadComponent),
    },
  ],
})
export class FileUploadComponent implements ControlValueAccessor {
  // PROPERTIES

  @Input() item?: FormItemFileUpload;
  @Input() control: any;
  @Input() disabled = false;

  @ViewChild('fileInputRef') fileInputRef!: ElementRef<HTMLInputElement>;
  @ViewChild(ModalComponent, { static: true }) modal: ModalComponent =
    {} as ModalComponent;

  value: FileData | null = null;
  onChange = (selected: any) => {};
  onTouched = () => {};
  touched = false;

  inputMode = false;

  // LIFE CYCLE HOOKS

  ngOnInit() {
    if (!this.control.value) {
      this.inputMode = true;
    }
  }

  // CONTROL VALUE ACCESSOR

  writeValue(value: FileData): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // FILE HANDLING

  openFilePicker() {
    this.fileInputRef.nativeElement.click();
  }

  onCancelFilePicker(event: any) {
    event.stopPropagation();

    if (this.control.value) {
      this.inputMode = false;
    }
  }

  onFileSelected(event: any): void {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = () => {
      const arrayBuffer = reader.result as ArrayBuffer;
      const uint8Array = new Uint8Array(arrayBuffer);

      var fileData = new FileData({
        base64StringValue: base64StringFromUint8Array(uint8Array),
        type: file.type,
        name: file.name,
      });

      this.value = fileData;
      this.onChange(this.value);
    };
    reader.onerror = (error) => {
      console.error('File reading error:', error);
    };
    reader.readAsArrayBuffer(file);
    this.inputMode = false;
  }

  onChangeFile() {
    this.inputMode = true;

    setTimeout(() => {
      this.openFilePicker();
    }, 1);
  }

  onDeleteFile(): void {
    this.value = null;
    this.onChange(this.value);
    this.inputMode = true;
  }

  downloadFile() {
    const fileData = this.value;
    if (
      !fileData ||
      !uInt8ArrayFromBase64String(fileData.base64StringValue || '')
    ) {
      // Handle case where file data or file content is not available
      console.error('No file data or content available for download.');
      return;
    }

    const blob = new Blob(
      [uInt8ArrayFromBase64String(fileData.base64StringValue || '')],
      {
        type: fileData.type,
      }
    );

    const blobUrl = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = fileData.name || 'file';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Clean up Blob URL
    URL.revokeObjectURL(blobUrl);
  }
}
