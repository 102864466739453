import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Connection } from 'src/app/models/connection/connection';
import { UserStateService } from 'src/app/services/user.state.service';
import { ModalComponent } from '../modal/modal.component';
import { ConnectionsStateService } from 'src/app/services/connections.state.service';

@Component({
  selector: 'app-connections',
  templateUrl: './connections.component.html',
  styleUrls: ['./connections.component.css'],
})
export class ConnectionsComponent {
  @ViewChild(ModalComponent, { static: true }) modal: ModalComponent =
    {} as ModalComponent;

  @Input() connections: Connection[] = [];
  @Input() errorMessageNoConnections: string = 'No connections found.';
  @Input() linkRoute: string = '/connection/';
  @Input() isAdminDisplay: boolean = false; // if true, hide irrelevant UI and allow signposts to be selected

  @Output() connectionOpened: EventEmitter<string> = new EventEmitter();
  @Output() signpostClicked: EventEmitter<string> = new EventEmitter();

  selectedConnection?: Connection;

  constructor(
    protected userService: UserStateService,
    protected connectionsStateService: ConnectionsStateService
  ) {}

  ngOnInit(): void {}

  onConnectionOpened(connectionName: string | undefined) {
    this.connectionOpened.emit(connectionName);
  }

  onSignpostClicked(connectionName: string | undefined) {
    this.signpostClicked.emit(connectionName);
  }

  openOpinionModal(e: any, connection: Connection) {
    e.stopPropagation();
    e.preventDefault();
    this.selectedConnection = connection;
    this.modal.open();
    // this.userService.openOpinionModal(connection);
  }
  closeOpinionModal() {
    this.modal.close();
  }
}
