<div class="flex" [formGroup]="dobFormGroup">
  <div class="flex-1 mr-[1%]">
    <label class="text-xs font-semibold opacity-50 mb-1">Day</label>
    <input
      formControlName="day"
      type="number"
      class="form-control"
      placeholder="DD"
      min="1"
      max="31"
    />
    <!-- <input type="number" class="form-control" placeholder="DD" min="1" max="31" [(ngModel)]="day" (ngModelChange)="updateDate()" /> -->
  </div>
  <div class="flex-1 mr-[1%]">
    <label class="text-xs font-semibold opacity-50 mb-1">Month</label>
    <div class="relative">
      <select
        formControlName="month"
        [class.is-placeholder]="
          !dobFormGroup.controls['month'] ||
          dobFormGroup.controls['month'].value == ''
        "
        class="form-select"
      >
        <option selected value="" class="disabled-option">Month</option>
        <option [value]="0">January</option>
        <option [value]="1">February</option>
        <option [value]="2">March</option>
        <option [value]="3">April</option>
        <option [value]="4">May</option>
        <option [value]="5">June</option>
        <option [value]="6">July</option>
        <option [value]="7">August</option>
        <option [value]="8">September</option>
        <option [value]="9">October</option>
        <option [value]="10">November</option>
        <option [value]="11">December</option>
      </select>
      <span
        class="ng-arrow-wrapper z-10 absolute right-0 top-0 h-full flex pointer-events-none"
        ><span class="ng-arrow my-auto mr-[25px]"></span
      ></span>
    </div>
  </div>
  <div class="flex-1">
    <label class="text-xs font-semibold opacity-50 mb-1">Year</label>
    <input
      formControlName="year"
      type="number"
      class="form-control"
      placeholder="YYYY"
      min="0"
      max="9999"
    />
  </div>
</div>

<label *ngIf="age" class="font-medium opacity-50 italic"
  >You are {{ age }} years old</label
>
