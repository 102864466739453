import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { environment } from 'src/environments/environment';
import Player from '@vimeo/player';
import { communityConnectionsIntroVimeoUrl } from 'src/assets/video/videoUrls';
import { SessionStorageService } from 'src/app/services/session-storage/session-storage.service';
import { CookieConsentService } from 'src/app/services/cookie-consent/cookie-consent.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css'],
})
export class IndexComponent {
  // PROPERTIES
  @ViewChild(ModalComponent, { static: true }) modal: ModalComponent =
    {} as ModalComponent;
  @ViewChild('vimeoPlayer') vimeoPlayer?: ElementRef;

  protected player?: Player;
  private readonly _vimeoUrl = communityConnectionsIntroVimeoUrl;

  private destroy$: Subject<void> = new Subject<void>();

  // CONSTRUCTOR
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private sessionStorageService: SessionStorageService,
    private cookieConsentService: CookieConsentService
  ) {}

  // LIFECYCLE HOOKS
  ngOnInit(): void {
    if (!environment.enableRegistration) {
      this.route.queryParams.subscribe((params) => {
        if (params['overrideRegistration']) {
          this.sessionStorageService.setOverrideRegistration(true);
          this.router.navigate([], {
            queryParams: { overrideRegistration: null },
            queryParamsHandling: 'merge',
          });
        }
      });
    }
  }

  ngAfterViewInit(): void {
    this.cookieConsentService.cookieConsent$
      .pipe(takeUntil(this.destroy$))
      .subscribe((cookieConsent) => {
        if (cookieConsent?.consentedVimeo) {
          this.player = new Player(this.vimeoPlayer?.nativeElement, {
            url: this._vimeoUrl,
            responsive: true,
          });
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // MODAL METHODS

  openVideoModal(e: any) {
    e.stopPropagation();
    e.preventDefault();
    this.modal.open();
    this.player?.play();
  }

  onVideoModalClose() {
    this.player?.pause();
  }
}
