import { AboutMeSection } from 'src/app/models/about-me/aboutMeSection';
import {
  getFormItemsUserSupportActionsList,
  getFormItemsUserSupportBedtime,
  getFormItemsUserSupportCommunication,
  getFormItemsUserSupportContinence,
  getFormItemsUserSupportDigital,
  getFormItemsUserSupportEatingAndDrinking,
  getFormItemsUserSupportFalls,
  getFormItemsUserSupportFootCare,
  getFormItemsUserSupportGeneralFitness,
  getFormItemsUserSupportGettingWashed,
  getFormItemsUserSupportMedication,
  getFormItemsUserSupportMovingAroundInside,
  getFormItemsUserSupportMovingAroundOutside,
  getFormItemsUserSupportPersonalAids,
  getFormItemsUserSupportPracticalHelp,
} from '../../forms/user/support-items/form-support';

export const supportSection: AboutMeSection = {
  title: 'My Support',
  description: 'Details about the support you need',
  longDescription:
    'In this section, you can tell us all about the support you need. Click on an area to get started.',
  routePath: 'my-support',
  userPath: 'support',
  children: [
    {
      title: 'Communication',
      description: 'Your needs and preferences for communication',
      longDescription: 'What do you want us to know before we contact you?',
      routePath: 'communication',
      userPath: 'communication',
      getFormItems: getFormItemsUserSupportCommunication,
    },
    {
      title: 'My Medication',
      description: 'Details about the medication you take',
      routePath: 'my-medication',
      userPath: 'medication',
      getFormItems: getFormItemsUserSupportMedication,
    },
    {
      title: 'General Fitness',
      description: 'Tell us more about your fitness',
      routePath: 'general-fitness',
      userPath: 'generalFitness',
      getFormItems: getFormItemsUserSupportGeneralFitness,
    },
    {
      title: 'Practical Help',
      description: 'Daily tasks you could use help with',
      routePath: 'practical-help',
      userPath: 'practicalHelp',
      getFormItems: getFormItemsUserSupportPracticalHelp,
    },
    {
      title: 'Moving Around Inside',
      description: 'How you get on moving around inside your home',
      routePath: 'moving-around-inside',
      userPath: 'movingAroundInside',
      getFormItems: getFormItemsUserSupportMovingAroundInside,
    },
    {
      title: 'Moving Around Outside',
      description: 'How you get on moving around outside your home',
      routePath: 'moving-around-outside',
      userPath: 'movingAroundOutside',
      getFormItems: getFormItemsUserSupportMovingAroundOutside,
    },
    {
      title: 'Getting Washed',
      description: 'Support you need with washing',
      routePath: 'getting-washed',
      userPath: 'gettingWashed',
      getFormItems: getFormItemsUserSupportGettingWashed,
    },
    {
      title: 'Personal Aids',
      description: 'Tell us more about the personal aids you use',
      routePath: 'personal-aids',
      userPath: 'personalAids',
      getFormItems: getFormItemsUserSupportPersonalAids,
    },
    {
      title: 'Continence',
      description: 'Details about your continence needs',
      routePath: 'continence',
      userPath: 'continence',
      getFormItems: getFormItemsUserSupportContinence,
    },
    {
      title: 'Eating & Drinking',
      description: 'Your needs and preferences for eating and drinking',
      routePath: 'eating-drinking',
      userPath: 'eatingAndDrinking',
      getFormItems: getFormItemsUserSupportEatingAndDrinking,
    },
    {
      title: 'Bedtime',
      description: 'Your support needs for going to bed and getting up',
      routePath: 'bedtime',
      userPath: 'bedtime',
      getFormItems: getFormItemsUserSupportBedtime,
    },
    {
      title: 'Falls',
      description: 'You can tell us about any falls you have had',
      routePath: 'falls',
      userPath: 'falls',
      getFormItems: getFormItemsUserSupportFalls,
    },
    {
      title: 'Digital',
      description: 'Details about technology you use for support',
      routePath: 'digital',
      userPath: 'digital',
      getFormItems: getFormItemsUserSupportDigital,
    },
    // { TODO: Add back in when BE ready
    //   title: 'Support Package',
    //   description: 'All about your support package',
    //   routePath: 'support-package',
    //   userPath: 'supportPackage',
    //   getFormItems: getFormItemsUserSupportSupportPackage,
    // },
    {
      title: 'Actions List',
      description: 'Tell us about the actions you need help with',
      routePath: 'actions-list',
      userPath: 'actionsList',
      getFormItems: getFormItemsUserSupportActionsList,
    },
    {
      title: 'Foot Care',
      description: 'Details about your foot care needs',
      routePath: 'foot-care',
      userPath: 'footCare',
      getFormItems: getFormItemsUserSupportFootCare,
    },
  ],
};
