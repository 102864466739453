import { Address } from '../base/address';

export class UserKeyInformation {
  medicalDetails?: UserKeyInformationMedicalDetails;
  medicationLocation?: UserKeyInformationMedicationLocation;
  mainSupport?: UserKeyInformationMainSupport;
  access?: UserKeyInformationAccess;
  priorities?: UserKeyInformationPriorities;
  supportPackage?: UserKeyInformationSupportPackage;
  preferences?: UserKeyInformationPreferences;

  constructor(keyInfo?: Partial<UserKeyInformation>) {
    this.medicalDetails = new UserKeyInformationMedicalDetails(
      keyInfo?.medicalDetails
    );
    this.medicationLocation = new UserKeyInformationMedicationLocation(
      keyInfo?.medicationLocation
    );
    this.mainSupport = new UserKeyInformationMainSupport(keyInfo?.mainSupport);
    this.access = new UserKeyInformationAccess(keyInfo?.access);
    this.priorities = new UserKeyInformationPriorities(keyInfo?.priorities);
    this.supportPackage = new UserKeyInformationSupportPackage(
      keyInfo?.supportPackage
    );
    this.preferences = new UserKeyInformationPreferences(keyInfo?.preferences);
  }
}

class UserKeyInformationMedicalDetails {
  history?: string;
  allergies?: string;
  emergencyMedication?: string;
  gp?: UserKeyInformationMedicalDetailsGP;
  surgeryName?: string;

  constructor(data: Partial<UserKeyInformationMedicalDetails | undefined>) {
    this.history = data?.history;
    this.allergies = data?.allergies;
    this.emergencyMedication = data?.emergencyMedication;
    this.gp = new UserKeyInformationMedicalDetailsGP(data?.gp);
    this.surgeryName = data?.surgeryName;
  }
}

class UserKeyInformationMedicalDetailsGP {
  name?: string;
  phone?: string;
  address?: Address;
  email?: string;

  constructor(data: Partial<UserKeyInformationMedicalDetailsGP | undefined>) {
    this.name = data?.name;
    this.phone = data?.phone;
    this.address = new Address(data?.address);
    this.email = data?.email;
  }
}

class UserKeyInformationMedicationLocation {
  storage?: string;
  times?: string;

  constructor(data: Partial<UserKeyInformationMedicationLocation | undefined>) {
    this.storage = data?.storage;
    this.times = data?.times;
  }
}

class UserKeyInformationMainSupport {
  person?: string;
  name?: string;
  phone?: string;
  address?: Address;

  constructor(data: Partial<UserKeyInformationMainSupport | undefined>) {
    this.person = data?.person;
    this.name = data?.name;
    this.phone = data?.phone;
    this.address = new Address(data?.address);
  }
}

class UserKeyInformationAccess {
  howToInEmergency?: string[];
  keySafeCode?: string;
  keyPersonName?: string;
  keyPersonPhone?: string;
  neighbourName?: string;
  neighbourPhone?: string;
  additionalInfo?: string;

  constructor(data: Partial<UserKeyInformationAccess | undefined>) {
    this.howToInEmergency = data?.howToInEmergency;
    this.keySafeCode = data?.keySafeCode;
    this.keyPersonName = data?.keyPersonName;
    this.keyPersonPhone = data?.keyPersonPhone;
    this.neighbourName = data?.neighbourName;
    this.neighbourPhone = data?.neighbourPhone;
    this.additionalInfo = data?.additionalInfo;
  }
}

class UserKeyInformationPriorities {
  otherDependents?: string;
  pets?: string;

  constructor(data: Partial<UserKeyInformationPriorities | undefined>) {
    this.otherDependents = data?.otherDependents;
    this.pets = data?.pets;
  }
}

class UserKeyInformationSupportPackage {
  generalInfo?: string;
  organisations?: string;
  schedule?: string;

  constructor(data: Partial<UserKeyInformationSupportPackage | undefined>) {
    this.generalInfo = data?.generalInfo;
    this.organisations = data?.organisations;
    this.schedule = data?.schedule;
  }
}

class UserKeyInformationPreferences {
  positive?: string;
  avoid?: string;

  constructor(data: Partial<UserKeyInformationPreferences | undefined>) {
    this.positive = data?.positive;
    this.avoid = data?.avoid;
  }
}
