import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { UserContactCreationResponse } from 'src/app/models/server-models/contacts/userContactCreationResponse';
import { UserContactListResponse } from 'src/app/models/server-models/contacts/userContactListResponse';
import { UserContactData } from 'src/app/models/user/contact/userContactData';
import { UserContactPdsEntry } from 'src/app/models/user/contact/userContactPdsEntry';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserContactsApiService {
  private readonly baseUrl: string;
  http: any;

  constructor(private httpClient: HttpClient) {
    this.baseUrl = environment.apiBaseUrl + '/contacts';
    this.http = this.httpClient;
  }

  getAllUserContacts(): Observable<UserContactPdsEntry[]> {
    return this.http.get(this.baseUrl).pipe(
      map((response: UserContactListResponse) => {
        let userContacts: UserContactPdsEntry[] = response.contacts.map(
          (userContact: UserContactPdsEntry) =>
            new UserContactPdsEntry(userContact)
        );
        return userContacts;
      })
    );
  }

  createUserContact(
    contactData: UserContactData
  ): Observable<UserContactCreationResponse> {
    return this.http
      .post(this.baseUrl, contactData)
      .pipe(
        map(
          (response: UserContactCreationResponse) =>
            new UserContactCreationResponse(response)
        )
      );
  }

  updateUserContact(contactPdsEntry: UserContactPdsEntry): Observable<string> {
    return this.http.put(this.baseUrl, contactPdsEntry, {
      responseType: 'text',
    });
  }

  deleteUserContact(contactId: string): Observable<string> {
    return this.http.delete(`${this.baseUrl}/${contactId}`, {
      responseType: 'text',
    });
  }
}
