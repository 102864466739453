import { UserMeMyPeopleRelationship } from './userMe';

export class UserSupportingRole {
  personalDetails?: UserSupportingRolePersonalDetails;
  supportedPeople?: UserSupportingRoleSupportedPeople;
  caringRole?: UserSupportingRoleCaringRole;
  healthImpact?: UserSupportingRoleHealthImpact;
  emotionalWellbeing?: UserSupportingRoleEmotionalWellbeing;
  financialImpact?: UserSupportingRoleFinancialImpact;
  lifeBalance?: UserSupportingRoleLifeBalance;
  futurePlansImpact?: UserSupportingRoleFuturePlansImpact;
  employmentImpact?: UserSupportingRoleEmploymentImpact;
  homeImpact?: UserSupportingRoleHomeImpact;
  otherLifeAspects?: UserSupportingRoleOtherLifeAspects;
  skillsAndExperience?: UserSupportingRoleSkillsAndExperience;
  whatMatters?: UserSupportingWhatMatters;

  constructor(supportingRole?: Partial<UserSupportingRole>) {
    this.personalDetails = new UserSupportingRolePersonalDetails(
      supportingRole?.personalDetails
    );
    this.supportedPeople = new UserSupportingRoleSupportedPeople(
      supportingRole?.supportedPeople
    );
    this.caringRole = new UserSupportingRoleCaringRole(
      supportingRole?.caringRole
    );
    this.healthImpact = new UserSupportingRoleHealthImpact(
      supportingRole?.healthImpact
    );
    this.emotionalWellbeing = new UserSupportingRoleEmotionalWellbeing(
      supportingRole?.emotionalWellbeing
    );
    this.financialImpact = new UserSupportingRoleFinancialImpact(
      supportingRole?.financialImpact
    );
    this.lifeBalance = new UserSupportingRoleLifeBalance(
      supportingRole?.lifeBalance
    );
    this.futurePlansImpact = new UserSupportingRoleFuturePlansImpact(
      supportingRole?.futurePlansImpact
    );
    this.employmentImpact = new UserSupportingRoleEmploymentImpact(
      supportingRole?.employmentImpact
    );
    this.homeImpact = new UserSupportingRoleHomeImpact(
      supportingRole?.homeImpact
    );
    this.otherLifeAspects = new UserSupportingRoleOtherLifeAspects(
      supportingRole?.otherLifeAspects
    );
    this.skillsAndExperience = new UserSupportingRoleSkillsAndExperience(
      supportingRole?.skillsAndExperience
    );
    this.whatMatters = new UserSupportingWhatMatters(
      supportingRole?.whatMatters
    );
  }
}

class UserSupportingRolePersonalDetails {
  relationshipsToPeopleCaredFor?: string;
  willingToProvideCare?: boolean;
  ableToContinueProvidingCare?: boolean;
  feelingValuedAsCarer?: boolean;

  constructor(data: Partial<UserSupportingRolePersonalDetails | undefined>) {
    this.relationshipsToPeopleCaredFor = data?.relationshipsToPeopleCaredFor;
    this.willingToProvideCare = data?.willingToProvideCare;
    this.ableToContinueProvidingCare = data?.ableToContinueProvidingCare;
    this.feelingValuedAsCarer = data?.feelingValuedAsCarer;
  }
}

class UserSupportingRoleSupportedPeople {
  listOfPeople?: SupportedPerson[];
  supportInYears?: string;
  weeklyHours?: string;

  constructor(data: Partial<UserSupportingRoleSupportedPeople | undefined>) {
    this.listOfPeople = data?.listOfPeople?.map((s) => new SupportedPerson(s));
    this.supportInYears = data?.supportInYears;
    this.weeklyHours = data?.weeklyHours;
  }
}

class SupportedPerson {
  name: UserMeMyPeopleRelationship;
  ageGroup?: string;
  residence?: string;
  ethnicity?: string;
  hasCareNeedsAssessment?: string;
  careNeedsAssessmentDate?: Date;
  careNeedsAssessmentDoneBy?: string;
  careNeedsAssessmentNotDoneReason?: string;
  hadOccupationalTherapyAssessment?: string;
  needs?: string[];
  careProvided?: string[];
  situationSummary?: string;
  caringIsVoluntary?: boolean;

  constructor(data: Partial<SupportedPerson | undefined>) {
    this.name = data?.name ?? new UserMeMyPeopleRelationship({});
    this.ageGroup = data?.ageGroup;
    this.residence = data?.residence;
    this.ethnicity = data?.ethnicity;
    this.hasCareNeedsAssessment = data?.hasCareNeedsAssessment;
    this.careNeedsAssessmentDate = data?.careNeedsAssessmentDate || undefined;
    this.careNeedsAssessmentDoneBy = data?.careNeedsAssessmentDoneBy;
    this.careNeedsAssessmentNotDoneReason =
      data?.careNeedsAssessmentNotDoneReason;
    this.hadOccupationalTherapyAssessment =
      data?.hadOccupationalTherapyAssessment;
    this.needs = data?.needs;
    this.careProvided = data?.careProvided;
    this.situationSummary = data?.situationSummary;
    this.caringIsVoluntary = data?.caringIsVoluntary;
  }
}

class UserSupportingRoleCaringRole {
  isCaringForMultiplePeople?: boolean;
  detailsOfCaredForPeople?: string;
  isSoleCarer?: boolean;
  howOthersDoOrCouldSupport?: string;
  servicesInPlace?: string;
  involvedInServicesDesign?: boolean;
  isSatisfiedWithServices?: boolean;
  satisfactionDetails?: string;
  challenges?: string;
  pastTraining?: string;
  desiredTraining?: string;

  constructor(data: Partial<UserSupportingRoleCaringRole | undefined>) {
    this.isCaringForMultiplePeople = data?.isCaringForMultiplePeople;
    this.detailsOfCaredForPeople = data?.detailsOfCaredForPeople;
    this.isSoleCarer = data?.isSoleCarer;
    this.howOthersDoOrCouldSupport = data?.howOthersDoOrCouldSupport;
    this.servicesInPlace = data?.servicesInPlace;
    this.involvedInServicesDesign = data?.involvedInServicesDesign;
    this.isSatisfiedWithServices = data?.isSatisfiedWithServices;
    this.satisfactionDetails = data?.satisfactionDetails;
    this.challenges = data?.challenges;
    this.pastTraining = data?.pastTraining;
    this.desiredTraining = data?.desiredTraining;
  }
}

class UserSupportingRoleHealthImpact {
  caringAffectsPhysicalHealth?: boolean;
  caringAffectsPhysicalHealthDetails?: string;
  healthAffectsCaring?: boolean;
  discussedWithGp?: boolean;
  summary?: number;

  constructor(data: Partial<UserSupportingRoleHealthImpact | undefined>) {
    this.caringAffectsPhysicalHealth = data?.caringAffectsPhysicalHealth;
    this.caringAffectsPhysicalHealthDetails =
      data?.caringAffectsPhysicalHealthDetails;
    this.healthAffectsCaring = data?.healthAffectsCaring;
    this.discussedWithGp = data?.discussedWithGp;
    this.summary = data?.summary;
  }
}

class UserSupportingRoleEmotionalWellbeing {
  caringAffectsWellbeing?: boolean;
  caringAffectsWellbeingDetails?: string;
  caringAffectsMentalHealth?: boolean;
  caringAffectsMentalHealthDetails?: string;
  wellbeingHasImpactOnCaring?: boolean;
  discussedWithGp?: boolean;
  summary?: number;

  constructor(data: Partial<UserSupportingRoleEmotionalWellbeing | undefined>) {
    this.caringAffectsWellbeing = data?.caringAffectsWellbeing;
    this.caringAffectsWellbeingDetails = data?.caringAffectsWellbeingDetails;
    this.caringAffectsMentalHealth = data?.caringAffectsMentalHealth;
    this.caringAffectsMentalHealthDetails =
      data?.caringAffectsMentalHealthDetails;
    this.wellbeingHasImpactOnCaring = data?.wellbeingHasImpactOnCaring;
    this.discussedWithGp = data?.discussedWithGp;
    this.summary = data?.summary;
  }
}

class UserSupportingRoleFinancialImpact {
  caringAffectsFinances?: string;
  hasFinancialDifficulties?: string;
  summary?: number;

  constructor(data: Partial<UserSupportingRoleFinancialImpact | undefined>) {
    this.caringAffectsFinances = data?.caringAffectsFinances;
    this.hasFinancialDifficulties = data?.hasFinancialDifficulties;
    this.summary = data?.summary;
  }
}

class UserSupportingRoleLifeBalance {
  areasAffectedByCaring?: string[];
  areasAffectedByCaringDetails?: string;
  summary?: number;

  constructor(data: Partial<UserSupportingRoleLifeBalance | undefined>) {
    this.areasAffectedByCaring = data?.areasAffectedByCaring;
    this.areasAffectedByCaringDetails = data?.areasAffectedByCaringDetails;
    this.summary = data?.summary;
  }
}

class UserSupportingRoleFuturePlansImpact {
  caredForPersonHasCommunityAlarm?: boolean;
  arrangementsForCareInPlace?: boolean;
  arrangementInPlaceAppointee?: string;
  arrangementInPlaceGuardianFinancial?: string;
  arrangementInPlaceGuardianWelfare?: string;
  arrangementInPlaceNamedPerson?: string;
  arrangementInPlaceWill?: string;
  arrangementInPlacePoaFinancial?: string;
  arrangementInPlacePoaContinuingWelfare?: string;
  arrangementsWillImpactRole?: string;
  desires?: string;
  summary?: number;

  constructor(data: Partial<UserSupportingRoleFuturePlansImpact | undefined>) {
    this.caredForPersonHasCommunityAlarm =
      data?.caredForPersonHasCommunityAlarm;
    this.arrangementsForCareInPlace = data?.arrangementsForCareInPlace;
    this.arrangementInPlaceAppointee = data?.arrangementInPlaceAppointee;
    this.arrangementInPlaceGuardianFinancial =
      data?.arrangementInPlaceGuardianFinancial;
    this.arrangementInPlaceGuardianWelfare =
      data?.arrangementInPlaceGuardianWelfare;
    this.arrangementInPlaceNamedPerson = data?.arrangementInPlaceNamedPerson;
    this.arrangementInPlaceWill = data?.arrangementInPlaceWill;
    this.arrangementInPlacePoaFinancial = data?.arrangementInPlacePoaFinancial;
    this.arrangementInPlacePoaContinuingWelfare =
      data?.arrangementInPlacePoaContinuingWelfare;
    this.arrangementsWillImpactRole = data?.arrangementsWillImpactRole;
    this.desires = data?.desires;
    this.summary = data?.summary;
  }
}

class UserSupportingRoleEmploymentImpact {
  employmentStatus?: string;
  hasOrInSearchOfOccupation?: boolean;
  caringAffectsOccupation?: boolean;
  caringAffectsOccupationDetails?: string;
  summary?: number;

  constructor(data: Partial<UserSupportingRoleEmploymentImpact | undefined>) {
    this.employmentStatus = data?.employmentStatus;
    this.hasOrInSearchOfOccupation = data?.hasOrInSearchOfOccupation;
    this.caringAffectsOccupation = data?.caringAffectsOccupation;
    this.caringAffectsOccupationDetails = data?.caringAffectsOccupationDetails;
    this.summary = data?.summary;
  }
}

class UserSupportingRoleHomeImpact {
  caringAffectsLivingEnvironment?: boolean;
  caringAffectsLivingEnvironmentDetails?: string;
  homeIsSuitableForCaredForPerson?: boolean;
  homeIsSuitableForCaredForPersonDetails?: string;
  difficultDailyTasks?: string;
  summary?: number;

  constructor(data: Partial<UserSupportingRoleHomeImpact | undefined>) {
    this.caringAffectsLivingEnvironment = data?.caringAffectsLivingEnvironment;
    this.caringAffectsLivingEnvironmentDetails =
      data?.caringAffectsLivingEnvironmentDetails;
    this.homeIsSuitableForCaredForPerson =
      data?.homeIsSuitableForCaredForPerson;
    this.homeIsSuitableForCaredForPersonDetails =
      data?.homeIsSuitableForCaredForPersonDetails;
    this.difficultDailyTasks = data?.difficultDailyTasks;
    this.summary = data?.summary;
  }
}

class UserSupportingRoleOtherLifeAspects {
  caringAffectsRelationships?: boolean;
  caringAffectingRelationshipsImpactsOnCaring?: boolean;
  caringAffectingRelationshipsImpactsOnCaringDetails?: string;
  botheringCaringRoleAspects?: string;
  desiredChangesAboutSituation?: string;
  relationshipsImpactSummary?: number;

  constructor(data: Partial<UserSupportingRoleOtherLifeAspects | undefined>) {
    this.caringAffectsRelationships = data?.caringAffectsRelationships;
    this.caringAffectingRelationshipsImpactsOnCaring =
      data?.caringAffectingRelationshipsImpactsOnCaring;
    this.caringAffectingRelationshipsImpactsOnCaringDetails =
      data?.caringAffectingRelationshipsImpactsOnCaringDetails;
    this.botheringCaringRoleAspects = data?.botheringCaringRoleAspects;
    this.desiredChangesAboutSituation = data?.desiredChangesAboutSituation;
    this.relationshipsImpactSummary = data?.relationshipsImpactSummary;
  }
}

class UserSupportingRoleSkillsAndExperience {
  qualifications?: string;

  constructor(
    data: Partial<UserSupportingRoleSkillsAndExperience | undefined>
  ) {
    this.qualifications = data?.qualifications;
  }
}

class UserSupportingWhatMatters {
  importanceOfRoleAsCarer?: string;

  constructor(data: Partial<UserSupportingWhatMatters | undefined>) {
    this.importanceOfRoleAsCarer = data?.importanceOfRoleAsCarer;
  }
}
