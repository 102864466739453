import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';
import { switchMap } from 'rxjs';

@Component({
  selector: 'app-mydex-login',
  templateUrl: './mydex-login.component.html',
  styleUrls: ['./mydex-login.component.css'],
})
export class MydexLoginComponent {}
