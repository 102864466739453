import { NgModule } from '@angular/core';
import { AuthModule, LogLevel } from 'angular-auth-oidc-client';
import { environment } from 'src/environments/environment';

@NgModule({
  imports: [
    AuthModule.forRoot({
      config: {
        authority: environment.oidc.mydex.authority,
        postLoginRoute: '/account/about-me',
        scope: 'openid mydexid',
        responseType: 'code',
        clientId: environment.oidc.mydex.clientId,
        redirectUrl: environment.oidc.mydex.redirectUrl,
        postLogoutRedirectUri: environment.oidc.mydex.postLogoutRedirectUri,
      },
    }),
  ],
  exports: [AuthModule],
})
export class AuthConfigModule {}
