import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SessionStorageService } from 'src/app/services/session-storage/session-storage.service';

@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.css'],
})
export class RegisterSetupComponent {
  step: Number = 0;
  steps: any;

  constructor(
    protected sessionStorageService: SessionStorageService,
    private router: Router
  ) {
    let routeChildren =
      router.config
        .find(({ path }) => path == 'register/setup')
        ?.children?.map(({ path }) => path) || [];
    routeChildren.shift();

    this.steps = routeChildren;

    router.events.subscribe((url: any) => {
      //update current step
      let route = this.router.url.split('?')[0].split('/').at(-1);
      this.step = this.steps?.findIndex((step: any) => step == route) || 0;
    });
  }
}
