import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Connection } from 'src/app/models/connection/connection';
import { OutputEmail } from 'src/app/models/connection/connectionOutput';
import { FormItem, FormItemTitle } from 'src/app/models/form/form';
import { ConnectionsStateService } from 'src/app/services/connections.state.service';
import { User, UserEvent } from 'src/app/models/user/user';
import { UserStateService } from 'src/app/services/user.state.service';
import * as _ from 'lodash';
import { EmailApiService } from 'src/app/services/email.api.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { FinderService } from 'src/app/services/finder/finder.service';
import { UserEventsService } from 'src/app/services/user.event.service';
import { getFormItemsReferralMe } from 'src/assets/forms/referral/form-me';
import { FormComponent } from 'src/app/components/form/form.component';
import { Subject, first, takeUntil } from 'rxjs';
import { SessionStorageService } from 'src/app/services/session-storage/session-storage.service';
import { PdsService } from 'src/app/services/pds/pds.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { FormService } from 'src/app/services/form/form.service';
import { UserMeMyPeopleRelationship } from 'src/app/models/user/userMe';
import { PdsConnectionStatus } from 'src/app/models/pds/pdsConnectionStatus';
import { ReferralRequest } from 'src/app/models/server-models/referralRequest';
import { getAllServerMamPaths } from 'src/assets/about-me/serverMamPaths';

@Component({
  selector: 'app-connection-referral',
  templateUrl: './connection-referral.component.html',
  styleUrls: ['./connection-referral.component.css'],
})
export class ConnectionReferralComponent {
  // PROPERTIES

  @ViewChild(FormComponent, { static: false }) form: FormComponent =
    {} as FormComponent;
  protected pdsConnectionStatus: PdsConnectionStatus | undefined = undefined;

  protected connection?: Connection;
  protected connectionOutput?: OutputEmail;

  protected user: User | undefined;

  protected step: 'form' | 'permissions' | 'complete' = 'form';

  protected selectedReferralSubject: string | undefined =
    this.finderService.audience == 'them' ? 'them' : 'me'; // can be 'me', 'them', or a myPeople index

  protected permissionToReferSomeoneElse: boolean = false;
  protected agreementDataProcessing: boolean = false;
  protected requiredFieldsError: string = '';

  //form that's rendered
  protected referralForm: FormItem<any>[] = [];
  protected formValues: any;

  protected dataSharingFields: { me: any[]; them: any[] } = {
    me: [],
    them: [],
  };

  private destroy$: Subject<void> = new Subject<void>();

  // GETTERS

  get referralSubjectOptions(): { label: string; name: string | number }[] {
    let result: { label: string; name: string | number }[] = [
      { label: 'myself', name: 'me' },
      { label: 'someone else', name: 'them' },
    ];

    const people = this.user?.me?.myPeople?.relationships;
    if (people?.length) {
      result = [
        { label: 'myself', name: 'me' },
        ...people.map((p, index) => {
          return {
            label: p.firstName + (p.lastName ? ' ' + p.lastName : ''),
            name: index,
          };
        }),
        { label: 'someone else', name: 'them' },
      ];
    }

    return result;
  }

  // CONSTRUCTOR

  constructor(
    protected emailService: EmailApiService,
    protected userService: UserStateService,
    protected connectionsStateService: ConnectionsStateService,
    protected navigationService: NavigationService,
    protected authService: AuthService,
    protected finderService: FinderService,
    protected eventService: UserEventsService,
    protected pdsService: PdsService,
    private router: Router,
    private route: ActivatedRoute,
    private sessionStorageService: SessionStorageService,
    private analyticsService: AnalyticsService,
    private formService: FormService
  ) {}

  // LIFECYCLE HOOKS

  ngOnInit() {
    this.pdsService.pdsConnectionStatus$
      .pipe(takeUntil(this.destroy$))
      .subscribe((connectionStatus) => {
        this.pdsConnectionStatus = connectionStatus;
      });

    this.connectionsStateService.connections$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        let connectionName = this.route.snapshot.paramMap.get('slug') || '';
        this.connection =
          this.connectionsStateService.getConnectionByName(connectionName);
        this.connectionOutput = this.connection?.emailOutput;

        this.setFormItemsForSelectedReferralSubject();
      });

    this.userService.user$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (user) => {
        this.user = user;

        // prefill user data from session storage
        if (user == undefined) {
          this.user = this.sessionStorageService.getUserData();
        }

        this.setFormItemsForSelectedReferralSubject();
      },
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();

    this.userService.resetErrors();
    this.connectionsStateService.resetErrors();
  }

  // METHODS

  toStep(step: 'form' | 'permissions' | 'complete') {
    this.step = step;
    window.scrollTo(0, 0);
  }

  // METHODS - FORM STEP

  private setFormItemsForSelectedReferralSubject() {
    let referralFormInProgress: FormItem<any>[] = [];

    // Case referring self
    if (this.selectedReferralSubject == 'me') {
      referralFormInProgress =
        this.formService.createReferralFormFromOutputFormSections(
          this.connection?.outputForm ?? []
        );

      this.referralForm = [
        ...[
          new FormItemTitle({
            label: 'My Details',
            class: 'mb-3 mt-2',
          }),
        ],
        ...referralFormInProgress,
      ];
      this.formValues = this.user;

      return;
    }

    // Case referring someone else
    referralFormInProgress =
      this.formService.createReferralFormFromOutputFormSections(
        this.connection?.useSeparateOutputFormThem
          ? this.connection?.outputFormThem ?? []
          : this.connection?.outputForm ?? [],
        'them'
      );

    // check if this is someone from myPeople
    let myPeopleRelationship: UserMeMyPeopleRelationship | undefined =
      undefined;

    // get the relationship object via the selected referral subject index
    let selectedReferralSubjectRelationshipIndex = parseInt(
      this.selectedReferralSubject ?? ''
    );
    if (selectedReferralSubjectRelationshipIndex !== undefined) {
      myPeopleRelationship =
        this.user?.me?.myPeople?.relationships?.[
          selectedReferralSubjectRelationshipIndex
        ];
    }

    // if it is someone from myPeople, try to pre-fill values
    if (myPeopleRelationship) {
      let themData = referralFormInProgress.reduce((acc, item) => {
        if (item.path) {
          let pathNode: string = item.path?.split('.').at(-1) ?? '';
          let value = _.get(myPeopleRelationship, pathNode);
          _.set(acc, item.path, value !== undefined ? value : '');
        }
        return acc;
      }, {});
      this.formValues = { ...this.user, ...themData };
    }
    // if not, just use the user data for the 'me' form
    else {
      this.formValues = this.user;
    }

    let formItems = [
      ...[
        // Them title
        new FormItemTitle({
          label:
            myPeopleRelationship && myPeopleRelationship?.firstName
              ? `${myPeopleRelationship.firstName}'s details`
              : 'Who is this about?',
          class: 'mb-3',
        }),
      ],
      // Them items
      ...referralFormInProgress,
      // Me title
      ...[
        new FormItemTitle({
          label: 'My Details',
          class: 'mb-3 border-0 border-t border-solid border-gray-200',
        }),
      ],
      // Me items
      ...getFormItemsReferralMe(),
    ];

    this.referralForm = formItems;
  }

  navigateBackToConnection() {
    const currentUrl = this.router.url;
    const baseRoute = currentUrl.replace('/referral', '');
    this.router.navigateByUrl(baseRoute);
  }

  handleContinue(data: Partial<User>) {
    this.formValues = data;
    this.updateDataSharingFields();
    this.toStep('permissions');
  }

  updateDataSharingFields() {
    this.dataSharingFields.me = [];
    this.dataSharingFields.them = [];

    for (const item of this.referralForm) {
      //check path to avoid processing title items
      if (!item?.path?.length) {
        continue;
      }
      let type: 'them' | 'me' =
        item?.path?.split('.')[0] == 'them' ? 'them' : 'me';

      let value = _.get(this.formValues, item.path ?? '');

      if (value === undefined || value === null || value === '') {
        continue;
      }

      this.dataSharingFields[type].push({
        name: item.label,
        value: value,
        path: item.path,
      });
    }
  }

  // METHODS - PERMISSIONS STEP

  get themPersonName() {
    if (this.dataSharingFields?.them) {
      return this.dataSharingFields.them.find(({ path }) =>
        path.includes('firstName')
      )?.value;
    }
    return undefined;
  }

  showRequiredFieldsError() {
    if (this.selectedReferralSubject !== 'me') {
      this.requiredFieldsError =
        'Please ensure that you consent to the processing of the entered details and have permission to share the data.';
    } else {
      this.requiredFieldsError =
        'Please ensure that you consent to the processing of the entered details.';
    }

    setTimeout(() => {
      this.requiredFieldsError = '';
    }, 5000);
  }

  handleComplete() {
    // Ensure that the user has permission to refer someone else (if needed)
    if (
      (!this.permissionToReferSomeoneElse &&
        this.selectedReferralSubject !== 'me') ||
      !this.agreementDataProcessing
    ) {
      this.showRequiredFieldsError();
      return;
    }

    this.analyticsService.trackEmailReferralCompleted(
      this.connection?.name ?? 'connection-name-unknown',
      this.selectedReferralSubject === 'me' ? 'self' : 'someone else'
    );

    var referralRequest = new ReferralRequest({
      connectionName: this.connection?.name ?? '',
      outputType: this.connectionOutput?.type ?? 'email',
      userReferralName: this.getReferralSubjectName(),
      userReferralEmail: _.get(this.formValues, 'me.contact.email'),
      UserReferralData: this.dataSharingFields,
    });

    this.emailService.send(referralRequest).subscribe({
      next: () => {
        this.toStep('complete');
        this.updateUserData();
      },
    });
  }

  private updateUserData() {
    let newUserData = this.formValues;

    let newEvent = new UserEvent({
      type: 'referral',
      connection: this.connection?.name,
      context: this.finderService.audience,
    });

    newUserData.events = newUserData.events ?? [];
    newUserData.events.push(newEvent);

    this.pdsService.pdsConnectionStatus$
      .pipe(first(), takeUntil(this.destroy$))
      .subscribe((pdsConnectionStatus) => {
        if (pdsConnectionStatus?.isValid) {
          // data from any section could be affected (that is why all MAM paths are passed)
          this.userService.updateUser(newUserData, getAllServerMamPaths());
        }
        // If not verified, merge the data into session storage so it can be used when the user registers
        else {
          this.sessionStorageService.mergeUserData(newUserData);
        }
      });
  }

  private getReferralSubjectName(): string | undefined {
    let firstName: string | undefined = _.get(
      this.formValues,
      'me.personalDetails.firstName'
    );
    let lastName: string | undefined = _.get(
      this.formValues,
      'me.personalDetails.lastName'
    );

    if (firstName === undefined) {
      return undefined;
    }

    if (lastName === undefined) {
      return firstName;
    }

    return `${firstName} ${lastName}`;
  }
}
