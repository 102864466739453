import { Component } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { computeCurrentAgeFromDateOfBirth } from 'src/app/helper/dateHelper';
import { User } from 'src/app/models/user/user';
import { UserStateService } from 'src/app/services/user.state.service';

@Component({
  selector: 'app-page-account-summary',
  templateUrl: './page-account-summary.component.html',
  styleUrl: './page-account-summary.component.css',
})
export class PageAccountSummaryComponent {
  // PROPERTIES

  protected user: User | undefined;

  private destroy$: Subject<void> = new Subject<void>();

  // CONSTRUCTOR

  constructor(protected userService: UserStateService) {}

  // LIFECYCLE HOOKS

  ngOnInit() {
    this.userService.user$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (user) => {
        this.user = user;
      },
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();

    this.userService.resetErrors();
  }

  // PUBLIC API

  protected computeAge(dob: Date | undefined): number | undefined {
    if (!dob) {
      return undefined;
    }

    return computeCurrentAgeFromDateOfBirth(dob);
  }

  protected get hasBasicDetails(): boolean {
    return !(
      !this.user?.me?.personalDetails?.firstName &&
      !this.user?.me?.personalDetails?.dateOfBirth &&
      !this.user?.me?.personalDetails?.preferredName &&
      !this.user?.me?.personalDetails?.pronouns
    );
  }

  protected get hasMedicationsDetails(): boolean {
    return !!this.user?.support?.medication?.listOfMedications?.length;
  }

  protected get hasEatingAndDrinkingDetails(): boolean {
    return !(
      !this.user?.keyInformation?.medicalDetails?.allergies &&
      !this.user?.support?.eatingAndDrinking?.diet &&
      !this.user?.support?.eatingAndDrinking?.favouriteMeal &&
      !this.user?.support?.eatingAndDrinking?.preferredFoodsAndDrinks &&
      !this.user?.support?.eatingAndDrinking?.avoidedFoodsAndDrinks &&
      !this.user?.support?.eatingAndDrinking?.areasWithNoSupportNeed?.length &&
      !this.user?.support?.eatingAndDrinking?.impairments?.length &&
      !this.user?.support?.eatingAndDrinking?.impairmentsAdditionalInfo &&
      !this.user?.support?.eatingAndDrinking?.equipmentOrPreferences?.length &&
      !this.user?.support?.eatingAndDrinking
        ?.equipmentOrPreferencesAdditionalInfo &&
      !this.user?.support?.eatingAndDrinking?.preferredDiningLocation &&
      !this.user?.support?.eatingAndDrinking?.generalAdditionalInfo
    );
  }

  protected get hasCommunicationDetails(): boolean {
    return !(
      !this.user?.support?.communication?.firstLanguage &&
      !this.user?.support?.communication?.preferredTypes?.length &&
      !this.user?.support?.communication?.preferredTypesAdditionalInfo &&
      !this.user?.support?.communication?.generalAids &&
      !this.user?.support?.communication?.signLanguages?.length &&
      !this.user?.support?.communication?.signLanguagesAdditionalInfo &&
      (this.user?.support?.communication?.hearingAids === undefined ||
        this.user?.support?.communication?.hearingAids === null) &&
      !this.user?.support?.communication?.existingSupport &&
      !this.user?.support?.communication?.generalAdditionalInfo
    );
  }

  protected get hasToiletDetails(): boolean {
    return (
      this.user?.support?.movingAroundInside?.areasWithSupportNeed?.includes(
        'Getting to the toilet'
      ) === true
    );
  }

  protected get hasContinenceDetails(): boolean {
    return !(
      !this.user?.support?.continence?.productsInUse &&
      !this.user?.support?.continence?.routines &&
      !this.user?.support?.continence?.supportNeededInCaseOfEmergency &&
      (this.user?.support?.continence?.inContactWithCareTeam === undefined ||
        this.user?.support?.continence?.inContactWithCareTeam === null)
    );
  }
}
