import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, first, takeUntil } from 'rxjs';
import { FormComponent } from 'src/app/components/form/form.component';
import { PdsConnectionStatus } from 'src/app/models/pds/pdsConnectionStatus';
import { User } from 'src/app/models/user/user';
import { AuthService } from 'src/app/services/auth/auth.service';
import { PdsService } from 'src/app/services/pds/pds.service';
import { SessionStorageService } from 'src/app/services/session-storage/session-storage.service';
import { UserStateService } from 'src/app/services/user.state.service';
import { unpaidCarerForm } from 'src/assets/forms/carer/form-carer-questions';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.css'],
})
export class CarerAssessmentQuestionsComponent {
  // PROPERTIES
  @ViewChild('carerForm', { static: false })
  formComponent?: FormComponent;

  protected user: User | undefined;
  protected pdsConnectionStatus: PdsConnectionStatus | undefined = undefined;

  protected unpaidCarerForm = unpaidCarerForm;

  protected step: 'form' | 'results' = 'form';
  protected savingResults = false;

  private destroy$: Subject<void> = new Subject<void>();
  private readonly _unpaidCarerNextStepsUrl = '/carer-assessment/next-steps';

  // CONSTRUCTOR
  constructor(
    protected authService: AuthService,
    protected pdsService: PdsService,
    protected userService: UserStateService,
    private sessionStorageService: SessionStorageService,
    private router: Router
  ) {}

  // LIFE CYCLE HOOKS
  ngOnInit() {
    this.pdsService.pdsConnectionStatus$
      .pipe(takeUntil(this.destroy$))
      .subscribe((connectionStatus) => {
        this.pdsConnectionStatus = connectionStatus;
      });

    this.userService.user$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (user) => {
        this.user = user;

        // prefill user data from session storage
        if (user == undefined) {
          this.user = this.sessionStorageService.getUserData();
        }

        this.initUnpaidCarerForm();
      },
    });
  }

  // PUBLIC API
  protected saveUnpaidCarerForm(formResults: any) {
    this.user = User.mergeUserData(this.user, formResults);

    // If the user is verified, update the user data
    this.pdsService.pdsConnectionStatus$
      .pipe(first(), takeUntil(this.destroy$))
      .subscribe((pdsConnectionStatus) => {
        if (pdsConnectionStatus?.isValid) {
          this.userService.updateUser(this.user); // TODO: update to pass MAM paths once Supporting Role is connected to PDS
        }
        // If not verified, merge the data into session storage so it can be used when the user registers
        else {
          this.sessionStorageService.mergeUserData(formResults);
        }
      });

    this.toStep('results');
  }

  protected onReviewedResults() {
    this.router.navigate([this._unpaidCarerNextStepsUrl]);
  }

  protected toStep(to: 'form' | 'results') {
    this.step = to;

    if (to === 'form') {
      this.initUnpaidCarerForm();
    }

    window.scrollTo(0, 0);
  }

  // PRIVATE API

  private initUnpaidCarerForm() {
    setTimeout(() => {
      this.formComponent?.init();
    });
  }
}
