import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-stateful-button',
  templateUrl: './stateful-button.component.html',
  styleUrl: './stateful-button.component.css',
})
export class StatefulButtonComponent {
  @Input() type: 'filled' | 'outline' = 'filled';
  @Input() iconName: string = '';

  @Input() onClick: () => void = () => {};
  @Input() disabled: boolean = false;

  @Input() isInProgress: boolean = false;
  @Input() isSuccess: boolean = false;

  @Input() text: string = 'Save';
  @Input() textProgress: string = 'Saving';
  @Input() textSuccess: string = 'Saved';

  @Input() errorMessage: string = '';
  @Input() errorMessagePlacement: 'left' | 'right' = 'right';

  constructor() {}
}
