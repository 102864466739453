import { Validators } from '@angular/forms';
import { FormItemTextArea } from 'src/app/models/form/form';

export const unpaidCarerWishesForCaredForPeople = new FormItemTextArea({
  key: 'unpaidCarerWishesForCaredForPeople',
  path: 'planningAhead.unpaidCarer.wishesForCaredForPeople',
  label: 'When I cannot care for them, my wishes would be:',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});
