<div class="mb-5" [formGroup]="formSectionsFormGroup">
  <div formArrayName="formSectionsFormArray">
    <!-- LOOP FORM SECTIONS -->
    <div
      *ngFor="
        let formSection of formSectionsFormArray.controls;
        let formSectionIndex = index
      "
      class="rounded-lg pt-4 pl-8 pr-8 pb-8 mb-5 bg-white flex flex-col"
      style="border: 1px solid #979797; position: relative"
      [formGroupName]="formSectionIndex"
    >
      <!-- REMOVE FORM SECTION BUTTON -->
      <div style="position: absolute; top: 0; right: 0" class="mr-3 mt-3">
        <button
          type="button"
          (click)="removeFormSection(formSectionIndex)"
          class="btn-red-outline my-auto px-3 py-1"
          [disabled]="disabled"
        >
          X
        </button>
      </div>
      <!-- END REMOVE FORM SECTION BUTTON -->

      <!-- FORM SECTION PROPERTIES -->

      <!-- SECTION TITLE -->
      <div class="mb-5">
        <label class="mb-1">Section Title</label>
        <p class="m-0 mb-2 text-sm text-grey">
          If this is left blank, no section title will be displayed.
        </p>
        <input
          type="text"
          formControlName="title"
          class="form-control"
          placeholder="e.g. Personal Details"
          [class.is-invalid]="formSection.get('title').invalid"
          helpText="This is the title of the section."
        />
      </div>
      <!-- END SECTION TITLE -->

      <!-- SECTION DESCRIPTION -->
      <div class="mb-5">
        <label class="mb-1">Description</label>
        <p class="m-0 mb-2 text-sm text-grey">
          If this is left blank, no section description will be displayed.
        </p>
        <input
          type="text"
          formControlName="description"
          class="form-control"
          placeholder="e.g. Please provide your personal details below."
          [class.is-invalid]="formSection.get('description').invalid"
        />
      </div>
      <!-- END SECTION DESCRIPTION -->

      <!-- LOOP FORM ITEMS -->
      <div formArrayName="formItems">
        <label class="mb-2">Form Items</label>
        <div
          *ngFor="
            let formItem of formItemsFormArrayOfFormSection(formSectionIndex)
              .controls;
            let formItemIndex = index
          "
          [formGroupName]="formItemIndex"
          class="flex items-center mb-3"
        >
          <!-- FORM ITEM PATH SELECT -->
          <ng-select
            [items]="availableFormItemPathOptions"
            [formControl]="formItem.get('path')"
            bindLabel="label"
            bindValue="path"
            [searchable]="false"
            [clearable]="false"
            class="w-full mr-2 form-select"
            [groupBy]="groupItemByPath"
            [placeholder]="'Select a data point'"
            [ngClass]="{ 'form-select-disabled': disabled }"
            (change)="filterDropdown()"
          >
            <!-- SEARCH BAR -->
            <ng-template ng-header-tmp>
              <div class="relative flex items-center">
                <h3 class="mr-1 ml-2">Available Data Points</h3>
                <p>
                  <!-- SEARCH ICON -->
                </p>

                <div class="relative">
                  <div
                    class="absolute inset-y-0 left-0 flex items-center pointer-events-none"
                  >
                    <img
                      src="/assets/img/icons/search.svg"
                      alt="Search Icon"
                      class="h-4 w-4 text-grey ml-2"
                    />
                  </div>
                </div>
                <!-- END SEARCH ICON -->

                <!-- SEARCH INPUT -->
                <input
                  #searchInput
                  id="searchInput"
                  style="width: 100%; line-height: 24px"
                  type="text"
                  placeholder="Filter"
                  (input)="filterDropdown($event.target.value)"
                  class="ml-1 mr-1"
                  (blur)="filterDropdown('')"
                />
                <!-- END SEARCH INPUT -->

                <!-- CLEAR SEARCH BUTTON -->
                <div
                  *ngIf="searchInput.value"
                  class="absolute inset-y-0 right-0 flex items-center pr-2"
                >
                  <button
                    type="button"
                    class="focus:outline-none bg-transparent"
                    (click)="filterDropdown('')"
                  >
                    <img
                      src="/assets/img/icons/clear.svg"
                      alt="Clear Icon"
                      class="h-3 w-3 text-red-500 mr-2 ml-2"
                    />
                  </button>
                </div>
                <!-- END CLEAR SEARCH BUTTON -->
              </div>
            </ng-template>
            <!-- END SEARCH BAR -->
            <!-- SELECT OPTION GROUPS -->
            <ng-template ng-optgroup-tmp let-item="item" let-index="index">
              <h4>{{ item.label }}</h4>
            </ng-template>
            <!-- END SELECT OPTION GROUPS -->
            <!-- SELECT OPTIONS -->
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <div class="flex items-start">
                <div class="mr-4">
                  <strong>{{ item.label || "no label specified" }}</strong>
                  <p class="text-grey text-sm mt-1">
                    {{ getFormattedItemPath(item.path) }}
                  </p>
                </div>
                <div class="flex-grow text-right">
                  <p class="text-sm mt-0">{{ item.guidanceForUsage }}</p>
                </div>
              </div>
            </ng-template>
            <!-- END SELECT OPTIONS -->
          </ng-select>
          <!-- END FORM ITEM PATH SELECT -->

          <!-- FORM ITEM REQUIRED -->
          <div class="flex items-end mr-2">
            <label class="switch">
              <input type="checkbox" formControlName="required" />
              <span
                class="slider"
                [disabled]="disabled"
                [ngClass]="{ 'disabled-button': disabled }"
              ></span>
            </label>
          </div>
          <!-- END FORM ITEM REQUIRED -->

          <!-- REMOVE FORM ITEM BUTTON -->
          <button
            type="button"
            (click)="removeFormItem(formSectionIndex, formItemIndex)"
            class="btn-red-outline h-full my-auto ml-2 px-4 py-2"
            [disabled]="disabled"
          >
            X
          </button>
          <!-- END REMOVE FORM ITEM BUTTON -->
        </div>
        <!-- ADD FORM ITEM BUTTON -->
        <button
          *ngIf="
            item?.maxNumberOfItemsPerSection >
            formItemsFormArrayOfFormSection(formSectionIndex).controls.length
          "
          type="button"
          (click)="addFormItem(formSectionIndex)"
          class="px-2 py-2 mt-2 btn-theme-outline outlined btn-sm"
          [disabled]="disabled"
        >
          Add Form Item +
        </button>
        <p
          *ngIf="
            item?.maxNumberOfItemsPerSection <=
            formItemsFormArrayOfFormSection(formSectionIndex).controls.length
          "
          class="text-grey mt-5 italic"
        >
          You have reached the maximum number of form items for this section.
        </p>
        <!-- END ADD FORM ITEM BUTTON -->
      </div>
      <!-- END LOOP FORM ITEMS -->

      <!-- END FORM SECTION PROPERTIES -->
    </div>
    <!-- END LOOP FORM SECTIONS -->

    <!-- ADD FORM SECTION BUTTON -->
    <button
      *ngIf="item?.maxNumberOfSections > formSectionsFormArray.controls.length"
      type="button"
      (click)="addFormSection()"
      class="px-2 py-2 mt-2 btn-theme-outline outlined btn-sm"
      [disabled]="disabled"
    >
      Add Form Section +
    </button>
    <p
      *ngIf="item?.maxNumberOfSections <= formSectionsFormArray.controls.length"
      class="text-grey mt-5 italic"
    >
      You have reached the maximum number of form sections.
    </p>
    <!-- END ADD FORM SECTION BUTTON -->
  </div>
</div>
