import { AboutMeSection } from 'src/app/models/about-me/aboutMeSection';
import {
  getFormItemsUserMeContactDetails,
  getFormItemsUserMeCurrent,
  getFormItemsUserMeHome,
  getFormItemsUserMeMoneyMatters,
  getFormItemsUserMeMyPeople,
  getFormItemsUserMePastimes,
  getFormItemsUserMePersonalDetails,
  getFormItemsUserMePersonalQualities,
  getFormItemsUserMeReminiscence,
  getFormItemsUserMeRoutines,
} from '../../forms/user/me-items/form-me';
import { ServerMamPaths } from '../serverMamPaths';

export const thisIsMeSection: AboutMeSection = {
  title: 'This is Me',
  description: 'Tell us about yourself',
  longDescription:
    'In this section, you can tell us about yourself. Click on an area to get started.',
  routePath: 'this-is-me',
  userPath: 'me',
  children: [
    {
      title: 'Personal Details',
      description: 'General information about yourself',
      routePath: 'personal-details',
      userPath: 'personalDetails',
      serverMamPaths: [ServerMamPaths.ThisIsMe.PersonalDetails],
      getFormItems: getFormItemsUserMePersonalDetails,
    },
    {
      title: 'Contact Details',
      description: 'How we can contact you',
      routePath: 'contact-details',
      userPath: 'contact',
      serverMamPaths: [ServerMamPaths.ThisIsMe.ContactDetails],
      getFormItems: getFormItemsUserMeContactDetails,
    },
    {
      title: 'Current Situation',
      description: 'All about your occupation & hobbies',
      routePath: 'current',
      userPath: 'current',
      serverMamPaths: [ServerMamPaths.ThisIsMe.Current],
      getFormItems: getFormItemsUserMeCurrent,
    },
    {
      title: 'Personal Qualities',
      description: 'Tell us about your personal qualities',
      routePath: 'personal-qualities',
      userPath: 'personalQualities',
      serverMamPaths: [ServerMamPaths.ThisIsMe.PersonalQualities],
      getFormItems: getFormItemsUserMePersonalQualities,
    },
    {
      title: 'My People',
      description: 'The people in your life',
      routePath: 'my-people',
      userPath: 'myPeople',
      serverMamPaths: [ServerMamPaths.ThisIsMe.MyPeople],
      getFormItems: getFormItemsUserMeMyPeople,
    },
    {
      title: 'Pastimes',
      description: 'Your favourite pastimes',
      routePath: 'pastimes',
      userPath: 'pastimes',
      serverMamPaths: [ServerMamPaths.ThisIsMe.Pastimes],
      getFormItems: getFormItemsUserMePastimes,
    },
    {
      title: 'Home',
      description: 'All about your residence',
      routePath: 'home',
      userPath: 'home',
      serverMamPaths: [
        ServerMamPaths.ThisIsMe.Home,
        ServerMamPaths.ThisIsMe.ContactDetails,
        ServerMamPaths.ThisIsMe.Current,
      ],
      getFormItems: getFormItemsUserMeHome,
    },
    {
      title: 'Routines',
      description: 'Your daily routines',
      routePath: 'routines',
      userPath: 'routines',
      serverMamPaths: [ServerMamPaths.ThisIsMe.Routines],
      getFormItems: getFormItemsUserMeRoutines,
    },
    {
      title: 'Reminiscence',
      description: 'Your memories',
      routePath: 'reminiscence',
      userPath: 'reminiscence',
      serverMamPaths: [ServerMamPaths.ThisIsMe.ReminiscenceGallery],
      getFormItems: getFormItemsUserMeReminiscence,
    },
    {
      title: 'Money Matters',
      description: 'Your money affairs',
      routePath: 'money-matters',
      userPath: 'moneyMatters',
      serverMamPaths: [ServerMamPaths.ThisIsMe.MoneyMatters],
      getFormItems: getFormItemsUserMeMoneyMatters,
    },
  ],
};
