import { Component } from '@angular/core';
import { frequentlyAskedQuestions } from 'src/assets/texts/faq';

@Component({
  selector: 'app-page-faq',
  templateUrl: './page-faq.component.html',
  styleUrl: './page-faq.component.css',
})
export class PageFaqComponent {
  frequentlyAskedQuestions = frequentlyAskedQuestions;
}
