import {
  FormItemButtonSelect,
  FormItemDropdown,
} from 'src/app/models/form/form';

export const attitudeToChangeSummary = new FormItemDropdown({
  key: 'attitudeToChangeSummary',
  label: 'How would you describe how you feel about change?',
  path: 'planningAhead.attitudeToChange.summary',
  class: 'mb-5',
  placeholder: 'Please select...',
  options: [
    {
      name: '1',
      label:
        'I am always anxious about change and like things to stay the same',
    },
    {
      name: '2',
      label:
        "I am apprehensive of change but we'll try new things if I have support",
    },
    {
      name: '3',
      label: 'I cope well with change as long as everything is explained to me',
    },
    {
      name: '4',
      label:
        'I am relaxed about things changing and trust others to keep me right',
    },
    {
      name: '5',
      label: 'I enjoy change and find it brings me new opportunities',
    },
  ],
});

export const attitudeToChangeContinousCareHousingPreference =
  new FormItemButtonSelect({
    key: 'attitudeToChangeContinousCareHousingPreference',
    path: 'planningAhead.attitudeToChange.continousCareHousingPreference',
    label:
      'In the event you need 24 hour support, where would you want to live?',
    class: 'mb-5',
    multiple: true,
    options: [
      { name: 'My own home', label: 'My own home' },
      { name: 'Supported housing', label: 'Supported housing' },
      { name: 'Nursing home', label: 'Nursing home' },
      { name: 'Other', label: 'Other' },
    ],
  });

export const attitudeToChangeImportantCareAspects = new FormItemButtonSelect({
  key: 'attitudeToChangeImportantCareAspects',
  path: 'planningAhead.attitudeToChange.importantCareAspects',
  label:
    'With regards to your care what aspects would be most important to you?',
  class: 'mb-5',
  multiple: true,
  options: [
    { name: 'Cleaning the house', label: 'Cleaning the house' },
    { name: 'My garden', label: 'My garden' },
    { name: 'Looking after my pets', label: 'Looking after my pets' },
    { name: 'Medication', label: 'Medication' },
    { name: 'Help getting up', label: 'Help getting up' },
    { name: 'Getting my meals', label: 'Getting my meals' },
    { name: 'Assistance to the toilet', label: 'Assistance to the toilet' },
    { name: 'Shopping', label: 'Shopping' },
    { name: 'Transport', label: 'Transport' },
    { name: 'DIY/Handy person service', label: 'DIY/Handy person service' },
    { name: 'Hanging curtains', label: 'Hanging curtains' },
    { name: 'Other', label: 'Other' },
  ],
});
