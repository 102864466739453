import { FormItem } from 'src/app/models/form/form';
import {
  alissId,
  appLinkText,
  appLinkUrl,
  basicInformationFormTitle,
  dataUsageStatement,
  excerpt,
  icon,
  image,
  organisation,
  postcode,
  priority,
  priorityUser,
  slug,
  source,
  title,
  type,
  webLinkText,
  webLinkUrl,
} from './items/basic-information-items';
import {
  summaryDescription,
  summaryFormTitle,
  summaryHeadline,
  summaryHighlights,
  summaryImages,
  summaryTitle,
} from './items/summary-items';
import {
  displayInPublicFinder,
  displayConditionsFormTitle as displaySettingsFormTitle,
  finderPaths,
} from './items/display-settings-items';
import {
  outputContactSubform,
  outputEmailSubform,
  outputRedirectSubform,
  outputsFormTitle,
} from './items/output-items';
import {
  outputFormsTitle,
  useSeparateOutputFormThem,
  outputForm,
  outputFormThem,
} from './items/data-form-items';

export function getManualInputConnectionAdminBaseForm(): FormItem<any>[] {
  return [
    ...getFormItemsConnectionBasicInformation(),
    ...getFormItemsConnectionSummary(),
    ...getFormItemsConnectionDisplaySettings(),
    ...getFormItemsConnectionOutputs(),
    ...getFormItemsOutputForms(),
  ];
}

function getFormItemsConnectionBasicInformation(): FormItem<any>[] {
  return [
    basicInformationFormTitle,
    source,
    alissId,
    type,
    slug,
    webLinkUrl,
    webLinkText,
    title,
    excerpt,
    image,
    icon,
    priority,
    priorityUser,
    postcode,
    organisation,
    dataUsageStatement,
    appLinkUrl,
    appLinkText,
  ];
}

function getFormItemsConnectionSummary(): FormItem<any>[] {
  return [
    summaryFormTitle,
    summaryTitle,
    summaryHeadline,
    summaryDescription,
    summaryHighlights,
    summaryImages,
  ];
}

function getFormItemsConnectionDisplaySettings(): FormItem<any>[] {
  return [
    displaySettingsFormTitle,
    finderPaths,
    displayInPublicFinder,
    // TODO: Uncomment when display logic is implemented
    // displayLogic,
  ];
}

function getFormItemsConnectionOutputs(): FormItem<any>[] {
  return [
    outputsFormTitle,
    outputContactSubform,
    outputRedirectSubform,
    outputEmailSubform,
  ];
}

function getFormItemsOutputForms(): FormItem<any>[] {
  return [
    outputFormsTitle,
    outputForm,
    useSeparateOutputFormThem,
    outputFormThem,
  ];
}
