import { Validators } from '@angular/forms';
import {
  FormItemButtonSelect,
  FormItemImageUpload,
  FormItemTextArea,
  FormItemTextbox,
  FormItemTitle,
} from 'src/app/models/form/form';
import { yesNoOptions, yesNoSometimesOptions } from '../item-options';

export const bedtimeSleepPatternTitle = new FormItemTitle({
  key: 'bedtimeSleepPatternTitle',
  label: 'Sleep pattern',
  class: 'mt-2 mb-5',
});

export const bedtimePreferredBedtime = new FormItemTextbox({
  key: 'bedtimePreferredBedtime',
  path: 'support.bedtime.preferredBedtime',
  label: 'When do you like to go to bed?',
  placeholder: 'e.g. an approximate time',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const bedtimeGettingToBedTitle = new FormItemTitle({
  key: 'bedtimeGettingToBedTitle',
  label: 'Getting to bed',
  class: 'mt-2 mb-5',
});

export const bedtimeGettingToBedSupportNeeded = new FormItemButtonSelect({
  key: 'bedtimeGettingToBedSupportNeeded',
  path: 'support.bedtime.gettingToBedSupportNeeded',
  label: 'Do you need support with getting to bed?',
  class: 'mb-5',
  options: yesNoOptions,
});

export const bedtimeGettingToBedAreasWithSupportNeed = new FormItemButtonSelect(
  {
    key: 'bedtimeGettingToBedAreasWithSupportNeed',
    path: 'support.bedtime.gettingToBedAreasWithSupportNeed',
    label: 'What parts of getting to bed do you need support with?',
    class: 'mb-5',
    multiple: true,
    options: [
      { name: 'Clean teeth', label: 'Clean teeth' },
      { name: 'Wash', label: 'Wash' },
      { name: 'Get undressed', label: 'Get undressed' },
      { name: 'Get in bed', label: 'Get in bed' },
      { name: 'Take medication', label: 'Take medication' },
      { name: 'Toilet', label: 'Toilet' },
      { name: 'Other', label: 'Other' },
    ],
    displayConditions: [
      {
        key: 'bedtimeGettingToBedSupportNeeded',
        values: [true],
      },
    ],
  }
);

export const bedtimeGettingToBedEquipmentInUse = new FormItemButtonSelect({
  key: 'bedtimeGettingToBedEquipmentInUse',
  path: 'support.bedtime.gettingToBedEquipmentInUse',
  label: 'If you need equipment as part of getting to bed, what do you use?',
  class: 'mb-5',
  multiple: true,
  options: [
    { name: 'Bed ladder', label: 'Bed ladder' },
    { name: 'Slip mat', label: 'Slip mat' },
    { name: 'Hoist', label: 'Hoist' },
    { name: 'special pillow', label: 'special pillow' },
    { name: 'Switching lights off', label: 'Switching lights off' },
    { name: 'Other', label: 'Other' },
  ],
});

export const bedtimeGettingToBedSupportAdditionalInfo = new FormItemTextArea({
  key: 'bedtimeGettingToBedSupportAdditionalInfo',
  path: 'support.bedtime.gettingToBedSupportAdditionalInfo',
  label:
    'Anything else you would like to share about the support you need getting to bed?',
  placeholder: 'e.g. I need to sleep on my left side supported by pillows.',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const bedtimeGettingToBedSupportPhoto = new FormItemImageUpload({
  key: 'bedtimeGettingToBedSupportPhotoTemporary',
  path: 'support.bedtime.gettingToBedSupportPhotoTemporary',
  label: 'Upload photo relating to support required',
  class: 'mb-5',
  imageDownloadName: 'pds-bedtime-support-image',
  placeholderIconName: 'image',
  validators: [Validators.minLength(1), Validators.maxLength(5000000)], // TODO: add compression library?
});

export const bedtimeGettingUpThroughNight = new FormItemButtonSelect({
  key: 'bedtimeGettingUpThroughNight',
  path: 'support.bedtime.gettingUpThroughNight',
  label: 'Do you regularly need to get up through the night?',
  class: 'mb-5',
  options: yesNoSometimesOptions,
});

export const bedtimeGettingUpThroughNightSupportNeeded =
  new FormItemButtonSelect({
    key: 'bedtimeGettingUpThroughNightSupportNeeded',
    path: 'support.bedtime.gettingUpThroughNightSupportNeeded',
    label: 'Do you need support with getting up in the night?',
    class: 'mb-5',
    options: yesNoOptions,
  });

export const bedtimeGettingUpTitle = new FormItemTitle({
  key: 'bedtimeGettingUpTitle',
  label: 'Getting up in the morning',
  class: 'mt-2 mb-5',
});

export const bedtimeGettingUpSupportNeeded = new FormItemButtonSelect({
  key: 'bedtimeGettingUpSupportNeeded',
  path: 'support.bedtime.gettingUpSupportNeeded',
  label: 'Do you need support with getting up in the morning?',
  class: 'mb-5',
  options: yesNoOptions,
});

export const bedtimeGettingUpAreasWithSupportNeed = new FormItemButtonSelect({
  key: 'bedtimeGettingUpAreasWithSupportNeed',
  path: 'support.bedtime.gettingUpAreasWithSupportNeed',
  label: 'What parts of getting up in the morning do you need support with?',
  class: 'mb-5',
  multiple: true,
  options: [
    { name: 'Clean teeth', label: 'Clean teeth' },
    { name: 'Wash', label: 'Wash' },
    { name: 'Get dressed', label: 'Get dressed' },
    { name: 'Get out of bed', label: 'Get out of bed' },
    { name: 'Take medication', label: 'Take medication' },
    { name: 'Toilet', label: 'Toilet' },
    { name: 'Other', label: 'Other' },
  ],
  displayConditions: [
    {
      key: 'bedtimeGettingUpSupportNeeded',
      values: [true],
    },
  ],
});

export const bedtimeGettingUpEquipmentInUse = new FormItemButtonSelect({
  key: 'bedtimeGettingUpEquipmentInUse',
  path: 'support.bedtime.gettingUpEquipmentInUse',
  label: 'If you need equipment as part of getting up, what do you use?',
  class: 'mb-5',
  multiple: true,
  options: [
    { name: 'Bed ladder', label: 'Bed ladder' },
    { name: 'Slip mat', label: 'Slip mat' },
    { name: 'Hoist', label: 'Hoist' },
    { name: 'special pillow', label: 'special pillow' },
    { name: 'Other', label: 'Other' },
  ],
});

export const bedtimeGettingUpSupportAdditionalInfo = new FormItemTextArea({
  key: 'bedtimeGettingUpSupportAdditionalInfo',
  path: 'support.bedtime.gettingUpSupportAdditionalInfo',
  label:
    'Anything else you would like to share about the support you need getting up in the morning?',
  placeholder: 'e.g. I need to sleep on my left side supported by pillows.',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});
