import {
  FormItem,
  FormItemButtonSelect,
  FormItemDropdown,
} from 'src/app/models/form/form';
import {
  supportedPeopleSupportInYears,
  supportedPeopleWeeklyHours,
} from '../user/supporting-role-items/supported-people-items';
import {
  caringRoleIsCaringForMultiplePeople,
  caringRoleIsSoleCarer,
} from '../user/supporting-role-items/caring-role-items';
import { healthImpactCaringAffectsPhysicalHealth } from '../user/supporting-role-items/health-impact-items';
import { emotionalWellbeingCaringAffectsWellbeing } from '../user/supporting-role-items/emotional-wellbeing-items';
import { financialImpactCaringAffectsFinances } from '../user/supporting-role-items/financial-impact-items';
import { personalDetailsAbleToContinueProvidingCare } from '../user/supporting-role-items/personal-details-items';
import { dataProcessingConsent } from '../general/consent';

const supportedPeopleWeeklyHoursCarerForm = new FormItemButtonSelect(
  supportedPeopleWeeklyHours
);
supportedPeopleWeeklyHoursCarerForm.styleType = 'tab';

const caringRoleIsCaringForMultiplePeopleCarerForm = new FormItemButtonSelect(
  caringRoleIsCaringForMultiplePeople
);
caringRoleIsCaringForMultiplePeopleCarerForm.styleType = 'tab';

const caringRoleIsSoleCarerCarerForm = new FormItemButtonSelect(
  caringRoleIsSoleCarer
);
caringRoleIsSoleCarerCarerForm.styleType = 'tab';

const supportedPeopleSupportInYearsCarerForm = new FormItemButtonSelect(
  supportedPeopleSupportInYears
);
supportedPeopleSupportInYearsCarerForm.styleType = 'tab';

const healthImpactCaringAffectsPhysicalHealthCarerForm =
  new FormItemButtonSelect(healthImpactCaringAffectsPhysicalHealth);
healthImpactCaringAffectsPhysicalHealthCarerForm.styleType = 'tab';

const emotionalWellbeingCaringAffectsWellbeingCarerForm =
  new FormItemButtonSelect(emotionalWellbeingCaringAffectsWellbeing);
emotionalWellbeingCaringAffectsWellbeingCarerForm.styleType = 'tab';

const financialImpactCaringAffectsFinancesCarerForm = new FormItemButtonSelect(
  financialImpactCaringAffectsFinances
);
financialImpactCaringAffectsFinancesCarerForm.styleType = 'tab';

const personalDetailsAbleToContinueProvidingCareCarerForm =
  new FormItemButtonSelect(personalDetailsAbleToContinueProvidingCare);
personalDetailsAbleToContinueProvidingCareCarerForm.styleType = 'tab';

export const unpaidCarerForm: FormItem<any>[] = [
  supportedPeopleWeeklyHoursCarerForm,
  caringRoleIsCaringForMultiplePeopleCarerForm,
  caringRoleIsSoleCarerCarerForm,
  supportedPeopleSupportInYearsCarerForm,
  healthImpactCaringAffectsPhysicalHealthCarerForm,
  emotionalWellbeingCaringAffectsWellbeingCarerForm,
  financialImpactCaringAffectsFinancesCarerForm,
  personalDetailsAbleToContinueProvidingCareCarerForm,
  dataProcessingConsent,
];
