import { FormItem, FormItemTitle } from 'src/app/models/form/form';
import {
  getFormItemsUserMe,
  getFormItemsUserMeContactDetails,
  getFormItemsUserMeCurrent,
  getFormItemsUserMeHome,
  getFormItemsUserMeMyPeople,
  getFormItemsUserMePastimes,
  getFormItemsUserMePersonalDetails,
  getFormItemsUserMePersonalQualities,
  getFormItemsUserMeReminiscence,
  getFormItemsUserMeRoutines,
} from './me-items/form-me';
import {
  getFormItemsUserKeyInfo,
  getFormItemsUserKeyInfoAccess,
  getFormItemsUserKeyInfoMainSupport,
  getFormItemsUserKeyInfoMedicalDetails,
  getFormItemsUserKeyInfoMedicationLocation,
  getFormItemsUserKeyInfoPreferences,
  getFormItemsUserKeyInfoPriorities,
  getFormItemsUserKeyInfoSupportPackage,
} from './key-info-items/form-key-info';
import {
  getFormItemsUserHealth,
  getFormItemsUserHealthImportant,
  getFormItemsUserHealthMedicalHistory,
  getFormItemsUserHealthMemory,
  getFormItemsUserHealthStayingSafe,
  getFormItemsUserHealthWellbeing,
} from './health-items/form-health';
import {
  getFormItemsUserSupportingRole,
  getFormItemsUserSupportingRoleCaringRole,
  getFormItemsUserSupportingRoleEmotionalWellbeing,
  getFormItemsUserSupportingRoleEmploymentImpact,
  getFormItemsUserSupportingRoleFinancialImpact,
  getFormItemsUserSupportingRoleFuturePlansImpact,
  getFormItemsUserSupportingRoleHealthImpact,
  getFormItemsUserSupportingRoleHomeImpact,
  getFormItemsUserSupportingRoleLifeBalance,
  getFormItemsUserSupportingRoleOtherLifeAspects,
  getFormItemsUserSupportingRolePersonalDetails,
  getFormItemsUserSupportingRoleSkillsAndExperience,
  getFormItemsUserSupportingRoleSupportedPeople,
  getFormItemsUserSupportingRoleWhatMatters,
} from './supporting-role-items/form-supporting-role';
import {
  getFormItemsUserSupport,
  getFormItemsUserSupportActionsList,
  getFormItemsUserSupportBedtime,
  getFormItemsUserSupportCommunication,
  getFormItemsUserSupportContinence,
  getFormItemsUserSupportDigital,
  getFormItemsUserSupportEatingAndDrinking,
  getFormItemsUserSupportFalls,
  getFormItemsUserSupportFootCare,
  getFormItemsUserSupportGeneralFitness,
  getFormItemsUserSupportGettingWashed,
  getFormItemsUserSupportMedication,
  getFormItemsUserSupportMovingAroundInside,
  getFormItemsUserSupportMovingAroundOutside,
  getFormItemsUserSupportPersonalAids,
  getFormItemsUserSupportPracticalHelp,
} from './support-items/form-support';
import {
  getFormItemsUserPlanningAheadAttitudeToChange,
  getFormItemsUserPlanningAheadFutureCarePlan,
  getFormItemsUserPlanningAheadLivingWill,
  getFormItemsUserPlanningAheadPowerOfAttorney,
  getFormItemsUserPlanningAheadUnpaidCarer,
  getFormItemsUserPlanningAheadWill,
} from './planning-ahead-items/form-planning-ahead';

// All form items for user
export function getFormItemsUser(filter: string = ''): FormItem<any>[] {
  return [
    new FormItemTitle({ label: 'This is Me' }),
    ...getFormItemsUserMe(),
    new FormItemTitle({ label: 'Key Information' }),
    ...getFormItemsUserKeyInfo(),
    new FormItemTitle({ label: 'Health & Wellbeing' }),
    ...getFormItemsUserHealth(),
    new FormItemTitle({ label: 'Supporting Role' }),
    ...getFormItemsUserSupportingRole(),
    new FormItemTitle({ label: 'My Support' }),
    ...getFormItemsUserSupport(),
  ];
}

// All form items for user
export function getAllFormItemsUser(filter: string = ''): FormItem<any>[] {
  return [
    // ------------------------------------- THIS IS ME -----------------------------------------
    ...getFormItemsUserMePersonalDetails(),
    ...getFormItemsUserMeContactDetails(),
    ...getFormItemsUserMeCurrent(),
    ...getFormItemsUserMePersonalQualities(),
    ...getFormItemsUserMeMyPeople(),
    ...getFormItemsUserMePastimes(),
    ...getFormItemsUserMeHome(),
    ...getFormItemsUserMeRoutines(),
    ...getFormItemsUserMeReminiscence(),
    // ------------------------------------- KEY INFORMATION -----------------------------------------
    ...getFormItemsUserKeyInfoMedicalDetails(),
    ...getFormItemsUserKeyInfoMedicationLocation(),
    ...getFormItemsUserKeyInfoMainSupport(),
    ...getFormItemsUserKeyInfoAccess(),
    ...getFormItemsUserKeyInfoPriorities(),
    ...getFormItemsUserKeyInfoSupportPackage(),
    ...getFormItemsUserKeyInfoPreferences(),
    // ------------------------------------- HEALTH & WELLBEING -----------------------------------------
    ...getFormItemsUserHealthMedicalHistory(),
    ...getFormItemsUserHealthWellbeing(),
    ...getFormItemsUserHealthMemory(),
    ...getFormItemsUserHealthStayingSafe(),
    ...getFormItemsUserHealthImportant(),
    // ------------------------------------- SUPPORTING ROLE -----------------------------------------
    ...getFormItemsUserSupportingRolePersonalDetails(),
    ...getFormItemsUserSupportingRoleSupportedPeople(),
    ...getFormItemsUserSupportingRoleCaringRole(),
    ...getFormItemsUserSupportingRoleHealthImpact(),
    ...getFormItemsUserSupportingRoleEmotionalWellbeing(),
    ...getFormItemsUserSupportingRoleFinancialImpact(),
    ...getFormItemsUserSupportingRoleLifeBalance(),
    ...getFormItemsUserSupportingRoleFuturePlansImpact(),
    ...getFormItemsUserSupportingRoleEmploymentImpact(),
    ...getFormItemsUserSupportingRoleHomeImpact(),
    ...getFormItemsUserSupportingRoleOtherLifeAspects(),
    ...getFormItemsUserSupportingRoleSkillsAndExperience(),
    ...getFormItemsUserSupportingRoleWhatMatters(),
    // ------------------------------------- MY SUPPORT -----------------------------------------
    ...getFormItemsUserSupportCommunication(),
    ...getFormItemsUserSupportMedication(),
    ...getFormItemsUserSupportGeneralFitness(),
    ...getFormItemsUserSupportPracticalHelp(),
    ...getFormItemsUserSupportMovingAroundInside(),
    ...getFormItemsUserSupportMovingAroundOutside(),
    ...getFormItemsUserSupportGettingWashed(),
    ...getFormItemsUserSupportPersonalAids(),
    ...getFormItemsUserSupportContinence(),
    ...getFormItemsUserSupportEatingAndDrinking(),
    ...getFormItemsUserSupportBedtime(),
    ...getFormItemsUserSupportFalls(),
    ...getFormItemsUserSupportDigital(),
    ...getFormItemsUserSupportActionsList(),
    ...getFormItemsUserSupportFootCare(),
    // ------------------------------------- PLANNING AHEAD -----------------------------------------
    ...getFormItemsUserPlanningAheadAttitudeToChange(),
    ...getFormItemsUserPlanningAheadPowerOfAttorney(),
    ...getFormItemsUserPlanningAheadFutureCarePlan(),
    ...getFormItemsUserPlanningAheadWill(),
    ...getFormItemsUserPlanningAheadUnpaidCarer(),
    ...getFormItemsUserPlanningAheadLivingWill(),
  ];
}
