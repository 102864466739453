import { Injectable } from '@angular/core';
import { FinderNode } from 'src/app/models/finder/finderNode';
import { finderNodes as finderNodes } from 'src/assets/finder-nodes/finder.nodes.collection';

@Injectable({
  providedIn: 'root',
})
export class FinderService {
  private nodesSource: FinderNode[] = finderNodes;
  private _audience: 'me' | 'them' = 'me';

  public getNodeForNodePath(nodePath: string): FinderNode | undefined {
    let node: FinderNode | undefined;

    // this is going to be used by connections page to decide whether to show extra guidance
    if (nodePath === 'them') {
      this._audience = 'them';
    } else if (nodePath === 'me') {
      this._audience = 'me';
    }
    nodePath = nodePath.replace('them', 'me');

    // if nodePath is empty, create base level node
    if (nodePath === '') {
      node = new FinderNode({ followUpQuestion: 'Who is this for?' });
    }
    // else get node for nodePath
    else {
      node = this.nodesSource.find((node) => {
        return node.fullPath === nodePath;
      });
    }

    // if no node exists with that path, return
    if (node === undefined) {
      return node;
    }

    // inject child nodes into node
    node.childNodes = this.nodesSource.filter((childNodes) => {
      return childNodes.parentPath === node?.fullPath;
    });

    return node;
  }

  public getNodePathForNode(nodeName: string): string {
    let node = this.nodesSource.find((nodes) => {
      return nodes.name === nodeName;
    });

    if (node === undefined) {
      return '';
    }

    return node.fullPath;
  }

  get audience(): string {
    return this._audience;
  }
}
