import { Validators } from '@angular/forms';
import {
  FormItemButtonSelect,
  FormItemDropdown,
  FormItemTextArea,
} from 'src/app/models/form/form';

export const lifeBalanceAreasAffectedByCaring = new FormItemButtonSelect({
  key: 'lifeBalanceAreasAffectedByCaring',
  path: 'supportingRole.lifeBalance.areasAffectedByCaring',
  label: 'My caring role impacts on these aspects of my life',
  class: 'mb-5',
  multiple: true,
  options: [
    { name: 'Social life', label: 'Social life' },
    { name: 'Cultural activities', label: 'Cultural activities' },
    { name: 'Leisure activities', label: 'Leisure activities' },
    { name: 'Childcare', label: 'Childcare' },
    { name: 'Religious activities', label: 'Religious activities' },
    { name: 'Other', label: 'Other' },
  ],
});

export const lifeBalanceAreasAffectedByCaringDetails = new FormItemTextArea({
  key: 'lifeBalanceAreasAffectedByCaringDetails',
  path: 'supportingRole.lifeBalance.areasAffectedByCaringDetails',
  label: 'How this impacts me',
  placeholder: 'e.g. losing touch with friends, missing wider family events',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
  displayConditions: [
    {
      key: 'lifeBalanceAreasAffectedByCaring',
      values: [
        'Social life',
        'Cultural activities',
        'Leisure activities',
        'Childcare',
        'Religious activities',
        'Other',
      ],
    },
  ],
});

export const lifeBalanceSummary = new FormItemDropdown({
  key: 'lifeBalanceSummary',
  label:
    'Thinking about the balance between my caring role and a life of my own, the selected statement best matches how I currently feel.',
  path: 'supportingRole.lifeBalance.summary',
  class: 'mb-5',
  placeholder: 'Please select...',
  options: [
    {
      name: '1',
      label:
        'Things are as good as they can be. I take breaks and balance caring with other things.',
    },
    {
      name: '2',
      label:
        "I have some time for myself, and some activities or social life outside my caring role, but things could be better. I feel I don't have many choices of things to do.",
    },
    {
      name: '3',
      label:
        "I'm trying to get some time for myself, and some activities or social life outside my caring role, but it's difficult and often doesn't work out.",
    },
    {
      name: '4',
      label:
        "Caring has taken over my life. I rarely get away from my caring role, and can't plan to take time to myself. I'm getting some help to see if I can change this.",
    },
    {
      name: '5',
      label:
        "Caring is my whole life. I can't see how it could be any way. I get no breaks, and have no life of my own away from caring.",
    },
  ],
});
