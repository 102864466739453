import { Validators } from '@angular/forms';
import { FormItemTextArea } from 'src/app/models/form/form';

export const stayingSafeImpactsFeelingSafe = new FormItemTextArea({
  key: 'stayingSafeImpactsFeelingSafe',
  path: 'health.stayingSafe.impactsFeelingSafe',
  label: 'What makes you feel safe?',
  placeholder: 'e.g. my family',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const stayingSafeImpactsFeelingUnsafe = new FormItemTextArea({
  key: 'stayingSafeImpactsFeelingUnsafe',
  path: 'health.stayingSafe.impactsFeelingUnsafe',
  label: 'What makes you feel unsafe?',
  placeholder: 'e.g. my neighbour shouts a lot',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const stayingSafeHomeAdjustments = new FormItemTextArea({
  key: 'stayingSafeHomeAdjustments',
  path: 'health.stayingSafe.homeAdjustments',
  label: 'Have any adjustments been made to your home to keep you safe?',
  placeholder:
    'e.g. Handrails (indoor/outdoor), bathroom aids, kitchen aids, telecare, adaptations, ramps',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});
