import { Validators } from '@angular/forms';
import { FormItemTextArea } from 'src/app/models/form/form';

// KEY INFORMATION - PRIORITIES -----------------------------------------------

export const otherDependents = new FormItemTextArea({
  key: 'otherDependents',
  path: 'keyInformation.priorities.otherDependents',
  label: 'Other dependents:',
  placeholder:
    'e.g. I care for my mum and get her medication and breakfast in the morning. She lives at...',
  class: 'mb-5',
  width: 'half',
  type: 'string',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const pets = new FormItemTextArea({
  key: 'pets',
  path: 'keyInformation.priorities.pets',
  label: 'Pets:',
  placeholder:
    'e.g. my son looks after them in an emergency. His number is 072803948024',
  class: 'mb-5',
  width: 'half',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});
