import {
  FormItemButtonSelect,
  FormItemTextArea,
} from 'src/app/models/form/form';
import { yesNoOptions } from '../item-options';
import { Validators } from '@angular/forms';

export const caringRoleIsCaringForMultiplePeople = new FormItemButtonSelect({
  key: 'caringRoleIsCaringForMultiplePeople',
  label: 'I care for more than one person',
  path: 'supportingRole.caringRole.isCaringForMultiplePeople',
  class: 'mb-5',
  options: yesNoOptions,
});

export const caringRoleDetailsOfCaredForPeople = new FormItemTextArea({
  key: 'caringRoleDetailsOfCaredForPeople',
  path: 'supportingRole.caringRole.detailsOfCaredForPeople',
  label: 'Details of others depending on me for care',
  placeholder: 'e.g. age, condition, the help I provide',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const caringRoleIsSoleCarer = new FormItemButtonSelect({
  key: 'caringRoleIsSoleCarer',
  label: 'I am the only carer',
  path: 'supportingRole.caringRole.isSoleCarer',
  class: 'mb-5',
  options: yesNoOptions,
});

export const caringRoleHowOthersDoOrCouldSupport = new FormItemTextArea({
  key: 'caringRoleHowOthersDoOrCouldSupport',
  path: 'supportingRole.caringRole.howOthersDoOrCouldSupport',
  label:
    'Other family members/friends who do/could help, and what they could do',
  placeholder: '',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const caringRoleServicesInPlace = new FormItemTextArea({
  key: 'caringRoleServicesInPlace',
  path: 'supportingRole.caringRole.servicesInPlace',
  label: 'Services in place now, for me and/or the person I care for',
  placeholder: '',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const caringRoleInvolvedInServicesDesign = new FormItemButtonSelect({
  key: 'caringRoleInvolvedInServicesDesign',
  label: 'I feel involved in designing the services/support in place',
  path: 'supportingRole.caringRole.involvedInServicesDesign',
  class: 'mb-5',
  options: yesNoOptions,
});

export const caringRoleIsSatisfiedWithServices = new FormItemButtonSelect({
  key: 'caringRoleIsSatisfiedWithServices',
  label: 'I am satisfied with current services/support',
  path: 'supportingRole.caringRole.isSatisfiedWithServices',
  class: 'mb-5',
  options: yesNoOptions,
});

export const caringRoleServicesSatisfactionDetails = new FormItemTextArea({
  key: 'caringRoleServicesSatisfactionDetails',
  label: 'Because...',
  path: 'supportingRole.caringRole.satisfactionDetails',
  placeholder: '“I still feel I am spending too much time indoors as a carer”',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
  displayConditions: [
    {
      key: 'caringRoleIsSatisfiedWithServices',
      values: [true, false],
    },
  ],
});

export const caringRoleChallenges = new FormItemTextArea({
  key: 'caringRoleChallenges',
  label:
    'Things I find particularly challenging, or difficult to deal with in my role as a carer',
  path: 'supportingRole.caringRole.challenges',
  placeholder: 'Disturbed night sleep',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const caringRolePastTraining = new FormItemTextArea({
  key: 'caringRolePastTraining',
  label: 'Training I have had to help me in my role',
  path: 'supportingRole.caringRole.pastTraining',
  placeholder: 'Moving and handling training',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const caringRoleDesiredTraining = new FormItemTextArea({
  key: 'caringRoleDesiredTraining',
  label: 'Training I think would help',
  path: 'supportingRole.caringRole.desiredTraining',
  placeholder: 'Digital skills training',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});
