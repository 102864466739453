import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-quarriers',
  templateUrl: './quarriers.component.html',
  styleUrls: ['./quarriers.component.css'],
})
export class QuarriersComponent {
  constructor(protected authService: AuthService) {}
}
