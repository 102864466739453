import { Validators } from '@angular/forms';
import { FormItemTextArea } from 'src/app/models/form/form';

export const actionsListOwnTasks = new FormItemTextArea({
  key: 'actionsListOwnTasks',
  path: 'support.actionsList.ownTasks',
  label: 'Things for you to do',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const actionsListTasksWithSupportNeed = new FormItemTextArea({
  key: 'actionsListTasksWithSupportNeed',
  path: 'support.actionsList.tasksWithSupportNeed',
  label: 'Needs help from others',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const actionsListUrgentTasks = new FormItemTextArea({
  key: 'actionsListUrgentTasks',
  path: 'support.actionsList.urgentTasks',
  label: 'Urgent',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});
