import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-piechart',
  templateUrl: './piechart.component.html',
  styleUrls: ['./piechart.component.css']
})
export class PiechartComponent {
  @Input() width: Number = 150;
  @Input() borderWidth: Number = 8;
  @Input() value: Number = 50;
}
