import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormItem, FormItemAddress } from 'src/app/models/form/form';

declare const google: any;

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressComponent),
      multi: true,
    },
  ],
})
export class AddressComponent implements ControlValueAccessor {
  @Input() options!: any[];
  @Input() item!: FormItem<any>;
  @Input() disabled = false;
  @Input() isInvalid!: boolean;
  @ViewChild('addressInput') addressInput!: ElementRef;

  @Output() autofill = new EventEmitter<any>();

  onChange = (value: any) => {};
  onTouched = () => {};
  touched = false;
  addressValue: string = '';

  constructor(private cdRef: ChangeDetectorRef) {}

  private hasAutofill(item: FormItem<any>): item is FormItemAddress {
    return 'autofill' in item;
  }

  update(event: Event): void {
    const value = (event.target as HTMLInputElement).value;
    this.markAsTouched();
    this.onChange(value);
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(value: string): void {
    this.addressValue = value || '';
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  ngAfterViewInit() {
    let autocomplete = new google.maps.places.Autocomplete(
      this.addressInput.nativeElement,
      {
        componentRestrictions: { country: 'uk' },
        strictBounds: false,
      }
    );

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();

      if (this.hasAutofill(this.item)) {
        //hide text, wait for transition, show again
        this.addressInput.nativeElement.style.color = 'transparent';

        setTimeout(() => {
          if (this.hasAutofill(this.item)) {
            //check again just for TS
            for (let [property, path] of Object.entries(this.item.autofill)) {
              let value = null;
              switch (property) {
                case 'lat':
                  value = place.geometry.location.lat();
                  break;
                case 'lng':
                  value = place.geometry.location.lng();
                  break;
                default:
                  value = place.address_components.find((component: any) =>
                    component.types.includes(property)
                  )?.long_name;
                  break;
              }
              this.autofill.emit({ path: path, value: value ?? null });
            }
          }

          const commaSplit = this.addressInput.nativeElement.value.split(','),
            first = commaSplit[0],
            line1 = [
              [first],
              ...(first.at(-1).match(/[\d ]/) ? [commaSplit[1]] : []),
            ].join(','); //if last char of first line is a number or space, add second line

          this.writeValue(line1 ?? '');
          this.onChange(line1);

          this.cdRef.detectChanges();

          this.addressInput.nativeElement.style.color = '';
        }, 150);
      }
    });
  }
}
