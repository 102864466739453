import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

/** A router wrapper, adding extra functions. */
@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private currentUrl?: string = undefined;
  private previousUrl?: string = undefined;
  private currentUrlNoQueryParams?: string = undefined;
  private previousUrlNoQueryParams?: string = undefined;

  private routerEventsSubscription: any;

  constructor(private router: Router) {
    this.routerEventsSubscription = this.router.events.subscribe((event) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }

      if (this.currentUrl) {
        this.previousUrl = this.currentUrl;
        this.previousUrlNoQueryParams = this.currentUrlNoQueryParams;
      }

      this.currentUrl = event.url;
      this.currentUrlNoQueryParams = event.url.split('?')[0];
    });
  }

  public getPreviousUrl(
    context: string = '/finder',
    withQueryParams: boolean = true
  ) {
    if (!this.previousUrl) {
      return context;
    }

    if (!withQueryParams) {
      return this.previousUrlNoQueryParams;
    }

    return this.previousUrl;
  }

  ngOnDestroy() {
    if (this.routerEventsSubscription !== undefined) {
      this.routerEventsSubscription.unsubscribe();
    }
  }
}
