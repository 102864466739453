import { FinderNode } from 'src/app/models/finder/finderNode';
import { FormItemOption } from 'src/app/models/form/form';

export const finderNodes = [
  // ------ 1st level nodes ------
  new FinderNode({
    name: 'me',
    title: 'Help for me',
    parentPath: '',
    followUpQuestion: 'In what area is help needed?',
    icon: '/assets/img/icons/home-user.svg',
    description: 'I am looking for help for myself.',
  }),
  new FinderNode({
    name: 'them',
    title: 'Help for someone I support',
    parentPath: '',
    followUpQuestion: '',
    icon: '/assets/img/icons/home-group.svg',
    description: 'I am looking for help for someone I support.',
  }),

  // ------ 2nd level nodes ------
  // ------ ME ------
  new FinderNode({
    name: 'meeting',
    title: 'Meeting up',
    parentPath: 'me',
    followUpQuestion: 'What specifically?',
    icon: '/assets/img/icons/sauna.svg',
    description: "I'm looking to meet new people and make friends.",
  }),
  new FinderNode({
    name: 'wellbeing',
    title: 'Wellbeing',
    parentPath: 'me',
    followUpQuestion: 'What specifically?',
    icon: '/assets/img/icons/heart.svg',
    description: "I'm looking for help with my wellbeing.",
  }),
  new FinderNode({
    name: 'practical',
    title: 'Practical Help',
    parentPath: 'me',
    followUpQuestion: 'What specifically?',
    icon: '/assets/img/icons/food.svg',
    description:
      "I'm looking for help with practical things like jobs around the house, transport, etc.",
  }),
  new FinderNode({
    name: 'support',
    title: 'Personal support',
    parentPath: 'me',
    followUpQuestion: 'What specifically?',
    icon: '/assets/img/icons/home-user.svg',
    description:
      "I'm looking for help with personal support like counselling, advocacy, etc.",
  }),
  new FinderNode({
    name: 'digital',
    title: 'Digital',
    parentPath: 'me',
    followUpQuestion: 'What specifically?',
    icon: '/assets/img/icons/computer.svg',
    description:
      "I'm looking for help with home technologies and digital skills.",
  }),
  new FinderNode({
    name: 'housing-money',
    title: 'Housing, Money & Legal',
    parentPath: 'me',
    followUpQuestion: 'What specifically?',
    icon: '/assets/img/icons/house.svg',
    description: "I'm looking for help with housing, money and legal issues.",
  }),
  new FinderNode({
    name: 'health-social-care',
    title: 'Health and Social Care Services',
    parentPath: 'me',
    followUpQuestion: 'What specifically?',
    icon: '/assets/img/icons/health-social-care.svg',
    description: "I'm interested in health and social care services.",
  }),
  // ------ 3rd level nodes ------
  // ------ ME > MEETING ------
  new FinderNode({
    name: 'exercise',
    title: 'Exercise & Activity',
    parentPath: 'me.meeting',
    icon: '/assets/img/icons/walking.svg',
    description: 'Find connections to help me get out and exercise more.',
  }),
  new FinderNode({
    name: 'events',
    title: 'Get-togethers & Events',
    parentPath: 'me.meeting',
    followUpQuestion: 'What specifically?',
    icon: '/assets/img/icons/people.svg',
    description:
      'Find connections to local events and get-togethers in my area.',
  }),

  // ------ ME > WELLBEING ------
  new FinderNode({
    name: 'exercise',
    title: 'Exercise & Activity',
    parentPath: 'me.wellbeing',
    icon: '/assets/img/icons/walking.svg',
    description: 'Find connections to help me get out and exercise more.',
  }),
  new FinderNode({
    name: 'health',
    title: 'Health',
    parentPath: 'me.wellbeing',
    icon: '/assets/img/icons/heart.svg',
    description: 'Find connections to help me with my general health.',
  }),
  new FinderNode({
    name: 'hearing-sight',
    title: 'Hearing & Sight',
    parentPath: 'me.wellbeing',
    icon: '/assets/img/icons/glasses.svg',
    description: 'Find connections to help me with my hearing and sight.',
  }),
  new FinderNode({
    name: 'carer',
    title: 'Carer Support',
    parentPath: 'me.wellbeing',
    followUpQuestion: '',
    icon: '/assets/img/icons/chat-heart.svg',
    description: 'Connections that can help with caring for someone.',
  }),
  new FinderNode({
    name: 'memory',
    title: 'Memory',
    parentPath: 'me.wellbeing',
    followUpQuestion: '',
    icon: '/assets/img/icons/brain.svg',
    description: 'Connections that can help with memory issues.',
  }),
  new FinderNode({
    name: 'counselling',
    title: 'Counselling',
    parentPath: 'me.wellbeing',
    followUpQuestion: '',
    icon: '/assets/img/icons/people.svg',
    description: 'Counselling and therapy services.',
  }),
  new FinderNode({
    name: 'local',
    title: 'Local Services',
    parentPath: 'me.wellbeing',
    followUpQuestion: '',
    icon: '/assets/img/icons/home-pin.svg',
    description: 'Local services.',
  }),
  new FinderNode({
    name: 'weight',
    title: 'Healthy Weight',
    parentPath: 'me.wellbeing',
    followUpQuestion: '',
    icon: '/assets/img/icons/healthy-weight.svg',
    description: 'Connections that help manage my weight.',
  }),
  new FinderNode({
    name: 'veterans',
    title: 'Veterans',
    parentPath: 'me.wellbeing',
    followUpQuestion: '',
    icon: '/assets/img/icons/veteran.svg',
    description: 'Support for veterans.',
  }),
  // ------ ME > PRACTICAL ------
  new FinderNode({
    name: 'trades',
    title: 'Trades People',
    parentPath: 'me.practical',
    followUpQuestion: '',
    icon: '/assets/img/icons/hammer.svg',
    description:
      'People who can help with with jobs around the house like plumbing, electrics.',
  }),
  new FinderNode({
    name: 'transport',
    title: 'Transport',
    parentPath: 'me.practical',
    followUpQuestion: '',
    icon: '/assets/img/icons/taxi.svg',
    description: 'Help with getting from A to B.',
  }),

  // ------ ME > SUPPORT ------
  new FinderNode({
    name: 'telecare',
    title: 'Telecare',
    parentPath: 'me.support',
    followUpQuestion: '',
    icon: '/assets/img/icons/phone.svg',
    description:
      'Connections to help with telecare and personal alarms for the home.',
  }),
  new FinderNode({
    name: 'dementia',
    title: 'Dementia Support',
    parentPath: 'me.support',
    followUpQuestion: '',
    icon: '/assets/img/icons/brain.svg',
    description: 'Connections to help with dementia.',
  }),
  new FinderNode({
    name: 'sds',
    title: 'Self Directed Support',
    parentPath: 'me.support',
    followUpQuestion: '',
    icon: '/assets/img/icons/home-user.svg',
    description: 'Connections for SDS resources.',
  }),
  new FinderNode({
    name: 'care',
    title: 'Self Care',
    parentPath: 'me.support',
    followUpQuestion: '',
    icon: '/assets/img/icons/self-care.svg',
    description: 'Find connections for self care.',
  }),

  // ------ ME > DIGITAL ------
  new FinderNode({
    name: 'it',
    title: 'IT Support',
    parentPath: 'me.digital',
    followUpQuestion: '',
    icon: '/assets/img/icons/computer.svg',
    description: 'Connections to help with IT and other technical needs.',
  }),
  new FinderNode({
    name: 'telecare',
    title: 'Telecare',
    parentPath: 'me.digital',
    followUpQuestion: '',
    icon: '/assets/img/icons/phone.svg',
    description:
      'Connections to help with telecare and personal alarms for the home.',
  }),

  // ------ ME > HEALTH SOCIAL CARE ------
  new FinderNode({
    name: 'social-work',
    title: 'Social Work',
    parentPath: 'me.health-social-care',
    followUpQuestion: '',
    icon: '/assets/img/icons/social-work.svg',
    description: 'Social work services.',
  }),
  new FinderNode({
    name: 'nursing',
    title: 'Community Nursing',
    parentPath: 'me.health-social-care',
    followUpQuestion: '',
    icon: '/assets/img/icons/community-nurse.svg',
    description: 'Community nursing services.',
  }),
  new FinderNode({
    name: 'ot',
    title: 'Occupational Therapy',
    parentPath: 'me.health-social-care',
    followUpQuestion: '',
    icon: '/assets/img/icons/occupational-therapy.svg',
    description: 'Occupational therapy services.',
  }),

  // ------ 4th level nodes ------
  // ------ ME > MEETING > EVENTS ------
  new FinderNode({
    name: 'general',
    title: 'Social groups',
    parentPath: 'me.meeting.events',
    icon: '/assets/img/icons/chat-dots.svg',
    description: 'Social groups in my area.',
  }),
  new FinderNode({
    name: 'recovery',
    title: 'Recovery groups',
    parentPath: 'me.meeting.events',
    icon: '/assets/img/icons/chat-heart.svg',
    description:
      'Specialist groups in my area for people with specific needs or interests.',
  }),
];

export function getOptionsForFinderPathSelection(): FormItemOption[] {
  // get all leaf nodes (nodes with no children)
  let leafNodes = finderNodes.filter((node) => {
    return !finderNodes.some((potentialChildNodes) => {
      return potentialChildNodes.parentPath === node.fullPath;
    });
  });

  // remove 'them' node from leaf nodes
  leafNodes = leafNodes.filter((node) => {
    return node.fullPath !== 'them';
  });

  // create options from leaf nodes
  let options: FormItemOption[] = leafNodes.map((node) => {
    return {
      name: node.fullPath,
      label: node.title + ' (' + node.fullPath + ')',
    };
  });

  // add quarriers option (temporary, remove when generic journey flow has been implemented)
  options.push({
    name: 'next.steps',
    label: 'Quarriers (next.steps)',
  });

  return options;
}
