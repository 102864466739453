import { Validators } from '@angular/forms';
import { DefaultShortDisplayComponent } from 'src/app/components/form/displays/default-short-display/default-short-display.component';
import {
  FormItemSubform,
  FormItemTextbox,
  FormItemTitle,
} from 'src/app/models/form/form';

const name = new FormItemTextbox({
  key: 'name',
  label: 'What medication do you take?',
  placeholder: 'e.g. Paracetamol',
  class: 'mb-5',
  required: true,
  validators: [
    Validators.minLength(1),
    Validators.maxLength(2000),
    Validators.required,
  ],
});

const intakeTime = new FormItemTextbox({
  key: 'intakeTime',
  label: 'When do you want to take it?',
  placeholder: 'e.g. after food',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

const intakeFrequency = new FormItemTextbox({
  key: 'intakeFrequency',
  label: 'What frequency do you take it?',
  placeholder: 'e.g. 3 times a day',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

const intakeMethod = new FormItemTextbox({
  key: 'intakeMethod',
  label: 'How do you want to take it?',
  placeholder: 'e.g. with my cup of tea, after a meal',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

const storageLocation = new FormItemTextbox({
  key: 'storageLocation',
  label: 'Where do you keep it?',
  placeholder: 'e.g. in the bathroom',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

const pharmacy = new FormItemTextbox({
  key: 'pharmacy',
  label: 'Which Pharmacy do you use?',
  placeholder: 'e.g. Pharmacy name',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

const collectionMethod = new FormItemTextbox({
  key: 'collectionMethod',
  label: 'How do you collect your medicine?',
  placeholder: 'e.g. Delivered to my home',
  class: 'mb-5',
  validators: [Validators.minLength(1), Validators.maxLength(2000)],
});

export const medicationListOfMedications = new FormItemSubform({
  key: 'medicationListOfMedications',
  path: 'support.medication.listOfMedications',
  multiple: true,
  addTitle: 'Add Medication',
  displayComponent: DefaultShortDisplayComponent,
  display: 'modal',
  class: 'mb-5',
  placeholder: 'No medication added yet.',
  label: 'Medication List',
  saveOnChange: true,
  subform: [
    name,
    intakeTime,
    intakeFrequency,
    intakeMethod,
    storageLocation,
    pharmacy,
    collectionMethod,
  ],
});

export const prescriptionFeatureComingSoonTitle = new FormItemTitle({
  key: 'prescriptionFeatureComingSoonTitle',
  label: 'Prescriptions',
  class: 'mt-2',
  titleType: 'h4',
});

export const prescriptionFeatureComingSoonDescription = new FormItemTitle({
  key: 'prescriptionFeatureComingSoonDescription',
  label: 'Feature to add prescriptions coming soon...',
  class: 'mt-2 mb-5',
  titleType: 'p',
});
